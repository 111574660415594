import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DOMAIN,GET_EXPERIENCE,STORE_EXPERIENCE,DELETE_EXPERIENCE,UPDATE_EXPERIENCE } from '../../AppConfig';

import "react-datepicker/dist/react-datepicker.css";
import Loader from 'react-loader';

class ProfileExperience extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentlyyes: true,
            currentlyno: false,
            //basic Detail
            experienceList: [],
            designations: [],
            countries: [],
            states: [],
            country: "",
            selectedCountry: "",
            countryId: "",
            state: "",
            id:"",
            designation: "",
            startDate: "",
            endDate: "",
            organization: "",
            city:"",
            heading:"",
            startMaxDate:new Date(),
            deleteId:"",
            isLoad:true,
            isLoad1:true
        }
    }
    componentWillMount() {
        this.setState({isLoad:false})
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        axios.post(DOMAIN + GET_EXPERIENCE, { id: localStorage.getItem('id') },
            {
                headers: headers
            }
        )
            .then(response => {
                this.setState({isLoad:true})
                if (response.data.status === 200) {
                    this.setState({
                        designations: response.data.data.designation,
                        countries: response.data.data.country,
                        states: response.data.data.state,
                        experienceList:response.data.data.experience.data
                    });
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                }

            })
            .catch(function (error) {
                console.log(error);
            })
    }
    onChangeExp(e) {
        if (e.target.name === 'country') {
            this.setState({
                countryId: e.target.value,
                selectedCountry: this.state.countries[e.target.value].country,
                country: this.state.countries[e.target.value].id,
            })
        } else if (e.target.name === 'organization') {
            const orgregx =/^[^/<>%*?=^!~`:;|\/\\{}()]*$/;
            if (orgregx.test(e.target.value) || e.target.value === "") {

                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        }else if (e.target.name === 'city') {
            const cityregx =/^[A-Za-z]+$/;
            if (cityregx.test(e.target.value) || e.target.value === "") {

                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        } else{
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    }
    workingStatus(e) {
        if (e.target.name === "currentlyyes") {
            this.setState({
                currentlyyes: true,
                currentlyno: false,
                endDate:"",
                startMaxDate:new Date()
            })
        } else {
            this.setState({
                currentlyyes: false,
                currentlyno: true
            })
        }
    }
    setStartDate(date) {
        this.setState({
            startDate: date
        })
    }
    setEndDate(date) {
        this.setState({
            endDate: date,
            startMaxDate:date
        })
    }
    storeExperience() {
        var expinputdata = this.state;
        var fd = new Date(expinputdata.startDate).getDate();
        var fm = new Date(expinputdata.startDate).getMonth()+1;
        var fy = new Date(expinputdata.startDate).getFullYear();
        var end_date=undefined;
        if(expinputdata.currentlyyes!== true){
            var td = new Date(expinputdata.endDate).getDate();
            var tm = new Date(expinputdata.endDate).getMonth()+1;
            var ty = new Date(expinputdata.endDate).getFullYear();
            end_date = ty+"-"+tm+"-"+td;
        }
        var expdata = {
            jobseeker_id: localStorage.getItem('id'),
            country: expinputdata.country,
            city: expinputdata.city,
            title: expinputdata.designation,
            company_name: expinputdata.organization,
            from_date:fy+"-"+fm+"-"+fd,
            to_date: end_date,
            currently_working: expinputdata.currentlyyes,
            created_at: new Date(),
            updated_at: new Date()
        }
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        this.setState({isLoad1:false})
        axios.post(DOMAIN + STORE_EXPERIENCE, {
            expdata
        },
            {
                headers: headers
            }
        )
            .then(response => {
                this.setState({isLoad1:true})
                if (response.data.status === 200) {
                    document.getElementById('officialDisable').click();
                    this.setState({
                        experienceList: response.data.data,
                        designation:"",
                        startDate: "",
                        endDate: "",
                        organization: "",
                        currentlyyes: true,
                        currentlyno: false,
                        country: "",
                        selectedCountry: "",
                        countryId: "",
                        state: "",
                    })
                        toast.success("Experience Added Successfully", {
                        position: toast.POSITION.TOP_CENTER
                      });
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    deleteExperience(id){
        document.getElementById('confirmActive').click();
        this.setState({
            deleteId:id
        })
    }
    editExperience(key){
        var editData = this.state.experienceList[key];
        if(editData.currently_working===1){
            this.setState({
                currentlyyes: true,
                currentlyno: false,
            })
        }else{
            this.setState({
                currentlyyes: false,
                currentlyno: true,
                endDate: new Date(editData.to_date),
            })
        }
        this.setState({
            id:editData.id,
            designation:editData.title,
            startDate:new Date(editData.from_date),
            organization: editData.company_name,
            country: editData.country,
            city : editData.city,
            heading:"Update Experience"
        });
        (this.state.countries).forEach((value,key) => {
            if(editData.country===value.id){
                this.setState({
                    country:value.id,
                    selectedCountry:value.country,
                    countryId:key
                })
            }
        });
        document.getElementById('empActive').click();
    }
    updateExperience(){
        var expdata = this.state;
        var fd = new Date(expdata.startDate).getDate();
        var fm = new Date(expdata.startDate).getMonth()+1;
        var fy = new Date(expdata.startDate).getFullYear();

        var end_date=undefined;
        if(expdata.currentlyyes!== true){
            var td = new Date(expdata.endDate).getDate();
            var tm = new Date(expdata.endDate).getMonth()+1;
            var ty = new Date(expdata.endDate).getFullYear();
            end_date = ty+"-"+tm+"-"+td;
        }
        var expdata = {
            id:expdata.id,
            jobseeker_id: localStorage.getItem('id'),
            country: expdata.country,
            city: expdata.city,
            title: expdata.designation,
            company_name: expdata.organization,
            from_date:fy+"-"+fm+"-"+fd,
            to_date: end_date,
            currently_working: expdata.currentlyyes,
            updated_at: new Date()
        }

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        this.setState({isLoad1:false})
        axios.post(DOMAIN + UPDATE_EXPERIENCE, {
            expdata
        },
            {
                headers: headers
            }
        )
            .then(response => {
                this.setState({isLoad1:true})
                if (response.data.status === 200) {
                    document.getElementById('officialDisable').click();
                    this.setState({
                        experienceList: response.data.data,
                        designation:"",
                        startDate: "",
                        endDate: "",
                        organization: "",
                        currentlyyes: true,
                        currentlyno: false,
                        country: "",
                        selectedCountry: "",
                        countryId: "",
                        state: "",
                    })
                    toast.success("Experience Updated successfully", {
                        position: toast.POSITION.TOP_CENTER
                      });
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    emptyDetail(){
        this.setState({
        currentlyyes: true,
            currentlyno: false,
            designation: "",
            startDate: "",
            endDate: "",
            organization: "",
            city:"",
            country: "",
            selectedCountry: "",
            countryId: "",
            state: "",
            id:"",
            startMaxDate:new Date(),
            heading:"Add Experience"
        })
        document.getElementById('empActive').click();
    }
    confirmation_experience_yes(){
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        axios.post(DOMAIN + DELETE_EXPERIENCE, {
            id:this.state.deleteId,
            userId:localStorage.getItem('id')
        },
        {
            headers: headers
        }
        )
        .then(response => {
            if (response.data.status === 200) {
                this.setState({
                    experienceList: response.data.data
                })
                toast.success("Experience Deleted Successfully", {
                    position: toast.POSITION.TOP_CENTER
                });
            }else if(response.data.status === 401){
                localStorage.clear();
                this.props.history.push('/login');
            }
            document.getElementById('modal-dismiss-apply').click();
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    render() {
        var experienModel = {
            borderRadius: "0px",
            width: "61rem"
        }
        return (
            <React.Fragment>
                <div className="border rounded col-md-12 p-1">
    <Loader loaded={this.state.isLoad}/>
              <div className="row">
               <div className="col-lg-12">
                  <div className="show-results">
                     <div className="sort-button float-right pb-2 mt-4">
                  <a  className="btn btn-sm btn-alert" onClick={this.emptyDetail.bind(this)}> <i className="fas fa-plus"></i> Add Experience </a>
                        <a id="empActive" type="button" data-toggle="modal"  hidden data-target="#myModal"></a>

                     </div>
                  </div>
               </div>
            </div>
                {this.state.experienceList.length > 0 ?
                    <React.Fragment>
                        {this.state.experienceList.map((value, key) => (
                            <div  key={key}  className="col-lg-12">
                            <div  className="job-detail border rounded mt-4 p-3 bg-light">
                                  <div  className="row">
                                     <div  className="col-lg-12">
                                        <div  className="show-results">
                                           <div  className="sort-button float-right pb-2">
                                              <a  className="btn btn-sm btn-alert mr-1" onClick={()=>this.editExperience(key)}><i  className="fas fa-edit"></i></a>
                                              <a  className="btn btn-sm btn-trash" onClick={()=>this.deleteExperience(value.id)}><i  className="fas fa-trash-alt"></i></a>
                                           </div>
                                        </div>
                                     </div>
                                     <div  className="col-lg-3">
                                        <div  className="col-md-12">
                                           <div  className="form-group position-relative">
                                              <label  className="">Company Name</label>
                                              <p >{value.company_name} </p>
                                           </div>
                                        </div>
                                     </div>
                                     <div  className="col-lg-3">
                                        <div  className="col-md-12">
                                           <div  className="form-group position-relative">
                                              <label  className="">Role</label>
                                              <p >{value.title} </p>  </div>
                                        </div>
                                     </div>
                                     <div  className="col-lg-3">
                                        <div  className="col-md-12">
                                           <div  className="form-group position-relative">
                                              <label  className="">From Date</label>
                                              <p >{(value.from_date).substr(0, 10)}</p> </div>
                                        </div>
                                     </div>
                                     <div  className="col-lg-3">
                                        <div  className="col-md-12">
                                           <div  className="form-group position-relative">
                                              <label  className="">To Date</label>
                                           <p>{value.currently_working===0?(value.to_date).substr(0, 10):"PRESENTLY WORKING"}</p>
                                           </div>
                                        </div>
                                     </div>
                                  </div>
                            </div>
                         </div>
                        ))}
                    </React.Fragment>
                    : <div className="  password-sec  ">
                        <div className="col-sm-12 jobs pad-20 footer-bottom-set">
                            <div className="col-sm-12">
                                <i> Mention your employment details including your current and previous company work experience.</i>
                            </div>
                        </div>
                    </div>
                }
                <div  className="modal fade" id="myModal" role="dialog">
               <div  className="modal-dialog">

                 <div  className="modal-content model-border-st" id="addExp">
                 <div className="container-fluid jb-resume-container-fluid">
                 <div className="col-md-12 card innerPopup">
                   <div  className="modal-header">

                     <h4  className="modal-title">
                     {this.state.id===""? "Add Experience": "Update Experience"}</h4>
                     <button type="button" id="officialDisable"  className="close" data-dismiss="modal">&times;</button>
                   </div>
                   <div  className="modal-body">

    <Loader loaded={this.state.isLoad1}/>
                     <div  className="row">
                        <div  className="col-lg-12">
                           <div  className="job-detail rounded mt-2">
                                 <div  className="row">

                                      <div  className="col-lg-12">
                                       <div  className="col-md-12">
                                           <div  className="form-group position-relative">
                                               <label>Role <span  className="text-danger">*</span></label>
                                               <select className="form-control fs10-mob" name="designation" id="designation" value={this.state.designation} onChange={this.onChangeExp.bind(this)} title="Choose your Designation" placeholder="Choose your Designation" >
                                                    <option value="" disabled>Choose your Role</option>
                                                    {this.state.designations.map((value, key) => {
                                                        return <option key={key} value={value.value}>{value.value}</option>;
                                                    })}
                                                </select>
                                                </div>
                                       </div>
                                       <div  className="col-md-12">
                                           <div  className="form-group position-relative">
                                               <label>Organization <span  className="text-danger">*</span></label>
                                               <input type="text"  className="form-control" placeholder="Enter your organization" name="organization" maxLength={30} value={this.state.organization} onChange={this.onChangeExp.bind(this)} />
                                           </div>
                                       </div>
                                       <div  className="col-md-12">
                                          <div  className="form-group position-relative">
                                             <label>Country <span  className="text-danger">*</span></label>
                                             <select className="form-control fs10-mob" name="country" value={this.state.countryId} onChange={this.onChangeExp.bind(this)} title="Choose your country" placeholder="Choose your country">
                                                    <option value="" disabled>Choose your country</option>
                                                    {this.state.countries.map((value, key) => {
                                                        return <option key={key} value={key}>{value.country}</option>;
                                                    })}
                                                </select>
                                          </div>
                                      </div>
                                      <div  className="col-md-12">
                                       <div  className="form-group position-relative">
                                           <label>City <span  className="text-danger">*</span></label>
                                           <input type="text"  className="form-control" placeholder="Enter your city" name="city" maxLength={25} value={this.state.city} onChange={this.onChangeExp.bind(this)}/>
                                       </div>
                                   </div>
                                   <div  className="col-md-12">
                                    <div  className="form-group position-relative">
                                       <div  className="col-md-6 col-lg-6 pl-0">
                                        <label>Currently Working <span  className="text-danger">*</span></label>
                                       </div>
                                       <div  className="col-md-6 col-lg-6">
                                        <div  className="card-body p-0  flex">
                                          <div  className="custom-control custom-radio radio-toolbar">
                                             <input id="Radio5" name="currentlyyes" type="radio" onChange={this.workingStatus.bind(this)} checked={this.state.currentlyyes} />
                                              <label htmlFor="Radio5">Yes</label>
                                          </div>
                                          <div  className="custom-control custom-radio radio-toolbar">
                                             <input id="Radio4" name="currentlyno" type="radio" onChange={this.workingStatus.bind(this)} checked={this.state.currentlyno}/>
                                           <label htmlFor="Radio4">No</label>
                                          </div>

                                       </div>
                                    </div>
                                    </div>
                                </div>
                                   <div  className="col-md-12">
                                    <div  className="form-group position-relative">
                                        <label className="mr-2">Start Date <span  className="text-danger">*</span></label>
                                        <DatePicker
                                                    className="form-control fs10-mob"
                                                    name="startDate"
                                                    selected={this.state.startDate}
                                                    onChange={this.setStartDate.bind(this)}
                                                    maxDate={this.state.startMaxDate}
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dateFormat="yyyy-MM-dd"
                                                    dropdownMode="select"
                                                />  </div>
                                </div>
                                <div  className="col-md-12">
                                    <div  className="form-group position-relative">
                                     {this.state.currentlyyes === false ?
                                     <>
                                        <label className="mr-3">End Date <span  className="text-danger">*</span></label>
                                        <DatePicker
                                                className="form-control fs10-mob"
                                                name="endDate"
                                                selected={this.state.endDate}
                                                minDate={this.state.startDate}
                                                maxDate={new Date()}
                                                onChange={this.setEndDate.bind(this)}
                                                peekNextMonth
                                                showMonthDropdown
                                                dateFormat="yyyy-MM-dd"
                                                showYearDropdown
                                                dropdownMode="select" />
                                                </>:""}
                                    </div>
                                </div>

                                       <div  className="col-md-12">
                                       {this.state.id===""?
                                          <button  className="btn btn-primary w-100  text-nowrap" title="All fields are mandatory" onClick={this.storeExperience.bind(this)} disabled={this.state.organization === "" || this.state.startDate === "" || this.state.designation === "" || this.state.country === "" || this.state.city === "" || (this.state.currentlyyes === false && this.state.endDate === "")}>Add Experience</button>:
                                          <button  className="btn btn-primary w-100  text-nowrap" style={{width: "100% !important"}} title="All fields are mandatory" onClick={this.updateExperience.bind(this)} disabled={this.state.organization === "" || this.state.startDate === "" || this.state.designation === "" || this.state.country === "" || this.state.city === "" || (this.state.currentlyyes === false && this.state.endDate === "")}>Update Experience</button>}
                                         <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                                          </div>
                                      </div>

                                 </div>
                           </div>
                        </div>
                     </div>
                   </div>

                 </div>
                 </div>
</div>
               </div>
             </div>
                <style>{"\
                    .react-datepicker {\
                        font-size: 10.8px;\
                    }\
                "}</style>
                <a data-toggle="modal" data-target="#confirmModel" id="confirmActive" hidden></a>
                    <div className="modal fade" id="confirmModel" data-backdrop="static" role="dialog" style={{ display: "none" }}>
                    <div className="modal-dialog">
                        <div className="container-fluid">
                            <div className=" col-md-offset-1">
                                <div className="modal-content model-border-st" id="expDelete">
                                    {/* <a data-dismiss="modal" id="modal-dismiss-apply" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i></a> */}
                                    <div className="form form-mob">
                                        <div className="container-fluid jb-resume-container-fluid">
                                            <div className="col-md-12 card innerPopup">
                                            <div  className="modal-header">

                                                <h4  className="modal-title">Are you sure! you want to delete?</h4>
                                                <button type="button" id="modal-dismiss-apply"  className="close" data-dismiss="modal">&times;</button>
                                                </div>
                                                <div  className="modal-body">
                                                <div className="form-group mob-popup-btn d-flex">

                                                    <button className="btn btn-block sign-in-button mr-1" onClick={() => this.confirmation_experience_yes(1)}>Yes</button>
                                                    <button className="btn btn-block sign-in-button mt-0" data-dismiss="modal">No</button>
                                                </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </React.Fragment>
        );

    }

}

export default ProfileExperience;
