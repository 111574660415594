import React, { Component } from "react";
import axios from 'axios'
import {DOMAIN,FORGOT_PASSWORD,LOGIN,REGISTER_JOBSEEKERS,LINKED_IN,ACTIVATE_ACCOUNT,ACTIVATE,FB_APP_ID,GOOGLE_CLIENT_ID,LINKEDIN_CLIENT_ID} from '../../AppConfig'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import FacebookLogin from 'react-facebook-login';
import { Link } from 'react-router-dom';
import GoogleLogin from 'react-google-login';
import cookie from 'react-cookies';
import { LinkedIn } from '../../Linkedin';
import Loader from 'react-loader';
class Login extends Component {

   constructor(props) {
      super(props);
      this.state={
         isLoad: true,
         email:"",
         password:"",
         EmailError:"",
         PasswordError:"",
         forgetemail:"",
         forgetEmailError:"",
         color:"",
         activate_btn:false,
         EmailSucess:"",
         remember:false,
         code:""

      }
      this.login = this.login.bind(this);
      this.sendMail=this.sendMail.bind(this);
      this.getLogin=this.getLogin.bind(this);
      this.activateAccount=this.activateAccount.bind(this);
      this.login_social=this.login_social.bind(this);
      this.closePopup=this.closePopup.bind(this);
      this.linkedingetUser=this.linkedingetUser.bind(this);
   }
   closePopup(){
      document.getElementById('act-dismiss').click();
      document.getElementById('reset-dismiss').click();
   }
   componentDidMount(){
      if(localStorage.getItem('signup_war')==1){ //==
         toast.error("Verification sent to your registered email Id. Please verify your email and action !!!", {
             position: toast.POSITION.TOP_CENTER
          });
          localStorage.removeItem('signup_war');
      }
      if(localStorage.getItem('forgot')){
         document.getElementById('forgot-password').click();
         localStorage.removeItem('forgot');
      }
   }
  componentWillMount(){
   window.scrollTo(0, 0);
   var url=window.location.href;
   if(localStorage.getItem('id')){
      this.props.history.push('/joblisting');
  }
  else{
     const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
   }
   if(!(window.location.href).includes("email_token")){

   url=url.split('=');
   if(url.length===2){
      axios.post(DOMAIN + ACTIVATE,{
         token:url[1],
         },
             {
                 headers: headers
             }
         )
         .then(response => {
             if(response.data.status===200){//type checked
             }

         })
         .catch(function (error) {
             console.log(error);
         })
   }
}
  }
  if(cookie.load('_remembme')){
     if(cookie.load('_remembme')=='true'){
      this.setState({
         remember:cookie.load('_remembme'),
         email:cookie.load('email'),
         password:cookie.load('password')
      })
   }

  }
  if((window.location.href).includes("email_token")){
   // var url=window.location.href;
   url=url.split('=');
   if(url[1]){
   const header = {
       "Content-Type": "application/json",
       "Accept": "application/json",
   }
   axios.post(DOMAIN + "/emailconfirmation",
   {
       email_token: url[1]
   }, {
       headers: header
   }
   )
   .then(response => {
       if(response.data.status === 200) {
           toast.success("Email Verification successfully completed !",{
               position: toast.POSITION.TOP_CENTER
           });
       }else{
           toast.error("Email Verification token expired. Please verify your email again!",{
               position: toast.POSITION.TOP_CENTER
           });
       }
   })
   .catch(function (error) {
       console.log(error);
   })
}
}
  }
  getLogin(email,password){
     this.setState({isLoad:false})
   const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
   }
   axios.post(DOMAIN + LOGIN,{
      data:{email:email,password:password,org_visible_status:null}
   },
      {
         headers: headers
      }
   )
   .then(response => {
      this.setState({isLoad:true})
      if(response.data.status === 200) {
         if(response.data.data.email_confirm===1){
         cookie.save('email', email);
         cookie.save('password', password);
         cookie.save('_remembme', this.state.remember);
         localStorage.setItem("token",response.data.api_token);
         localStorage.setItem("firstname",response.data.data.firstname);
         localStorage.setItem("lastname",response.data.data.lastname);
         localStorage.setItem('email',response.data.data.email);
         localStorage.setItem('id',response.data.data.id);
         localStorage.setItem('is_confirm',response.data.data.email_confirm);
         // this.props.history.push('/joblisting');
         window.location.href = "./joblisting";
         }else{
            toast.error("The account is pending Activation. Please check your email to activate your account !", {
               position: toast.POSITION.TOP_CENTER
            });
         }
      }else if(response.data.status === 201) {
         document.getElementById('loginActive').click();
      }
      else{
         if(response.data.error_code===1){
            this.setState({
               EmailError:response.data.msg
            })
         }else{
            this.setState({
               PasswordError:response.data.msg
            })
         }
      }
   })
   .catch(function (error) {
      console.log(error);
   })
  }

   sendMail(){
      const headers = {
         "Content-Type": "application/json",
         "Accept": "application/json"
      }
      document.getElementById("fade").style.opacity = "0.5";
      document.getElementById("fade").style['pointer-events'] = 'none';
      this.setState({isLoad:false});
      axios.post(DOMAIN + FORGOT_PASSWORD,{
         email:this.state.forgetemail,
      },
         {
            headers: headers
         }
      )
      .then(response => {
         document.getElementById("fade").style.opacity = "unset";
         document.getElementById("fade").style['pointer-events'] = '';
         this.setState({isLoad:true});
         if(response.data.status===200)
         {
           this.setState({EmailSucess:response.data.message,forgetEmailError:"",color:"green"});
         }
         else if(response.data.status===401){
            toast.error(response.data.message, {
               position: toast.POSITION.TOP_CENTER
            });
         }
         else{
            this.setState({forgetEmailError:response.data.message,EmailSucess:"",color:"red"})
         }

      })
      .catch(function (error) {
         console.log(error);
      })

   }
   forgotTab(){
      this.setState({
         forgetemail:"",
         forgetEmailError:"",
         EmailSucess:""
      })
   }
   onChange(e){

      if(e.target.name==='email'){
         const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
         if (emailregex.test(e.target.value)) {
           this.setState({
            EmailError: "",
            EmailSucess:""
           })
         } else {
           this.setState({
            EmailError: "Please enter a valid  email id !",
            EmailSucess:""
           })
         }
      }
      if(e.target.name==='forgetemail'){
         const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
         if (emailregex.test(e.target.value)) {
           this.setState({
            EmailError: "",
            EmailSucess:""
           })
         } else {
           this.setState({
            EmailError: "Please enter a valid  email id !",
            EmailSucess:""
           })
         }
      }
      if(e.target.name==='password'){
         this.setState({
            PasswordError:""
         })
      }

      this.setState({
         [e.target.name]:e.target.value
         })
         if(e.target.name==='remember'){
            if(this.state.remember){
               this.setState({
                  remember:false
               })
            }else{
               this.setState({
                  remember:true
               })
            }
         }
    }

    login(){
      // if((window.location.href).includes("/token=")){
      // const headers = {
      //    "Content-Type": "application/json",
      //    "Accept": "application/json"
      // }
      // var url=window.location.href;
      // url=url.split('=');
      // if(url.length==2){
      //    axios.post(DOMAIN + ACTIVATE,{
      //       email:this.state.email,
      //       password:this.state.password,
      //       token:url[1],
      //       },
      //           {
      //               headers: headers
      //           }
      //       )
      //       .then(response => {
      //           if(response.data.status==200){
      //             this.getLogin(this.state.email,this.state.password);
      //           }

      //       })
      //       .catch(function (error) {
      //           console.log(error);
      //       })

      // }else{
      //    this.getLogin(this.state.email,this.state.password);
      // }
      if(this.state.email!==""&&this.state.password!=="")
      this.getLogin(this.state.email,this.state.password);


    }
    activateAccount(flag){
      this.setState({activate_btn:true});
       if(flag===1)
       {
      const headers = {
         "Content-Type": "application/json",
         "Accept": "application/json"
      }
      document.getElementById("fade").style.opacity = "0.5";
      document.getElementById("fade").style['pointer-events'] = 'none';
      this.setState({isLoad:false});
      axios.post(DOMAIN + ACTIVATE_ACCOUNT,
         {email:this.state.email},
         {
            headers: headers
         }
      )
      .then(response => {
         document.getElementById("fade").style.opacity = "unset";
         document.getElementById("fade").style['pointer-events'] = '';
         this.setState({activate_btn:false,isLoad:true});
         if(response.data.status===200)
         {
            document.getElementById('modal-dismiss-login').click();
            // document.getElementById('ss').click();
            this.setState({EmailError:response.data.message});
         }
         else {
            document.getElementById('modal-dismiss-login').click();
            this.setState({EmailError:response.data.message});
         }
      })
      .catch(function (error) {
         console.log(error);
      })
   }else{
      document.getElementById('modal-dismiss-login').click();
   }
    }

   handleSuccess = (data) => {
      this.setState({
        code: data.code,
        errorMessage: '',
      });
      if(this.state.code!==""){
         this.linkedingetUser();
      }
    }

    handleFailure = (error) => {
      this.setState({
        code: '',
        errorMessage: error.errorMessage,
      });
      if(this.state.code!==""){
         this.linkedingetUser();
      }
   }
    login_social(fname,lname,email){
      const headers = {
         "Content-Type": "application/json",
         "Accept": "application/json"
      }
      var RegisterData={
                  firstname:fname,
                  lastname: lname,
                  email:email,
                  org_visible_status:null,
                  flag:1
      }
      document.getElementById("fade").style.opacity = "0.5";
      document.getElementById("fade").style['pointer-events'] = 'none';
      this.setState({isLoad:false});
      axios.post(DOMAIN + REGISTER_JOBSEEKERS,{
         data:RegisterData
      },
         {
            headers: headers
         }
      )
      .then(response => {
         this.setState({isLoad:true})
         if(response.data.status === 200) {
            document.getElementById("fade").style.opacity = "unset";
            document.getElementById("fade").style['pointer-events'] = '';

            // if(response.data.is_confirm!=0){
               localStorage.setItem("token",response.data.api_token);
               if(response.data.first_name){
                  localStorage.setItem("firstname",response.data.first_name);
                  localStorage.setItem("lastname",response.data.last_name);
               }else{
                  localStorage.setItem("firstname",fname);
                  localStorage.setItem("lastname",lname);
               }
               localStorage.setItem('email',email);
               localStorage.setItem('id',response.data.user_id);
               this.props.history.push('/joblisting');
            // }else{
            //    toast.error("Please verify your email confirmation", {
            //       position: toast.POSITION.TOP_CENTER
            //    });
            // }
         }else if(response.data.status === 201){
            this.setState({email: email});
            document.getElementById('loginActive').click()
            if(response.data.error_code===1){
               this.setState({
                  emailValidMsg:response.data.msg
               })
            }
         }
         else{
            toast.error("Database authentication failed",{
               position: toast.POSITION.TOP_CENTER
           });

         }
      })
      .catch(function (error) {
         console.log(error);
      })

    }
    linkedingetUser(){
      const headers = {
         "Content-Type": "application/json",
         "Accept": "application/json"
      }
      if(this.state.code!==""){
         if(document.getElementById("fade")){
         document.getElementById("fade").style.opacity = "0.5";
         document.getElementById("fade").style['pointer-events'] = 'none';
         }
         this.setState({isLoad:false});
      axios.post(DOMAIN + LINKED_IN,{
         code:this.state.code
      },
         {
            headers: headers
         }
      )
      .then(response => {
         // console.log(response.data);
         var fname=response.data.user_data[0].firstname;
         var lname=response.data.user_data[0].lastname;
         var email=response.data.user_data[0].email["handle~"].emailAddress;

        this.setState({
         code:"",
      },this.login_social(fname,lname,email));

      })
      .catch(function (error) {
         console.log(error);
      })
   }
    }
   render() {
      if(this.props.location.state!==undefined){
         if(this.props.location.state.Footer!==undefined)
         {
         window.scrollTo(0, 0);
         this.props.history.push('/login');
         }
     }
      const responseFacebook = (response) => {
         if(response.status!=="unknown")
         {
            var name=response.name.split(" ");
            this.login_social(name[0],name[1],response.email);
         }else{
            toast.error("Do you have faced any issue please contact admin to access social login module",{
               position: toast.POSITION.TOP_CENTER
           });
         }

       }
      //  const code = this.state.code;
      //  if(code!="")
      //  {
      //    this.linkedingetUser();
      //  }
       const responseGoogle = (response) => {
          console.log(response);
         if(response.error!=="popup_closed_by_user")
         {
            var name=response.profileObj.name.split(" ");
            this.login_social(name[0],name[1],response.profileObj.email);
         }else{
            toast.error("Do you have faced any issue please contact admin to access social login module",{
               position: toast.POSITION.TOP_CENTER
           });
         }

       }
      var basicCloseStyle = {
         position: "relative",
         left: "41rem",
         top: "14px",
         zIndex: "10"
       }

      return (
        <React.Fragment>
        <div onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.login()
                }
              }}>
        <ToastContainer />
            <>
            <section  className="vh-100-desk signin">
            <Loader loaded={this.state.isLoad}/>
            <div  className="home-center">
                <div  className="home-desc-center">
                    <div  className="container">
                        <div  className="row justify-content-center mr-top">
                            <div  className="col-lg-4 col-md-8 mb-4">
                                <div  className="login-page bg-white shadow rounded p-4">
                                    <div  className="text-center">
                                        <h4  className="mb-4">Login</h4>
                                    </div>
                                        <div  className="row">
                                            <div  className="col-lg-12">
                                                <div  className="form-group form-space position-relative">
                                                    <label>Email <span  className="text-danger">*</span></label>
                                                    <input type="email" maxLength={50} className="form-control" placeholder="Enter your email address" name="email" onChange={this.onChange.bind(this)} value={this.state.email} />
                                                    {this.state.EmailError!==""? <span style={{color:"red"}}>{this.state.EmailError}</span>:""}
                                                </div>
                                            </div>

                                            <div  className="col-lg-12">
                                                <div  className="form-group form-space position-relative">
                                                    <label>Password <span  className="text-danger">*</span></label>
                                                    <input type="password" maxLength={20} className="form-control" placeholder="Enter your Password" autoComplete="nope" name="password" onChange={this.onChange.bind(this)} value={this.state.password}/>
                                                    {this.state.PasswordError!==""? <span style={{color:"red"}}>{this.state.PasswordError}</span>:""}
                                                </div>
                                            </div>

                                            <div  className="col-lg-12">
                                                <p  className="float-right forgot-pass"><a href="/#" data-toggle="modal" id="forgot-password" data-target="#myModal" onClick={this.forgotTab.bind(this)}>Forgot password ?</a></p>
                                                <div  className="form-group form-space">
                                                    <div  className="custom-control m-0 custom-checkbox">
                                                    <input type="checkbox"  className="custom-control-input" name="remember" checked={this.state.remember}  onChange={this.onChange.bind(this)} id="customCheck1"/>
                                                        <label  className="custom-control-label" htmlFor="customCheck1">Remember me</label> </div>
                                                </div>
                                            </div>
                                            <div  className="col-lg-12 mb-0">
                                                <button   className="btn btn-primary w-100" onClick={this.login} disabled={this.state.email==="" || this.state.password===""}>Sign in</button>
                                                <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                                            </div>
                                            {/* <div  className="col-lg-12 mt-4 text-center">
                                                <h6>Or Login With</h6>
                                                <ul  className="list-unstyled social-icon mb-0 mt-3">
                                                    <li  className="list-inline-item">
                                                            <FacebookLogin
                                                                  appId={FB_APP_ID}
                                                                  fields="name,email,picture"
                                                                  callback={responseFacebook}
                                                                  render={renderProps => (
                                                                     <a  className="rounded" onClick={renderProps.onClick}>
                                                                     <i  className="mdi mdi-facebook" title="Facebook"></i></a>
                                                                  )}
                                                                  />
                                                      </li>
                                                    <li  className="list-inline-item">
                                                            <GoogleLogin
                                                               clientId={GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                                               buttonText="Login"
                                                               onSuccess={responseGoogle}
                                                               onFailure={responseGoogle}
                                                               cookiePolicy={'single_host_origin'}
                                                               render={renderProps => (
                                                                  <a className="rounded"  onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                                     <i className="mdi mdi-gmail" title="Gmail"></i>
                                                                  </a>
                                                               )}
                                                            />
                                                          </li>
                                                    <li  className="list-inline-item">
                                                            <LinkedIn
                                                      clientId={LINKEDIN_CLIENT_ID}
                                                      redirectUri={`${window.location.origin}/linkedin`}
                                                      scope = {["r_liteprofile","r_emailaddress","w_member_social"]}
                                                      state="ba015d"
                                                      onFailure={this.handleFailure}
                                                      onSuccess={this.handleSuccess}
                                                      supportIE
                                                      redirectPath='/linkedin'
                                                      renderElement={({ onClick, disabled }) => (
                                                         <a  onClick={onClick} disabled={disabled}
                                                         ><i className="mdi mdi-linkedin" title="LinkedIn"></i>
                                                         </a>
                                              )}/>
                                                      </li>
                                                </ul>
                                            </div> */}
                                            <div  className="col-12 text-center">
                                            <p  className="forget-password" style={{marginTop:"19px"}}><a href="/#" data-toggle="modal" data-target="#myModal1"> Activate Your Account?</a></p>

                                                <p  className="mb-0 mt-3"><small  className="text-dark mr-2">Don't have an account ?</small> <Link to="/signup"  className="font-weight-bold">Sign Up</Link></p>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
                          </>
         {/* Forgot popup */}
         <div  className="modal fade" id="myModal" role="dialog" style={{display:"none"}}>
         <div  className="modal-dialog">
            <div  className="container-fluid">
            <Loader loaded={this.state.isLoad}/>
               <div  className="col-md-10 col-md-offset-1" id="fade">
                  <div  className="modal-content model-border-st" id="forgotPopup">
                  {/* <a data-dismiss="modal" id="reset-dismiss"  href="#/" style={basicCloseStyle}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                     <div  className="form form-mob">
                        <div  className="container-fluid jb-resume-container-fluid">
                           <div  className="col-md-12 card innerPopup">
                              {/* <div  className="head">
                                 <h4>Reset Password</h4>
                              </div> */}
                              <div  className="modal-header">

                                 <h4  className="modal-title">Forgot Password</h4>
                                 <button type="button" id="reset-dismiss"  className="close" data-dismiss="modal">&times;</button>
                                 </div>
                                 <div  className="modal-body">
                                    <div  className="form-group">
                                       <label>Email <span className="text-danger">*</span></label>
                                       <input type="email"  maxLength={50} name="forgetemail" onChange={this.onChange.bind(this)} value={this.state.forgetemail} className="form-control fs10-mob" placeholder="Enter your email address"/>
                                       <span style={{color:"red"}}>{this.state.forgetEmailError}</span>
                                       <span style={{color:"green"}}>{this.state.EmailSucess}</span>
                                    </div>
                                    <button  className="btn btn-block sign-in-button fs12-mob" onClick={this.sendMail} disabled={this.state.forgetemail==="" || this.state.EmailError!==""}>SEND </button>

                                    <p  className="message" style={{textAlign:"center"}}>Remeber Password?
                                    <Link to='/login' ><span onClick={this.closePopup}> Sign In</span>  </Link></p>
                                 </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>


      <div   className="modal fade" id="myModal1" role="dialog" style={{display:"none"}}>
         <div  className="modal-dialog">
            <div  className="container-fluid">
            <Loader loaded={this.state.isLoad}/>
               <div  className="col-md-10 col-md-offset-1" id="fade">
                  <div  className="modal-content model-border-st" id="activatePopup2">
                  {/* <a data-dismiss="modal" id="act-dismiss"  href="#/"  style={basicCloseStyle}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                     <div  className="form form-mob">
                        <div  className="container-fluid jb-resume-container-fluid">
                           <div  className="col-md-12 card innerPopup">
                              {/* <div  className="head">
                                 <h4>Activate Account</h4>
                              </div> */}
                               <div  className="modal-header">

                                 <h4  className="modal-title">Activate Account</h4>
                                 <button type="button" id="act-dismiss"  className="close" data-dismiss="modal">&times;</button>
                                 </div>
                                 <div  className="modal-body">
                                 <div  className="form-group">
                                    <label>Email  <span  className="text-danger">*</span> </label>
                                    <input type="email"  name="email" maxLength={50} onChange={this.onChange.bind(this)} value={this.state.email} className="form-control fs10-mob" placeholder="Enter your email address"/>
                                    {this.state.EmailError!=""? <span style={{color:"red"}}>{this.state.EmailError}</span>:null}
                                 </div>
                                 <button  className="btn btn-block sign-in-button fs12-mob" disabled={this.state.activate_btn}  onClick={() => this.activateAccount(1)}>SEND </button>



                           <div  className="form-group">
                                 <p  className="message" style={{textAlign:"center"}}>Do you want to signup?
                                 <Link to='/signup' ><span onClick={this.closePopup}> Sign Up</span>  </Link></p>
                           </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      </div>

                    <a data-toggle="modal" data-target="#loginModel1"  href="#/" id="loginActive" hidden> </a>
                    <div  className="modal fade" id="loginModel1" role="dialog" style={{display:"none"}}>
                        <div  className="modal-dialog">
                            <div  className="container-fluid">
                            <div  className="col-md-offset-1">
                                <div  className="modal-content model-border-st" id="activatePopup">
                                {/* <a data-dismiss="modal" id="modal-dismiss-login"  href="#/" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                                    <div  className="form form-mob">
                                        <div  className="container-fluid jb-resume-container-fluid">
                                        <div  className="col-md-12 card innerPopup">
                                            {/* <div  className="head">
                                                <h4>Your Account is deactivated</h4>
                                            </div> */}
                                             <div  className="modal-header">

                                             <h4  className="modal-title">Your Account is deactivated</h4>
                                             <button type="button" id="modal-dismiss-login"  className="close" data-dismiss="modal">&times;</button>
                                             </div>
                                             <div  className="modal-body">
                                                <div className="form-group d-flex">

                                                <button className="btn btn-block sign-in-button mr-1" disabled={this.state.activate_btn} onClick={() => this.activateAccount(1)} >Activate</button><span>&nbsp;&nbsp;</span>&nbsp;&nbsp;
                                                <button className="btn btn-block sign-in-button mt-0" onClick={() => this.activateAccount(2)} >Cancel</button>
                                                </div>
                                                </div>
                                            </div>

                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                        </div>
      </React.Fragment>
      );
   }

}

export default Login;