import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DOMAIN, GET_JOB_LIST,GOOGLE_MAP_API_KEY,GET_SEEKERS_DATA, APPLY_JOB, GET_JOB_LIST_PAGE,LINKED_IN, LOGIN,SORT_ALERT, REGISTER_JOBSEEKERS, ACTIVATE_ACCOUNT, FB_APP_ID, GOOGLE_CLIENT_ID,LINKEDIN_CLIENT_ID,UPLOAD_MY_RESUME,CHECK_RESUME } from '../../AppConfig';
import Pagination from "react-js-pagination";
import { PAGINATION_COUNT } from '../../AppConfig';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import GoogleMap from './GoogleMap'
import ReactTags from 'react-tag-autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { LinkedIn } from '../../Linkedin';
import Popup from "reactjs-popup";
import Loader from 'react-loader';
import ZipcodeValidate from '../../Validation/ZipcodeValidate'
// import  FBLogin from '../Login/FBLogin.js';
// import './react_tag.css';
const KeyCodes = {
    enter: 13
  };

const delimiters = [KeyCodes.enter];
class Joblisting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            joblist: [],
            zip_city: "",
            activePage: 1,
            itemsCountPerPage: 1,
            totalItemsCount: 1,
            pageRangeDisplayed: 3,
            job_org: "",
            job_id: "",
            date: false,
            relevance: false,
            distance: true,
            relevances: 0,
            radius_value: 0,
            latitude: '',
            longitude: '',
            pay_rate: { min: 0, max: 500 },
            experience: { min: 0, max: 50 },
            radius_values:0,
            job_position: [],
            position: "",
            dates: 0,
            distances: 1,
            countryId: "",
            state: "",
            countries: [],
            states: [],
            country: "",
            isDisabled: true,
            pay_rates: { min: 0, max: 0 },
            experiences: { min: 0, max: 0 },
            // radius_value:{min:0,max:0},
            relevance_disable: true,
            map_redirect: false,
            skills: [],
            suggestions: [],
            // skills_designation:[]
            alertname: "",
            position_type: "",
            clear: false,
            email: "",
            password: "",
            EmailError: "",
            PasswordError: "",
            distances_status: true,
            // PERSONAL DETALS
            countries_reg: [],
            states_reg: [],
            country_reg: "",
            selectedCountry: "",
            countryId_reg: "",
            state_reg: "",
            firstname: "",
            lastname: "",
            email_reg: "",
            phone_number: "",
            address: "",
            city: "",
            zipcode: "",
            password_reg: "",
            confirmpassword: "",
            terms: false,
            passwordStatus: false,
            emailValidMsg: "",
            apply_id: "",
            apply_org: "",
            activate_btn: false,
            visible: null,
            visible_check: false,
            visibleStatus:false,
            visible_reg:false,
            code:"",
            linkedin_signin_signup:"",
            prop:0,
            isLoad: true,
            isLoad1: true,
            uploadActive:false,
            resume_upload:true,
            check_resume_login:0,
            upload_heading:"",
            invalid_file:"",
            error_signup:"",
            org_status:0,
            file_label:false,
            phone_number_issue:"",
            alert_disable:false,
            zipcode_error:"",
            freeText:"",
            city_error: ''
        }
        this.linkedingetUser=this.linkedingetUser.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.applyJob = this.applyJob.bind(this);
        this.getRadius = this.getRadius.bind(this);
        this.getMyLocation = this.getMyLocation.bind(this);
        this.onChangeHandle = this.onChangeHandle.bind(this);
        this.getPayrate = this.getPayrate.bind(this);
        this.getExperience=this.getExperience.bind(this);
        this.getMap = this.getMap.bind(this);
        this.getList = this.getList.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.signUp = this.signUp.bind(this);
        this.registerJobseekers = this.registerJobseekers.bind(this);
        this.confirm = this.confirm.bind(this);
        this.confirmation_yes = this.confirmation_yes.bind(this);
        this.activateAccount = this.activateAccount.bind(this);
        this.login_social = this.login_social.bind(this);
        this.getJoblist=this.getJoblist.bind(this);
        this.zipcode_validation = this.zipcode_validation.bind(this);
        this.getRelavenceBasedData = this.getRelavenceBasedData.bind(this);
        this.continueJobList = this.continueJobList.bind(this);
        // this.handleInputChange=this.handleInputChange.bind(this);
        // this.check=this.check.bind(this);
    }
    loginPopup(){
        document.getElementById('basicDisable').click();
        document.getElementById('loginActive').click();
      }
    resumeUpload(e){
        document.querySelector("#file-name").textContent ="";
   this.setState({file_label:false,resumeName:"",uploadActive:false});
        if(e.target.files[0]!==undefined)
      {
         var ext = e.target.files[0].name.split('.').pop();
         if(ext==="pdf" || ext==="docx" || ext==="doc"){
            document.querySelector("#file-name").textContent =e.target.files[0].name;
            this.setState({
               uploadActive:true,
               resumeName:e.target.files[0].name,
               invalid_file:"",
               error_signup:"",
               file_label:true
           });
        } else{
            document.querySelector("#file-name").textContent="";
         this.setState({invalid_file:"File Format should be .pdf or .docx or .doc",
         error_signup:"Please check your form carefully",uploadActive:false});
        }
   }
    }
    myResumeUpload(flag){
       this.setState({resume_upload:false});
       var jobseeker_id="";
       if(flag===1)
       {
            jobseeker_id=this.state.user_Id;
           document.getElementById('closeModel').click();
       }else if(flag===2 && this.state.check_resume_login===1){
         jobseeker_id=localStorage.getItem("id");
       }
       else if(flag===2 && this.state.check_resume_login===0){
        document.getElementById('closeModel').click();
      }
       if(flag===1 || (flag===2 && this.state.check_resume_login===1)){
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
       var formData = new FormData();
       formData.append('file', document.getElementById('resumeUpload').files[0]);
       formData.append('userId',jobseeker_id);
        axios.post(DOMAIN + UPLOAD_MY_RESUME, formData,{
            headers: headers
        })
        .then(response => {
            document.getElementById('closeModel').click();
        this.setState({isLoad1:true});
            if (response.data.status === 200) {
            document.getElementById('basicDisable').click();
            if(flag===1){
            toast.error("Verification sent to your registered email Id. Please verify your email and action ! ", {
                position: toast.POSITION.TOP_CENTER
            });
    }else{
        this.applyJob(this.state.apply_id, this.state.apply_org);
    }

    }else if(response.data.status === 500){
       localStorage.clear();

    }
    });
       }
    }
    handleSuccess = (data) => {
        this.setState({
          code: data.code,
          linkedin_signin_signup:1,
          errorMessage: '',
        });
        if(this.state.code!==""){
            this.linkedingetUser();
        }
      }

      handleFailure = (error) => {
        this.setState({
          code: '',
          errorMessage: error.errorMessage,
        });
        if(this.state.code!==""){
            this.linkedingetUser();
        }
     }
     handleSuccessSignup = (data) => {
        this.setState({
          code: data.code,
          linkedin_signin_signup:2,
          errorMessage: '',
        });
        if(this.state.code!==""){
            this.linkedingetUser();
        }
      }

      handleFailureSignup = (error) => {
        this.setState({
          code: '',
          errorMessage: error.errorMessage,
        });
        if(this.state.code!==""){
            this.linkedingetUser();
        }
     }
    check(data) {
        if (data === 2) {
            if (localStorage.getItem("id")) {
                this.setState({
                    relevance: true,
                    relevances: 1,
                    dates: 0,
                    date:false,
                    distance:false,
                    distances: 0
                },()=> this.handlePageChange(1));
                // this.handlePageChange(this.state.activePage);
            }
            else {
                // this.setState({
                //     relevance: true,
                //     relevances: 1,
                //     dates: 0,
                //     date:false,
                //     distance:false,
                //     distances: 0});
                toast.error("Please login to get relevant results !", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } else if (data === 3) {
            if (this.state.distances_status === true) {

                    this.setState({
                        distance: true,
                        distances: 1,
                        dates: 0,
                        date:false,
                        relevance:false,
                        relevances: 0
                    },()=> this.handlePageChange(1));
            }
                else {
                    // this.setState({
                    //     distance: true,
                    //     distances: 1,
                    //     dates: 0,
                    //     date:false,
                    //     relevance:false,
                    //     relevances: 0});
                    toast.error("Please remove url block list from browser to access distance wise sorting", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }

        }
        else {
            this.setState({
                date: true,
                dates: 1,
                distances: 0,
                relevances: 0,
                distance:false,
                relevance:false
            },()=> this.handlePageChange(1));
            // this.handlePageChange(this.state.activePage);
        }
    }
    ChangeVisbleStatus() {
        // console.log(this.state.visible);
        if (this.state.visible) {
            this.setState({
                visible: false,
            })
        } else {
            this.setState({
                visible: true
            })
        }
    }
    activateAccount(flag) {
        this.setState({ activate_btn: true });
        if (flag === 1) {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
            axios.post(DOMAIN + ACTIVATE_ACCOUNT,
                { email: this.state.email },
                {
                    headers: headers
                }
            )
                .then(response => {
                    this.setState({ activate_btn: false });
                    if (response.data.status === 200) {
                        document.getElementById('modal-dismiss-deactivate').click();
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        // document.getElementById('ss').click();
                        //  this.setState({EmailError:response.data.message});
                    }
                    else {
                        document.getElementById('modal-dismiss-deactivate').click();
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        //  this.setState({EmailError:response.data.message});
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        } else {
            document.getElementById('modal-dismiss-deactivate').click();
        }
    }


    confirmation_yes(flag) {

        if (flag === 1) {
            this.applyJob(this.state.apply_id, this.state.apply_org);
        } else {
            document.getElementById('modal-dismiss-apply').click();
        }

    }
    confirm(id, org) {
        this.setState({ apply_id: id, apply_org: org });
        if (localStorage.getItem("id") !== null) {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": localStorage.getItem('token')
            }
            axios.post(DOMAIN + CHECK_RESUME, {
                userId: localStorage.getItem("id"),
            },
                {
                    headers: headers
                }
            )
                .then(response => {
                    if (response.data.status === 200) {
                        document.getElementById('applyActive').click();
                    } else{
                        // toast.info(response.data.message, {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        var orgs=null;
                        var check_status=null;
                        if(response.data.org_visible===null)
                        {
                        orgs=1;
                        check_status=false;
                        }
                        this.setState({check_resume_login:1,upload_heading:"Please Upload Resume to Apply Job.",org_status:orgs,visible:check_status});
                        document.getElementById('resumeModal1').click();

                    }
                })
                .catch(function (error) {
                    console.log(error);
                })

        } else {
            document.getElementById('loginActive').click();
        }

    }
    changeterms() {
        if (this.state.terms) {
            this.setState({
                terms: false,
            })
        } else {
            this.setState({
                terms: true
            })
        }
    }
    visibleOrg() {
        if (this.state.visible_reg) {
            this.setState({
                visible_reg: false,
            })
        } else {
            this.setState({
                visible_reg: true
            })
        }
    }
    registerJobseekers() {
        var org_visible_status =0;
        if (this.state.visible_reg)
             org_visible_status = 0;
        else
             org_visible_status = 1;
        var personaldata = this.state;
        if (personaldata.password_reg !== personaldata.confirmpassword) {
            this.setState({
                passwordStatus: true,
                error_signup:"Please check your form carefully"
            })
        } else {
            var RegisterData = {
                country: personaldata.country_reg,
                state: personaldata.state_reg,
                firstname: personaldata.firstname,
                lastname: personaldata.lastname,
                email: personaldata.email_reg,
                phone_number: personaldata.phone_number,
                address: personaldata.address,
                city: personaldata.city,
                zipcode: personaldata.zipcode,
                password: personaldata.password_reg,
                org_visible_status: org_visible_status,
                flag: 0
            }
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
            document.getElementById("fade").style.opacity = "0.5";
         document.getElementById("fade").style['pointer-events'] = 'none';
         document.getElementById("fade1").style.opacity = "0.5";
         document.getElementById("fade1").style['pointer-events'] = 'none';
         this.setState({isLoad1:false});
            axios.post(DOMAIN + REGISTER_JOBSEEKERS, {
                data: RegisterData
            },
                {
                    headers: headers
                }
            )
                .then(response => {
                    this.setState({isLoad1:false});
                    document.getElementById("fade").style.opacity = "unset";
                    document.getElementById("fade").style['pointer-events'] = '';
                    document.getElementById("fade1").style.opacity = "unset";
                    document.getElementById("fade1").style['pointer-events'] = '';
                    this.setState({error_signup:""});
                    if (response.data.status === 200) {

                        this.setState({user_Id:response.data.user_id});
                        this.myResumeUpload(1);
                        // localStorage.setItem('signup_war',1);

                        // localStorage.setItem("token", response.data.api_token);
                        // localStorage.setItem("firstname", this.state.firstname);
                        // localStorage.setItem("lastname", this.state.lastname);
                        // localStorage.setItem('email', this.state.email_reg);
                        // localStorage.setItem('id', response.data.user_id);
                        // this.handlePageChange(this.state.activePage);

                        // this.props.history.push('/joblisting');
                        // if (this.state.apply_id != "" && this.state.apply_org != "")
                        //     this.applyJob(this.state.apply_id, this.state.apply_org);

                    } else if (response.data.status === 201) {
                        document.getElementById('modal-dismiss-login').click();
                        document.getElementById('deactivate').click();
                    } else {
                        if (response.data.error_code === 1) {
                            this.setState({
                                emailValidMsg: response.data.msg,
                                error_signup:"Please check your form carefully"
                            });
                        }
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
    }
    isDisabled() {
        if (this.state.firstname === "" || this.state.lastname === "" || this.state.terms === false || this.state.password_reg === "" || this.state.confirmpassword === "" ||  this.state.email_reg === "" || this.state.emailValidMsg !== "" || this.state.passwordError !== ""|| this.state.invalid_file!=="" || this.state.uploadActive!==true) {
            return true;
        }
        return false;
    }
    signUp() {
        document.getElementById('modal-dismiss-login').click();
        document.getElementById('basicActive').click();
    }
    zipcode_validation(zipcode, input_country, input_city, type = '') {
        const header = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
        }
        let url = '';
        if(type === 'city') {
           url = 'https://maps.googleapis.com/maps/api/geocode/json?address='+input_city+'&key='+GOOGLE_MAP_API_KEY;
        } else {
           url = 'https://maps.googleapis.com/maps/api/geocode/json?key='+GOOGLE_MAP_API_KEY+'&components=postal_code:'+zipcode;
        }
        axios.get(url, {
            headers: header
        })
        .then(response => {
            if(type == 'city') {
                if(response?.data?.status == 'ZERO_RESULTS') {
                   this.setState({
                      zipcode: '',
                      zipcode_error : '',
                      city_error: 'Please enter valid city.'
                   });
             }
             }
                if (Object.keys(response.data.results).length > 0) {
                    var address = response.data.results[0].address_components;
                    var zipcode_error = "";
      
                    //break up the components
                    var country = "", city = "", zip = "";
                    address.forEach(function (item, index) {
                        var type = item.types[0];
      
                        if (city == "" && (type == "sublocality_level_1" || type == "locality")) {
                            city = (item.long_name).trim();
                        }
                        // if ($state == "" && type=="administrative_area_level_1") {
                        //     $state = trim(item.short_name);
                        // }
                        if (country == "" && type == "country") {
                            country = (item.long_name).trim();
      
                        }
                        if(type === 'postal_code') {
                             zip = (item.long_name).trim();
                        }
      
                    })
                    if(type !== 'zipcode' && type !== '') {
                       this.setState({
                          zipcode: zip,
                          city_error: ''
                       });
                       if(zip === '') {
                        zipcode_error = '';
                     }
                    }
                    if (country.toLowerCase().toString() != 'canada' && country.trim().toLowerCase().toString() != 'united states') {
                        zipcode_error = "This Zipcode is not available in this Contry and City";
                        this.setState({
                            zipcode_error : zipcode_error
                            });
                    }
                    if (((country.toLowerCase().toString()) == 'canada' && input_country != 'Canada') || ((country.toLowerCase().toString()) == 'united states' && input_country != 'United States')) {
      
                        zipcode_error = "Country";
                    }
                    if ((country.toLowerCase().toString()) != 'canada' && (country.toLowerCase().toString()) != 'united states') {
                        zipcode_error = "Country";
                    }
                    if ((input_city).toLowerCase() != (city.toLowerCase())) {
                        if (zipcode_error != "") {
                            zipcode_error = zipcode_error + " and City"
                        } else {
                            zipcode_error = "City";
                        }
                    }
                    if(zipcode === '') {
                       zipcode_error = '';
                    }
                    if (zipcode_error != "") {
                        zipcode_error = "This Zipcode is not available in this " + zipcode_error + ". This Zipcode is available from (" + country + " and " + city + ")";
      
                        this.setState({
                            zipcode_error : zipcode_error
                            });
                    } else {
                        this.setState({
                            zipcode_error : ''
                            });
                    }
                } else if(zipcode!=""){
                    this.setState({
                        zipcode_error : "zipcode is not valid"
                        });
                       }
            })
            setTimeout(() => {
              if(this.state.zipcode === '') {
                this.setState({
                    zipcode_error : ""
                    });
              }
            },800)
}
    onChange(e) {
        if (e.target.name === 'country_reg') {
            this.setState({
                countryId_reg: e.target.value,
                selectedCountry: this.state.countries_reg[e.target.value].country,
                country_reg: this.state.countries_reg[e.target.value].id,
            })
            // this.zipcode_validation(this.state.zipcode,this.state.countries_reg[e.target.value].country,this.state.city);
            // this.state.country=this.state.countries[e.target.value].id;
            // this.state.selectedCountry=this.state.countries[e.target.value].country;

        } else if (e.target.name === 'city') {
            this.setState({
                city: e.target.value

            })
            // this.zipcode_validation(this.state.zipcode,this.state.selectedCountry,e.target.value, 'city');
            // this.state.country=this.state.countries[e.target.value].id;
            // this.state.selectedCountry=this.state.countries[e.target.value].country;

        } else if (e.target.name === 'phone_number') {
            const numregx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
            if (numregx.test(e.target.value) || e.target.value === "") {
                if(e.target.value.length<9){
                    this.setState({
                        phone_number_issue:"Please enter valid phone number.Phone number must be a minimum Country Code + 10 digits !"
                    })
                }else{
                    this.setState({
                        phone_number_issue:""
                    })
                }
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        } else if (e.target.name === 'firstname' || e.target.name === 'lastname' || e.target.name === 'city') {
            const strregx = /^[A-Za-z]+$/;
            if (strregx.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        } else if (e.target.name === 'zipcode') {
            const zipregx = /^[^/+<>%#@&"*?=^!~`.,'_:;\$/|\/\\{}()\[/\]/-]*$/;
            if (zipregx.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                })
                // var promise = new Promise( (resolve, reject) => {
                // this.zipcode_validation(e.target.value,this.state.selectedCountry,this.state.city, 'zipcode');
                        // resolve('Success');
                    //  reject(Error("Promise rejected"));
                //    });
                //    promise.then(result => {
                //     console.log(result);
                //     console.log(1);
                //     console.log(zipcode_error);
                //    });
            }
        } else if (e.target.name === 'address') {
            const addressregx = /^[^<>%*?=^!~`]*$/;
            if (addressregx.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        }
        else if (e.target.name === 'design') {
            this.setState({
                designError: "",
                [e.target.name]: e.target.value
            })
        } else if (e.target.name === 'email_reg') {
            const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            if (emailregex.test(e.target.value)) {
                this.setState({
                    emailValidMsg: "",
                    error_signup:""
                })
            } else {
                this.setState({
                    emailValidMsg: "Please enter a valid  email id !",
                    error_signup:"Please check your form carefully"
                })
            }
            this.setState({
                [e.target.name]: e.target.value
            })
        } else {
            if (e.target.name === 'password_reg') {
                if (e.target.value.length < 5) {
                    this.setState({
                        passwordError: "Invalid Password . Password length must be minimum 5 letters !",
                        error_signup:"Please check your form carefully"
                    })
                } else {
                    this.setState({
                        passwordError: "",
                        error_signup:""
                    })
                }
            }
            if(e.target.name==='confirmpassword'){
                this.setState({
                   passwordStatus:false,
                   error_signup:""
                })
          }
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }

    clearAll() {
        var removedTags = this.state.skills;
        var suggestions = this.state.suggestions;
        removedTags.forEach(function(removedTag){
            suggestions = [].concat(removedTag, suggestions);
        });

        this.setState({
            suggestions:suggestions,
            joblist: [],
            zip_city: "",
            activePage: 1,
            itemsCountPerPage: 1,
            totalItemsCount: 1,
            pageRangeDisplayed: 3,
            job_org: "",
            job_id: "",
            // date: false,
            relevance: false,
            // distance: false,
            // relevances: 0,
            radius_value: 0,
            radius_values:0,
            isDisabled: true,
            pay_rate: { min: 0, max: 500 },
            experience: { min: 0, max: 50 },
            position: "",
            // dates: 0,
            distances: 0,
            countryId: "",
            state: "",
            country: "",
            pay_rates: { min: 0, max: 0 },
            experiences: { min: 0, max: 0 },
            relevance_disable: true,
            skills: [],
            position_type: "",
            clear: false,
        });
        if(this.state.distances_status===true){
            this.setState({date:false,dates:0,distances:1,distance:true},()=>{this.handlePageChange(1);})
        }
        else{
            this.setState({date:true,dates:1,distances:0,distance:false},()=>{this.handlePageChange(1);})
        }
    }
    searchjob() {
        this.handlePageChange(1);
    }
    handleAddition(skill) {
        // console.log(skill);
        if(skill.id!=undefined){
            let is_exist = this.state.skills.find(el => (el.name).toLowerCase() === (skill.name).toLowerCase());
            if(is_exist==undefined || skill.type==="freeText"){
                if(skill.type==="freeText"){
                    return this.state.suggestions;
                }
                // console.log(this.state.suggestions);
                const skills = [].concat(this.state.skills, skill);
                const suggestions = this.state.suggestions.filter(x => x.id !== skill.id);
                if(this.state.prop===0){
                this.setState({ skills, suggestions,freeText:"", clear: true }, () =>
                    this.handlePageChange(1));
                }
                else{
                    return suggestions;
                }
            }else{
                const suggestions = this.state.suggestions.filter(x => x.id !== skill.id);
                return suggestions;
            }
        }else{
            // console.log(this.state.suggestions);
            if(skill.name!=undefined){
                let is_exist = this.state.skills.find(el => (el.name).toLowerCase() === (skill.name).toLowerCase());
                    if(is_exist==undefined){
                        let select_skill = {id:1000+'_'+skill.name,name:skill.name,type:"freeText"};
                        const skills = [].concat(this.state.skills, select_skill);
                        this.setState({ skills, clear: true }, () =>
                        this.handlePageChange(1));
                    }
                }else{
                  if(this.state.freeText!=""){
                    let is_exist = this.state.skills.find(el => (el.name).toLowerCase() === (this.state.freeText).toLowerCase());
                    if(is_exist==undefined){
                        let freeTxt = this.state.freeText;
                        let select_skill = {id:1000+'_'+freeTxt,name:freeTxt,type:"freeText"};
                        const skills = [].concat(this.state.skills, select_skill);

                        this.setState({ skills, clear: true,freeText:"" }, () =>
                        this.handlePageChange(1));
                        // console.log(document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0].value);
                        // document.getElementsByClassName('react-tags__search-input')[1].getElementsByTagName('input')[0].value="";
                        //   this.setState({ titles,freeText:"" });
                // console.log();
                    }

                  }
                  setTimeout(function(){
                      if(document.getElementsByClassName('react-tags__search-input')){
                    //   console.log(document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input'));
                    if(document.getElementsByClassName('react-tags__search-input')[0])
                        document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0].value="";
                    if(document.getElementsByClassName('react-tags__search-input')[1])
                        document.getElementsByClassName('react-tags__search-input')[1].getElementsByTagName('input')[0].value="";
                      }
                },100)
        }
    }
}
    handleDelete(i) {
        if (i > -1) {
            const skills = this.state.skills.slice(0)
            skills.splice(i, 1)
            const removedTag = this.state.skills[i];
            // console.log(removedTag.type);
            var suggestions = [];
            if(removedTag.type!='freeText'){
                 suggestions = [].concat(removedTag, this.state.suggestions);
            }else{
                 suggestions = this.state.suggestions;
            }
            this.setState({ skills, suggestions,freeText:"" }, () =>{
                if (this.state.radius_values === 0 && this.state.zip_city === "" && this.state.skills !== [] && this.state.position === "" && this.state.country === "" && this.state.state === "" && this.state.pay_rates.min === 0 && this.state.pay_rates.max === 0 && this.state.experiences.min === 0 && this.state.experiences.max === 0 && this.state.skills.length === 0) {
                    this.setState({clear:false});
                }
                this.handlePageChange(1)});
        }

    }
    handleFreeText(query){
        // console.log('hai');
        // console.log('delemeters');
        this.setState({freeText:query});
    }
    delimiters(optional){
        // console.log('doooit');
    }
    suggestionsFilterData(item,query){
        console.log(query);
    }
    getList() {
        this.setState({ map_redirect: false, }, () =>
            this.handlePageChange(this.state.activePage));
    }
    getMap() {
        if (this.state.joblist.length > 0) {
            this.setState({ map_redirect: true, }, () =>
                this.handlePageChange(this.state.activePage));
        } else {
            toast.error("No records found..", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    onChangeHandle(e) {

        if (e.target.name === 'position') {
            (this.state.job_position).forEach(element => {
                if (element.id == e.target.value) {

                    this.setState({
                        position_type: element.value,
                        clear: true
                    })
                }
            });
            this.setState({ position: e.target.value, clear: true }, () =>
                this.handlePageChange(1));
        }
        else if (e.target.name === 'country') {
            this.setState({ country: e.target.value, clear: true }, () =>
                this.handlePageChange(1));

                if(e.target.value===""){
                    this.setState({
                        state:""
                    })
                }
        }
        else if (e.target.name === 'zip_city') {
            const zip_cityregx = /^[^/<>%*?=^!~`:;|\/\\{}()]*$/;
            if (zip_cityregx.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value,
                    isDisabled: false, clear: true
                },()=>{
                    if(this.state.zip_city.length>=4){
                        var zipcode = this.state.zip_city;
                        const header = {
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Accept": "application/json",
                        }
                        axios.get('https://maps.googleapis.com/maps/api/geocode/json?key='+GOOGLE_MAP_API_KEY+'&components=postal_code:'+zipcode, {
                            headers: header
                        })
                        .then(response => {
                            if(response.data.length>0){
                                this.setState({
                                    latitude :response.data.results[0].geometry.location.lat,
                                    longitude :response.data.results[0].geometry.location.long,
                                }, () =>
                                this.handlePageChange(1));
                            }else{
                                this.handlePageChange(1);
                            }
                        });

                    }
                })

                if (e.target.value === "") {
                    this.setState({ isDisabled: true }, () =>
                        this.handlePageChange(1));
                }
            }
            else {
                // this.setState({ zip_city:""});
            }

        }
        else if (e.target.name === 'email') {
            this.setState({ email: e.target.value }, () =>
                console.log("search"));
        }
        else if (e.target.name === 'password') {
            this.setState({ password: e.target.value }, () =>
                console.log("search"));
        }
        else {
            this.setState({ state: e.target.value, clear: true }, () =>
                this.handlePageChange(1));
        }
            if((e.target.name === 'zip_city' || e.target.name === 'position' ||e.target.name === 'country'||e.target.name === 'state') && e.target.value==="" && this.state.radius_values === 0 &&  this.state.pay_rates.min === 0 && (this.state.pay_rates.max ===0 ||this.state.pay_rates.max ===100) &&  this.state.experiences.min === 0 && (this.state.experiences.max ===0 ||this.state.experiences.max ===50) && this.state.skills.length === 0){
                this.setState({clear:false});
            }

    }
    getPayrate() {
        this.setState({ pay_rates: this.state.pay_rate, clear: true }, () =>{
            if (this.state.radius_values === 0 && this.state.zip_city === "" && this.state.skills !== [] && this.state.position === "" && this.state.country === "" && this.state.state === "" && this.state.pay_rates.min === 0 && (this.state.pay_rates.max === 100||this.state.pay_rates.max ===0)&& this.state.experiences.min === 0 && (this.state.experiences.max === 50||this.state.experiences.max ===0)  && this.state.skills.length === 0) {
                this.setState({clear:false});
            }
            this.handlePageChange(1)});

    }
    getExperience(){
        this.setState({ experiences: this.state.experience, clear: true }, () =>{
            if (this.state.radius_values === 0 && this.state.zip_city === "" && this.state.skills !== []  && this.state.position === "" && this.state.country === "" && this.state.state === "" &&  this.state.pay_rates.min === 0 && (this.state.pay_rates.max === 100||this.state.pay_rates.max ===0)&& this.state.experiences.min === 0 && (this.state.experiences.max === 50||this.state.experiences.max ===0) && this.state.skills.length === 0) {
                this.setState({clear:false});
            }
            this.handlePageChange(1)});
    }
    // disabledClearAll(){
    //     if(this.state.radius_values === 0 && this.state.zip_city === "" && this.state.skills !== [] && this.state.exprn === "" && this.state.position == "" && this.state.country === "" && this.state.state === "" && this.state.pay_rates.min === 0 && this.state.pay_rates.max === 0 && this.state.skills.length === 0) {
    //         this.setState({clear:false});
    //     }
    // }
getJoblist(){

    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
    var radius=this.state.radius_value;
    if(this.state.latitude===""&& this.state.longitude===""){
        radius=0;
    }
    this.setState({isLoad:false});
    axios.post(DOMAIN + GET_JOB_LIST, { pagination: PAGINATION_COUNT, jobseeker_id: localStorage.getItem("id"), latitude: this.state.latitude, longitude: this.state.longitude, radius: radius, date: this.state.dates, distance: this.state.distances, position: this.state.position, country: this.state.country, state: this.state.state, pay_rate: this.state.pay_rates,experience: this.state.experiences, relevence: this.state.relevances, map: this.state.map_redirect, skills_designation: this.state.skills, zip_city: this.state.zip_city },
        {
            headers: headers
        }
    )
        .then(response => {
            if (response.data.status === 200) {
                this.setState({isLoad:true});
                // console.log(response.data.data.jobseeker_status.org_visible_status);
                var Suggest = [];
                (response.data.data.skills).forEach(value => {
                    Suggest.push({ id: value.id, name: value.skill, type: "skill" });
                });
                (response.data.data.designation).forEach(value => {
                    Suggest.push({ id: value.id, name: value.value, type: "designation" });
                });

                if (response.data.data.jobseeker_status != undefined) {
                    if (response.data.data.jobseeker_status.org_visible_status === 0) {
                        this.setState({ visible_check: false, });
                    }
                    else {
                        this.setState({ visible_check: true, });
                    }
                }
                this.setState({
                    joblist: response.data.data.data.data,
                    itemsCountPerPage: response.data.data.data.per_page,
                    totalItemsCount: response.data.data.data.total,
                    activePage: response.data.data.data.current_page,
                    job_position: response.data.data.job_position,
                    countries: response.data.data.country,
                    states: response.data.data.state,
                    countries_reg: response.data.data.country,
                    states_reg: response.data.data.state,
                    suggestions: Suggest
                    // map:response.data.data.data.data,
                },()=>this.continueJobList());

            }

        })
        .catch(function (error) {
            console.log(error);
        })
}
continueJobList(){
    if(this.props.location.state!==undefined)
                {
                if(this.props.location.state.titles!==undefined){
                    for(var i=0;i<this.props.location.state.titles.length;i++){
                        this.setState({prop:1});
                       this.setState({suggestions:this.handleAddition(this.props.location.state.titles[i])}) ;
                    }
                    this.setState({prop:0});
                    console.log(this.state.suggestions);
                }
            }
                if (this.props.location.state != undefined) {
                    if (this.props.location.state.city !== undefined && this.props.location.state.skills.length > 0) {
                        if (Object.keys(this.state.joblist).length === 0) {
                            // if (localStorage.getItem("id") !== null) {
                            //     this.setState({  distance: true, distances: 1, dates: 0, relevances: 0 }, this.clearAll());
                            // }
                        }
                    }
                }
}
getPostDate(date){
                var postdate = new Date(date);
                var strArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                var d = postdate.getDate();
                var m = strArray[postdate.getMonth()];
                var y = postdate.getFullYear();
                return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
}
    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            location.getCurrentPosition((position, error) => {
                this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude, distances_status: true }, () =>
                    this.getJoblist());

            }, (error) => {
                // this.setState({date:true,dates:1,distance:false,distances:0,distances_status:false});
                this.setState({ latitude: '', longitude: '', distance: false, distances: 0, date: true, dates: 1, distances_status: false }, () =>
                    this.getJoblist());
            })
        }

    }
    getRadius() {
        if (this.state.distances_status === true) {
            this.setState({ radius_value: this.state.radius_value,radius_values: this.state.radius_value, clear: true }, () =>{
                if (this.state.radius_values === 0 && this.state.zip_city === "" && this.state.position === "" && this.state.country === "" && this.state.state === "" && this.state.pay_rates.min === 0 && (this.state.pay_rates.max ===100|| this.state.pay_rates.max ===0)&& this.state.experiences.min === 0 &&(this.state.experiences.max ===50|| this.state.experiences.max ===0) && this.state.skills.length === 0) {
                    this.setState({clear:false});
                }
                this.handlePageChange(this.state.activePage)});
        }
        else {
            this.setState({ radius_values: this.state.radius_value});
            toast.error("Please remove url block list from browser to access radius wise filter", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    componentWillMount() {
        window.scrollTo(0, 0);
        var skill_desgn = [];
        if (this.props.location.state !== undefined) {
            if(this.props.location.state.titles!==undefined)
            {
            if (this.props.location.state.titles.length > 0) {
                this.setState({
                    skills: this.props.location.state.titles,
                    clear: true
                });
            }
        }
            if (this.props.location.state.zip_city !== undefined) {
                this.setState({
                    zip_city: this.props.location.state.zip_city,
                    clear: true
                });
            }
            if (this.props.location.state.city !== undefined && this.props.location.state.skills.length > 0) {
                (this.props.location.state.skills).forEach(value => {
                    skill_desgn.push({ id: value.id, name: value.name, type: "skill" });
                });
                if (this.props.location.state.design !== undefined)
                    skill_desgn.push({ id: 0, name: this.props.location.state.design, type: "designation" });
                this.setState({
                    zip_city: this.props.location.state.city,
                    skills: skill_desgn,
                    clear: true
                });
            }

        }
        else {
            // if (localStorage.getItem("id") !== null) {
            //     this.setState({  distance: true, distances: 1, dates: 0, relevances: 0 });
            // }
        }
    }
    componentDidMount() {
        this.getMyLocation();
    }
    handleKeyPress(query){
        console.log('why');
    }
    applyJob(job_id, job_org) {
        if (localStorage.getItem("id") !== null) {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": localStorage.getItem('token')
            }
            axios.post(DOMAIN + APPLY_JOB, {
                job_id: job_id,
                org: job_org,
                jobseeker_id: localStorage.getItem("id"),
                name: localStorage.getItem('firstname'),
                org_visible_status: this.state.visible,
            },
                {
                    headers: headers
                }
            )
                .then(response => {
                    document.getElementById('modal-dismiss-apply').click();
                    if (response.status === 200) {
                       if(response.data.status === 200){
                        toast.success("Job "+response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    else{
                        toast.error("Job "+response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    this.handlePageChange(this.state.activePage);

                    } else if (response.data.status === 401) {
                        localStorage.clear();
                        this.props.history.push('/login');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
        }
        else {
            document.getElementById('loginActive').click();
        }
    }
    storeQuadinates(pageNumber,radius){
        const header = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        axios.post(DOMAIN + GET_SEEKERS_DATA,
            {
                userId: localStorage.getItem('id')
            }, {
            headers: header
        })
        .then(response => {
            if (response.data.status === 200) {
                var zipcode = response.data.data.zipcode;
                if(zipcode!=null){
                    const header = {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "Accept": "application/json",
                    }
                    axios.get('https://maps.googleapis.com/maps/api/geocode/json?key='+GOOGLE_MAP_API_KEY+'&components=postal_code:'+zipcode, {
                        headers: header
                    })
                    .then(response => {
                        if(response.data.results.length>0){
                            this.setState({
                                latitude :response.data.results[0].geometry.location.lat,
                                longitude :response.data.results[0].geometry.location.lng,
                            },()=>this.getRelavenceBasedData(pageNumber,radius))
                        }else{
                            this.getRelavenceBasedData(pageNumber,radius);
                        }
                    });
                }else{
                    this.getRelavenceBasedData(pageNumber,radius);
                }
            }else if(response.data.status === 401){
                localStorage.clear();
                this.props.history.push('/login');
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    getRelavenceBasedData(pageNumber,radius){
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
        axios.post(DOMAIN + GET_JOB_LIST_PAGE + pageNumber, { pagination: PAGINATION_COUNT, jobseeker_id: localStorage.getItem("id"), latitude: this.state.latitude, longitude: this.state.longitude, radius:radius, date: this.state.dates, distance: this.state.distances, position: this.state.position, country: this.state.country, state: this.state.state, pay_rate: this.state.pay_rates, experience: this.state.experiences, relevence: this.state.relevances, map: this.state.map_redirect, skills_designation: this.state.skills, zip_city: this.state.zip_city },
            {
                headers: headers
            }
        )
            .then(response => {
                window.scrollTo(0, 530);
                if (response.data.status === 200) {
                    this.setState({isLoad:true});
                    if (this.state.relevances === 1) {
                        if (response.data.data.data.msg !== undefined) {
                            toast.info(response.data.data.data.msg, {
                                position: toast.POSITION.TOP_CENTER
                            });
                            this.check(1);
                        }
                    }
                    // var Suggest = [];
                    // (response.data.data.skills).forEach(value => {
                    //     Suggest.push({ id: value.id, name: value.skill, type: "skill" });
                    // });
                    // (response.data.data.designation).forEach(value => {
                    //     Suggest.push({ id: value.id, name: value.value, type: "designation" });
                    // });

                    if (response.data.data.jobseeker_status != undefined) {
                        if (response.data.data.jobseeker_status.org_visible_status === 0) {
                            this.setState({ visible_check: false, });
                        }
                        else {
                            this.setState({ visible_check: true, });
                        }
                    }
                    this.setState({
                        joblist: response.data.data.data.data,
                        itemsCountPerPage: response.data.data.data.per_page,
                        totalItemsCount: response.data.data.data.total,
                        activePage: response.data.data.data.current_page,
                        job_position: response.data.data.job_position,
                        countries: response.data.data.country,
                        states: response.data.data.state,
                        countries_reg: response.data.data.country,
                        states_reg: response.data.data.state,
                        // suggestions: Suggest
                        // map:response.data.data.data.data,
                    });
                    setTimeout(function(){
                        if(document.getElementsByClassName('react-tags__search-input')){
                        //   console.log(document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input'));
                            document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0].value="";
                            document.getElementsByClassName('react-tags__search-input')[1].getElementsByTagName('input')[0].value="";
                        }
                      },100)
                }
            })
            .catch(function (error) {
                console.log(error);
            })
        this.setState({ activePage: pageNumber });
    }
    // Pagination
    handlePageChange(pageNumber) {
        var radius=this.state.radius_value;
        if(this.state.latitude===""&& this.state.longitude===""){
            radius=0;
        }
        this.setState({isLoad:false});
        // var promise = new Promise( (resolve, reject) => {

            if(this.state.relevance){
                this.storeQuadinates(pageNumber,radius);
            }else{
                this.getRelavenceBasedData(pageNumber,radius);
            }
            //  reject(Error("Promise rejected"));
        //    });
        //    promise.then( result => {

        //    });
    }
    createAlert() {
        this.setState({
            alert_disable : true
        })
        if (this.state.skills.length < 1) {
            toast.info("Please add at least one skill in the filter to get relevant results !", {
                position: toast.POSITION.TOP_CENTER
            });
        } else if (!localStorage.getItem('id')) {
            toast.error("Please login to create alert", {
                position: toast.POSITION.TOP_CENTER
            });
            document.getElementById('loginActive').click();
        } else {
            let flag =0;
            // (this.state.skills).forEach(element => {
            //     if (element.type === 'skill' || element.type === 'designation') {
                    flag =1;
                    document.getElementById('alertActive').click();
            //     }
            // });
            // if(flag==0){
            //     toast.info("Please add at least one skill in the filter to get relevant results !", {
            //         position: toast.POSITION.TOP_CENTER
            //     });
            // }

        }
        setTimeout(function(){
            this.setState({
                alert_disable : false
            })

        }.bind(this),5000)
    }

    onAlertHandle(e) {
        if (e.target.name === 'alertname') {
            const alertregx = /^[^<>%*?=^!~:;+\/\\|{}@#$&`]*$/;
            if (alertregx.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        }
    }
    storeAlert() {
        var title = "";
        if (this.state.radius_values !== 0) {
            title = title + '<b className="col-md-3">Radius</b>:' + this.state.radius_value + "km <br />";
        }
        // if (this.state.exprn_min !== "") {
        //     title = title + this.state.exprn_min + " Year Experience,"
        // }
        if (this.state.position_type != "") { //type warning check
            title = title + '<b className="col-md-3">Position Type</b>:' + this.state.position_type + " <br />"
        }
        if (this.state.country !== "") {
            title = title + '<b className="col-md-3">Country</b>:' + this.state.country + " <br />";
        }
        if (this.state.zip_city !== "") {
            title = title + '<b className="col-md-3">ZipCode OR City</b>:' +  this.state.zip_city + " <br />";
        }
        if (this.state.state !== "") {
            title = title + '<b className="col-md-3">State</b>:' +  this.state.state + "<br />";
        }
        if (this.state.pay_rates.min !== 0 || this.state.pay_rates.max !== 0) {
            title = title + '<b className="col-md-3">Salary</b>:' + this.state.pay_rates.min + "-" + this.state.pay_rates.max + "LPA <br />";
        }
        if (this.state.experiences.min !== 0 || this.state.experiences.max !== 0) {
            title = title + '<b className="col-md-3">Year Of Experience</b>:' + this.state.experiences.min + "-" + this.state.experiences.max + "Year<br />";
        }
        var skill_filter = "";
        var design_filter = "";
        (this.state.skills).forEach(element => {
            if (element.type === 'skill') {
                skill_filter = skill_filter + element.name + ",";
            } else {
                design_filter = design_filter + element.name + ",";
            }
        });
        if (skill_filter !== "") {
            title = title + '<b className="col-md-3">Skills</b>:' + skill_filter.substring(0, skill_filter.length - 1) + "<br />";
        }
        if (design_filter !== "") {
            title = title + '<b className="col-md-3">Designation</b>:' + design_filter.substring(0, design_filter.length - 1) + "<br />";
        }
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        axios.post(DOMAIN + SORT_ALERT,
            {
                jobseeker_id: localStorage.getItem("id"),
                alert_name: this.state.alertname,
                radius_value: this.state.radius_values,
                year_of_exp: this.state.exprn,
                experience_min:this.state.experiences.min,
                experience_max:this.state.experiences.max,
                position_type: this.state.position,
                country: this.state.country,
                state: this.state.state,
                title: title.substring(0, title.length - 1),
                salary_min: this.state.pay_rates.min,
                salary_max: this.state.pay_rates.max,
                skill_design: JSON.stringify(this.state.skills)
            },
            {
                headers: headers
            }
        )
            .then(response => {
                if (response.data.status === 200) {
                    this.setState({alertname:""});
                    toast.success("Alert created successfully", {
                        position: toast.POSITION.TOP_CENTER
                    });
                    document.getElementById('modal-dismiss').click();
                } else if (response.data.status === 401) {
                    localStorage.clear();
                    this.props.history.push('/login');
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    forgetTab(){
        document.getElementById('modal-dismiss-login').click();
        localStorage.setItem('forgot',1);
        this.props.history.push('/login');
    }
    visibleOrgLogin(){
        if (this.state.visibleStatus) {
           this.setState({
             visibleStatus: false,
             visible:null
           })
       } else {
           this.setState({
               visibleStatus: true,
               visible:null
           })
       }
     }
    login() {
        var org_visible_status =null;
    if(this.state.visibleStatus)
         org_visible_status = 1;
     else
         org_visible_status =null;

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
        this.setState({isLoad:false});
        axios.post(DOMAIN + LOGIN, {
            data: { email: this.state.email, password: this.state.password,org_visible_status:org_visible_status }
        },
            {
                headers: headers
            }
        )
            .then(response => {
                this.setState({isLoad:true});
                if(response.data.status === 200) {
                    if(response.data.data.email_confirm===1){
                        localStorage.setItem("token", response.data.api_token);
                        localStorage.setItem("firstname", response.data.data.firstname);
                        localStorage.setItem("lastname", response.data.data.lastname);
                        localStorage.setItem('email', response.data.data.email);
                        localStorage.setItem('id', response.data.data.id);
                        this.props.history.push('/joblisting');
                        document.getElementById('modal-dismiss-login').click();
                        if (localStorage.getItem("id")) {
                            this.handlePageChange(this.state.activePage);
                            if(response.data.check_resume!==null && response.data.check_resume!==""){
                            if (this.state.apply_id !== "" && this.state.apply_org !== "")
                                this.applyJob(this.state.apply_id, this.state.apply_org);
                            }else{
                                // toast.info("Please upload resume to apply job", {
                                //     position: toast.POSITION.TOP_CENTER
                                // });
                                var orgs=null;
                                var check_status=null;
                                if(response.data.org_visible_status===null)
                                {
                                orgs=1;
                                check_status=false;
                                }
                                this.setState({check_resume_login:1,upload_heading:"Please Upload Resume to Apply Job.",org_status:orgs,visible:check_status})
                                document.getElementById('resumeModal1').click();
                            }
                        }
                    }else{
                        toast.error("Already email verification sent to your email. please verify first!! or login with social", {
                           position: toast.POSITION.TOP_CENTER
                        });
                    }

                } else if (response.data.status === 201) {
                    document.getElementById('modal-dismiss-login').click();
                    document.getElementById('deactivate').click();
                } else {
                    if (response.data.error_code === 1) {
                        this.setState({
                            EmailError: response.data.msg
                        })
                    } else {
                        this.setState({
                            PasswordError: response.data.msg
                        })
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    linkedingetUser(){
        const headers = {
           "Content-Type": "application/json",
           "Accept": "application/json"
        }
        if(this.state.code!==""){
            document.getElementById("fade").style.opacity = "0.5";
            document.getElementById("fade").style['pointer-events'] = 'none';
            document.getElementById("fade1").style.opacity = "0.5";
            document.getElementById("fade1").style['pointer-events'] = 'none';
            this.setState({isLoad1:false});
        axios.post(DOMAIN + LINKED_IN,{
           code:this.state.code
        },
           {
              headers: headers
           }
        )
        .then(response => {
            document.getElementById("fade").style.opacity = "unset";
            document.getElementById("fade").style['pointer-events'] = '';
            document.getElementById("fade1").style.opacity = "unset";
            document.getElementById("fade1").style['pointer-events'] = '';
           var fname=response.data.user_data[0].firstname;
           var lname=response.data.user_data[0].lastname;
           var email=response.data.user_data[0].email["handle~"].emailAddress;
          if(this.state.linkedin_signin_signup===1)
          {
          this.setState({
           code:"",
        },this.login_social(fname,lname,email));
        }
        else{
            this.setState({firstname:response.data.user_data[0].firstname,
                lastname:response.data.user_data[0].lastname,
                email_reg:response.data.user_data[0].email["handle~"].emailAddress,
                emailValidMsg: "",
                error_signup:"",
                code:"",
                isLoad1:true
             });
        }

        })
        .catch(function (error) {
           console.log(error);
        })
     }
      }
    login_social(fname, lname, email) {
        var org_visible_status =null;
    if(this.state.visibleStatus)
         org_visible_status = 1;
     else
         org_visible_status =null;

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
        var RegisterData = {
            lastname: lname,
            email: email,
            firstname: fname,
            org_visible_status:org_visible_status,
            flag: 1
        }
        document.getElementById("fade").style.opacity = "0.5";
        document.getElementById("fade").style['pointer-events'] = 'none';
        this.setState({isLoad1:false});
        axios.post(DOMAIN + REGISTER_JOBSEEKERS, {
            data: RegisterData
        },
            {
                headers: headers
            }
        )
            .then(response => {
                document.getElementById("fade").style.opacity = "unset";
                document.getElementById("fade").style['pointer-events'] = '';
                this.setState({isLoad1:true, error_signup:""});
                if (response.data.status === 200) {
                    localStorage.setItem("token", response.data.api_token);
                    localStorage.setItem("firstname", response.data.first_name);
                    localStorage.setItem("lastname", response.data.last_name);
                    localStorage.setItem('email', email);
                    localStorage.setItem('id', response.data.user_id);
                    this.handlePageChange(this.state.activePage);
                    document.getElementById('modal-dismiss-login').click();
                    document.getElementById('basicDisable').click();
                    this.props.history.push('/joblisting');
                    if(response.data.check_resume!==null)
                    {
                     if (this.state.apply_id !== "" && this.state.apply_org !== "")
                        this.applyJob(this.state.apply_id, this.state.apply_org);
                    }else{
                        // toast.info("Please upload resume to apply job", {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        if(response.data.org_visible_status!==undefined)
                        this.setState({org_status:1});
                        // alert(response.data.org_visible_status);
                        this.setState({check_resume_login:1,upload_heading:"Please Upload Resume to Apply Job."})
                        document.getElementById('resumeModal1').click();
                    }

                } else if (response.data.status === 201) {
                    document.getElementById('modal-dismiss-login').click();
                    document.getElementById('deactivate').click();
                } else {
                    if (response.data.error_code === 1) {
                        this.setState({
                            emailValidMsg: response.data.msg,
                            error_signup:"Please check your form carefully"
                        })
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            })

    }
    clearButton(){
        if(this.state.radius_values === 0 && this.state.zip_city === "" && this.state.skills !== [] && this.state.position === "" && this.state.country === "" && this.state.state === "" && this.state.pay_rates.min === 0 && (this.state.pay_rates.max === 100||this.state.pay_rates.max ===0)&& this.state.experiences.min === 0 && (this.state.experiences.max === 50||this.state.experiences.max ===0)  && this.state.skills.length === 0) {
            return false;
        }
        return true;
    }
    responseFacebook = (response) => {
        if (response.status !== "unknown") {
            var name = response.name.split(" ");
            this.setState({ email: response.email, firstname: name[0], lastname: name[1], emailValidMsg: "",
            error_signup:"" });
            this.login_social(name[0], name[1], response.email);
        }

    }
    responseFacebookSign = (response) => {
        if (response.status !== "unknown") {
            var name = response.name.split(" ");
            this.setState({ email_reg: response.email, firstname: name[0], lastname: name[1], emailValidMsg: "",
            error_signup:"" });
        }
    }
    responseGoogleSign = (response) => {
        if (response.error !== "popup_closed_by_user") {
            if(response.profileObj!=undefined){
                var name = response.profileObj.name.split(" ");
                this.setState({ email_reg: response.profileObj.email, firstname: name[0], lastname: name[1], emailValidMsg: "",
                error_signup:"" });
            }
        }else{
            toast.error("Do you have faced any issue please contact admin to access social login module",{
               position: toast.POSITION.TOP_CENTER
           });
         }


    }
    responseGoogle = (response) => {
        if (response.error !== "popup_closed_by_user") {
            if(response.profileObj!=undefined){
                var name = response.profileObj.name.split(" ");
                this.setState({ email: response.profileObj.email, firstname: name[0], lastname: name[1], emailValidMsg: "",
                error_signup:"" });
                this.login_social(name[0], name[1], response.profileObj.email);
            }
        }else{
            toast.error("Do you have faced any issue please contact admin to access social login module",{
               position: toast.POSITION.TOP_CENTER
           });
         }
    }
    render() {
        if(this.props.location.state!==undefined)
        {
            if(this.props.location.state.Footer!==undefined){
                window.scrollTo(0, 0);
                this.props.history.push('/joblisting');
            }
       }
          function activefile() {
        document.getElementById('resumeUpload').click();
      }
      var basicCloseStyle = {
        position: "relative",
        left: "41rem",
        top: "14px",
        zIndex: "10"
      }
      const card = document.querySelector('body');

  card.addEventListener('click', function (e) {
    // e.preventDefault();
    if(document.getElementById('tag_search'))
    document.getElementById('tag_search').click();
  });
  if(document.getElementsByClassName('react-tags__search-input')[0]){
  const taginput = document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0];
  taginput.addEventListener('click', function (e) {
    e.target.value = "";
    // setTimeout(function(){
    //     console.log(document.getElementsByClassName("react-tags__suggestions"));
    // },50)
    // console.log(document.getElementsByClassName("react-tags__suggestions"));
  });
}
if(document.getElementsByClassName('react-tags__search-input')[1]){
    const taginput = document.getElementsByClassName('react-tags__search-input')[1].getElementsByTagName('input')[0];
    taginput.addEventListener('click', function (e) {
      e.target.value = "";
    //   setTimeout(function(){
    //     console.log(document.getElementsByClassName("react-tags__suggestions"));
    // },50)
    });
  }
        // const responseFacebook = (response) => {
        //     if (response.status !== "unknown") {
        //         var name = response.name.split(" ");
        //         this.setState({ email: response.email, firstname: name[0], lastname: name[1] });
        //         this.login_social(name[0], name[1], response.email);
        //     }

        // }
        // const responseFacebookSign = (response) => {
        //     if (response.status !== "unknown") {
        //         var name = response.name.split(" ");
        //         this.setState({ email_reg: response.email, firstname: name[0], lastname: name[1] });
        //     }
        // }

        // const responseGoogle = (response) => {
        //     if (response.error !== "popup_closed_by_user") {
        //         var name = response.profileObj.name.split(" ");
        //         this.setState({ email: response.profileObj.email, firstname: name[0], lastname: name[1] });
        //         this.login_social(name[0], name[1], response.profileObj.email);
        //     }

        // }
        // const code = this.state.code;
        // if(code!="")
        // {
        //   this.linkedingetUser();
        // }
        // const errorMessage=this.state.errorMessage;
        // const responseGoogleSign = (response) => {
        //     if (response.error !== "popup_closed_by_user") {
        //         var name = response.profileObj.name.split(" ");
        //         this.setState({ email_reg: response.profileObj.email, firstname: name[0], lastname: name[1] });
        //     }


        // }

        function shoot() {
            var element = document.getElementById("mob-filter-section");
  element.classList.add("mob-dis-block");
          }
          function cancel(){
            var element = document.getElementById("mob-filter-section");
            element.classList.remove("mob-dis-block");
          }

        return (
           <>
           <button id="tag_search" onClick={this.handleAddition.bind(this)}></button>
           <ToastContainer />
           {/* <ZipcodeValidate ref={zipcode_validation => this.zipcode_validation = zipcode_validation} /> */}
           <section className="bg-half page-next-level job-details">
          <div className="bg-overlay" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="text-center text-white">
                  <div className="section-title text-center mb-4 pb-2">
                    <h4 className="text-uppercase title mb-4">Job List</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section pt-6">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="section-title text-center mb-4 pb-2">
                  <h4 className="title title-line-job pb-5 title-heading">Available job for you</h4>
                </div>
              </div>
            </div>
            <button type="button" className="btn btn-lg btn-filter mobile-filter mobile-filter-button" data-toggle="modal" data-target="#myModal"><i className="fas fa-filter my-float" /></button>
            {/* Mobile Filter */}
            <div className="mobile-filter">
              <div className="modal fade" id="myModal" role="dialog">
                <div className="modal-dialog">
                  {/* Modal content*/}
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="text-dark mb-0 pt-2 f-18 title-heading-sub modal-title">Refine your search</h5>
                      <button type="button" className="close" data-dismiss="modal">×</button>
                    </div>
                    <div className="modal-body">
                      <div className="row">
                        <div className="col-lg-12 mt-1 pt-2">
                          <div className="left-sidebar">
                          {this.clearButton() ?
                            <a  className="btn btn-sm btn-alert clear-filter mt-4-5"  onClick={this.clearAll}><i className="fas fa-eraser mr-2" /> Clear All Filter</a>
                            : null}
                            <div className="accordion pt-2" id="accordionExample">
                              <div className="card rounded mt-4">
                                <a data-toggle="collapse" href="#collapsetwo" className="job-list" aria-expanded="true" aria-controls="collapseOne">
                                  <div className="card-header" id="headingOne">
                                    <h6 className="mb-0 text-dark f-18 bold">Search Job</h6>
                                  </div>
                                </a>
                                <div id="collapsetwo" className="collapse show" aria-labelledby="headingOne">
                                  <div className="card-body p-0">
                                    <div className="card-header inputbox">
                                      <form >
                                        <input type="text" className="form-control" autoComplete="nope" maxLength={25} name="zip_city" value={this.state.zip_city} onChange={this.onChangeHandle.bind(this)} placeholder="City or Zip Code" />
                                      </form>
                                    </div>
                                    <div className="card-header no-border-btm  inputbox">
                                      {/* <form action> */}

                                        {/* <input type="text" className="form-control" id name="skill" placeholder="Enter Skill & Designation" /> */}
                                      {/* </form> */}
                                      <ReactTags
                                        maxSuggestionsLength={500}
                                        tags={this.state.skills}
                                        suggestions={this.state.suggestions}
                                        noSuggestionsText={'No suggestions found'}
                                        placeholder={'Enter Skill & Designation'}
                                        handleDelete={this.handleDelete.bind(this)}
                                        handleAddition={this.handleAddition.bind(this)}
                                        handleInputChange={this.handleFreeText.bind(this)}
                                        // delimiters= {this.delimiters.bind(this)}
                                        delimiters={delimiters}
                                        allowNew={true}
                                        />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="accordion pt-2" id="accordionExample">
                              <div className="card rounded mt-4">
                                <a data-toggle="collapse" href="#collapseOne" className="job-list" aria-expanded="true" aria-controls="collapseOne">
                                  <div className="card-header" id="headingOne">
                                    <h6 className="mb-0 text-dark f-18">Sort By</h6>
                                  </div>
                                </a>
                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                                  <div className="card-body p-0">
                                    <div className="custom-control custom-radio">
                                      <input id="Radio1" name="Radios" type="radio" value="date" className="custom-control-input" checked={this.state.date} onClick={() => this.check(1)} readOnly/>
                                      <label className="custom-control-label ml-1 text-muted" htmlFor="customRadio1">Date</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                      <input id="Radio2" name="Radios" type="radio" value="relevance" className="custom-control-input" checked={this.state.relevance} onClick={() => this.check(2)} readOnly/>
                                      <label className="custom-control-label ml-1 text-muted" htmlFor="customRadio2">Relevance</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                      <input id="Radio3" name="Radios" type="radio" value="distance" className="custom-control-input" checked={this.state.distance} onClick={() => this.check(3)} readOnly/>
                                      <label className="custom-control-label ml-1 text-muted" htmlFor="customRadio3">Distance</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card rounded mt-4">
                              <div className="card-header">
                                <form >
                                  <select className="form-control text-muted"  id="position" name="position" value={this.state.position} onChange={this.onChangeHandle.bind(this)} title="Choose your position" placeholder="Choose your position">
                                        <option value="" >Choose your position</option>
                                        {this.state.job_position.map((value, key) => {
                                            return <option key={key} value={value.id}>{value.value}</option>;
                                        })}
                                    </select>
                                </form>
                              </div>
                              <div className="card-header">
                                <form >
                                  <select className="form-control text-muted" name="country" value={this.state.country} onChange={this.onChangeHandle.bind(this)} title="Choose your country" placeholder="Choose your country">
                                        <option value="" >Choose your country</option>
                                        {this.state.countries.map((value, key) => {
                                            return <option key={key} value={value.country}>{value.country}</option>;
                                        })}
                                    </select>
                                </form></div>
                              <div className="card-header no-border-btm">
                                <select className="form-control text-muted" name="state" id="state" value={this.state.state} onChange={this.onChangeHandle.bind(this)} title="Choose your State" placeholder="Choose your State" disabled={this.state.country === ""} required>
                            <option value="" >Choose your state</option>
                            {this.state.states.map((value, key) => {
                                if (value.country === this.state.country)
                                    return <option key={key} value={value.state}>{value.state}</option>;
                            })}
                        </select>
                              </div>
                            </div>
                            <div className="card rounded mt-4">
                              <div className="card-header">
                                <h6 className="mb-0 text-dark f-18 bold">Experience</h6>
                                <div className="wrapper">
                                  <fieldset className="filter-price">
                                  <InputRange  maxValue={50} minValue={0} formatLabel={value => `${value}yrs`} value={this.state.experience}
                        onChange={experience => this.setState({ experience })} onChangeComplete={this.getExperience} />
                                    {/* <div className="price-field">
                                      <input type="range" min={0} max={50} defaultValue={0} id="lowermodel" />
                                      <input type="range" min={0} max={50} defaultValue={50} id="uppermodel" />
                                    </div>
                                    <div className="price-wrap">
                                      <div className>
                                        <div className="row">
                                          <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                            <div className="price-wrap-1 float-left">
                                              <input id="onemodel" />
                                              <label htmlFor="onemodel">Year</label>
                                            </div></div>
                                          <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div className="price-wrap_line">-</div>
                                          </div>
                                          <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                            <div className="price-wrap-2  float-right">
                                              <input id="twomodel" />
                                              <label htmlFor="twomodel">Year</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                  </fieldset>
                                </div>
                              </div>
                              <div className="card-header">
                                <h6 className="mb-0 text-dark f-18 bold">Radius</h6>
                                <div className="wrapper">
                                  <fieldset className="filter-price">
                                  <InputRange draggableTrack maxValue={500} minValue={0} formatLabel={value => `${value}KM`}  value={this.state.radius_value}
                        onChange={radius_value => this.setState({ radius_value })} onChangeComplete={this.getRadius} />
                                    {/* <div className="price-field">
                                      <input type="range" min={0} max={1000} defaultValue={0} id="lowerradiusmodel" />
                                      <input type="range" min={0} max={1000} defaultValue={1000} id="upperradiusmodel" />
                                    </div>
                                    <div className="price-wrap">
                                      <div className>
                                        <div className="row">
                                          <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                            <div className="price-wrap-1 float-left">
                                              <input id="oneradiusmodel" />
                                              <label htmlFor="oneradiusmodel">Km</label>
                                            </div></div>
                                          <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                            <div className="price-wrap_line">-</div>
                                          </div>
                                          <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                            <div className="price-wrap-2  float-right">
                                              <input id="tworadiusmodel" />
                                              <label htmlFor="tworadiusmodel">Km</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                  </fieldset>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 mt-4 pt-2 desktop-filter">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="show-results">
                      <div className="float-left">
                        <h5 className="text-dark mb-0 pt-2 f-18 title-heading-sub">Refine your search</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="left-sidebar">
                {this.clearButton() ?
                            <a  className="btn btn-sm btn-alert clear-filter mt-4-5"  onClick={this.clearAll}><i className="fas fa-eraser mr-2" /> Clear All Filter</a>
                            : null}
                  {/* <a href="#" className="btn btn-sm btn-alert clear-filter mt-4-5"><i className="fas fa-eraser mr-2" /> Clear All Filter</a> */}
                  <div className="accordion j-input-tag pt-2" id="accordionExample">
                    <div className="card rounded mt-4">
                      <a data-toggle="collapse" href="#collapsetwo" className="job-list" aria-expanded="true" aria-controls="collapseOne">
                        <div className="card-header" id="headingOne">
                          <h6 className="mb-0 text-dark f-18 bold">Search Job</h6>
                        </div>
                      </a>
                      <div id="collapsetwo" className="collapse show" aria-labelledby="headingOne">
                        <div className="card-body p-0">
                          <div className="card-header inputbox">
                          <input type="text" className="form-control"  name="zip_city" value={this.state.zip_city} onChange={this.onChangeHandle.bind(this)} placeholder="City or Zip Code" />
                            {/* <input type="text" className="form-control" id name="zip" placeholder="City or Zip Code" /> */}
                          </div>
                          {/* <div className="card-header no-border-btm  inputbox"> */}
                            <form >
                                 <ReactTags
                                        maxSuggestionsLength={500}
                                        tags={this.state.skills}
                                        suggestions={this.state.suggestions}
                                        noSuggestionsText={'No suggestions found'}
                                        placeholder={'Enter Skill & Designation'}
                                        handleDelete={this.handleDelete.bind(this)}
                                        handleAddition={this.handleAddition.bind(this)}
                                        handleInputChange={this.handleFreeText.bind(this)}
                                        // delimiters ={this.handleKeyPress.bind(this)}
                                        // delimiters= {this.delimiters.bind(this)}
                                        delimiters={delimiters}
                                        allowNew={true}
                                    />
                              {/* <input type="text" className="form-control" id name="skill" placeholder="Enter Skill & Designation" /> */}
                            </form>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion pt-2" id="accordionExample">
                    <div className="card rounded mt-4">
                      <a data-toggle="collapse" href="#collapseOne" className="job-list" aria-expanded="true" aria-controls="collapseOne">
                        <div className="card-header" id="headingOne">
                          <h6 className="mb-0 text-dark f-18">Sort By</h6>
                        </div>
                      </a>
                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                        <div className="card-body p-0">
                          <div className="custom-control custom-radio">
                          <input id="Radio1" name="Radios" type="radio" value="date" className="custom-control-input" checked={this.state.date} onClick={() => this.check(1)} readOnly/>
                            {/* <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input" /> */}
                            <label className="custom-control-label ml-1 text-muted" htmlFor="Radio1">Date</label>
                          </div>
                          <div className="custom-control custom-radio">
                          <input id="Radio2" name="Radios" type="radio" value="relevance" className="custom-control-input" checked={this.state.relevance} onClick={() => this.check(2)} readOnly/>
                            {/* <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input" /> */}
                            <label className="custom-control-label ml-1 text-muted" htmlFor="Radio2">Relevance</label>
                          </div>
                          <div className="custom-control custom-radio">
                          <input id="Radio3" name="Radios" type="radio" value="distance" className="custom-control-input" checked={this.state.distance} onClick={() => this.check(3)} readOnly/>
                            {/* <input type="radio" id="customRadio3" name="customRadio" className="custom-control-input" /> */}
                            <label className="custom-control-label ml-1 text-muted" htmlFor="Radio3">Distance</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card rounded mt-4">
                    <div className="card-header">
                      <form >
                        {/* <select id="position" name="position" className="form-control bold">
                          <option value="select">Choose your Position</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                          <option value="Freelance">Freelance</option>
                        </select> */}
                        <select className="form-control text-muted"  id="position" name="position" value={this.state.position} onChange={this.onChangeHandle.bind(this)} title="Choose your position" placeholder="Choose your position">
                                        <option value="" >Choose your position</option>
                                        {this.state.job_position.map((value, key) => {
                                            return <option key={key} value={value.id}>{value.value}</option>;
                                        })}
                                    </select>
                      </form>
                    </div>
                    <div className="card-header">
                      <form >
                      <select id="country" name="country"  className="form-control text-muted" value={this.state.country} onChange={this.onChangeHandle.bind(this)} title="Choose your country" placeholder="Choose your country">
                                        <option value="" >Choose your country</option>
                                        {this.state.countries.map((value, key) => {
                                            return <option key={key} value={value.country}>{value.country}</option>;
                                        })}
                                    </select>
                      </form></div>
                    <div className="card-header no-border-btm">
                      {/* <select id="state" name="state" className="form-control bold">
                        <option value="select">Choose your State</option>
                        <option value="Full Time">xxx</option>
                        <option value="Part Time">xxx</option>
                        <option value="Freelance">xxx</option>
                      </select> */}
                      <select className="form-control text-muted" name="state" id="state" value={this.state.state} onChange={this.onChangeHandle.bind(this)} title="Choose your State" placeholder="Choose your State" disabled={this.state.country === ""} required>
                            <option value="" >Choose your state</option>
                            {this.state.states.map((value, key) => {
                                if (value.country === this.state.country)
                                    return <option key={key} value={value.state}>{value.state}</option>;
                            })}
                        </select>
                    </div>
                  </div>
                  <div className="card rounded mt-4">
                    <div className="card-header">
                      <h6 className="mb-0 text-dark f-18 bold">Experience</h6>
                      <div className="wrapper">
                        <fieldset className="filter-price">
                        <InputRange  maxValue={50} minValue={0} formatLabel={value => `${value}yrs`} value={this.state.experience}
                        onChange={experience => this.setState({ experience })} onChangeComplete={this.getExperience} />
                          {/* <div className="price-field">
                            <input type="range" min={0} max={50} defaultValue={0} id="lower" />
                            <input type="range" min={0} max={50} defaultValue={50} id="upper" />
                          </div>
                          <div className="price-wrap">
                            <div className>
                              <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                  <div className="price-wrap-1 float-left">
                                    <input id="one" />
                                    <label htmlFor="one">Year</label>
                                  </div></div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                  <div className="price-wrap_line">-</div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                  <div className="price-wrap-2  float-right">
                                    <input id="two" />
                                    <label htmlFor="two">Year</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </fieldset>
                      </div>
                    </div>
                    <div className="card-header">
                      <h6 className="mb-0 text-dark f-18 bold">Radius</h6>
                      <div className="wrapper">
                        <fieldset className="filter-price">
                        <InputRange draggableTrack maxValue={500} minValue={0} formatLabel={value => `${value}KM`}  value={this.state.radius_value}
                        onChange={radius_value => this.setState({ radius_value })} onChangeComplete={this.getRadius} />
                          {/* <div className="price-field">
                            <input type="range" min={0} max={1000} defaultValue={0} id="lowerradius" />
                            <input type="range" min={0} max={1000} defaultValue={1000} id="upperradius" />
                          </div>
                          <div className="price-wrap">
                            <div className>
                              <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                  <div className="price-wrap-1 float-left">
                                    <input id="oneradius" />
                                    <label htmlFor="oneradius">Km</label>
                                  </div></div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-2">
                                  <div className="price-wrap_line">-</div>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 col-5">
                                  <div className="price-wrap-2  float-right">
                                    <input id="tworadius" />
                                    <label htmlFor="tworadius">Km</label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </fieldset>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Loader loaded={this.state.isLoad}></Loader>
              <div className="col-lg-9 mt-4 pt-2">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="show-results">
                      <div className="float-left">
                        <h5 className="text-dark mb-0 pt-2 f-18 title-heading-sub">Job Listing</h5>
                      </div>
                      <div className="sort-button mob-center float-right">
                        {/* <a href="#" className="btn btn-sm btn-location"><i className="mdi mdi-map-marker mr-1" /></a>
                        <a href="#" className="btn btn-sm btn-alert">Create Alert</a> */}
                        {this.state.map_redirect === false ? <button title="Map View"  onClick={this.getMap} className="btn btn-sm btn-location mr-2" ><i className="mdi mdi-map-marker mr-1"></i> </button> : ""}
                {this.state.map_redirect === true ? <button title="List View" onClick={this.getList} className="btn btn-sm btn-alert mr-2" ><i className="mdi mdi-view-list mr-1" aria-hidden="true" ></i> </button> : ""}
                <button className="btn btn-sm btn-alert" onClick={this.createAlert.bind(this)} value="" disabled={this.state.alert_disable}>Create Alert</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                {this.state.joblist.length > 0 && this.state.map_redirect === false ?
                  <>
                  {this.state.joblist.map((list, i) => (
                  <div key={i} className="col-lg-12 mt-4 pt-2">
                    <div key={i} className="job-list-box border rounded">
                      <div className="p-3">
                        <div className="row align-items-center">
                          {/* <div className="col-lg-2 col-md-2">
                            <div className="company-logo-img text-center">
                              <img src="assets/images/featured-job/img-11.png" alt="" className="img-fluid mx-auto d-block" />
                              <h6 className="mb-2">
                                  <div className="text-dark text-center">UP4HIRE</div></h6>
                            </div>
                          </div> */}
                          <div className="col-lg-7 col-md-8">
                            <div className="job-list-desc">
                              <h6 className="mb-2"><Link to={`/job/${list.id}`} target="_blank" className="text-dark">{list.title}</Link></h6>
                              <p className="text-muted mb-1 col-md-12"  ><i className="fas fa-building mr-1" /> {(list.company).charAt(0).toUpperCase()+(list.company).slice(1)}</p>
                              <ul className="list-inline  mob-align  mb-0 mt-0 ml-3">
                                <li className="list-inline-item mr-3">
                                  <p className="text-muted mb-0"><i className="mdi mdi-map-marker mr-1" />{(list.city).charAt(0).toUpperCase()+(list.city).slice(1)}</p>
                                </li>
                                <li className="list-inline-item mr-3">
                                  <p className="text-muted mb-0"><i className="fas fa-suitcase mr-1" /> {list.position_type}</p>
                                </li>
                                {list.distance != 0 ?
                                <li className="list-inline-item mr-3">
                                  <p className="text-muted mb-0"><i className="fas fa-road mr-1" /> {Number(list.distance).toFixed(2) + "KM"}</p>
                                </li>: null}
                                <li className="list-inline-item">
                                  <p className="text-muted mb-0"><i className="fas fa-calendar-week mr-1" /> {this.getPostDate(list.postedDate)}</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-3 col-md-2">
                            <div className="job-list-button-sm text-right">
                              <div className="mt-3 mob-hide">
                                <Link to={`/job/${list.id}`} target="_blank"  className="btn btn-sm btn-view">View</Link>

                                {/* <Popup
                                            trigger={open => (
                                                <i className="btn btn-sm btn-view" title="View job">View</i>
                                            //   <button className="button">Trigger - {open ? "Opened" : "Closed"}</button>
                                            )}
                                            position="left center"
                                            // closeOnDocumentClick
                                            on={"hover"}
                                            className={"tool-"+list.id}
                                        >
                                            <div className="tool-card">
                                                <div className="tool-header jobList-header">{list.title}</div>
                                                <div className="content">
                                                <p>{list.country} - {list.state},{list.city}, {list.zip}</p>


                                                <div className="jobList-text">
                                                <p><b>Employment Type: </b> </p>
                                                <span>{list.position_type}</span>
                                                </div>
                                                <div className="jobList-text">
                                                <p> <b>Posted Date: </b> </p>
                                                <span>{this.getPostDate(list.postedDate)}</span>
                                                </div>
                                                </div>
                                            </div>
                                        </Popup> */}
                              </div>
                              <div className="mt-3 mob-view">
                                <Link to={`/job/${list.id}`} target="_blank" className="btn btn-sm btn-view">View</Link>

                                {/* <Popup
                                            trigger={open => (
                                                <i className="btn btn-sm btn-view" title="View job">View</i>
                                            //   <button className="button">Trigger - {open ? "Opened" : "Closed"}</button>
                                            )}
                                            position="top center"
                                            // closeOnDocumentClick
                                            on={"hover"}
                                            className={"tool-"+list.id}
                                        >
                                            <div className="tool-card">
                                                <div className="tool-header jobList-header">{list.title}</div>
                                                <div className="content">
                                                <p>{list.country} - {list.state},{list.city}, {list.zip}</p>


                                                <div className="jobList-text">
                                                <p><b>Employment Type: </b> </p>
                                                <span>{list.position_type}</span>
                                                </div>
                                                <div className="jobList-text">
                                                <p> <b>Posted Date: </b> </p>
                                                <span>{this.getPostDate(list.postedDate)}</span>
                                                </div>
                                                </div>
                                            </div>
                                        </Popup> */}
                              </div>
                              <div className="mt-3">
                                {/* <a href="#" className="btn btn-sm btn-apply">Apply</a> */}
                                {list.apply === "Apply" || localStorage.getItem("id") === null ? <button title="Apply job" className="btn btn-sm btn-apply" style={{cursor: 'pointer'}} onClick={() => this.confirm(list.id, list.org)} > Apply </button> : <span> <p id="apply">{list.apply}</p> <p id="apply" >{list.created_at}</p></span>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                    ))
                }
                  </>
                  : <div className="mob-map-height" style={{width: "50%"}}>{this.state.map_redirect === false ? <div className=" no-record-found">No records found!</div> :
                <div className="section-3 map-mob" style={{  width: '100%', height: '39rem' }}>
                    {this.state.joblist !== 0 ?
                        <GoogleMap  data={this.state.joblist} /> : <div className="no-record-found">No records found!</div>}</div>}
                        </div>
                        }

                </div>

                  {/* <div className="col-lg-12 mt-4 pt-2">
                    <nav aria-label="Page navigation example">
                      <ul className="pagination job-pagination mb-0 float-right">
                        <li className="page-item disabled">
                          <a className="page-link" href="#" tabIndex={-1} aria-disabled="true">
                            <i className="mdi mdi-chevron-double-left" />
                          </a>
                        </li>
                        <li className="page-item active"><a className="page-link" href="#">1</a></li>
                        <li className="page-item"><a className="page-link" href="#">2</a></li>
                        <li className="page-item"><a className="page-link" href="#">3</a></li>
                        <li className="page-item"><a className="page-link" href="#">4</a></li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            <i className="mdi mdi-chevron-double-right" />
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div> */}
                  <div className="container pagination-space">
    <div className="col-md-5 col-xs-12 box-align">
        <div className="content">
            <div className="pagination-holder clearfix">
                {this.state.joblist.length > 0 && this.state.map_redirect === false ?
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.itemsCountPerPage}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={this.state.pageRangeDisplayed}
                        onChange={this.handlePageChange.bind(this)}
                    />
                    : null}
            </div>
        </div>
    </div>
</div>
              </div>
            </div>
          </div>
          <a data-toggle="modal" data-target="#alertModel" href="#/" id="alertActive" hidden> </a>
<div className="modal fade" id="alertModel" role="dialog" style={{ display: "none" }}>
    <div className="modal-dialog">
        <div className="container-fluid">
            <div className="col-md-10 col-md-offset-1">
                <div className="modal-content" id="alert-model-content">
                    {/* <a data-dismiss="modal" href="#/" id="modal-dismiss" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                    <div className="form form-mob">
                        <div className="container-fluid jb-resume-container-fluid">
                            <div className="col-md-12 card innerPopup">
                                {/* <div className="head">
                                    <h4>Create Alert</h4>
                                </div> */}
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Create Alert</h5>
                                    <button type="button" id="modal-dismiss" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label>Alert Name</label>
                                        <input type="text" className="form-control fs10-mob" maxLength={25} name="alertname" onChange={this.onAlertHandle.bind(this)} value={this.state.alertname} title="Enter Your Alert Name" placeholder="Enter Your Alert Name" required />
                                    </div>
                                    <div className="form-group col-md-6">

                                    </div>
                                    <div className="form-group col-md-6">
                                        <button className="btn btn-block sign-in-button float-right" onClick={this.storeAlert.bind(this)} disabled={this.state.alertname === ""}>Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<a href="#/" data-toggle="modal" data-target="#loginModel" id="loginActive" hidden> </a>
<div className="modal fade" id="loginModel" role="dialog" style={{ display: "none" }}>
    <div className="modal-dialog">
        <div className="">
        <Loader loaded={this.state.isLoad1}/>
            <div className="col-md-10 col-md-offset-1" id="fade">
                <div className="modal-content jb-signup-pop">
                    {/* <a href="#/" data-dismiss="modal" id="modal-dismiss-login" style={{ position: "relative", left: "42rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                    <div className="form form-mob">
                        <div className="">
                            <div className="col-md-12 card innerPopup">
                                {/* <div className="head">
                                    <h4>LOGIN</h4>
                                </div> */}
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Login</h5>
                                    <button type="button" id="modal-dismiss-login" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                {/* <div className="form-group text-center">
                                <h6>LogIn with Social Media or Manually</h6>
                                    <ul className="  plr0 justify col-md-12" style={{display: "flex",paddingLeft: "unset"}}>
                                        <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                            <FacebookLogin
                                                appId={FB_APP_ID}
                                                fields="name,email,picture"
                                                callback={this.responseFacebook}
                                                render={renderProps => (
                                                    <a href="#/" onClick={renderProps.onClick}><i className="fa fa-facebook-f" style={{fontSize: "19px"}}></i> </a>
                                                )}
                                            />
                                        </li>
                                        <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                            <GoogleLogin
                                                clientId={GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                                render={renderProps => (
                                                    <a href="#/" onClick={renderProps.onClick} disabled={renderProps.disabled}><i className="fab fa-google-plus-g" style={{fontSize: "19px"}}></i> </a>
                                                )}
                                                buttonText="Login"
                                                onSuccess={this.responseGoogle}
                                                onFailure={this.responseGoogle}
                                                cookiePolicy={'single_host_origin'}
                                            />
                                        </li>
                                        <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                        <LinkedIn
                                            clientId={LINKEDIN_CLIENT_ID}
                                            redirectUri={`${window.location.origin}/linkedin`}
                                            scope = {["r_liteprofile","r_emailaddress","w_member_social"]}
                                            state="ba015d"
                                            onFailure={this.handleFailure}
                                            onSuccess={this.handleSuccess}
                                            supportIE
                                            redirectPath='/linkedin'
                                            renderElement={({ onClick, disabled }) => (
                                                <a href="#/" onClick={onClick} disabled={disabled}><i className="fa fa-linkedin " style={{fontSize: "19px"}}></i> </a>
                                            )}
                                            >
                                            </LinkedIn>
                                        </li>
                                    </ul>

                                </div>
                                <hr style={{borderTop: "1px solid #cecece",marginBottom: "1rem"}}/> */}
                                <div className="form-group">
                                    <label>Email  <span  className="text-danger">*</span> </label>
                                    <input type="email" name="email" maxLength={50} onChange={this.onChangeHandle.bind(this)} value={this.state.email} className="form-control fs10-mob" placeholder="Enter your email address" />
                                    {this.state.EmailError !== "" ? <span style={{ color: "red" }}>{this.state.EmailError}</span> : ""}
                                </div>
                                <div className="form-group">
                                    <label>Password   <span  className="text-danger">*</span> </label>
                                    <input type="password" autoComplete="nope" maxLength={15}  name="password" onChange={this.onChangeHandle.bind(this)} value={this.state.password} className="form-control fs10-mob" placeholder="Enter your password" />
                                    {this.state.PasswordError !== "" ? <span style={{ color: "red" }}>{this.state.PasswordError}</span> : ""}
                                </div>
                                <div className="md-checkbox m0" style={{display:"flex"}}>
                                <input id="i6" type="checkbox" style={{marginRight: "8px"}} onChange={this.visibleOrgLogin.bind(this)} defaultChecked={this.visibleStatus} />
                                <label htmlFor="i6" className="fs12-mob job-apply-checkbox" style={{position: "relative",top: "-4px"}}> Make my profile visible to a particular employers.</label>
                            </div><br/>
                            <p  className="float-right forgot-pass"><a href="#"  onClick={this.forgetTab.bind(this)}>Forgot password ?</a></p>
                                <button className="btn btn-block sign-in-button" onClick={this.login.bind(this)} disabled={this.state.email === "" || this.state.password === ""}>SIGN IN</button>
                                <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                                <br />
                          <div className="modelAcc-mob" style={{textAlign: "-webkit-center"}}> Don't you have account?<a href="#/" onClick={this.signUp}> SignUp</a></div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<a href="#/" data-toggle="modal" data-target="#deactivate1" id="deactivate" hidden> </a>
<div className="modal fade" id="deactivate1" role="dialog" style={{ display: "none" }}>
    <div className="modal-dialog">
        <div className="container-fluid">
            <div className="col-md-10 col-md-offset-1">
                <div className="modal-content model-border-st">
                   <div className="form form-mob">
                        <div className="container-fluid jb-resume-container-fluid">
                            <div className="col-md-12 card">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Your Account is deactivated</h5>
                                    <button type="button" id="modal-dismiss-deactivate" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group col-md-12 d-flex">
                                        <div className="mr-1">
                                            <button className="btn btn-block sign-in-button" disabled={this.state.activate_btn} onClick={() => this.activateAccount(1)} >Activate</button><span>&nbsp;&nbsp;</span>&nbsp;&nbsp;
                                        </div>
                                        <div className="ml-1">
                                            <button className="btn btn-block sign-in-button" onClick={() => this.activateAccount(2)} >Cancel</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<a href="#/" data-toggle="modal" data-target="#basicModal" id="basicActive" hidden> </a>
<div className="modal fade" id="basicModal" role="dialog">
    <div className="modal-dialog">
        <div className="container-fluid">
        <Loader loaded={this.state.isLoad1}/>
            <div className="col-md-10 col-md-offset-1" id="fade1">

                <div className="modal-content jb-signup-pop">
                    {/* <a href="#/" data-dismiss="modal" id="basicDisable" style={{ position: "relative", left: "42rem", top: "1rem", zIndex: "3" }} ><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                    <div className="form form-mob card innerPopup">
                    {/* <div className="head mob-ml15">
                                <h4>Sign Up</h4>
                            </div> */}
                            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Sign Up</h5>
                        <button type="button" id="basicDisable" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div className="modal-body" style={{ overflowY: "scroll" ,height:"500px",paddingTop: "10px"}}>
                        <div className="container-fluid" >

                            {/* <form  > */}
                            {/* <div className="form-group text-center">
                                <h6>Sign Up with Social Media or Manually</h6>
                                <ul className="  plr0 justify col-md-12" style={{display: "flex",paddingLeft: "unset"}}>
                                    <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                        <FacebookLogin
                                            appId={FB_APP_ID}
                                            fields="name,email,picture"
                                            callback={this.responseFacebookSign}
                                            render={renderProps => (
                                                <a href="#/" onClick={renderProps.onClick}><i className="fa fa-facebook-f" style={{fontSize: "19px"}}></i> </a>
                                            )}
                                        />
                                    </li>
                                    <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                        <GoogleLogin
                                            clientId={GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                            render={renderProps => (
                                                <a href="#/" onClick={renderProps.onClick} disabled={renderProps.disabled}><i className="fab fa-google-plus-g" style={{fontSize: "19px"}}></i> </a>
                                            )}
                                            buttonText="Login"
                                            onSuccess={this.responseGoogleSign}
                                            onFailure={this.responseGoogleSign}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                    </li>
                                    <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                    <LinkedIn
                                            clientId={LINKEDIN_CLIENT_ID}
                                            redirectUri={`${window.location.origin}/linkedin`}
                                            scope = {["r_liteprofile","r_emailaddress","w_member_social"]}
                                            state="ba015d"
                                            onFailure={this.handleFailureSignup}
                                            onSuccess={this.handleSuccessSignup}
                                            supportIE
                                            redirectPath='/linkedin'
                                            renderElement={({ onClick, disabled }) => (
                                                <a href="#/" onClick={onClick} disabled={disabled}><i className="fa fa-linkedin " style={{fontSize: "19px"}}></i> </a>
                                            )}
                                            >
                                            </LinkedIn>
                                    </li>
                                </ul>

                            </div> */}
{/* <hr style={{borderTop: "1px solid #cecece",marginBottom: "1rem"}}/> */}
                            <div className="form-group">
                                <label>First Name<span style={{ color: "red" }}>*</span>:</label>
                                <input type="text" className="form-control fs10-mob" maxLength={20} name="firstname" onChange={this.onChange.bind(this)} value={this.state.firstname} title="Enter Your First Name" placeholder="Enter Your First Name" required />
                            </div>
                            <div className="form-group">
                                <label>Last Name<span style={{ color: "red" }}>*</span>:</label>
                                <input type="text" className="form-control fs10-mob" maxLength={20} name="lastname" onChange={this.onChange.bind(this)} value={this.state.lastname} title="Enter Your Last Name" placeholder="Enter Your Last Name" required />
                            </div>

                            <div className="form-group">
                                <label>Email<span style={{ color: "red" }}>*</span>:</label>
                                <input type="email" className="form-control fs10-mob" maxLength={50}  name="email_reg" onChange={this.onChange.bind(this)} value={this.state.email_reg} title="Enter your email address" placeholder="Enter your email address" required />
                                {this.state.emailValidMsg !== "" ? <span style={{ color: "red" }}>{this.state.emailValidMsg}</span> : ""}
                            </div>

                            {/* <div className="form-group">
                                <label>Mobile Number<span style={{ color: "red" }}>*</span>:</label>
                                <input type="text" className="form-control fs10-mob" name="phone_number" minLength={9}  maxLength={15} onChange={this.onChange.bind(this)} value={this.state.phone_number} title="Enter your mobile number" placeholder="Enter your mobile number"  />
                                {this.state.phone_number_issue!=""?<p style={{color:"red"}}>{this.state.phone_number_issue}</p>:""}
                            </div>

                            <div className="form-group">
                                <label>Address<span style={{ color: "red" }}>*</span>:</label>
                                <textarea className="form-control fs10-mob" name="address" maxLength={255} onChange={this.onChange.bind(this)} value={this.state.address} title="Enter your Address" placeholder="Enter your Address" ></textarea>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Country<span style={{ color: "red" }}>*</span>:</label>
                                        <select className="form-control fs10-mob" name="country_reg" value={this.state.countryId_reg} onChange={this.onChange.bind(this)} title="Choose your country" placeholder="Choose your country">
                                            <option value="" disabled>Choose your country</option>
                                            {this.state.countries_reg.map((value, key) => {
                                                return <option key={key} value={key}>{value.country}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>State<span style={{ color: "red" }}>*</span>:</label>
                                        <select className="form-control fs10-mob" name="state_reg" id="state_reg" value={this.state.state_reg} onChange={this.onChange.bind(this)} title="Choose your State" placeholder="Choose your State" disabled={this.state.country_reg === ""} >
                                            <option value="" disabled>Choose your state</option>
                                            {this.state.states_reg.map((value, key) => {
                                                if (value.country === this.state.selectedCountry)
                                                    return <option key={key} value={value.id}>{value.state}</option>;
                                            })}
                                        </select>
                                        
                                    </div>
                                </div>

                            </div>
                            <div className="form-group">
                                <div className="row">
                                <div className="col-sm-6">
                                        <label>City<span style={{ color: "red" }}>*</span>:</label>
                                        <input type="text" className="form-control fs10-mob" onChange={this.onChange.bind(this)} value={this.state.city} maxLength={25} name="city" title="Enter your City" placeholder="Enter your City"  />
                                        {this.state.city_error !== "" ? <div className="col-md-12 text-right"><span style={{ color: "red" }}>{this.state.city_error}</span></div> : ""}
                                    </div>
                                    
                                    <div className="col-sm-6">
                                        <label>Zipcode:</label>
                                        <input type="text" className="form-control fs10-mob" name="zipcode" maxLength={7} onChange={this.onChange.bind(this)} value={this.state.zipcode} title="Enter your Zipcode" placeholder="Enter your Zipcode" required />
                                    </div>
                                    {this.state.zipcode_error !== "" ? <div className="col-md-12 text-right"><span style={{ color: "red" }}>{this.state.zipcode_error}</span></div> : ""}
                                </div>

                            </div> */}
                            {/* <div className="form-group"> */}

                          {/* </div> */}
                            <div className="form-group">

                                <label>Password<span style={{ color: "red" }}>*</span>:</label>
                                <input type="password" maxLength={15} autoComplete="nope" className="form-control fs10-mob" name="password_reg" onChange={this.onChange.bind(this)} value={this.state.password_reg} title="Enter your Password" placeholder="Enter your Password" required />
                                {this.state.passwordError !== "" ? <span style={{ color: "red" }}>{this.state.passwordError}</span> : ""}
                            </div>
                            <div className="form-group">
                                <label>Confirm Password<span style={{ color: "red" }}>*</span>:</label>

                                <input type="password" maxLength={15} autoComplete="nope" className="form-control fs10-mob" name="confirmpassword" onChange={this.onChange.bind(this)} value={this.state.confirmpassword} title="Enter your Confirm Password" placeholder="Enter your Confirm Password" disabled={this.state.password_reg.length < 5} />
                                {this.state.passwordStatus === true ? <span style={{ color: "red" }}>confirm Invalid Password. Please tray with valid password !</span> : ""}
                            </div>
                            <label>Upload Resume<span style={{ color: "red" }}>*</span>:</label>
                            <div className="form-group resume-align">
                            {/* <input id="resumeUpload" placeholder="Upload Resume" disabled="disabled" /> */}
                            <label htmlFor="resumeUpload" className="custom-file-upload btn btn-block">
                             <i className="fa fa-cloud-upload"></i> Upload Resume
                          </label>
                          <input name="resume"   id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUpload.bind(this)}  type="file" style={{display:"none"}}/>
                 <label id="file-name"></label>
                            {this.state.invalid_file!==""?<div><br/><span style={{color:"red"}}>{this.state.invalid_file}</span></div>:null}
              {/* <button data-toggle="modal" title="upload you resume here" data-target="#resumeModal" onClick={this.uploadResume} style={uploadBtnStyle}>upload</button> */}
              </div>
                            <div className="md-checkbox m0" style={{display:"flex"}}>
                                <input id="i2" type="checkbox" style={{marginRight: "8px"}}  onChange={this.visibleOrg.bind(this)} defaultChecked={this.visible_reg} />
                                <label htmlFor="i2" className="fs12-mob job-apply-checkbox" style={{position: "relative",top: "-4px"}}> Make my profile visible to all employers.</label>
                            </div><br/>
                            <div className="md-checkbox m0" style={{display:"flex"}}>
                                <input id="i1" type="checkbox" style={{marginRight: "8px"}} onChange={this.changeterms.bind(this)} defaultChecked={this.terms} />
                                <label htmlFor="i1" className="fs12-mob" style={{position: "relative",top: "-4px"}}>I Agree to the <a href="/terms" target="_blank" className="font-bold">Terms of Use</a><span style={{ color: "red" }}>*</span></label>
                            </div><br/>
        {this.state.error_signup!==""?
                        <div>    <span style={{color:"red"}}>{this.state.error_signup}</span><br></br></div>:null}
                            <button type="submit" onClick={this.registerJobseekers} className="btn btn-block sign-in-button" style={{backgroundColor: "#182b6a",color: "white"}} disabled={this.isDisabled()} title="All fields are mandatory">SIGN UP</button>
                            <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                            <br/><br/> {/* <Link to="/login" style={{marginLeft:"10px"}}>Do you want to Login?</Link> */}
                            <div className="modelAcc-mob">Do you have an account? <a href="#/" onClick={this.loginPopup}>SignIn</a></div>
                            <br />
                            {/* </form> */}
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<a  href="#/" data-toggle="modal" data-target="#loginModel1" id="applyActive" hidden> </a>
<div  className="modal fade" id="loginModel1" data-backdrop="static" role="dialog" style={{display:"none"}}>
                        <div  className="modal-dialog">
                            <div  className="container-fluid">
                            <div  className="col-md-10 col-md-offset-1">
                                <div  className="modal-content model-border-st">
                                {/* <a href="#/" data-dismiss="modal" id="modal-dismiss-apply" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                                    <div  className="form form-mob">
                                        <div  className="container-fluid jb-resume-container-fluid">
                                        <div  className="col-md-12 card">
                                            {/* <div  className="head">
                                                <h4>Do you want to Apply?</h4>
                                            </div> */}
                                            <div  className="modal-header">

                                                <h4  className="modal-title">Do you want to Apply?</h4>
                                                <button type="button" id="modal-dismiss-apply"  className="close" data-dismiss="modal">&times;</button>
                                            </div>
                                            <div  className="modal-body">
                                                {this.state.visible_check === true ?
                                                    <div className="form-group">
                                                    <div className="md-checkbox m0 d-flex">
                                                        <input id="i3" type="checkbox" className ="alignCheckbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                                        <label htmlFor="i3" className="fs12-mob job-apply-checkbox"> Make my profile visible to all employers.</label>
                                                    </div>
                                                    </div> : null}
                                                <div className="form-group d-flex">
                                                    <button  className="btn btn-block sign-in-button mr-1" onClick={() => this.confirmation_yes(1)}>Yes</button>
                                                    <button  className="btn btn-block sign-in-button mt-0" onClick={() => this.confirmation_yes(2)} >No</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
{/* <div className="modal fade" id="loginModel1" data-backdrop="static" role="dialog" style={{ display: "none" }}>
    <div className="modal-dialog">
        <div className="container-fluid">
            <div className="col-md-8 col-md-offset-2">
                <div className="modal-content">
                    <a href="#/" data-dismiss="modal" id="modal-dismiss-apply" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a>
                    <div className="form form-mob newModal-pad">
                        <div className="container-fluid">
                            <div className="col-md-12">
                                <div className="head">
                                    <h4>Do you want to Apply?</h4>
                                </div>
                                {this.state.visible_check === true ?

                                    <div className="form-group">
                                        <div className="md-checkbox m0">
                                            <input id="i3" type="checkbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                            <label htmlFor="i3" className="fs12-mob job-apply-checkbox"> Do you want to visible your profile for all organization</label>
                                        </div>
                                    </div> : null}
                                <div className="form-group mob-job-doApply">

                                    <button className="btn btn-block sign-in-button" onClick={() => this.confirmation_yes(1)}>Yes</button>
                                    <button style={{ marginLeft: '30px' }} className="btn btn-block sign-in-button" onClick={() => this.confirmation_yes(2)} >No</button>
                                </div>


                            </div>

                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}
{/* </Loader> */}
<a href="#/" data-toggle="modal" data-target="#resumeModal" id="resumeModal1" hidden> </a>
{/* <div className="modal fade" id="resumeModal" role="dialog" data-backdrop="static" style={{ display: "none" }}>
                    <div className="modal-dialog">
                        <div className="container-fluid">
                        <div className="col-md-10 col-md-offset-2">
                            <div className="modal-content">

                            <a href="#/" data-dismiss="modal" id="closeModel" style={basicCloseStyle} ><i className="fa fa-times" aria-hidden="true"></i> </a>
                            <input type="file" name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUpload.bind(this)} placeholder="Enter your email address" style={{ visibility: "hidden" }} />
                            <div className="form form-mob newModal-pad">

                                <div className="container-fluid">

                                <div className="col-md-12">

                                    <div className="head">
                                    <div style={{color:"red"}}>{this.state.upload_heading}</div><br/>
                                    <h4>Upload Resume</h4>


                                    </div>
                                    <div className="form-group">
                                    <button className="btn btn-block sign-in-button fs12-mob mob-resume-upload" style={{ width: "31rem" }} onClick={activefile} >{this.state.uploadActive===true?this.state.resumeName: "RESUME"}</button>

                                    <br /><br /><span className="mob-mb15"> Max 2 MB, doc, docx, pdf<br />
                                        We will tried to fill your details</span><br /> <br />
                                        {this.state.org_status===1 ?
                                        <div className="form-group">
                                        <div className="md-checkbox m0">
                                            <input id="i8" type="checkbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                            <label htmlFor="i8" className="fs12-mob job-apply-checkbox"> Do you want to visible your profile for all organization</label>
                                        </div>
                                    </div>:null}
                                        <div className="col-md-8"></div>
                                        <button className="btn btn-primary"  onClick={() => this.myResumeUpload(2)}  disabled={this.state.uploadActive===false}>Upload</button>
                                        <br/> <span style={{color:"red"}}>{this.state.invalid_file}</span>


                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div> */}
                    <div className="modal fade" id="resumeModal" role="dialog" data-backdrop="static" style={{ display: "none" }}>
                                    <div className="modal-dialog">
                                        <div className="container-fluid">
                                        <div className=" col-md-offset-1">
                                            <div className="modal-content model-border-st">

                                            {/* <a href="#/" data-dismiss="modal" id="closeModel" style={basicCloseStyle} ><i className="fa fa-times" aria-hidden="true"></i> </a> */}

                                            <div className="form form-mob">
                                                <div className="container-fluid jb-resume-container-fluid">
                                                <div className="col-md-12 card">

                                                    {/* <div className="head">
                                                          <div style={{color:"red"}}>{this.state.upload_heading}</div><br/>
                                                    <h4>Upload Resume</h4>
                                                    </div> */}
                                                    <div  className="modal-header">
                                                        <h4  className="modal-title text-nowrap">Upload Resume</h4>

                                                        <input type="file" name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUpload.bind(this)} placeholder="Enter your email address" style={{ visibility: "hidden" }} />
                                                        <button type="button" id="closeModel"  className="close" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div  className="modal-body">
                                                    <div className="form-group">
                                                    <h6>{this.state.upload_heading}</h6>
                                                    <button className="btn btn-block sign-in-button fs12-mob mob-resume-upload w-100"  onClick={activefile} >{this.state.uploadActive===true?this.state.resumeName: "RESUME"}</button>

                                                    <br /><span className="mob-mb15"> Max 2 MB, doc, docx, pdf<br />
                                                        We will tried to fill your details</span><br /><br/>
                                                        {this.state.org_status===1 ?
                                                        <div className="form-group">
                                                        <div className="md-checkbox m0 d-flex">
                                                            <input id="i8" type="checkbox" className ="alignCheckbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                                            <label htmlFor="i8" className="fs12-mob job-apply-checkbox"> Make my profile visible to all employers.</label>
                                                        </div>
                                                    </div>:null}
                                                        <button className="btn btn-primary float-right"  onClick={() => this.myResumeUpload(2)}  disabled={this.state.uploadActive===false}>Upload</button>
                                                        <br/> <span style={{color:"red"}}>{this.state.invalid_file}</span>
                                                    {/* <span style={{color:this.state.color}}>{this.state.EmailError}</span> */}

                                                    </div>
                                                    </div>
                                                </div>

                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
        </section>
        <style>{"\
                    .react-tags {\
                        border-radius: 12px !important;\
                        padding-left: 34px;\
                        padding-top: 7px;\
                        border: none;\
                      }\
                      .loader {\
                        position: absolute;\
                        top: 0;\
                        right: 0;\
                        bottom: 0;\
                        left: 0;\
                        background: #00000070;\
                        z-index: 9999;\
                      }\
                      @media only screen and (max-width: 768px) {\
                        .react-tags__suggestions {\
                          width: 240px;\
                          position: unset !important;\
                        }\
                      }\
                "}</style>
           </>


        )
    }
}
export default Joblisting;