import React, { Component } from "react";
import axios from 'axios';
import { DOMAIN,GOOGLE_MAP_API_KEY, ATSDOMAIN,GETHOMEPAGE,UPLOAD_REGISTER,REGISTER_JOBSEEKERS,UPDATE_JOBSEEKERS_OFFICIAL } from '../../AppConfig';
import ReactTags from 'react-tag-autocomplete';
// import './react_tag.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from 'react-router-dom';
// import Organization from './Organization';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Loader from 'react-loader';
import { isTSTypeAliasDeclaration } from "@babel/types";
const KeyCodes = {
  enter: 13
};

const delimiters = [KeyCodes.enter];
var freeTxt = "";
class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      show_logo: false,
      content: "",
      banner:"",
      client_logo: [],
      resumeParsedData: [],
      resume_url: "",
      // PERSONAL DETALS
      countries: [],
      states: [],
      country: "",
      selectedCountry: "",
      countryId: "",
      state: "",
      firstname: "",
      lastname: "",
      email: "",
      phone_number: "",
      address: "",
      city: "",
      zipcode: "",
      password: "",
      confirmpassword: "",
      terms: false,
      passwordStatus: false,
      emailValidMsg: "",
      //  Official Information
      designation: [],
      skills: [
      ],
      qualification: "",
      design: "",
      experience: "",
      yearofexp: "",
      suggestions: [
      ],
      designError:"",
      skillError:"",
      titles:[],
      title_suggestions:[],
      titleError:"",
      zip_city:"",
      bannerStyle:{},
      loaded:true,
      is_resume_disable:false,
      isLoad:true,
      error_signup:"",
      phone_number_issue:"",
      i:0,
      freeText:"",
      zipcode_error:""

    }
    this.resumeUploadRegister = this.resumeUploadRegister.bind(this);
    this.registerJobseekers = this.registerJobseekers.bind(this);
    this.searchJob=this.searchJob.bind(this);
    this.getJoblist=this.getJoblist.bind(this);
    this.zipcode_validation = this.zipcode_validation.bind(this);
    // this.postJob=this.postJob.bind(this);
  }
  // postJob(){
  //   document.getElementById('postjob').click();

  // }
  // Signin_signup(flag){
  //   document.getElementById('modal-dismiss-postjob').click();
  //   if(flag===1)
  //   {
  //   this.props.history.push(
  //      '/organization',this.state
  //    )
  //   }
  //   else
  //   {
  //     var win = window.open(ATS_URL, '_blank');
  //     win.focus();
  //   }

  // }
  getJoblist(){
    this.props.history.push('/joblisting');
    return false;
  }
  componentDidMount(){
    // console.log(this.props.location.state.orgcreate);
    if(this.props.location.state!==undefined)
    {
      if(this.props.location.state.orgcreate!==undefined &&  localStorage.getItem('org_create')==1){
        console.log(this.props.location.state.orgcreate);

        toast.success(this.props.location.state.orgcreate, {
          position: toast.POSITION.TOP_CENTER
        });
        localStorage.removeItem('org_create');
      }
    }
  }
  componentWillMount() {
    window.scrollTo(0, 0);
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
    axios.get(DOMAIN + GETHOMEPAGE,
      {
        headers: headers
      }
    )
      .then(response => {
        if (response.data.status === 200) {
          var logo = (response.data.data.home_page.data.client_logo).split('|');
          var banners = (response.data.data.home_page.data.banners).split('|');
          var Suggest = [];
          (response.data.data.get_skills).forEach(value => {
            Suggest.push({ id: value.id, name: value.skill,type:"skill" });
          });
          (response.data.data.get_designation).forEach(value => {
            Suggest.push({ id: value.id, name: value.value,type:"designation" });
          });
          if(response.data.data.home_page.data.client_logo !== "") {
            this.setState({
              show_logo: true
          })
          }
          this.setState({
            content:response.data.data.home_page.data.content,
            banner:banners,
            client_logo: logo,
            title_suggestions:Suggest,

          })
          if(banners.length>0){
            this.setState({
              bannerStyle:{
                backgroundImage: 'url('+ATSDOMAIN+banners[0]+')'
              }
            })
            if(banners.length>1){
              this.setState({
                i:1
              });
            }
          }
          setInterval(() => {
            var index = this.state.i;
            var ban_count = banners.length;
            if(index>=ban_count){
              if(banners[index]!=undefined){
                this.setState({
                  i:0,
                  bannerStyle:{
                    backgroundImage: 'url('+ATSDOMAIN+banners[index]+')'
                  }
                })
              }else{
                this.setState({
                  i:0
                })
              }
            }else{
              if(banners[index]!=undefined){
              this.setState({
                i:this.state.i+1,
                bannerStyle:{
                  backgroundImage: 'url('+ATSDOMAIN+banners[index]+')'
                }
              })
            }else{
              this.setState({
                i:this.state.i+1
              })
            }
            }
            // console.log(this.state.freeText);
            console.log(freeTxt);
            // console.log(document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0].value);
            // if(document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0].value===""){
              // setTimeout(() => {
                if(freeTxt===""){
                  console.log(this.state.freeText);
                  if(document.getElementsByClassName('react-tags__search-input')[0])
                    document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0].value="";
                }
              // }, 100);
            // }


          }, 5000);
        }
      })
      .catch(function (error) {
        console.log(error);
      })
  }
  searchJob(){
    if(this.state.titles.length!==0 || this.state.zip_city!=="" || this.state.freeText !== "")
    {
      console.log(this.state.freeText);
      if(this.state.freeText!==""){
          const tempSkills =this.state.titles;
          tempSkills.push({id:1000+'_'+this.state.freeText,name:this.state.freeText,type:"freeText"})
          this.setState({titles:tempSkills})
      }
      this.props.history.push('/joblisting',this.state);
    }
    else
    {
      toast.error("Please fill atleast one field to proceed further !", {
        position: toast.POSITION.TOP_CENTER,
    });
    }

  }
  resumeUploadRegister(e) {
    //e.preventDefault();
    this.setState({
      loaded:false,
      is_resume_disable:true
    })
    document.getElementById("fade_upload").style.opacity = "0.5";
    document.getElementById("fade_upload").style['pointer-events'] = 'none';
    var formData = new FormData();
    formData.append('file', e.target.files[0]);
    axios.post(DOMAIN + UPLOAD_REGISTER, formData)
      .then(response => {
        document.querySelector('input[type="file"]').value = null
        if (response.data.status === 200) {
          document.getElementById("fade_upload").style.opacity = "unset";
            document.getElementById("fade_upload").style['pointer-events'] = '';
          this.setState({
            loaded:true,
            is_resume_disable:false
          })
          var parse = JSON.parse(response.data.data);
          if(parse.exception!==""){
            toast.error(parse.exception, {
              position: toast.POSITION.TOP_CENTER
            });
          }else{
          var Suggest = [];
          (response.data.basicData.skills).forEach(value => {
            Suggest.push({ id: value.id, name: value.skill });
          });
          this.setState({
            resumeParsedData: parse,
            countries: response.data.basicData.country,
            states: response.data.basicData.state,
            designation: response.data.basicData.designation,
            resume_url: response.data.url,
            suggestions: Suggest
          })
          if (parse.basics) {
            let names = parse.basics.name[0];
            let name = names.split(" ");
            let firstnam ="";
            if(name.length>0){
              firstnam = name[0];
            }
            let lastnam = "";
            if(name.length>1){
              lastnam = name[1];
            }
            this.setState({
              firstname: firstnam,
              lastname: lastnam,
              email: parse.basics.email[0],
              phone_number: parse.basics.phone[0],
            })
          }

          document.getElementById('closeModel').click();
          document.getElementById('basicActive').click();
        }
        }
      })
  }
  zipcode_validation(zipcode, input_country, input_city) {
    const header = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Accept": "application/json",
    }
    axios.get('https://maps.googleapis.com/maps/api/geocode/json?key='+GOOGLE_MAP_API_KEY+'&components=postal_code:'+zipcode, {
        headers: header
    })
    .then(response => {

            if (Object.keys(response.data.results).length > 0) {
                var address = response.data.results[0].address_components;
                var zipcode_error = "";

                //break up the components
                var country = "", city = "";
                address.forEach(function (item, index) {
                    var type = item.types[0];

                    if (city == "" && (type == "sublocality_level_1" || type == "locality")) {
                        city = (item.long_name).trim();
                    }
                    // if ($state == "" && type=="administrative_area_level_1") {
                    //     $state = trim(item.short_name);
                    // }
                    if (country == "" && type == "country") {
                        country = (item.long_name).trim();

                    }

                })
                if (country.toLowerCase().toString() != 'canada' && country.trim().toLowerCase().toString() != 'united states') {
                    zipcode_error = "This Zipcode is not available in this Contry and City";
                    this.setState({
                        zipcode_error : zipcode_error
                        });
                }
                if (((country.toLowerCase().toString()) == 'canada' && input_country != 'Canada') || ((country.toLowerCase().toString()) == 'united states' && input_country != 'United States')) {

                    zipcode_error = "Country";
                }
                if ((country.toLowerCase().toString()) != 'canada' && (country.toLowerCase().toString()) != 'united states') {
                    zipcode_error = "Country";
                }
                if ((input_city).toLowerCase() != (city.toLowerCase())) {
                    if (zipcode_error != "") {
                        zipcode_error = zipcode_error + " and City"
                    } else {
                        zipcode_error = "City";
                    }
                }
                if (zipcode_error != "") {
                    zipcode_error = "This Zipcode is not available in this " + zipcode_error + ". This Zipcode is available from (" + country + " and " + city + ")";

                    this.setState({
                        zipcode_error : zipcode_error
                        });
                } else {
                    this.setState({
                        zipcode_error : zipcode_error
                        });
                }
            }else{
              if(zipcode!=""){
                this.setState({
                    zipcode_error : "zipcode is not valid"
                    });
                  }
            }
        })
 }
  onChange(e) {
    if (e.target.name === 'country') {
      this.setState({
        countryId: e.target.value,
        selectedCountry: this.state.countries[e.target.value].country,
        country: this.state.countries[e.target.value].id,
      })
      // this.zipcode_validation(this.state.zipcode,this.state.countries_reg[e.target.value].country,this.state.city);
      // this.state.country=this.state.countries[e.target.value].id;
      // this.state.selectedCountry=this.state.countries[e.target.value].country;

    } else if (e.target.name === 'city') {
      this.setState({
          city: e.target.value

      })
      // this.zipcode_validation(this.state.zipcode,this.state.selectedCountry,e.target.value);
      // this.state.country=this.state.countries[e.target.value].id;
      // this.state.selectedCountry=this.state.countries[e.target.value].country;

  } else if (e.target.name === 'phone_number') {
      const numregx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
      if (numregx.test(e.target.value) || e.target.value === "") {
        if(e.target.value.length<9){
          this.setState({
              phone_number_issue:"Please enter valid phone number.Phone number must be a minimum Country Code + 10 digits !"
          })
      }else{
          this.setState({
              phone_number_issue:""
          })
      }
        this.setState({
          [e.target.name]: e.target.value
        })
      }
    } else if(e.target.name==='qualification'){
      const qualifyregx = /^[^:;<>%$#&"/|*()?=^@!~`]*$/;
      if(qualifyregx.test(e.target.value) || e.target.value===""){
        this.setState({ qualify: e.target.value,});
      }

  } else if(e.target.name==='zipcode'){
      const zipregx=/^[^/+<>%#@&"*?=^!~`.,'_:;\$/|\/\\{}()\[/\]/-]*$/
      if(zipregx.test(e.target.value) || e.target.value===""){
         this.setState({
            [e.target.name]:e.target.value
         })
         this.zipcode_validation(e.target.value,this.state.selectedCountry,this.state.city);
      }
   } else if (e.target.name === 'firstname' || e.target.name === 'lastname' || e.target.name === 'city') {
      const strregx = /^[A-Za-z]+$/;
      if (strregx.test(e.target.value) || e.target.value === "") {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
    } else if (e.target.name === 'address') {
      const addressregx = /^[^<>%*?=^!~`]*$/;
      if (addressregx.test(e.target.value) || e.target.value === "") {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
    }  else if (e.target.name === 'zip_city') {
      const zip_cityregx = /^[^/+<>%#@&"*?=^!~`.,'_:;\$/|\/\\{}()\[/\]/-]*$/;
      if(zip_cityregx.test(e.target.value)){
          this.setState({
              [e.target.name]: e.target.value,

          })
      }

  } else if(e.target.name === 'design'){
        this.setState({
          designError:"",
          [e.target.name]: e.target.value
        })
    } else if (e.target.name === 'email') {
      const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (emailregex.test(e.target.value)) {
        this.setState({
          emailValidMsg: "",
          error_signup:""

        })
      } else {
        this.setState({
          emailValidMsg: "Please enter a valid  email id !",
          error_signup:"Please check your form carefully"
        })
      }
      this.setState({
        [e.target.name]: e.target.value
      })
    } else {
      if (e.target.name === 'password') {
        if (e.target.value.length < 5) {
          this.setState({
            passwordError: "please type minimum length 5",
            error_signup:"Please check your form carefully"
          })
        } else {
          this.setState({
            passwordError: "",
            error_signup:""
          })
        }
      }
      if(e.target.name==='confirmpassword'){
        this.setState({
           passwordStatus:false,
           error_signup:""
        })
  }
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }
  changeterms() {
    if (this.state.terms) {
      this.setState({
        terms: false,
      })
    } else {
      this.setState({
        terms: true
      })
    }
  }
  registerJobseekers() {
    var personaldata = this.state;
    if (personaldata.password !== personaldata.confirmpassword) {
      this.setState({
        passwordStatus: true,
        error_signup:"Please check your form carefully"
      })
    } else {
      var RegisterData = {
        country: personaldata.country,
        state: personaldata.state,
        firstname: personaldata.firstname,
        lastname: personaldata.lastname,
        email: personaldata.email,
        phone_number: personaldata.phone_number,
        address: personaldata.address,
        city: personaldata.city,
        zipcode: personaldata.zipcode,
        password: personaldata.password,
        org_visible_status:1,
        flag:0
      }

      const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
      document.getElementById("fade").style.opacity = "0.5";
      document.getElementById("fade").style['pointer-events'] = 'none';
      this.setState({isLoad:false});
      axios.post(DOMAIN + REGISTER_JOBSEEKERS, {
        data: RegisterData
      },
        {
          headers: headers
        }
      )
        .then(response => {
          document.getElementById("fade").style.opacity = "unset";
                    document.getElementById("fade").style['pointer-events'] = '';
                    this.setState({isLoad:true});
          if (response.data.status === 200) {
            // localStorage.setItem("token", response.data.api_token);
            // localStorage.setItem("firstname", this.state.firstname);
            // localStorage.setItem("lastname", this.state.lastname);
            // localStorage.setItem('email', this.state.email);
            // localStorage.setItem('id', response.data.user_id);
            //  this.props.history.push('/joblisting');
            document.getElementById('basicDisable').click();
            toast.error("Verification sent to your registered email Id. Please verify your email and action ! ", {
              position: toast.POSITION.TOP_CENTER
          });
            // document.getElementById('officialActive').click();
          } else {
            if (response.data.error_code === 1) {
              this.setState({
                emailValidMsg: response.data.msg
              })
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  }
  isDisabled() {
    if (this.state.firstname === "" || this.state.lastname === "" || this.state.terms === false || this.state.password === "" || this.state.confirmpassword === "" ||  this.state.email === "" || this.state.emailValidMsg !== "" || this.state.passwordError !== "") {
      return true;
    }
    return false;
  }
  handleChange(skills) {
    this.setState({
      skills
    })
  }
  updateOfficial() {
    if (this.state.design === "") {
      this.setState({
        designError: "Please select designation"
      })
    }
    else if (this.state.skills.length <= 0) {
      this.setState({
        skillError: "Please type atleast one skill"
      })
    } else {
      const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": localStorage.getItem('token')
      }
      axios.post(DOMAIN + UPDATE_JOBSEEKERS_OFFICIAL,
        {
          id: localStorage.getItem('id'),
          design: this.state.design,
          experience: this.state.experience,
          yearOfExp: this.state.yearofexp,
          qualification: this.state.qualification,
          skills: this.state.skills,
          resume_location: this.state.resume_url
        },
        {
          headers: headers
        }
      )
        .then(response => {
          // document.getElementById('officialDisable').click();
          if (response.data.status === 200) {
            document.getElementById('officialDisable').click();

            // setTimeout(function(){
              // this.getJoblist();
              this.props.history.push('/joblisting',this.state);
            // }.bind(this),2000)

          }else if(response.data.status === 401){
            localStorage.clear();
            this.props.history.push('/login');
          }
        })
        .catch(function (error) {
          console.log(error);
        })
    }
  }
  handleAddition(skill) {
    this.setState({
      skillError:""
    })
    const skills = [].concat(this.state.skills, skill);
    const suggestions = this.state.suggestions.filter(x => x.id !== skill.id);

    this.setState({ skills, suggestions });
  }
  handleDelete(i) {
    if (i > -1) {
      const skills = this.state.skills.slice(0)
      skills.splice(i, 1)
      const removedTag = this.state.skills[i];
      const suggestions = [].concat(removedTag,this.state.suggestions);
      this.setState({ skills, suggestions })
    }
  }
  handleFreeText(query){
    // console.log(this.state.freeText);
  this.setState({freeText:query})
  freeTxt = query;
  }
  handleTitleAddition(title) {
    if(title.id!=undefined){
      let is_exist = this.state.titles.find(el => (el.name).toLowerCase() === (title.name).toLowerCase());
      if(is_exist==undefined){
        this.setState({
          titleError:""
        })
        const titles = [].concat(this.state.titles, title);
        const title_suggestions = this.state.title_suggestions.filter(x => x.id !== title.id);
        this.setState({ titles, title_suggestions,freeText:""});
        freeTxt="";
      }

    }else{
      if(title.name!=undefined){
        let is_exist = this.state.titles.find(el => (el.name).toLowerCase() === (title.name).toLowerCase());
            if(is_exist==undefined){
              let select_skill = {id:1000+'_'+title.name,name:title.name,type:"freeText"};
              const titles = [].concat(this.state.titles, select_skill);
              this.setState({ titles,freeText:""});
              freeTxt="";
            }
      // this.handlePageChange(1);
      }else{
        if(this.state.freeText!=""){
          let is_exist = this.state.titles.find(el => (el.name).toLowerCase() === (this.state.freeText).toLowerCase());
            if(is_exist==undefined){
                let freeTxt1 = this.state.freeText;
                let select_skill = {id:1000+'_'+freeTxt,name:freeTxt1,type:"freeText"};
                const titles = [].concat(this.state.titles, select_skill);


                // console.log(document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0].value);
                // document.getElementsByClassName('react-tags__search-input')[1].getElementsByTagName('input')[0].value="";
                this.setState({ titles });
              }
              this.setState(
                {
                  freeText:""
                }
              )
              freeTxt="";
// console.log();
        }
      }
      setTimeout(function(){
        if(document.getElementsByClassName('react-tags__search-input')[0])
        document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0].value="";
      },100)
    }

  }

  handleTitleDelete(i) {
    console.log(i);
    if (i > -1) {
      const titles = this.state.titles.slice(0)
      titles.splice(i, 1)
      const removedTag = this.state.titles[i];
      if(this.state.titles[i].type!=='freeText'){
        const title_suggestions = [].concat(removedTag,this.state.title_suggestions);
        this.setState({ titles, title_suggestions })
      }else{
        this.setState({ titles })
      }
    }
  }
  componentWillReceiveProps(){
    if(this.props.location.state != undefined)
        if(this.props.location.state.Uploadresume == 'upload')
        document.getElementById('uploadResumeBlock').click();
  }

  render() {
    const card = document.querySelector('body');

  card.addEventListener('click', function (e) {
    // console.log(e);
    // e.preventDefault();
    if(document.getElementById('tag_search'))
    document.getElementById('tag_search').click();
  });
  if(document.getElementsByClassName('react-tags__search-input')[0]){
  const taginput = document.getElementsByClassName('react-tags__search-input')[0].getElementsByTagName('input')[0];
  taginput.addEventListener('click', function (e) {
    e.target.value = "";
  });
}
// document.addEventListener('click', function(event) {
//    console.log('test click');
// });
    if(this.props.location.state!==undefined)
        {
            if(this.props.location.state.Footer!==undefined){
                window.scrollTo(0, 0);
                this.props.history.push('/');
            }
       }
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        partialVisibilityGutter: 20,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        partialVisibilityGutter: 30
      }
    };
    function activefile() {
      document.getElementById('resumeUpload').click();
    }

    var basicCloseStyle = {
      left: "19rem",
      position: "absolute",
      top: "14px",
      zIndex: "10",
      color: "black"
    }
    let resumeComp;
    resumeComp = (
      <React.Fragment>
          <div className="modal-content " id="fade_upload">
                <Loader loaded={this.state.loaded} zIndex={2e9}/>


                  <div className="form form-mob">
                    <div className="container-fluid jb-resume-container-fluid">
                      <div className="col-md-12 card innerPopup">
                        {/* <div className="card-head">
                          <h4>Upload Resume</h4>
                          <a href="#/" data-dismiss="modal" id="closeModel" style={basicCloseStyle}><i className="fa fa-times" aria-hidden="true"></i> </a>
                          <input type="file" name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUploadRegister.bind(this)} placeholder="Enter your email address" style={{ visibility: "hidden",position:"absolute" }} />
                        </div>
                        <div className="card-body">
                          <button className="btn btn-block upload-button fs12-mob"  onClick={activefile} disabled={this.state.is_resume_disable}>UPLOAD RESUME</button>
                          <span> Max 2 MB, doc, docx, pdf<br />
                            We will try to fill your details</span> */}

                          {/* <span style={{color:this.state.color}}>{this.state.EmailError}</span> */}

                        {/* </div> */}
                        <div className="modal-header">
                        <input type="file" name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUploadRegister.bind(this)} placeholder="Enter your email address" style={{ visibility: "hidden",position:"absolute" }} />
                          <h5 className="modal-title" id="exampleModalLabel">Upload Resume</h5>
                          <button type="button" id="closeModel" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                        <button className="btn btn-block upload-button fs12-mob"  onClick={activefile} disabled={this.state.is_resume_disable}>UPLOAD RESUME</button>
                          <span> Max 2 MB, doc, docx, pdf<br />
                            We will try to fill your details</span>
                        </div>
                        {/* <div className="modal-footer">
                          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                      </div>

                    </div>
                  </div>
                </div>
      </React.Fragment>
    )
    return (
      <React.Fragment>
        <button id="tag_search" onClick={this.handleTitleAddition.bind(this)}></button>
<ToastContainer />
<section className="bg-home" style={this.state.bannerStyle}>
          <div className="bg-overlay" />
          <div className="home-center">
            <div className="home-desc-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-12">
                    <div className="title-heading text-center text-white">
                      <h1 className="heading font-weight-bold banner-font">Find Your Job… You Deserve It.</h1>
                      {/* <h6 className="small-title text-uppercase text-light mb-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h6> */}
                    </div>
                  </div>
                </div>
                <div className="home-form-position">
                  <div className="row banner-form-offset">
                    <div className="col-lg-12">
                      <div className="home-registration-form p-3 mb-3  text-center">
                        <div className="registration-form">
                          <div className="row">
                            <div className="col-lg-4 col-md-6">
                              <div className="registration-form-box">
                                <i className="fa fa-briefcase" style={{zIndex:"4"}}/>
                                <ReactTags
                                id ={'tag_id'}
                                maxSuggestionsLength={500}
                          tags={this.state.titles}
                          suggestions={this.state.title_suggestions}
                          noSuggestionsText={'No suggestions found'}
                          placeholder={'Job Title / Skill'}
                          handleDelete={this.handleTitleDelete.bind(this)}
                          handleAddition={this.handleTitleAddition.bind(this)}
                          handleInputChange={this.handleFreeText.bind(this)}
                          delimiters={delimiters}
                                        allowNew={true} />
                            {this.state.titleError !== "" ? <span style={{ color: "red" }}>{this.state.titleError}</span> : ""}
                                {/* <input type="text" id="exampleInputName1" className="form-control rounded registration-input-box" placeholder="Enter Job titles/Skills" /> */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="registration-form-box">
                                <i className="fa fa-list-alt" />
                                <input type="text" autoComplete="nope" id="exampleInputName1" name="zip_city" onChange={this.onChange.bind(this)} maxLength={25}  value={this.state.zip_city} className="form-control rounded registration-input-box" placeholder="Enter City/Pincode" />
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                              <div className="registration-form-box homeSumbit">
                                <input type="button" id="submit" name="send" className="submitBnt btn btn-primary btn-block" defaultValue="Search" onClick={this.searchJob} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mt-4 pt-2">
              {!localStorage.getItem("id")?
               <div className="hoverCursor blackText" id="uploadResumeBlock" data-toggle="modal" data-target="#registerModal">
                <div className="how-it-work-box bg-light p-4 text-center rounded shadow">
                  <div className="how-it-work-img position-relative rounded-pill mb-3">
                      <img src="assets/images/how-it-work/uploadresume.png" alt="" className="mx-auto d-block" height={80} />
                  </div>
                  <div>
                    <h4>Upload Resume</h4>
                    {/* <p className="text-muted paragraph-medium">We will create your profile</p> */}
                  </div>
                </div> </div>:""}
              </div>

              <div className="col-md-4 mt-4 pt-2">
              <div className="hoverCursor">
              <Link className="hoverCursor blackText" to="/organization" onClick={this.postJob}>
                <div className="how-it-work-box bg-light p-4 text-center rounded shadow">
                  <div className="how-it-work-img position-relative rounded-pill mb-3">
                    <img src="assets/images/how-it-work/ats.png" alt="" className="mx-auto d-block" height={80} />
                   </div>
                  <div>
                    <h4>Register For ATS</h4>
                    {/* <p className="text-muted paragraph-medium">We will create your profile</p> */}
                  </div>
                </div>
              </Link>
              </div>
              </div>
              <div className="col-md-4 mt-4 pt-2">
              {!localStorage.getItem("id")?
              <div className="hoverCursor">
               <Link className="hoverCursor blackText" to="/signup">
                <div className="how-it-work-box bg-light p-4 text-center rounded shadow">
                  <div className="how-it-work-img position-relative rounded-pill mb-3">
                  <img src="assets/images/how-it-work/register.png" alt="" className="mx-auto d-block" height={80} />
                  </div>
                  <div>
                    <h4>Create Jobseeker</h4>
                    {/* <p className="text-muted paragraph-medium">We will create your profile</p> */}
                  </div>
                </div></Link></div>:""}
              </div>
            </div>
          </div>
        </section>
        <section className="section bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="pb-4">
                  <img src="assets/images/aboutus.jpg" className="img-fluid rounded shadow" alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="section-title text-left mb-4 pb-0">
                  <h2 className="title pb-0 title-heading about">About Us</h2>
                </div>
                <p className="text-muted para-desc mx-auto mb-1 justify" dangerouslySetInnerHTML={{ __html: this.state.content }}></p>
                </div>
            </div>
          </div>
        </section>
        {this.state.show_logo === true ?
        <section className="section">
          <div className="container mt-60">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="section-title text-center mb-4 pb-2">
                  <h4 className="title title-line pb-5 title-heading">Our Client's</h4>
                </div>
              </div>
            </div>
            <div className="container text-center">
              <div className="row">
                <div className="col-md-12">

                        <Carousel  autoPlay={true}
                      autoPlaySpeed={3000}
                      infinite={true}
                      centerMode={true}
                      renderButtonGroupOutside={true}
                      responsive={responsive}>
                          {this.state.client_logo.map((value, key) => {
                            return (<div key={key} ><img src={ATSDOMAIN + value} alt="Touch" style={{minWidth: "129px"}} className="img-responsive" /></div>);
                          })}
                      </Carousel>
                  </div>
                 </div>
            </div>
          </div>
        </section>:''
  }
        {/* <div className="container-fluid no-padding section-1 hero-left job-banner-overlay">
          <div className="col-md-12 col-sm-12 hero-right second-banner" style={this.state.bannerStyle}>

            <div className="container h-450">
              <div className="row">
                <div className="col-md-12">
                  <div className="job-banner-txt">
                    <h4>Find your job you deserve it.</h4>
                    <p>Lorem Iposum Lorem Iposum Lorem Iposum Lorem Iposum Lorem Iposum Lorem
                      Iposum Lorem Iposum Lorem Iposum Lorem Iposum Lorem Iposum Lorem Iposum
                      Lorem Iposum
                    </p>

                  <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                    <div className="white-space-box-2 job-banner-search">
                  <div className="form-group row"  onKeyPress={event => {
                if (event.key === 'Enter') {
                  this.searchJob()
                }
              }}>
                    <div className="col-md-5 col-sm-5 p0">
                    <ReactTags  maxSuggestionsLength={500}
                          tags={this.state.titles}
                          suggestions={this.state.title_suggestions}
                          noSuggestionsText={'No suggestions found'}
                          placeholder={'Search by Job Titles and Skills'}
                          handleDelete={this.handleTitleDelete.bind(this)}
                          handleAddition={this.handleTitleAddition.bind(this)} />
                            {this.state.titleError !== "" ? <span style={{ color: "red" }}>{this.state.titleError}</span> : ""}
                    </div>
                    <div className="col-md-4 col-sm-4 p0">
                    <input type="text" className="form-control banner-zip fs10-mob br0" name="zip_city" onChange={this.onChange.bind(this)} value={this.state.zip_city}  placeholder=" City or Zip Code" />
                    </div>
                    <div className="col-md-3 col-sm-3 p0">
                    <button className="btn btn-block sign-in-button job-search-btn banner-btn br0" onClick={this.searchJob}>SEARCH</button>
                    </div>
                  </div>
                </div>
                    </div>
                  </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="row">

                <div>

                <div className="col-md-4 col-sm-4">
                {!localStorage.getItem("id")?
                  <div className="jobPort-section">
                  <div className="jobPort-img">
                  <a href="#/" data-toggle="modal" data-target="#registerModal">  <img src="assets/images/resume.png" alt=""/> </a>
                    </div>
                  <div className="jobPort-txt">
                  <a href="#/" data-toggle="modal" data-target="#registerModal"> <h4>Upload Resume</h4></a>
                      <p>We will create your profile</p>
                    </div>
                  </div>
                   :null}
                </div>


                <div className="col-md-4 col-sm-4">
                  <div className="jobPort-section">
                    <div className="jobPort-img">
                    <Link to="/organization" onClick={this.postJob}><img src="assets/images/register1.png" alt=""/></Link>
                    </div>
                    <div className="jobPort-txt">
                    <Link to="/organization" onClick={this.postJob}> <h4>Register With ATS</h4></Link>
                      <p>We will create your profile</p>
                    </div>
                  </div>
                </div>


                <div className="col-md-4 col-sm-4">
                {!localStorage.getItem("id")?
                  <div className="jobPort-section">
                  <div className="jobPort-img">
                  <Link to="/signup">  <img src="assets/images/register.png" alt=""/></Link>
                    </div>
                  <div className="jobPort-txt">
                  <Link to="/signup">   <h4>Register with Us</h4></Link>
                      <p>We will create your profile</p>
                    </div>
                  </div>
                   :null}
                </div>

                </div>

              </div>
            </div>
          </div>
        </div>



        <div className="container">
        <div className="owl-sejc job-client-section">
<div className="owl-sec">

   <div className="row">
          <div className="col-md-12">
             <div className="client-head">
              <h4>Our Clients</h4>
            </div>

  <Carousel  autoPlay={true}
autoPlaySpeed={3000}
infinite={true}
centerMode={true}
renderButtonGroupOutside={true}
responsive={responsive}>

    {this.state.client_logo.map((value, key) => {
      return (<div key={key} ><img src={ATSDOMAIN + value} alt="Touch" style={{minWidth: "129px"}} className="img-responsive" /></div>);
    })}
</Carousel>
          </div>
   </div>
  </div>
</div>

</div>




        <div className="container content-section">

          <div className="col-md-6 col-sm-5">
            <div>
              <img src="assets/images/content.png" alt="" className="img-responsive" />
            </div>
          </div>
          <div className="col-md-6 col-sm-7">
          <div className="job-content-para">
            <p dangerouslySetInnerHTML={{ __html: this.state.content }}></p>
          </div>
          </div>

        </div> */}



        <a href="#/" data-toggle="modal" data-target="#basicModal" id="basicActive" hidden> </a>
        <a href="#/" data-toggle="modal" data-target="#officialModal" id="officialActive" hidden> </a>
        <div className="modal fade" id="registerModal" role="dialog" data-backdrop="static" style={{ display: "none" }}>

          <div className="modal-dialog">
            <div className="container-fluid">
              <div className="col-md-10 col-md-offset-1">

                {resumeComp}
              </div>
            </div>
          </div>

        </div>
        <div className="modal fade" id="basicModal" role="dialog" data-backdrop="static">
          <div className="modal-dialog">
            <div className="container-fluid ">
            <Loader loaded={this.state.isLoad}/>
              <div className="col-md-10 col-md-offset-1" id="fade">

                <div className="modal-content jb-signup-pop">
                  {/* <a href="#/" data-dismiss="modal" id="basicDisable" style={basicCloseStyle}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                  <div className="form form-mob card innerPopup">
                  <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Sign Up</h5>
                        <button type="button" id="basicDisable" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                      <div className="modal-body" style={{ overflowY: "scroll" ,height:"383px"}}>
                    <div className="container-fluid" >

                      {/* <form  > */}

                      <div className="form-group">
                        <label>First Name<span style={{ color: "red" }}>*</span>:</label>
                        <input type="text" className="form-control fs10-mob" maxLength={20} name="firstname" onChange={this.onChange.bind(this)} value={this.state.firstname} title="Enter Your First Name" placeholder="Enter Your First Name" required />
                      </div>
                      <div className="form-group">
                        <label>Last Name<span style={{ color: "red" }}>*</span>:</label>
                        <input type="text" className="form-control fs10-mob" maxLength={20} name="lastname" onChange={this.onChange.bind(this)} value={this.state.lastname} title="Enter Your Last Name" placeholder="Enter Your Last Name" required />
                      </div>

                      <div className="form-group">
                        <label>Email<span style={{ color: "red" }}>*</span>:</label>
                        <input type="email" maxLength={50} className="form-control fs10-mob" name="email" onChange={this.onChange.bind(this)} value={this.state.email} title="Enter your email address" placeholder="Enter your email address" required />
                        {this.state.emailValidMsg !== "" ? <span style={{ color: "red" }}>{this.state.emailValidMsg}</span> : ""}
                      </div>

                      {/* <div className="form-group">
                        <label>Mobile Number:</label>
                        <input type="text" className="form-control fs10-mob" minLength={9} maxLength={15} name="phone_number" onChange={this.onChange.bind(this)} value={this.state.phone_number} title="Enter your mobile number" placeholder="Enter your mobile number" required />
                        {this.state.phone_number_issue!=""?<p style={{color:"red"}}>{this.state.phone_number_issue}</p>:""}
                      </div>

                      <div className="form-group">
                        <label>Address:</label>
                        <textarea className="form-control fs10-mob" maxLength={255} name="address" onChange={this.onChange.bind(this)} value={this.state.address} title="Enter your Address" placeholder="Enter your Address" required></textarea>
                      </div>

                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-6">
                            <label>City:</label>
                            <input type="text" className="form-control fs10-mob" onChange={this.onChange.bind(this)} value={this.state.city} maxLength={25} name="city" title="Enter your City" placeholder="Enter your City" required />
                          </div>
                          <div className="col-sm-6">
                            <label>Country:</label>
                            <select className="form-control fs10-mob" name="country" value={this.state.countryId} onChange={this.onChange.bind(this)} title="Choose your country" placeholder="Choose your country">
                              <option value="" disabled>Choose your country</option>
                              {this.state.countries.map((value, key) => {
                                return <option key={key} value={key}>{value.country}</option>;
                              })}
                            </select>
                          </div>
                        </div>

                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-6">
                            <label>State:</label>
                            <select className="form-control fs10-mob" name="state" id="state" value={this.state.state} onChange={this.onChange.bind(this)} title="Choose your State" placeholder="Choose your State" disabled={this.state.country === ""} required>
                              <option value="" disabled>Choose your state</option>
                              {this.state.states.map((value, key) => {
                                if (value.country === this.state.selectedCountry)
                                  return <option key={key} value={value.id}>{value.state}</option>;
                              })}
                            </select>
                            
                          </div>
                          <div className="col-sm-6">
                            <label>Zipcode<span style={{ color: "red" }}>*</span>:</label>
                            <input type="text" className="form-control fs10-mob" name="zipcode" maxLength={7} onChange={this.onChange.bind(this)} value={this.state.zipcode} title="Enter your Zipcode" placeholder="Enter your Zipcode" required />
                          </div>
                          {this.state.zipcode_error !== "" ? <div className="col-md-12 text-right"><span style={{ color: "red" }}>{this.state.zipcode_error}</span></div> : ""}
                        </div>

                      </div> */}

                      <div className="form-group">

                        <label>Password<span style={{ color: "red" }}>*</span>:</label>
                        <input type="password" maxLength={15} autoComplete="nope" className="form-control fs10-mob" name="password" onChange={this.onChange.bind(this)} value={this.state.password} title="Enter your Password" placeholder="Enter your Password" required />
                        {this.state.passwordError !== "" ? <span style={{ color: "red" }}>{this.state.passwordError}</span> : ""}
                      </div>
                      <div className="form-group">
                        <label>Confirm Password<span style={{ color: "red" }}>*</span>:</label>

                        <input type="password" maxLength={15} autoComplete="nope" className="form-control fs10-mob" name="confirmpassword" onChange={this.onChange.bind(this)} value={this.state.confirmpassword} title="Enter your Confirm Password" placeholder="Enter your Confirm Password" disabled={this.state.password.length < 5} />
                        {this.state.passwordStatus === true ? <span style={{ color: "red" }}>confirm Invalid Password. Please tray with valid password !</span> : ""}
                      </div>
                      <div className="md-checkbox m0">
                        <input id="i1" type="checkbox" onChange={this.changeterms.bind(this)} defaultChecked={this.terms} />
                        <label htmlFor="i1" className="fs12-mob">I Agree to the <a href="/terms" target="_blank" className="font-bold">Terms of Use</a><span style={{ color: "red" }}>*</span></label>
                      </div>

                      {this.state.error_signup!==""?
                                        <div>    <span style={{color:"red"}}>{this.state.error_signup}</span><br></br></div>:null}
                      <button type="submit" onClick={this.registerJobseekers} className="btn btn-block sign-in-button" style={{backgroundColor: "#182b6a",color: "white"}} disabled={this.isDisabled()}>SIGN UP</button>
                      <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                      <br />
                      {/* </form> */}
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="officialModal" role="dialog" data-backdrop="static" style={{ display: "none", overflowY: "scroll" }}>
          <div className="modal-dialog">
            <div className="container-fluid">
              <div className="col-md-10 col-md-offset-1">

                <div className="modal-content">
                  <a href="#/" data-dismiss="modal" id="officialDisable"  style={basicCloseStyle}><i className="fa fa-times" aria-hidden="true"></i> </a>
                  <div className="form form-mob">
                    <div className="container-fluid">
                      <div className="head">
                        <h4>Sign Up</h4>
                      </div>
                      {/* <form  > */}

                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Designation<span style={{ color: "red" }}>*</span>:</label>
                            <select className="form-control fs10-mob" name="design" id="design" value={this.state.design} onChange={this.onChange.bind(this)} title="Choose your State" placeholder="Choose your State" >
                              <option value="" disabled>Choose your Designation</option>
                              {this.state.designation.map((value, key) => {
                                return <option key={key} value={value.value}>{value.value}</option>;
                              })}
                            </select>
                            {this.state.designError !== "" ? <span style={{ color: "red" }}>{this.state.designError}</span> : ""}
                            {/* <input type="text" className="form-control fs10-mob" placeholder="Choose your State" /> */}
                          </div>
                        </div>
                      </div>

                      <div className="form-group">

                        <label>Skills<span style={{ color: "red" }}>*</span>:</label>
                        {/* <TagsInput value={this.state.skills} onlyUnique={true} onChange={this.handleChange.bind(this)} /> */}
                        <ReactTags
                         maxSuggestionsLength={500}
                          tags={this.state.skills}
                          suggestions={this.state.suggestions}
                          noSuggestionsText={'No suggestions found'}
                          placeholder ={'Add a skill'}
                          handleDelete={this.handleDelete.bind(this)}
                          handleAddition={this.handleAddition.bind(this)} />
                            {this.state.skillError !== "" ? <span style={{ color: "red" }}>{this.state.skillError}</span> : ""}

                      </div>
                      <div className="form-group">
                        <label>Qualification</label>
                        <textarea className="form-control fs10-mob" name="qualification" onChange={this.onChange.bind(this)} value={this.state.qualification} title="Qualification" placeholder="Enter your Qualification" ></textarea>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-sm-12">
                            <label>Year Of Experience</label>
                            <select className="form-control fs10-mob" name="yearofexp" id="state" value={this.state.yearofexp} onChange={this.onChange.bind(this)} title="Year Of Experience" placeholder="Year Of Experience" >
                              {/* <option value="" disabled>Select Year Of Experience</option> */}
                              {Array(31).fill(1).map((el, i) =>
                                <option key={i} value={i}>{i} Years</option>
                              )}
                            </select>
                            {/* <input type="text" className="form-control fs10-mob" placeholder="Choose your State" /> */}
                          </div>
                        </div>
                      </div>
                      <button type="submit" onClick={this.updateOfficial.bind(this)} className="btn btn-block sign-in-button">Submit</button>
                      <br />
                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <a data-toggle="modal" data-target="#postjob1" id="postjob" hidden></a>
                <div className="modal fade" id="postjob1" role="dialog" style={{ display: "none" }}>
                    <div className="modal-dialog">
                        <div className="container-fluid">
                            <div className="col-md-8 col-md-offset-2">
                                <div className="modal-content">
                                    <a data-dismiss="modal" id="modal-dismiss-postjob" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i></a>
                                    <div className="form form-mob newModal-pad">
                                        <div className="container-fluid">
                                            <div className="col-md-12">
                                                <div className="head">
                                                    <h4>Do you want to login or register to post a job?</h4>
                                                </div>
                                                <div className="form-group">
                                                    <button className="btn btn-block sign-in-button" onClick={() => this.Signin_signup(2)}  >LOG IN</button>
                                                <button style={{ marginLeft: '30px' }} className="btn btn-block sign-in-button" onClick={() => this.Signin_signup(1)}  >REGISTER</button>
                                                </div>

                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                 <style>{"\
                    .react-tags__selected {\
                        max-height: 66px;\
                        overflow-y: auto;\
                        display: block;\
                      }\
                "}</style>
      </React.Fragment>
    );
  }
}
export default Home;