import React, { Component } from "react";
import axios from 'axios';
import { DOMAIN,GET_BASIC_DATA,EMAIL_DOMAIN_LIST} from '../../AppConfig';
// import ReactTags from 'react-tag-autocomplete';
// import './react_tag.css';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {Link} from 'react-router-dom';
import Loader from 'react-loader';
class Organization  extends Component {

    constructor(props) {
      super(props);
      this.state={
          countries:[],
          states:[],
          subscription_types:[],
          employ_types:[],
          OrgDesignation:[],
          services:[],
          country:"",
          countryId:"",
          selectedCountry:"",

          state:"",
          firstname:"",
          lastname:"",
          email:"",
          mobile:"",
          company_name:"",
          domain_name:"",
          no_of_employ:"",
          city:"",
          postal_code:"",
          subsciption_type:"",
          email_domain:"",
          designation:"",
          service:"",
          company_address:"",

          emailValidMsg:"",
          emailDomainValidMsg:"",
          DomainErrorMsg:"",
          DomainSuccessMsg:"",

          is_disable:false,
          isLoad:true,
          phone_number_issue:""

    }

    }
    componentWillMount(){
        window.scrollTo(0, 0);
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
        axios.get(DOMAIN + GET_BASIC_DATA,
            {
                headers: headers
            }
        )
        .then(response => {
            if (response.data.status === 200) {
                this.setState({
                    OrgDesignation:response.data.data.OrgDesignation,
                    countries:response.data.data.country,
                    states:response.data.data.state,
                    employ_types:response.data.data.no_of_emp_type,
                    subscription_types:response.data.data.getSubscriptionType,
                    services:response.data.data.services,
                    subsciption_type:3
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    onChange(e){
        // if(e.target.name==='country'){
        //     this.setState({
        //           countryId:e.target.value,
        //           selectedCountry:this.state.countries[e.target.value].country,
        //           country:this.state.countries[e.target.value].id,
        //        })
        if(e.target.name==='mobile'){
            const numregx=/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
            if(numregx.test(e.target.value) || e.target.value===""){
                if(e.target.value.length<9){
                    this.setState({
                        phone_number_issue:"Please enter valid phone number.Phone number must be a minimum Country Code + 10 digits !"
                    })
                }else{
                    this.setState({
                        phone_number_issue:""
                    })
                }
               this.setState({
                  [e.target.name]:e.target.value
               })
            }
         }else if(e.target.name==='firstname' || e.target.name==='lastname' || e.target.name==='city'){
            const strregx = /^[A-Za-z]+$/;
            if(strregx.test(e.target.value) || e.target.value===""){
               this.setState({
                  [e.target.name]:e.target.value
               })
            }
        }else if(e.target.name==="domain_name"){
            this.setState({
                DomainErrorMsg:"",
                DomainSuccessMsg:"",
            })
            const strregx = /^[a-z]+$/;
            if(strregx.test(e.target.value) || e.target.value===""){
               this.setState({
                  [e.target.name]:e.target.value
               })
            }
         }else if(e.target.name==='company_name'){
            // const strregx = /^[A-Za-z0-9!@#$%^&* ]+$/;
            const strregx =  /^[^*|\<>[\]{}`\\~%^+&=]+$/;
            if(strregx.test(e.target.value) || e.target.value===""){
               this.setState({
                  [e.target.name]:e.target.value
               })
            }
         }else if(e.target.name==='company_address'){
            const addressregx =  /^[^<>%*?=^!~`]*$/;
            if(addressregx.test(e.target.value) || e.target.value===""){
               this.setState({
                  [e.target.name]:e.target.value
               })
            }
         }else if(e.target.name==='email'){
            const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            if(emailregex.test(e.target.value)){
                var domain = (e.target.value).split('@');
                var Checkdomain = '@'+domain[1];
                var base_email_domain = EMAIL_DOMAIN_LIST.split(',');
                if(base_email_domain.includes(Checkdomain)){
                    this.setState({
                        emailValidMsg:"Please use your business domain name based email address."
                    })
                }else{
                    this.setState({
                        emailValidMsg:"",
                        emailDomainValidMsg:""
                    })
                }
            }else{
               this.setState({
                  emailValidMsg:"Please enter a valid  email id !"
               })
            }
            this.setState({
               [e.target.name]:e.target.value
            })
         }
         else if(e.target.name==='postal_code'){
            const zipregx=  /^[^/+<>%#@&"*?=^!~`.,'_:;\$/|\/\\{}()\[/\]/-]*$/;
            if(zipregx.test(e.target.value) || e.target.value===""){
               this.setState({
                  [e.target.name]:e.target.value
               })
            }
         }else if(e.target.name=="email_domain"){
            const emailregex = /^@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            if(emailregex.test(e.target.value)){
                var Checkdomain = e.target.value;
                var base_email_domain = EMAIL_DOMAIN_LIST.split(',');
                if(base_email_domain.includes(Checkdomain)){
                    this.setState({
                        emailDomainValidMsg:"please create new email domain."
                    })
                }else{
                    this.setState({
                        emailDomainValidMsg:""
                    })
                }
            }else{
               this.setState({
                emailDomainValidMsg:"Please enter the valid domain and first letter should starts with '@'"
               })
            }
            this.setState({
               [e.target.name]:e.target.value
            })
         }else{
            this.setState({
                [e.target.name]:e.target.value
            })
        }
    }
    storeOrganization(){
        var domain = (this.state.email).split('@');
        var Checkdomain = '@'+domain[1];
        if(Checkdomain!==this.state.email_domain){
            this.setState({
                emailDomainValidMsg:'Your email domain should match exactly with your primary email (@abc.com) !'
            })
        }else{
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
            this.setState({isLoad:false});
            axios.post(DOMAIN + "/storeOrganization",{
                country:this.state.country,
                state:this.state.state,
                firstname:this.state.firstname,
                lastname:this.state.lastname,
                email:this.state.email,
                phone:this.state.mobile,
                company:this.state.company_name,
                url :this.state.domain_name,
                noe :this.state.no_of_employ,
                city:this.state.city,
                address1:this.state.company_address,
                zip :this.state.postal_code,
                subscription_type:this.state.subsciption_type,
                email_domain:this.state.email_domain,
                designation :this.state.designation,
                type:this.state.service
                },
                {
                    headers: headers
                }
            )
            .then(response => {
                this.setState({isLoad:true});
                if (response.data.status === 200) {
                    // toast.success("Your Organization created successfully and shortly we are touch with you", {
                    //     position: toast.POSITION.TOP_CENTER
                    // });
                    localStorage.setItem('org_create',1);
                    this.props.history.push('/',{orgcreate:"Your Organization has been created successfully .Our Customer success team meber will contact you shortly !"});
                }else{
                    if(response.data.error){
                        if(response.data.error.email){
                            this.setState({
                                emailValidMsg:response.data.error.email
                            })
                        }
                        if(response.data.error.url){
                            this.setState({
                                DomainSuccessMsg:"",
                                DomainErrorMsg:response.data.error.url
                            })
                        }
                        if(response.data.error.email_domain){
                            this.setState({
                                emailDomainValidMsg:response.data.error.email_domain
                            })
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    }
    isDisabled(){
        if(this.state.firstname==="" || this.state.lastname==="" || this.state.state==="" || this.state.postal_code==="" || this.state.company_name===""  || this.state.company_address==="" || this.state.phone_number_issue!="" || this.state.mobile === "" || this.state.email==="" || this.state.emailValidMsg!=="" || this.state.emailDomainValidMsg!=="" || this.state.subsciption_type=="" || this.state.designation=="" || this.state.no_of_employ=="" || this.state.service=="" || this.state.domain_name=="" || this.state.DomainErrorMsg!=""){
            return true;
          }
          return false;
    }
    domainCheck(){
        if(this.state.domain_name===""){
            this.setState({
                DomainErrorMsg:"User name should not be empty"
            })
        }else{
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
        axios.post(DOMAIN + "/domainNameCheck",{
            domain_name:this.state.domain_name
        },
            {
                headers: headers
            }
        )
        .then(response => {
            if(response.data.status === 200) {
                this.setState({
                    DomainErrorMsg:"",
                    DomainSuccessMsg:"Available",
                })
            }else{
                this.setState({
                    DomainSuccessMsg:"",
                    DomainErrorMsg:response.data.error.domain_name
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    }
    render(){
        if(this.props.location.state!==undefined)
        {
            if(this.props.location.state.Footer!==undefined){
                window.scrollTo(0, 0);
                this.props.history.push('/organization');
            }
       }
        return(
            <>

            <section  className="bg-half page-next-level my-profile">
         <div  className="bg-overlay"></div>
         <div  className="container">
            <div  className="row justify-content-center">
               <div  className="col-md-6">
                  <div  className="text-center text-white">
                     <div  className="section-title text-center mb-4 pb-2">
                        <h4  className="text-uppercase title mb-4">organization</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section  className="section">
         <div  className="container">
            <div  className="row">
               <div  className="col-lg-12 col-md-12">
                  <div  className="job-detail rounded mt-2 p-4">
                        <div  className="row">
                           <div  className="col-lg-6 col-md-6">
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>First Name <span  className="text-danger">*</span></label>
                                    <input type="text"  className="form-control" placeholder="Enter your first name" name="firstname" maxLength={20} value={this.state.firstname} onChange={this.onChange.bind(this)} />
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Last Name <span  className="text-danger">*</span></label>
                                    <input type="text"  className="form-control" placeholder="Enter your lastst name"  maxLength={20} name="lastname" value={this.state.lastname}  onChange={this.onChange.bind(this)} />
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Email-ID <span  className="text-danger">*</span></label>
                                    <input type="email"  className="form-control" placeholder="Enter your email address" name="email" maxLength={50}  value={this.state.email}  onChange={this.onChange.bind(this)}  />
                                    {this.state.emailValidMsg!=""? <span style={{color:"red"}}>{this.state.emailValidMsg}</span>:""}
                                    </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Mobile Number <span  className="text-danger">*</span></label>
                                    <input type="text"  className="form-control" placeholder="Enter your mobile number" name="mobile" minLength={9} maxLength={15} value={this.state.mobile}  onChange={this.onChange.bind(this)} />
                                    {this.state.phone_number_issue!=""?<p style={{color:"red"}}>{this.state.phone_number_issue}</p>:""}
                                    </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Company Name <span  className="text-danger">*</span></label>
                                    <input type="text"  className="form-control" placeholder="Enter your company name" name="company_name" maxLength={30} value={this.state.company_name}  onChange={this.onChange.bind(this)} />
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative mb-0">
                                    <label>User Name <span  className="text-danger">*</span> (Max 4 letters & Only Lower Case letters are allowed)</label>
                                 </div>
                                 <div  className="row">
                                    <div  className="col-md-8">
                                       <div  className="form-group position-relative mb-1">
                                          <input type="text"  className="form-control" placeholder="Enter your user name" name="domain_name" maxLength={4} value={this.state.domain_name}  onChange={this.onChange.bind(this)}  />
                                       </div>
                                    </div>
                                    <div  className="col-md-4">
                                       <button  onClick={this.domainCheck.bind(this)} className="btn btn-primary">Check</button>
                                    </div>
                                 </div>
                                 <p  className="mt-0 tiny-font">All subsquents users will follow the same naming convention i.e john will become john#01 , john#02</p>
                                 {this.state.DomainErrorMsg!=""? <span style={{color:"red"}}>{this.state.DomainErrorMsg}</span>:""}
                                {this.state.DomainSuccessMsg!=""? <span style={{color:"green"}}>{this.state.DomainSuccessMsg}</span>:""}
                            </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Designation <span  className="text-danger">*</span></label>
                                    <select    className="demo-default selectize-input form-control" onChange={this.onChange.bind(this)} value={this.state.designation} name="designation">
                                            <option>Choose Designation</option>
                                            {this.state.OrgDesignation.map((value, key) => {
                                                return <option key={key} value={value.id}>{value.value}</option>;
                                            })}
                                    </select>
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Number of Employees <span  className="text-danger">*</span></label>
                                    <select  className="demo-default selectize-input form-control" onChange={this.onChange.bind(this)} value={this.state.no_of_employ} name="no_of_employ">
                                            <option>Choose Employee Type</option>
                                            {this.state.employ_types.map((value, key) => {
                                                return <option key={key} value={value.id}>{value.value}</option>;
                                            })}
                                    </select>
                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-6 col-md-6">
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Address of Company <span  className="text-danger">*</span></label>
                                    <input type="text"  className="form-control" maxLength={255} placeholder="Enter your company address" name="company_address" value={this.state.company_address}  onChange={this.onChange.bind(this)} />
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Country <span  className="text-danger">*</span></label>
                                    <select  className="demo-default selectize-input form-control" name="country" value={this.state.country} onChange={this.onChange.bind(this)} >
                                            <option value="" disabled>Choose your country</option>
                                            {this.state.countries.map((value, key) => {
                                                return <option key={key} value={value.country}>{value.country}</option>;
                                            })}
                                    </select>
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>State/Province <span  className="text-danger">*</span></label>
                                    <select   className="demo-default selectize-input form-control" name="state" id="state" value={this.state.state} onChange={this.onChange.bind(this)}>
                                            <option value="" disabled>Choose your state</option>
                                            {this.state.states.map((value, key) => {
                                                if(value.country==this.state.country)
                                                return <option key={key} value={value.state}>{value.state}</option>;
                                            })}
                                    </select>
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>City <span  className="text-danger">*</span></label>
                                    <input type="text"  className="form-control" placeholder="Enter your city" maxLength={25} value={this.state.city} name="city"  onChange={this.onChange.bind(this)}  />
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Zip Code <span  className="text-danger">*</span></label>
                                    <input type="text"  className="form-control" placeholder="Enter your zip code" name="postal_code" maxLength={8} value={this.state.postal_code}  onChange={this.onChange.bind(this)} />
                                 </div>
                              </div>
                              {/* <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Subscription Type <span  className="text-danger">*</span></label>
                                    <select  className="demo-default selectize-input form-control" onChange={this.onChange.bind(this)} value={this.state.subsciption_type} name="subsciption_type">
                                            <option>Choose Subscription Type</option>
                                            {this.state.subscription_types.map((value, key) => {
                                                return <option key={key} value={value.id}>{value.value}</option>;
                                            })}
                                    </select>
                                 </div>
                              </div> */}
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Email Domain <span  className="text-danger">*</span></label>
                                    <input type="text" maxLength={40}   className="form-control" placeholder="@example.com" name="email_domain" value={this.state.email_domain}  onChange={this.onChange.bind(this)} />
                                  {this.state.emailDomainValidMsg!=""? <span style={{color:"red"}}>{this.state.emailDomainValidMsg}</span>:""}
                                    </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Type of Career Service <span  className="text-danger">*</span></label>
                                    <select  className="demo-default selectize-input form-control" onChange={this.onChange.bind(this)} value={this.state.service} name="service">
                                            <option value="">Choose Service Type</option>
                                            {this.state.services.map((value, key) => {
                                                return <option key={key} value={value.id}>{value.value}</option>;
                                            })}
                                    </select>
                                 </div>
                              </div>
                              <div  className="col-lg-12 col-md-12">
                                 <button  onClick={this.storeOrganization.bind(this)} disabled={this.isDisabled()} className="btn btn-primary w-100">Register</button>
                                 <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                                 <p  className="mt-1 tiny-font">NOTE:After the registration, kindly configure the SMTP server under profile setup in ATS. Otherwise all the email will be sent by using UP4HIRE email server</p>
                              </div>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
            </>
        );
    }
}
export default Organization;