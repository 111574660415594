import React, { Component } from "react";
import {ATS_URL} from '../../AppConfig'
import { Link } from 'react-router-dom';
class Footer extends Component {
   constructor(props){
     super(props);
     this.postJob=this.postJob.bind(this);
     this.state = {Footer:"footer"}
   }
   Signin_signup(flag){
      document.getElementById('modal-dismiss-postjob').click();
      if(flag===1)
      {

   //    this.props.history.push({
   //       pathname: '/organization',
   //       state: { some: 'state' }
   //   })
      this.props.history.push(
         '/organization',this.state
       )
      }
      else
      {
        var win = window.open(ATS_URL, '_blank');
        win.focus();
      }

    }
    postJob(){
      document.getElementById('postjob').click();
    }
// function Footer() {
   render(){
//       const card = document.querySelector('body');

//   window.addEventListener('scroll', function (e) {
//      if(document.getElementById('login-hide')){
//      document.getElementById('login-hide').style.display="none";
//      }
//   })
   return (
      <>
       {/* <footer> */}
         {/* <div className="footer-section">
            <div className="container ">
               <div className="row">
                  <div className="col-md-3  col-sm-3 col-xs-6"  >
                     <ul className="directories footer">
                        <span>For Job Seekers</span>
                        <li>
                           <Link to={{pathname: `/joblisting`,state: {Footer:"footer"}}}>Find Jobs</Link>
                        </li>
                        <li>
                           <Link to={{pathname: `/`,state: {Footer:"footer"}}}>Upload Resume</Link>
                        </li>
                        {!localStorage.getItem('id')?
                        <React.Fragment>
                        <li>
                           <Link to={{pathname: `/login`,state: {Footer:"footer"}}}>Sign In</Link>
                        </li>
                        <li>
                           <Link to={{pathname: `/signup`,state: {Footer:"footer"}}}>Sign Up</Link>
                        </li></React.Fragment>:""}
                     </ul>
                  </div>

                  <div className="col-md-3 col-sm-3 col-xs-6"  >
                     <ul className="magazines footer">
                        <span>About Us</span>
                        <li>
                           <Link to={{pathname: `/aboutus`,state: {Footer:"footer"}}}>About Us</Link>
                        </li>
                        <li>
                           <Link to={{pathname: `/contactus`,state: {Footer:"footer"}}}>Contact Us</Link>
                        </li>
                     </ul>
                  </div>

                  <div className="col-md-3 col-sm-3 col-xs-6"  >
                     <ul className="nnouncements footer" >
                        <span>For Employers</span>
                        <li>
                           <Link onClick={this.postJob}>Post Jobs</Link>
                        </li>
                     </ul>
                  </div>



                  <div className="col-md-3 col-sm-3 col-xs-6"  >
                     <ul className="others footer">
                        <span>Resources</span>
                        <li>
                           <Link to={{pathname: `/terms`,state: {Footer:"footer"}}}>Terms of Use</Link>
                        </li>
                     </ul>
                  </div>
                  <div className="footer-divider col-md-12 col-sm-12 col-xs-12"></div>
                  <div className="col-md-6 col-sm-6 col-xs-12">
                     <ul className="social-footer">
                        <li>
                           <Link to="#"><i className="fa fa-facebook fa-2x"></i></Link>
                        </li>
                        <li>
                           <Link to="#"><i className="fa fa-twitter fa-2x"></i></Link>
                        </li>
                        <li>
                           <Link to="#"><i className="fa fa-linkedin fa-2x"></i></Link>
                        </li>
                     </ul>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12 pull-right">
                     <p className="copy-right">Copyright © 2020 - UP4HIRE </p>
                  </div>
               </div>
            </div>
         </div> */}
<footer className="footer">
         <div className="container">
            <div className="row">
               <div className="col-lg-4 col-md-6 col-sm-12 col-12 mb-0 mb-md-4 pb-0 pb-md-2 col-half-offset">
                  <Link to="/"><img src="assets/images/logo-light.png" height="20" alt="" /></Link>
                  {/* <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis ut lectus sed enim pretium tristique.</p>
                  <ul className="social-icon social list-inline mb-0">
                     <li className="list-inline-item"><a href="#" className="rounded"><i className="mdi mdi-facebook"></i></a></li>
                     <li className="list-inline-item"><a href="#" className="rounded"><i className="mdi mdi-twitter"></i></a></li>
                     <li className="list-inline-item"><a href="#" className="rounded"><i className="mdi mdi-linkedin"></i></a></li>
                  </ul> */}
               </div>
               <div className="col-lg-2 col-md-6 col-sm-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 col-half-offset">
                  <p className="text-white mb-4 footer-list-title">For Job Seekers</p>
                  <ul className="list-unstyled footer-list">
                     <li><Link to={{pathname: `/joblisting`,state: {Footer:"footer"}}} className="text-foot"><i className="mdi mdi-chevron-right"></i> Find Jobs</Link></li>
                     {!localStorage.getItem('id')?<React.Fragment>
                     <li><Link to={{pathname: `/`,state: {Footer:"footer",Uploadresume:"upload"}}} className="text-foot"><i className="mdi mdi-chevron-right"></i> Upload Resume</Link></li>
                      <li><Link to={{pathname: `/login`,state: {Footer:"footer"}}} className="text-foot"><i className="mdi mdi-chevron-right"></i> Sign In</Link></li>
                     <li><Link to={{pathname: `/signup`,state: {Footer:"footer"}}} className="text-foot"><i className="mdi mdi-chevron-right"></i> Sign Up</Link></li>
                     </React.Fragment>:""}
                  </ul>
               </div>
               <div className="col-lg-2 col-md-6 col-sm-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 col-half-offset">
                  <p className="text-white mb-4 footer-list-title">About Us</p>
                  <ul className="list-unstyled footer-list">
                     <li><Link to={{pathname: `/aboutus`,state: {Footer:"footer"}}} className="text-foot"><i className="mdi mdi-chevron-right"></i> About Us</Link></li>
                     <li><Link to={{pathname: `/contactus`,state: {Footer:"footer"}}} className="text-foot"><i className="mdi mdi-chevron-right"></i> Contact Us</Link></li>
                  </ul>
               </div>
               <div className="col-lg-2 col-md-6 col-sm-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 col-half-offset">
                  <p className="text-white mb-4 footer-list-title f-17">For Employers</p>
                  <ul className="list-unstyled footer-list">
                     <li>
                        <div onClick={this.postJob} className="hoverCursor text-foot"><i className="mdi mdi-chevron-right"></i> Post Jobs
                        </div>
                     </li>
                  </ul>
               </div>
               <div className="col-lg-2 col-md-6 col-sm-12 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0 col-half-offset">
                  <p className="text-white mb-4 footer-list-title f-17">Resources</p>
                  <ul className="list-unstyled footer-list">
                     <li><Link to={{pathname: `/terms`,state: {Footer:"footer"}}} className="text-foot"><i className="mdi mdi-chevron-right"></i> Terms of Use</Link></li>
                  </ul>
               </div>
            </div>
         </div>
      </footer>
      <hr />
      <footer className="footer footer-bar">
         <div className="container text-center">
            <div className="row justify-content-center">
               <div className="col-12">
                  <div className="">
                     <p className="mb-0">Copyright © {new Date().getFullYear()} - UP4HIRE</p>
                  </div>
               </div>
            </div>
         </div>
      </footer>
         <a href="#" data-toggle="modal" data-target="#postjob1" id="postjob" hidden></a>
                <div className="modal fade" id="postjob1" role="dialog" style={{ display: "none" }}>
                    <div className="modal-dialog">
                        <div className="container-fluid">
                            <div className="col-md-10 col-md-offset-1">
                                <div className="modal-content">
                                    <a href="#" data-dismiss="modal" id="modal-dismiss-postjob" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i></a>
                                    <div className="form form-mob">
                                        <div className="container-fluid">
                                            <div className="col-md-12">
                                                <div className="head">
                                                    <h4>Do you want to login or register to post a job?</h4>
                                                </div>
                                                <div className="form-group">
                                                    <button className="btn btn-block sign-in-button" onClick={() => this.Signin_signup(2)}  >LOG IN</button>
                                                <button style={{ marginLeft: '30px' }} className="btn btn-block sign-in-button" onClick={() => this.Signin_signup(1)}  >REGISTER</button>
                                                </div>

                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

      {/* </footer> */}
</>


   );

         }

}







export default Footer;