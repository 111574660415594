import React, { Component } from "react";
import axios from 'axios';
import { DOMAIN } from '../../AppConfig';
import "react-input-range/lib/css/index.css";
import 'react-toastify/dist/ReactToastify.css';
import { th } from "date-fns/esm/locale";

class Joblisting extends Component {

    constructor(props) {
        super(props);
        this.state={
            notification:[],
            RecommendJob:[],
            noticationStatus:0,
            selectedItem:[]
        }
    }

    componentWillMount() {
        var lat="",long="";
  const location = window.navigator && window.navigator.geolocation
    if(location) {
        location.getCurrentPosition((position, error) => {
            lat=position.coords.latitude;
            long=position.coords.longitude;
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        axios.post(DOMAIN + '/getAllNotification', {
            id: localStorage.getItem('id'),
            latitude:lat,
            longitude:long
         },
            {
                headers: headers
            }
        )
            .then(response => {
                if (response.data.status === 200) {
                    this.setState({
                        notification:response.data.notifications,
                        RecommendJob:response.data.alertNotification
                    });
                    this.notificationstatus(0);
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                  }

            })
            .catch(function (error) {
                console.log(error);
            })
        });
    }
    }
    notificationstatus(status){  
        this.setState({
            noticationStatus:status,
            selectedItem: status?this.state.RecommendJob : this.state.notification
        })
    }

    render() {

        return (
            <>
            
            <section  className="bg-half page-next-level my-profile">
         <div  className="bg-overlay"></div>
         <div  className="container">
            <div  className="row justify-content-center">
               <div  className="col-md-6">
                  <div  className="text-center text-white">
                     <div  className="section-title text-center mb-4 pb-2">
                        <h4  className="text-uppercase title mb-4">Notifications</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section> 
      <section  className="section">
         <div  className="container">
            <div  className="row align-items-center">
               <div  className="col-lg-12">
                  <div  className="show-results">
                     <div  className="float-left mob-center pb-2">
                        <a className={'btn mr-2 btn-sm btn-my-profile mt-1' +(this.state.noticationStatus ?"":" active")} onClick={()=>this.notificationstatus(0)}>Notifications</a>
                        <a className={'btn btn-sm btn-my-profile mt-1'+ (this.state.noticationStatus ?" active":"")} onClick={()=>this.notificationstatus(1)}>Recommended Jobs</a>
                     </div>
                     <div  className="sort-button float-right">
                       
                     </div>
                  </div>
               </div>
            </div>
            <div  className="row">
               <div  className="col-lg-12">               
               {this.state.selectedItem.map((list, i) => (
                  <div  key={i} className="job-detail border rounded mt-2 p-2 bg-light">                    
                     <div  className="row">
                        <div  className="col-lg-6 col-md-6">
                           <div  className="col-md-12 mt-2">
                              <h6  className="text-dark mb-0">{list.notification_type}</h6>
                              <p dangerouslySetInnerHTML={{ __html: list.notification_content }}></p>
                           </div>
                        </div>
                        <div  className="col-lg-6 col-md-6">
                           <div  className="col-md-12 mt-2">
                              <div  className="form-group position-relative text-center mob-left"> 
                                 <h6  className="text-dark mb-0  f-18">{list.created_at}</h6>
                                 
                              </div>
                           </div>
                        </div>                     
                        
                     </div> 
               </div>
               ))}
                  {this.state.selectedItem.length<1?
                        <p className="jobs mob-pad15">No Records Found</p>:""}
               </div>
            </div>
         </div>
      </section>


                </>


                )
            }
        }
export default Joblisting;