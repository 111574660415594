import React, { Component } from "react";
import Pagination from "react-js-pagination";
import {PAGINATION_COUNT} from '../../AppConfig';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { DOMAIN,FAVOURITE_JOB,APPLY_JOB,GET_MY_JOBLIST,GET_MY_JOBLIST_PAGE,CHECK_RESUME,UPLOAD_MY_RESUME } from '../../AppConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader';
import Popup from "reactjs-popup";
class Myjobs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            myjoblist: [],
            activePage:1,
            itemsCountPerPage:1,
            totalItemsCount:1,
            pageRangeDisplayed:3,
            job_org:"",
            job_id:"",
            flag:0,
            apply_radio:true,
            favourite_radio:false,
            visible_check: false,
            visible: null,
            isLoad:true,
            isLoad1: true,
            uploadActive:false,
            resume_upload:true,
            check_resume_login:0,
            invalid_file:"",
            upload_heading:""
        }
        this.handlePageChange=this.handlePageChange.bind(this);
        // this.setApplyFav=this.setApplyFav.bind(this);
        this.applyJob=this.applyJob.bind(this);
        this.favouriteJob=this.favouriteJob.bind(this);
        this.confirm=this.confirm.bind(this);
        this.confirmation_yes=this.confirmation_yes.bind(this);
        this.getMyLocation = this.getMyLocation.bind(this);
        this.getJoblist=this.getJoblist.bind(this);
      }
      resumeUpload(e){
        this.setState({resumeName:"",uploadActive:false});
        if(e.target.files[0]!==undefined)
      {
         var ext = e.target.files[0].name.split('.').pop();
         if(ext==="pdf" || ext==="docx" || ext==="doc"){
            this.setState({
               uploadActive:true,
               resumeName:e.target.files[0].name,
               invalid_file:""
           });
        } else{
         this.setState({invalid_file:"File Format should be .pdf or .docx or .doc"});
        }
   }
    }
    getPostDate(date){
        var postdate = new Date(date);
        var strArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var d = postdate.getDate();
        var m = strArray[postdate.getMonth()];
        var y = postdate.getFullYear();
        return '' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
}
    myResumeUpload(){

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
       var formData = new FormData();
       formData.append('file', document.getElementById('resumeUpload').files[0]);
       formData.append('userId',localStorage.getItem("id"));
axios.post(DOMAIN + UPLOAD_MY_RESUME, formData,{
    headers: headers
})
 .then(response => {
    document.getElementById('closeModel').click();
   this.setState({isLoad1:true});
    if (response.data.status === 200) {
            this.applyJob(this.state.apply_id, this.state.apply_org);
    }else if(response.data.status === 500){
       localStorage.clear();

    }
    });

    }
      ChangeVisbleStatus() {
        if (this.state.visible) {
            this.setState({
                visible: false,
            })
        } else {
            this.setState({
                visible: true
            })
        }
     }
      confirmation_yes(flag){

        if(flag===1){
            this.applyJob(this.state.apply_id,this.state.apply_org);
        }else{
            document.getElementById('modal-dismiss-apply').click();
        }

    }
    confirm(id,org){
         this.setState({apply_id:id,apply_org:org});
        if (localStorage.getItem("id") !== null) {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": localStorage.getItem('token')
            }
            axios.post(DOMAIN + CHECK_RESUME, {
                userId: localStorage.getItem("id"),
            },
                {
                    headers: headers
                }
            )
                .then(response => {
                   console.log(response);
                    if (response.data.status === 200) {


                        document.getElementById('applyActive').click();
                    } else{
                        // toast.info(response.data.message, {
                        //     position: toast.POSITION.TOP_CENTER
                        // });
                        var orgs=null;
                        var check_status=null;
                        if(response.data.org_visible===null)
                        {
                        orgs=1;
                        check_status=false;
                        }
                        this.setState({upload_heading:"Please Upload Resume to Apply Job.",org_status:orgs,visible:check_status});
                        document.getElementById('resumeModal1').click();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })



        }else{
            document.getElementById('loginActive').click();
        }

    }
    favouriteJob(favourite,job_id,org){
        if(localStorage.getItem("id")!==null)
        {
           if(favourite===1)
           favourite=0;
           else
           favourite=1;
              const headers = {
                 "Content-Type": "application/json",
                 "Accept": "application/json",
                 "Authorization":localStorage.getItem('token')
              }
              axios.post(DOMAIN + FAVOURITE_JOB,{
              favourite:favourite,
              job_id:job_id,
              jobseeker_id:localStorage.getItem("id"),
              org:org,
              },
                 {
                    headers: headers
                 }
              )
              .then(response => {
                if(response.data.status===200){
                    var msg="";
                    if(favourite===1)
                        msg="Job successfully marked as favourite !";
                    else
                        msg="Job Successfully Marked as UnFavourite";

                    toast.success(msg, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    this.handlePageChange(this.state.activePage)
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                }
              })
              .catch(function (error) {
                 console.log(error);
              })
        }
        else{
            toast.error("Please login to mark as favourite !", {
                position: toast.POSITION.TOP_CENTER
            });
        }
     }
    applyJob(job_id,job_org){
        if(localStorage.getItem("id")!==null)
            {
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization":localStorage.getItem('token')
            }
            axios.post(DOMAIN + APPLY_JOB,{
                job_id:job_id,
                org:job_org,
                jobseeker_id:localStorage.getItem("id"),
                org_visible_status: this.state.visible,
            },
                {
                    headers: headers
                }
            )
            .then(response => {
                document.getElementById('modal-dismiss-apply').click();
                if(response.status===200){
                    if(response.data.status === 200){
                        toast.success("Job "+response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    else{
                        toast.error("Job "+response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    this.handlePageChange(this.state.activePage);
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                  }

            })
            .catch(function (error) {
                console.log(error);
            })
            }
            else{
                toast.error("Please Login to Apply Job", {
                    position: toast.POSITION.TOP_CENTER
                });
            }
    }
    getMyLocation() {
        const location = window.navigator && window.navigator.geolocation
        if (location) {
            location.getCurrentPosition((position, error) => {
                this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude }, () =>
                    this.getJoblist());

            }, (error) => {
                // this.setState({date:true,dates:1,distance:false,distances:0,distances_status:false});
                this.setState({ latitude: '', longitude: '' }, () =>
                    this.getJoblist());
            })
        }

    }
    getJoblist(){
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization":localStorage.getItem('token')
         }
         this.setState({isLoad:false});
         axios.post(DOMAIN + GET_MY_JOBLIST,{pagination:PAGINATION_COUNT,jobseeker_id:localStorage.getItem("id"),flag:this.state.flag,latitude:this.state.latitude,longitude:this.state.longitude},
            {headers: headers}
         )
            .then(response => {
                this.setState({isLoad:true});
               if (response.data.status === 200) {
                   this.setState({myjoblist:response.data.data.data.data,
                    itemsCountPerPage:response.data.data.data.per_page,
                    totalItemsCount:response.data.data.data.total,
                    activePage:response.data.data.datacurrent_page});
                    if (response.data.data.jobseeker_status !== undefined) {
                        if (response.data.data.jobseeker_status.org_visible_status === 0) {
                            this.setState({ visible_check: false, });
                        }
                        else {
                            this.setState({ visible_check: true, });
                        }
                    }
               }else{
                localStorage.clear();
                this.props.history.push('/login');
              }
            })
            .catch(function (error) {
               console.log(error);
            })

    }
    componentWillMount(){
        if(!localStorage.getItem('id')){
            this.props.history.push('/login');
        }
        else
        this.getMyLocation();

    }
    setApplyFav(data){
        if(data==="applied"){
            this.setState({flag:0,favourite_radio:false,apply_radio:true}, () =>
            this.handlePageChange(this.state.activePage));
        }
        else{
            this.setState({flag:1,apply_radio:false,favourite_radio:true}, () =>
            this.handlePageChange(this.state.activePage));
        }

    }
    handlePageChange(pageNumber) {
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization":localStorage.getItem('token')
         }
         this.setState({isLoad:false});
           axios.post(DOMAIN + GET_MY_JOBLIST_PAGE+pageNumber,{pagination:PAGINATION_COUNT,jobseeker_id:localStorage.getItem("id"),flag:this.state.flag,latitude:this.state.latitude,longitude:this.state.longitude},
            {
               headers: headers
            }
         )
            .then(response => {
                this.setState({isLoad:true});
               if (response.data.status === 200) {
                   this.setState({myjoblist:response.data.data.data.data,
                                itemsCountPerPage:response.data.data.data.per_page,
                                totalItemsCount:response.data.data.data.total,
                                activePage:response.data.data.data.current_page
            });
            if (response.data.jobseeker_status !== undefined) {
                if (response.data.jobseeker_status.org_visible_status === 0) {
                    this.setState({ visible_check: false, });
                }
                else {
                    this.setState({ visible_check: true, });
                }
            }
               }else{
                localStorage.clear();
                this.props.history.push('/login');
              }
            })
            .catch(function (error) {
               console.log(error);
            })
        this.setState({activePage: pageNumber});
      }

    render() {
    function activefile() {
        document.getElementById('resumeUpload').click();
      }
      var basicCloseStyle = {
        position: "relative",
        left: "41rem",
        top: "14px",
        zIndex: "10"
      }
        return (
                <>
                 <ToastContainer />
                    <>
                    <section  className="bg-half page-next-level job-details">
         <div  className="bg-overlay"></div>
         <div  className="container">
            <div  className="row justify-content-center">
               <div  className="col-md-6">
                  <div  className="text-center text-white">
                     <div  className="section-title text-center mb-4 pb-2">
                        <h4  className="text-uppercase title mb-4">My Jobs</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section  className="section">
         <div  className="container">
            <div  className="row align-items-center">
               <div  className="col-lg-12">
                  <div  className="show-results">
                     <div  className="float-left mob-center pb-2">
                        <a  onClick={()=>this.setApplyFav("applied")} className={'btn mr-2 btn-sm btn-my-profile mt-1' +(this.state.apply_radio? " active":" ")}>Applied Jobs</a>
                        <a  onClick={()=>this.setApplyFav("favourite")} className={'btn btn-sm btn-my-profile mt-1'+(this.state.favourite_radio?" active":"")}>Favourite Jobs</a>
                     </div>
                     <div  className="sort-button float-right">
                     </div>
                  </div>
               </div>
            </div>
            <Loader loaded={this.state.isLoad} zIndex={2e9}/>
            { this.state.myjoblist.length>0 ?
            <div  className="row">
                 { this.state.myjoblist.map((list, i) => (
               <div key={i} className="col-lg-12 mt-4 pt-2">
                  <div  className="job-list-box border rounded">
                     <div  className="p-3">
                        <div  className="row align-items-center">
                           <div  className="col-lg-2 col-md-2">
                               <Link to ="/">
                              <div  className="company-logo-img text-center">
                                 <img src="/assets/images/featured-job/img-4.png" alt=""  className="img-fluid mx-auto d-block"/>
                                 <h6  className="mb-2"><div  className="text-dark text-center">UP4HIRE</div></h6>
                              </div>
                              </Link>
                           </div>
                           <div  className="col-lg-8 col-md-8">
                              <div  className="job-list-desc">
                                 <div  className="row">
                                    <div  className="col-lg-8 col-md-8">
                                       <h6  className="mb-2"><Link to={`/job/${list.id}`} target="_blank" className="text-dark">{list.title}</Link></h6>
                                    </div>
                                    <div  className="col-lg-3 col-md-3 p-0">
                                       <div  className=" float-left mob-center">
                                       { list.apply==="Apply" || localStorage.getItem("id")===null?
                                        <span>
                                        <i className="fa fa-briefcase fa-lg"  title="Apply job" onClick={() => this.confirm(list.id,list.org)} style={{cursor: 'pointer'}}  ></i>
                                        </span>:
                                          <p  className="text-muted mb-0 text-nowrap"><i  className="fas fa-mouse-pointer"></i>
                                        {list.apply} :<span>{list.created_at}</span> </p>}

                                       </div>
                                    </div>
                                 </div>
                                 <div  className="row">
                                    <div  className="col-lg-3 col-md-6 col-6 text-left">
                                       <p  className="text-muted mb-0"><i  className="mr-1 fas fa-building"></i> {list.company}</p>
                                    </div>
                                    <div  className="col-lg-3 col-md-6 col-6 text-left">
                                       <p  className="text-muted mb-0"><i  className="mr-1 fas fa-suitcase"></i> {list.position_type}</p>
                                    </div>
                                    <div  className="col-lg-3 col-md-6 col-6 text-left">
                                       <p  className="text-muted mb-0"><i  className="mr-1 fas fa-calendar-week"></i>{this.getPostDate(list.postedDate)}</p>
                                    </div>
                                    <div  className="col-lg-3 col-md-6 col-6 text-left">
                                       <p  className="text-muted mb-0"><i  className="mr-1 fas fa-road"></i>{Number(list.distance).toFixed(2) + "KM"}</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-2 col-md-2 mt-2">
                              <div  className="row">
                                 <div  className="col-lg-6 col-md-6 col-6 text-center mob-right">
                                 {list.favourite===1?
                                    <i id={list.id} title="Mark as unfavourite" className="fas fa-heart large-icon" style={{color: 'red',cursor: 'pointer'}} onClick={()=>this.favouriteJob(list.favourite,list.id,list.org)}></i>:
                                    <i id={list.id} title="Mark as favourite" className="fas fa-heart large-icon" style={{color: 'black',cursor: 'pointer'}} onClick={()=>this.favouriteJob(list.favourite,list.id,list.org)}></i>
                                 }
                                 </div>
                                 <div  className="col-lg-6 col-md-6 col-6 text-center mob-left mob-hide">
                                 <Link to={`/job/${list.id}`} target="_blank" className="blackText"> <i  className="fas fa-eye large-icon" title="View Job"></i>
                                 </Link>
                                                {/* <Popup
                                                            trigger={open => (
                                                                <i  className="fas fa-eye large-icon" title="View Job"></i>
                                                            )}
                                                            position="left center"
                                                            // closeOnDocumentClick
                                                            on={"hover"}
                                                            className={"tool-"+list.id}
                                                        >
                                                            <div className="tool-card">
                                                                <div className="tool-header jobList-header">{list.title}</div>
                                                                <div className="content">
                                                                <p>{list.country} - {list.state},{list.city}, {list.zip}</p>


                                                                <div className="jobList-text">
                                                                <p><b>Employment Type: </b> </p>
                                                                <span>{list.position_type}</span>
                                                                </div>

                                                                <div className="jobList-text">
                                                                <p> <b>Posted Date: </b> </p>
                                                                <span>{this.getPostDate(list.postedDate)}</span>
                                                                </div>

                                                                </div>
                                                            </div>
                                                        </Popup> */}
                                 </div>
                                 <div  className="col-lg-6 col-md-6 col-6 text-center mob-left mob-view">
                                 <Link to={`/job/${list.id}`} target="_blank" className="blackText"> <i  className="fas fa-eye large-icon" title="View Job"></i>
                                 </Link>
                                                {/* <Popup
                                                            trigger={open => (
                                                                <i  className="fas fa-eye large-icon" title="View Job"></i>
                                                            )}
                                                            position="top center"
                                                            // closeOnDocumentClick
                                                            on={"hover"}
                                                            className={"tool-"+list.id}
                                                        >
                                                            <div className="tool-card">
                                                                <div className="tool-header jobList-header">{list.title}</div>
                                                                <div className="content">
                                                                <p>{list.country} - {list.state},{list.city}, {list.zip}</p>


                                                                <div className="jobList-text">
                                                                <p><b>Employment Type: </b> </p>
                                                                <span>{list.position_type}</span>
                                                                </div>

                                                                <div className="jobList-text">
                                                                <p> <b>Posted Date: </b> </p>
                                                                <span>{this.getPostDate(list.postedDate)}</span>
                                                                </div>

                                                                </div>
                                                            </div>
                                                        </Popup> */}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               ))}
            </div>
                    :
                    <div className="text-center col-md-12 footer-bottom-set"><p className="jobs myjobs">No Records Found</p></div>
              }
         </div>
      </section>
                    </>
                    <div className="container">
                    <div className="col-md-12 col-xs-12 box-align">
                        <div className="content">
                            <div className="pagination-holder clearfix">
                                  { this.state.myjoblist.length>0 ?
                            <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.itemsCountPerPage}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={this.state.pageRangeDisplayed}
                                onChange={this.handlePageChange.bind(this)}
                                />
                                :null}
                               {/* <div id="light-pagination" className="pagination light-theme simple-pagination"><ul><li><a href="#page-9" className="page-link prev">Prev</a></li><li><a href="#page-1" className="page-link">1</a></li><li><a href="#page-2" className="page-link">2</a></li><li className=""><span className="ellipse clickable">…</span></li><li><a href="#page-6" className="page-link">6</a></li><li><a href="#page-7" className="page-link">7</a></li><li><a href="#page-8" className="page-link">8</a></li><li><a href="#page-9" className="page-link">9</a></li><li className="active"><a className="current">10</a></li><li className="disabled"><a className="current next">Next</a></li></ul></div> */}
                            </div>
                        </div>
                    </div>
                </div>


                <a href="#/" data-toggle="modal" data-target="#loginModel1" id="applyActive" hidden> </a>
                    <div  className="modal fade" id="loginModel1" data-backdrop="static" role="dialog" style={{display:"none"}}>
                        <div  className="modal-dialog">
                            <div  className="container-fluid">
                            <div  className="col-md-10 col-md-offset-1">
                                <div  className="modal-content model-border-st" id="jobApplyMyjobs">
                                {/* <a href="#/" data-dismiss="modal" id="modal-dismiss-apply" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                                    <div  className="form form-mob">
                                        <div  className="container-fluid jb-resume-container-fluid">
                                        <div  className="col-md-12 card innerPopup">
                                            {/* <div  className="head">
                                                <h4>Do you want to Apply?</h4>
                                            </div> */}
                                            <div  className="modal-header">

                                                <h4  className="modal-title">Do you want to Apply?</h4>
                                                <button type="button" id="modal-dismiss-apply"  className="close" data-dismiss="modal">&times;</button>
                                            </div>
                                            <div  className="modal-body">
                                                {this.state.visible_check === true ?
                                                    <div className="form-group">
                                                    <div className="md-checkbox m0 d-flex">
                                                        <input id="i3" type="checkbox"  className ="alignCheckbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                                        <label htmlFor="i3" className="fs12-mob job-apply-checkbox"> Make my profile visible to all employers.</label>
                                                    </div>
                                                    </div> : null}
                                                <div className="form-group d-flex">
                                                    <button  className="btn btn-block sign-in-button mr-1" onClick={() => this.confirmation_yes(1)}>Yes</button>
                                                    <button  className="btn btn-block sign-in-button mt-0" onClick={() => this.confirmation_yes(2)} >No</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>


                        <a href="#/" data-toggle="modal" data-target="#resumeModal" id="resumeModal1" hidden> </a>
                <div className="modal fade" id="resumeModal" role="dialog" data-backdrop="static" style={{ display: "none" }}>
                                    <div className="modal-dialog">
                                        <div className="container-fluid">
                                        <div className=" col-md-offset-1">
                                            <div className="modal-content model-border-st" id="myJobsResumeUpload">

                                            {/* <a href="#/" data-dismiss="modal" id="closeModel" style={basicCloseStyle} ><i className="fa fa-times" aria-hidden="true"></i> </a> */}

                                            <div className="form form-mob">
                                                <div className="container-fluid jb-resume-container-fluid">
                                                <div className="col-md-12 card innerPopup">

                                                    {/* <div className="head">
                                                          <div style={{color:"red"}}>{this.state.upload_heading}</div><br/>
                                                    <h4>Upload Resume</h4>
                                                    </div> */}
                                                    <div  className="modal-header">
                                                        <h4  className="modal-title text-nowrap">Upload Resume</h4>

                                                        <input type="file" name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUpload.bind(this)} placeholder="Enter your email address" style={{ visibility: "hidden" }} />
                                                        <button type="button" id="closeModel"  className="close" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div  className="modal-body">
                                                    <div className="form-group">
                                                    <h6>{this.state.upload_heading}</h6>
                                                    <button className="btn btn-block sign-in-button fs12-mob mob-resume-upload w-100"  onClick={activefile} >{this.state.uploadActive===true?this.state.resumeName: "RESUME"}</button>

                                                    <br /><span className="mob-mb15"> Max 2 MB, doc, docx, pdf<br />
                                                        We will tried to fill your details</span><br /><br/>
                                                        {this.state.org_status===1 ?
                                                        <div className="form-group">
                                                        <div className="md-checkbox m0 d-flex">
                                                            <input id="i8" type="checkbox" className="mr-1" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                                            <label htmlFor="i8" className="fs12-mob job-apply-checkbox"> Make my profile visible to all employers.</label>
                                                        </div>
                                                    </div>:null}
                                                        <button className="btn btn-primary float-right"  onClick={() => this.myResumeUpload()}  disabled={this.state.uploadActive===false}>Upload</button>
                                                        <br/> <span style={{color:"red"}}>{this.state.invalid_file}</span>
                                                    {/* <span style={{color:this.state.color}}>{this.state.EmailError}</span> */}

                                                    </div>
                                                    </div>
                                                </div>

                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>




                </>
        );
    }
}
export default Myjobs;