import React, { Component } from "react";
import {COMPANY_MAP_LOC,DOMAIN,GET_SEEKERS_DATA} from "./../../AppConfig";
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader';

class ContactUs extends Component {
   constructor(props) {
      super(props);
      this.state={
         is_disable:false,
         message:"",
         firstname:"",
         lastname:"",
         isLoad:true,
         email:"",
         phone_number:"",
         emailValidMsg:"",
         disable_btn:true,
         phone_number_disable:false
      }
   }
   componentWillMount(){
      window.scrollTo(0, 0);
      if(localStorage.getItem('id')){
       this.setState({isLoad:false})
         const header = {
               "Content-Type": "application/json",
               "Accept": "application/json",
               "Authorization": localStorage.getItem('token')
         }
         axios.post(DOMAIN + GET_SEEKERS_DATA,
               {
                  userId: localStorage.getItem('id')
               }, {
               headers: header
         }
         )
         .then(response => {
            this.setState({isLoad:true})
             if (response.data.status === 200) {
                 this.setState({
                     firstname: response.data.data.firstname,
                     lastname: response.data.data.lastname,
                     email: response.data.data.email,
                     phone_number: response.data.data.phone_number,
                     is_disable:true
                 });
                 if( response.data.data.phone_number===""|| response.data.data.phone_number===null){
                    this.setState({phone_number_disable:false});
                 }
             }
         })
         .catch(function (error) {
             console.log(error);
         })
      }
   }
   onChange(e){
      if(e.target.name==='phone_number'){
         const numregx=/^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
         if(numregx.test(e.target.value) || e.target.value===""){
            this.setState({
               [e.target.name]:e.target.value
            })
         }
      }else if(e.target.name==='firstname' || e.target.name==='lastname'){
         const strregx = /^[A-Za-z]+$/;
         if(strregx.test(e.target.value) || e.target.value===""){
            this.setState({
               [e.target.name]:e.target.value
            })
         }
      }else if(e.target.name==='email'){
         const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            this.setState({
            [e.target.name]:e.target.value,
            disable_btn:false,
         })
         if(emailregex.test(e.target.value)){
            this.setState({
               emailValidMsg:"",
               disable_btn:false,
            })
         }else{
            this.setState({
               emailValidMsg:"Please enter a valid  email id !",
               disable_btn:true
            })
         }

      }else if(e.target.name==='message'){
         const addressregx = /^[^<>]*$/;
         if(addressregx.test(e.target.value) || e.target.value===""){
            this.setState({
               [e.target.name]:e.target.value
            })
         }
      }
      if(this.state.message===""||!this.state.message||this.state.emailValidMsg!==""||this.state.email===""||this.state.firstname===""||this.state.lastname===""|| !this.state.phone_number ||this.state.phone_number==="")
      {
         this.setState({disable_btn:true});
      }else{
         this.setState({disable_btn:false});
      }

   }
   senQuery(){
    this.setState({isLoad:false})
      const headers = {
         "Content-Type": "application/json",
         "Accept": "application/json",
      }
      axios.post(DOMAIN + '/sendQuery',{
         message:this.state.message,
         userId : localStorage.getItem('id'),
         firstname:this.state.firstname,
         lastname:this.state.lastname,
         phone_number:this.state.phone_number,
         email : this.state.email
      },
      {
         headers: headers
      }
      )
      .then(response => {
        this.setState({isLoad:true})
         if(response.data.status===200)
         {
            toast.success("Query sent successfully",{
               position: toast.POSITION.TOP_CENTER
           });
           this.setState({
              message:""
           })
           if(!this.state.is_disable){
              this.setState({
                  firstname:"",
                  lastname:"",
                  phone_number:"",
                  email : ""
               })
           }
         }

      })
      .catch(function (error) {
         console.log(error);
      })
   }

render(){
   if(this.props.location.state!==undefined)
        {
            if(this.props.location.state.Footer!==undefined){
                window.scrollTo(0, 0);
                this.props.history.push('/contactus');
            }
       }
    return(
<div >
<ToastContainer />
<section className="bg-half page-next-level my-contact">
        <div className="bg-overlay"></div>
        <div className="container">
           <div className="row justify-content-center">
              <div className="col-md-6">
                 <div className="text-center text-white">
                    <div className="section-title text-center mb-4 pb-2">
                       <h4 className="text-uppercase title mb-4">Contact Us</h4>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
     {/* <section  className="section pt-0 bg-light">
        <div  className="container-fluid">
            <div  className="row">
                <div  className="col-lg-12 p-0">
                    <div  className="map">
                        <iframe src={COMPANY_MAP_LOC} style={{"border": 0}} allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </div>

        <div  className="container mt-100 mt-60">
            <div  className="row align-items-center">
                <div  className="col-lg-4 col-md-6">
                    <div  className="contact-item mt-40">
                        <div  className="float-left">
                            <div  className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                <i  className="mdi mdi-earth"></i>
                            </div>
                        </div>
                        <div  className="contact-details">
                            <h4  className="text-dark mb-1">Website</h4>
                            <p  className="mb-0 text-muted">www.jobbazaar.com</p>
                        </div>
                    </div>
                </div>

                <div  className="col-lg-4 col-md-6">
                    <div  className="contact-item mt-40">
                        <div  className="float-left">
                            <div  className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                <i  className="mdi mdi-cellphone-iphone"></i>
                            </div>
                        </div>
                        <div  className="contact-details">
                            <h4  className="text-dark mb-1">Call us</h4>
                            <p  className="mb-0 text-muted">+44 7742265020</p>
                        </div>
                    </div>
                </div>

                <div  className="col-lg-4 col-md-6">
                    <div  className="contact-item mt-40">
                        <div  className="float-left">
                            <div  className="contact-icon d-inline-block border rounded-pill shadow text-primary mt-1 mr-4">
                                <i  className="mdi mdi-email"></i>
                            </div>
                        </div>
                        <div  className="contact-details">
                            <h4  className="text-dark mb-1">Email</h4>
                            <p  className="mb-0 text-muted">info@jm-group.ca</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> */}

    <section  className="section">
    <Loader loaded={this.state.isLoad}/>
        <div  className="container">
            <div  className="row">
                <div  className="col-lg-12">
                    <h4  className="text-dark mb-0">Get In Touch :</h4>
                </div>
            </div>

            <div  className="row">
                <div  className="col-lg-8 col-md-7 mt-4 pt-2">
                    <div  className="custom-form rounded border p-4">
                        <div id="message"></div>
                         <div  className="row">
                                <div  className="col-lg-6">
                                    <div  className="form-group app-label">
                                        <label  className="text-muted">First Name <span  className="text-danger">*</span></label>
                                        <input id="name1" type="text"  maxLength={20}  className="form-control resume" name="firstname" value={this.state.firstname} onChange={this.onChange.bind(this)} placeholder="Enter your first name" disabled={this.state.is_disable}/>
                                    </div>
                                </div>
                                <div  className="col-lg-6">
                                    <div  className="form-group app-label">
                                        <label  className="text-muted">Last Name <span  className="text-danger">*</span></label>
                                        <input  id="name2" type="text"  maxLength={20}  className="form-control resume" name="lastname" value={this.state.lastname} onChange={this.onChange.bind(this)} placeholder="Enter your last name" disabled={this.state.is_disable}/>
                                    </div>
                                </div>
                                <div  className="col-lg-6">
                                    <div  className="form-group app-label">
                                        <label  className="text-muted">Email address <span  className="text-danger">*</span></label>
                                        <input  id="email1" type="email"  maxLength={50}  className="form-control resume" name="email" value={this.state.email} onChange={this.onChange.bind(this)} placeholder="Enter your email address" disabled={this.state.is_disable}/>
                                        {this.state.emailValidMsg!==""? <span style={{color:"red"}}>{this.state.emailValidMsg}</span>:""}
                              </div>
                                </div>
                                <div  className="col-lg-6">
                                    <div  className="form-group app-label">
                                        <label  className="text-muted">Phone Number <span  className="text-danger">*</span></label>
                                        <input id="name21" type="text" minLength={9}  maxLength={15} className="form-control resume" name="phone_number" value={this.state.phone_number} onChange={this.onChange.bind(this)} placeholder="Enter your phone number" disabled={this.state.phone_number_disable}/>
                                    </div>
                                </div>
                                <div  className="col-lg-12">
                                    <div  className="form-group app-label">
                                        <label  className="text-muted">Comments <span  className="text-danger">*</span></label>

                                        <textarea   className="form-control resume" name="message" onChange={this.onChange.bind(this)} id="message" value={this.state.message} placeholder="Enter description" cols="5" rows="5" required></textarea>
                                    </div>
                                </div>

                            </div>
                            <div  className="row">
                                <div  className="col-sm-12">
                                    <button type="submit" onClick={this.senQuery.bind(this)} title="All fields are mandatory" className="submitBnt btn btn-primary" disabled={this.state.disable_btn}>Submit</button>
                                    <div id="simple-msg"></div>
                                    <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                                </div>
                            </div>
                    </div>
                </div>

                <div  className="col-lg-4 col-md-5 mt-4 pt-2">
                    <div  className="border rounded text-center p-4">
                        <h5  className="text-dark pb-3">Contact Info</h5>
                        <div  className="contact-location rounded mt-5 p-4">
                            <div  className="contact-location-icon bg-white text-primary rounded-pill">
                                <i  className="mdi mdi-map-marker"></i>
                            </div>
                            <p  className="text-muted pt-4 f-20 mb-0">
                                {/* Unit 22 , 5225 Orbitor Drive ,<br/>
                                       Mississauga , ON,<br/>
                                       L4W 4Y8<br/> */}
                                       info@up4hire.com</p>
                        </div>

                        {/* <h6  className="text-muted mt-4 mb-0">Share</h6>
                        <ul  className="list-unstyled social-icon mt-3 mb-0">
                            <li  className="list-inline-item"><a href="#"  className=""><i  className="mdi mdi-facebook"></i></a></li>
                            <li  className="list-inline-item"><a href="#"  className=""><i  className="mdi mdi-twitter"></i></a></li>
                            <li  className="list-inline-item"><a href="#"  className=""><i  className="mdi mdi-whatsapp"></i></a></li>
                            <li  className="list-inline-item"><a href="#"  className=""><i  className="mdi mdi-linkedin"></i></a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </div>
    </section>

<br/>
   </div>
    )
}

}
export default ContactUs;