import React, { Component } from "react";

class AboutUs extends Component {

render(){
   if(this.props.location.state!==undefined)
        {
            if(this.props.location.state.Footer!==undefined){
                window.scrollTo(0, 0);
                this.props.history.push('/aboutus');
            }
       }
    return(
        <>
        <section  className="bg-half page-next-level about-banner">

         <div  className="bg-overlay"></div>

         <div  className="container">

            <div  className="row justify-content-center">

               <div  className="col-md-6">

                  <div  className="text-center text-white">

                     <div  className="section-title text-center mb-4 pb-2">

                        <h4  className="text-uppercase title mb-4">About Us</h4>

                     </div>

                  </div>

               </div>

            </div>

         </div>
         </section>
         <section  className="section bg-light">

         <div  className="container">

            <div  className="row">

               <div  className="col-lg-6 col-md-12 col-sm-12">

                  <div  className="pb-4">

                     <img src="/assets/images/aboutus.jpg"  className="img-fluid rounded shadow" alt=""/>

                  </div>

               </div>

               <div  className="col-lg-6 col-md-12 col-sm-12">

                  <div  className="section-title text-left mb-4 pb-0">

                     <h2  className="title pb-0 title-heading about">About Us</h2>

                  </div>

                  <p  className="text-muted para-desc mx-auto mb-1 justify">Up4Hire is one of the preferred job site in the world 1  with millions of visitors
every day Up4Hire is a global leader in connecting well deserved candidates
with the well-deserved jobs and jobs. We are on a mission to make every
employer find the most appropriate talent without having to spend too much
time by completely simplifying the way hiring managers identify the right fit.
Over years, Up4Hire has been helping so many prospective job seekers with
the active jobs in various industries and shape a better career for the future.
At Up4Hire we have all the latest digital tools integrated to give the best
experience for both the job seeker and the employer.</p>

                  {/* <p  className="text-muted para-desc mx-auto mb-1 justify">While many people do hold a full-time occupation, "day job" specifically refers to those who hold the position solely to pay living expenses so they can pursue, through low paying entry work, the job they really want (which may also be during the day). The phrase strongly implies that the day job would be quit, if only the real vocation paid a living wage.</p>

                  <p  className="text-muted para-desc mx-auto mb-1 justify">The phrase "don't quit your day job" is a humorous response to a poor or mediocre performance not up to professional caliber. The phrase implies that the performer is not talented enough in that activity to be able to make a career out of it.</p> */}

               </div>

            </div>

         </div>

      </section>
      </>
    )
}

}
export default AboutUs;