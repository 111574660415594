import React, { Component } from "react";
import axios from 'axios'
import {DOMAIN} from '../../AppConfig';

class resetPassword extends Component {

   constructor(props) {
      super(props);
      this.state={
         new_password:"",
         confirm_password:"",
         message:"",
         color:"",
      }
      this.resetPassword = this.resetPassword.bind(this);
   }

   onChange(e){
      this.setState({
         [e.target.name]:e.target.value
         })
    }
    resetPassword(){
        var url=window.location.href;
        url=url.split('=');
        if(this.state.new_password===this.state.confirm_password)
        {
                const headers = {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                }
                if(url!=="")
                {
                    axios.post(DOMAIN + '/resetPassword',{
                    password:this.state.new_password,
                    token:url[1],
                    },
                        {
                            headers: headers
                        }
                    )
                    .then(response => {
                        if(response.data.status===200){
                            this.setState({message:response.data.message,color:"green"},this.props.history.push("/Login"));
                        }
                        else{
                            this.setState({message:response.data.message,color:"red"});
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
             }
    }
    else{
        this.setState({message:"Both new password and confirm password should be same ! Please fix and try again !",color:"red"});
    }
    }

   render() {
      return (
        <React.Fragment>
         <section  className="vh-100 signin">
            <div  className="home-center">
                <div  className="home-desc-center">
                    <div  className="container">
                        <div  className="row justify-content-center mr-top">
                            <div  className="col-lg-4 col-md-8">
                                <div  className="login-page bg-white shadow rounded p-4">
                                    <div  className="text-center">
                                        <h4  className="mb-4">Reset Password</h4>
                                    </div>
                                        <div  className="row">
                                            <div  className="col-lg-12">
                                                <div  className="form-group form-space position-relative">
                                                    <label>New Password <span  className="text-danger">*</span></label>
                                                    <input type="password"  className="form-control" placeholder="New Password"  maxLength={30} name="new_password" onChange={this.onChange.bind(this)} value={this.state.new_password} />
                                                </div>
                                            </div>

                                            <div  className="col-lg-12">
                                                <div  className="form-group form-space position-relative">
                                                    <label>Confirm Password <span  className="text-danger">*</span></label>
                                                    <input type="password"  className="form-control" placeholder="Confirm Password" maxLength={15} autoComplete="nope" name="confirm_password" onChange={this.onChange.bind(this)} value={this.state.confirm_password}/>
                                                    {this.state.message !== ""? <span style={{color:this.state.color}}>{this.state.message}</span>:null}

                                                </div>
                                            </div>
                                            <div  className="col-lg-12 mb-0">
                                                <button  className="btn btn-primary w-100" onClick={this.resetPassword} disabled={this.state.new_password==="" || this.state.confirm_password===""}>Submit</button>
                                            </div>

                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
      );
   }

}

export default resetPassword;