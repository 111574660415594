import React,{Component} from 'react';
import { BrowserRouter as Router, Route,Link } from 'react-router-dom';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import { GOOGLE_MAP_API_KEY } from '../../AppConfig';
// import {Link} from 'react-router-dom';

const apiKey = GOOGLE_MAP_API_KEY;
export class GoogleMap extends Component {
    constructor(props){
        super(props);
        this.state={
            activeMarker: {},
    selectedPlace: {},
    showingInfoWindow: false,
    test:GOOGLE_MAP_API_KEY,
        }
        this.onMarkerClick=this.onMarkerClick.bind(this);
        this.onInfoWindowClose=this.onInfoWindowClose.bind(this);
    }
    onMarkerClick(props,marker){
        this.setState({
              activeMarker: marker,
              selectedPlace: props,
              showingInfoWindow: true
            });
    }
  onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });

  onMapClicked = () => {
    if (this.state.showingInfoWindow)
      this.setState({
        activeMarker: null,
        showingInfoWindow: false
      });
  };
    render() {
        // const google = window.google
        return (
          <div>
            {this.props.data[0]===undefined?<div className="no-record-found">No records found!</div>:
          <Map google={this.props.google} initialCenter={{

            lat: this.props.data[0].latitude,
            lng: this.props.data[0].longitude
        }} zoom={3} >
       { this.props.data.map((data, i) => (

    <Marker key={i}
        title={data.title}
        name={data.title}
        position={{lat: data.latitude, lng: data.longitude}}
        position_type={data.position_type}
        lat={data.latitude}
        lng={data.longitude}
        id={data.id}
        end_client={data.end_client}
        onClick={this.onMarkerClick}
          > </Marker>

       ))}

        <InfoWindow
                marker={this.state.activeMarker}
                onClose={this.onInfoWindowClose}
                visible={this.state.showingInfoWindow}
                >
             { this.props.data.map((data, i) => (
               <div key={i}>
             {this.state.selectedPlace.lat===data.latitude && data.longitude===this.state.selectedPlace.lng?
          <React.Fragment>
             <Router>
           <Link to={`/job/${data.id}`}> <h6>{data.title}</h6></Link></Router>
            <p>{data.end_client}</p>
            <p>{data.position_type}</p>
             <hr></hr></React.Fragment>
                :null}
            </div>
                ))}
        </InfoWindow>


          </Map>
    }
          </div>
        );
      }
}

export default  GoogleApiWrapper({
    apiKey: apiKey
  })(GoogleMap)