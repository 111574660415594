import React, { Component } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { DOMAIN,GOOGLE_MAP_API_KEY,APPLY_JOB,FAVOURITE_JOB,GET_JOB_DETAIL,LINKED_IN,JOB_APPLY_FAVOURITE,REGISTER_JOBSEEKERS,LOGIN,ACTIVATE_ACCOUNT,FB_APP_ID,GOOGLE_CLIENT_ID,LINKEDIN_CLIENT_ID,UPLOAD_MY_RESUME,CHECK_RESUME} from '../../AppConfig';
import {
   EmailShareButton,
   FacebookShareButton,
   LinkedinShareButton,
 } from "react-share";
 import { SocialIcon } from 'react-social-icons';
 import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { LinkedIn } from '../../Linkedin';
import Loader from 'react-loader';
 class Jobdetails extends Component {

  constructor(props){
    super(props);
    this.state = {
      jobtitle: "",
      companyName:"",
      position_type:"",
      experience:"",
      jobdescription:"",
      country_detail:"",
      states:"",
      job_city:"",
      job_org:"",
      job_id:"",
      btn_apply:"Apply",
      btn_enable:false,
      favourite:0,
      btn_favourite:"Favourite",
      shareUrl:"",
      email:"",
      password:"",
      skills:[],
      interview_start_date:"",
      interview_end_date:"",
      expected_salary:"",
      contact_name:"",
      nof_openings:"",
      postedDate:"",
      zip:"",
         // PERSONAL DETALS
         countries_reg: [],
         states_reg: [],
         country_reg: "",
         selectedCountry: "",
         countryId_reg: "",
         state_reg: "",
         firstname: "",
         lastname: "",
         email_reg: "",
         phone_number: "",
         address: "",
         city: "",
         zipcode: "",
         password_reg: "",
         confirmpassword: "",
         terms: false,
         passwordStatus: false,
         emailValidMsg: "",
         apply_id:"",
         apply_org:"",
         activate_btn:false,
         visible: null,
         Check_fav_apply:0,
         visibleStatus:false,
         visible_reg:false,
         code:"",
         linkedin_signin_signup:"",
         isLoad:true,
         resume_upload:true,
         check_resume_login:0,
         uploadActive:false,
         upload_heading:"",
         invalid_file:"",
         error_signup:"",
         latitude:"",
         longitude:"",
         org_status:0,
         file_label:false,
         phone_number_issue:"",
         zipcode_error:"",
         city_error: ''
  }
  this.applyJob=this.applyJob.bind(this);
  this.favouriteJob=this.favouriteJob.bind(this);
  this.getjobseekerDetail=this.getjobseekerDetail.bind(this);
  this.confirm=this.confirm.bind(this);
  this.confirmation_yes=this.confirmation_yes.bind(this);
  this.activateAccount=this.activateAccount.bind(this);
  this.registerJobseekers=this.registerJobseekers.bind(this);
  this.linkedingetUser=this.linkedingetUser.bind(this);
  this.getMyLocation=this.getMyLocation.bind(this);
  this.zipcode_validation = this.zipcode_validation.bind(this);
}
getMyLocation() {
   const location = window.navigator && window.navigator.geolocation
   if (location) {
       location.getCurrentPosition((position, error) => {
           this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude, distances_status: true }, () =>
               this.getJoblist());

       }, (error) => {
           // this.setState({date:true,dates:1,distance:false,distances:0,distances_status:false});
           this.setState({ latitude: '', longitude: '', distance: false, distances: 0, date: true, dates: 1, distances_status: false }, () =>
               this.getJoblist());
       })
   }

}
resumeUpload(e){
   document.querySelector("#file-name").textContent ="";
   this.setState({file_label:false,resumeName:"",uploadActive:false});
   if(e.target.files[0]!==undefined)
   {
      var ext = e.target.files[0].name.split('.').pop();
      if(ext==="pdf" || ext==="docx" || ext==="doc"){
         document.querySelector("#file-name").textContent =e.target.files[0].name;
         this.setState({
            uploadActive:true,
            resumeName:e.target.files[0].name,
            invalid_file:"",
            error_signup:"",
            file_label:true
        });
     } else{
      this.setState({invalid_file:"File Format should be .pdf or .docx or .doc",
      error_signup:"Please check your form carefully",uploadActive:false,});
     }
}
}
myResumeUpload(flag){
      this.setState({resume_upload:false});
      var jobseeker_id="";
      if(flag===1)
       {
           jobseeker_id=this.state.user_Id;
           document.getElementById('closeModel').click();
       }
       else if(flag===2 && this.state.check_resume_login===1){
         jobseeker_id=localStorage.getItem("id");
       }
       else if(flag===2 && this.state.check_resume_login===0){
         document.getElementById('closeModel').click();
       }
       if(flag===1 || (flag===2 && this.state.check_resume_login===1)){
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
       var formData = new FormData();
       formData.append('file', document.getElementById('resumeUpload').files[0]);
       formData.append('userId',jobseeker_id);
axios.post(DOMAIN + UPLOAD_MY_RESUME, formData,{
    headers: headers
})
 .then(response => {
    document.getElementById('closeModel').click();
   this.setState({isLoad1:true,isLoad:true});
    if (response.data.status === 200) {
       document.getElementById('basicDisable').click();
       if(flag===1){
       toast.error("Verification sent to your registered email Id. Please verify your email and action ! ", {
           position: toast.POSITION.TOP_CENTER
       });
    }else{
      this.applyJob(this.state.apply_id, this.state.apply_org);
  }

    }else if(response.data.status === 500){
       localStorage.clear();

    }
    });
       }
}
handleSuccess = (data) => {
   this.setState({
     code: data.code,
     linkedin_signin_signup:1,
     errorMessage: '',
   });
   if(this.state.code!==""){
      this.linkedingetUser();
   }
 }

 handleFailure = (error) => {
   this.setState({
     code: '',
     linkedin_signin_signup:1,
     errorMessage: error.errorMessage,
   });
   if(this.state.code!==""){
      this.linkedingetUser();
   }
}
handleSuccessSignup = (data) => {
   this.setState({
     code: data.code,
     linkedin_signin_signup:2,
     errorMessage: '',
   });
   if(this.state.code!==""){
      this.linkedingetUser();
   }
 }

 handleFailureSignup = (error) => {
   this.setState({
     code: '',
      linkedin_signin_signup:2,
     errorMessage: error.errorMessage,
   });
   if(this.state.code!==""){
      this.linkedingetUser();
   }
}
ChangeVisbleStatus() {
   if (this.state.visible) {
       this.setState({
           visible: false,
       })
   } else {
       this.setState({
           visible: true
       })
   }
}
visibleOrg() {
   if (this.state.visible_reg) {
       this.setState({
         visible_reg: false,
       })
   } else {
       this.setState({
         visible_reg: true
       })
   }
}
forgetTab(){
   document.getElementById('modal-dismiss-login').click();
   localStorage.setItem('forgot',1);
   this.props.history.push('/login');
}
visibleOrgLogin(){
   if (this.state.visibleStatus) {
      this.setState({
         visible:null,
        visibleStatus: false,
      })
  } else {
      this.setState({
         visible:null,
          visibleStatus: true
      })
  }
}
activateAccount(flag){
   this.setState({activate_btn:true});
   if(flag===1)
   {
  const headers = {
     "Content-Type": "application/json",
     "Accept": "application/json"
  }
  axios.post(DOMAIN + ACTIVATE_ACCOUNT,
     {email:this.state.email},
     {
        headers: headers
     }
  )
  .then(response => {
   this.setState({activate_btn:false});
     if(response.data.status===200)
     {
        document.getElementById('modal-dismiss-deactivate').click();
        toast.success(response.data.message, {
           position: toast.POSITION.TOP_CENTER
       });
     }
     else {
        document.getElementById('modal-dismiss-deactivate').click();
        toast.success(response.data.message, {
           position: toast.POSITION.TOP_CENTER
       });
     }
  })
  .catch(function (error) {
     console.log(error);
  })
}else{
  document.getElementById('modal-dismiss-deactivate').click();
}
}

confirmation_yes(flag){
   if(flag===1){
       this.applyJob();
   }else{
       document.getElementById('modal-dismiss-apply').click();
   }

}
confirm(){

   // this.setState({ apply_id: id, apply_org: org });
   if (localStorage.getItem("id") !== null) {
       const headers = {
           "Content-Type": "application/json",
           "Accept": "application/json",
           "Authorization": localStorage.getItem('token')
       }
       axios.post(DOMAIN + CHECK_RESUME, {
           userId: localStorage.getItem("id"),
       },
           {
               headers: headers
           }
       )
           .then(response => {
               if (response.data.status === 200) {
                   document.getElementById('applyActive').click();
               } else{
                  //  toast.info(response.data.message, {
                  //      position: toast.POSITION.TOP_CENTER
                  //  });
                  var orgs=null;
                  var check_status=null;
                  if(response.data.org_visible===null)
                  {
                  orgs=1;
                  check_status=false;
                  }
                   this.setState({check_resume_login:1,upload_heading:"Please Upload Resume to Apply Job.",org_status:orgs,visible:check_status})
                   document.getElementById('resumeModal1').click();

               }
           })
           .catch(function (error) {
               console.log(error);
           })

   }else{
      this.setState({Check_fav_apply:1});
       document.getElementById('loginActive').click();
   }

}
zipcode_validation(zipcode, input_country, input_city, type = '') {
   const header = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Accept": "application/json",
  }
  let url = '';
  if(type === 'city') {
     url = 'https://maps.googleapis.com/maps/api/geocode/json?address='+input_city+'&key='+GOOGLE_MAP_API_KEY;
  } else {
     url = 'https://maps.googleapis.com/maps/api/geocode/json?key='+GOOGLE_MAP_API_KEY+'&components=postal_code:'+zipcode;
  }
  axios.get(url, {
      headers: header
  })
  .then(response => {
   if(type == 'city') {
      if(response?.data?.status == 'ZERO_RESULTS') {
         this.setState({
            zipcode: '',
            zipcode_error : '',
            city_error: 'Please enter valid city.'
         });
   }
   }

          if (Object.keys(response.data.results).length > 0) {
              var address = response.data.results[0].address_components;
              var zipcode_error = "";

              //break up the components
              var country = "", city = "", zip = "";
              address.forEach(function (item, index) {
                  var type = item.types[0];

                  if (city == "" && (type == "sublocality_level_1" || type == "locality")) {
                      city = (item.long_name).trim();
                  }
                  // if ($state == "" && type=="administrative_area_level_1") {
                  //     $state = trim(item.short_name);
                  // }
                  if (country == "" && type == "country") {
                      country = (item.long_name).trim();

                  }
                  if(type === 'postal_code') {
                       zip = (item.long_name).trim();
                  }

              })
              if(type !== 'zipcode' && type !== '') {
                 this.setState({
                    zipcode: zip,
                    city_error: ''
                 });
                 if(zip === '') {
                  zipcode_error = '';
               }
              }
              if (country.toLowerCase().toString() != 'canada' && country.trim().toLowerCase().toString() != 'united states') {
                  zipcode_error = "This Zipcode is not available in this Contry and City";
                  this.setState({
                      zipcode_error : zipcode_error
                      });
              }
              if (((country.toLowerCase().toString()) == 'canada' && input_country != 'Canada') || ((country.toLowerCase().toString()) == 'united states' && input_country != 'United States')) {

                  zipcode_error = "Country";
              }
              if ((country.toLowerCase().toString()) != 'canada' && (country.toLowerCase().toString()) != 'united states') {
                  zipcode_error = "Country";
              }
              if ((input_city).toLowerCase() != (city.toLowerCase())) {
                  if (zipcode_error != "") {
                      zipcode_error = zipcode_error + " and City"
                  } else {
                      zipcode_error = "City";
                  }
              }
              if(zipcode === '') {
                 zipcode_error = '';
              }
              if (zipcode_error != "") {
                  zipcode_error = "This Zipcode is not available in this " + zipcode_error + ". This Zipcode is available from (" + country + " and " + city + ")";

                  this.setState({
                      zipcode_error : zipcode_error
                      });
              } else {
                  this.setState({
                      zipcode_error : ''
                      });
              }
          } else if(zipcode!=""){
              this.setState({
                  zipcode_error : "zipcode is not valid"
                  });
                 }
      })
      setTimeout(() => {
         console.log(this.state.zipcode);
        if(this.state.zipcode === '') {
          this.setState({
              zipcode_error : ""
              });
        }
      },1000)
}
onChange(e) {
   if (e.target.name === 'country_reg') {
      this.setState({
        countryId_reg: e.target.value,
        selectedCountry: this.state.countries_reg[e.target.value].country,
        country_reg: this.state.countries_reg[e.target.value].id,
      })
      this.zipcode_validation(this.state.zipcode,this.state.countries_reg[e.target.value].country,this.state.city);
      // this.state.country=this.state.countries[e.target.value].id;
      // this.state.selectedCountry=this.state.countries[e.target.value].country;

    } else if (e.target.name === 'city') {
      this.setState({
          city: e.target.value

      })
      this.zipcode_validation(this.state.zipcode,this.state.selectedCountry,e.target.value, 'city');
      // this.state.country=this.state.countries[e.target.value].id;
      // this.state.selectedCountry=this.state.countries[e.target.value].country;

  }   else if(e.target.name==='zipcode'){
      const zipregx= /^[^/+<>%#@&"*?=^!~`.,'_:;\$/|\/\\{}()\[/\]/-]*$/;
      if(zipregx.test(e.target.value) || e.target.value===""){
         this.setState({
            [e.target.name]:e.target.value
         })
         this.zipcode_validation(e.target.value,this.state.selectedCountry,this.state.city, 'zipcode');
      }
   }else if (e.target.name === 'phone_number') {
      const numregx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
      if (numregx.test(e.target.value) || e.target.value === "") {
         if(e.target.value.length<9){
            this.setState({
                phone_number_issue:"Please enter valid phone number.Phone number must be a minimum Country Code + 10 digits !"
            })
        }else{
            this.setState({
                phone_number_issue:""
            })
        }
        this.setState({
          [e.target.name]: e.target.value
        })
      }
    } else if (e.target.name === 'firstname' || e.target.name === 'lastname' || e.target.name === 'city') {
      const strregx = /^[A-Za-z]+$/;
      if (strregx.test(e.target.value) || e.target.value === "") {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
    } else if (e.target.name === 'address') {
      const addressregx =/^[^<>%*?=^!~`]*$/;
      if (addressregx.test(e.target.value) || e.target.value === "") {
        this.setState({
          [e.target.name]: e.target.value
        })
      }
    } else if(e.target.name === 'design'){
        this.setState({
          designError:"",
          [e.target.name]: e.target.value
        })
    } else if (e.target.name === 'email_reg') {
      const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
      if (emailregex.test(e.target.value)) {
        this.setState({
          emailValidMsg: "",
          error_signup:""
        })
      } else {
        this.setState({
          emailValidMsg: "Please enter a valid  email id !",
          error_signup:"Please check your form carefully"
        })
      }
      this.setState({
        [e.target.name]: e.target.value
      })
    } else {
      if (e.target.name === 'password_reg') {
        if (e.target.value.length < 5) {
          this.setState({
            passwordError: "Invalid Password . Password length must be minimum 5 letters !",
            error_signup:"Please check your form carefully"
          })
        } else {
          this.setState({
            passwordError: "",
            error_signup:""
          })
        }
      }
      if(e.target.name==='confirmpassword'){
         this.setState({
            passwordStatus:false,
            error_signup:""
         })
   }
      this.setState({
        [e.target.name]: e.target.value
      })
    }
}
 login(){
    this.setState({isLoad:false})
   var org_visible_status=null;
   if (this.state.visibleStatus)
    org_visible_status = 1;
else
    org_visible_status =null;
      const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }

        axios.post(DOMAIN + LOGIN,{
            data:{email:this.state.email,password:this.state.password,org_visible_status:org_visible_status}
        },
            {
            headers: headers
            }
        )
        .then(response => {
         this.setState({isLoad:true})
            if(response.data.status === 200) {
               if(response.data.data.email_confirm===1){
            localStorage.setItem("token",response.data.api_token);
            localStorage.setItem("firstname",response.data.data.firstname);
            localStorage.setItem("lastname",response.data.data.lastname);
            localStorage.setItem('email',response.data.data.email);
            localStorage.setItem('id',response.data.data.id);
            this.props.history.push('/job/'+this.props.match.params.id);
            this.getjobseekerDetail();
            if(this.state.Check_fav_apply===1)
            {
               if(response.data.check_resume!==null){
               this.applyJob();
            }else{
                  // toast.info("Please upload resume to apply job", {
                  //     position: toast.POSITION.TOP_CENTER
                  // });
                  var orgs=null;
                  var check_status=null;
                  if(response.data.org_visible_status===null)
                  {
                  orgs=1;
                  check_status=false;
                  }
                  this.setState({check_resume_login:1,upload_heading:"Please Upload Resume to Apply Job.",org_status:orgs,visible:check_status})
                  document.getElementById('resumeModal1').click();
             }
            }
            else
            {
               var favStatus = 0;
               if(this.state.favourite===1){
                  this.setState({
                     btn_favourite:"Marked as favourite",
                     favourite:0,
                  });
                  favStatus=1;
               }
               else{
                  this.setState({
                     btn_favourite:"Favourite",
                     favourite:1,
                  });
                  favStatus=0;
               }
            this.favouriteJob();
            }
            }else{
               toast.error("Already email verification sent to your email. Please verify first!! or login with social", {
                  position: toast.POSITION.TOP_CENTER
               });
         }
            document.getElementById('modal-dismiss-login').click();
            }
            else if(response.data.status===201){
            document.getElementById('modal-dismiss-login').click();
            document.getElementById('deactivate').click();
          }else{
            if(response.data.error_code===1){
                this.setState({
                    EmailError:response.data.msg
                })
            }else{
                this.setState({
                    PasswordError:response.data.msg
                })
            }
            }
        })
        .catch(function (error) {
            console.log(error);
        })
      }
applyJob(){
   if(localStorage.getItem("id")!==null)
   {

   const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization":localStorage.getItem('token')
   }
   axios.post(DOMAIN + APPLY_JOB,{
     job_id:this.state.job_id,
     jobseeker_id:localStorage.getItem("id"),
     org:this.state.job_org,
     name:localStorage.getItem('firstname'),
     org_visible_status: this.state.visible,
   },
      {
         headers: headers
      }
   )
   .then(response => {
      document.getElementById('modal-dismiss-apply').click();
      if(response.status===200)
      {
         if(response.data.status === 200){
            toast.success("Job "+response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        else{
            toast.error("Job "+response.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
      this.setState({
         btn_apply:"Applied",
         btn_enable:true});
      }else if(response.status === 401){
         localStorage.clear();
         this.props.history.push('/login');
       }
   })
   .catch(function (error) {
      console.log(error);
   })
}
else{
   // alert("shbdhds");
   // this.setState({Check_fav_apply:1},()=>{document.getElementById('loginActive').click()});
   document.getElementById('loginActive').click();
}

}
favouriteJob(){

   if(localStorage.getItem("id")!==null)
   {
       var favStatus = 0;
   if(this.state.favourite===1){
      this.setState({
         btn_favourite:"Marked as favourite",
         favourite:0,
      });
      favStatus=1;
   }
   else{
      this.setState({
         btn_favourite:"Favourite",
         favourite:1,
      });
      favStatus=0;
   }

         const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization":localStorage.getItem('token')
         }
         axios.post(DOMAIN + FAVOURITE_JOB,{
         favourite:favStatus,
         job_id:this.state.job_id,
         jobseeker_id:localStorage.getItem("id"),
         org:this.state.job_org,
         name:localStorage.getItem('firstname')
         },
            {
               headers: headers
            }
         )
         .then(response => {
            if(response.data.status===200){
               var msg="";
               if(favStatus===1)
               {
                  this.setState({ btn_favourite:"Marked as favourite"});
                  msg="Job successfully marked as favourite !";
               }
               else
               {
                  this.setState({ btn_favourite:"Favourite"});
                  msg="Job Successfully Marked as UnFavourite";
               }

               toast.success(msg, {
                  position: toast.POSITION.TOP_CENTER
               });
            }else if(response.data.status === 401){
               localStorage.clear();
               this.props.history.push('/login');
             }
         })
         .catch(function (error) {
            console.log(error);
         })
   }
   else{
      this.setState({Check_fav_apply:2},()=>{ document.getElementById('loginActive').click()});
      // document.getElementById('loginActive').click();
   }
}
getJoblist(){
   window.scrollBy(0, 530);
   // setTimeout(function(){
   //    window.scrollTo(0, 530);
   // },4000)
   // window.scrollBy(0, 630);
   this.setState({isLoad:false})
   const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
   }
   axios.post(DOMAIN + GET_JOB_DETAIL,{
      id:this.props.match.params.id,
      jobseeker_id:localStorage.getItem("id"),
      latitude:this.state.latitude,
      longitude:this.state.longitude
   },
      {
         headers: headers
      }
   )
   .then(response => {
      this.setState({isLoad:true})
      if(response.data.status === 200) {
         if(response.data.data.jobdata.skills!=="" && response.data.data.jobdata.skills!=='[]')
         this.setState({ skills:JSON.parse(response.data.data.jobdata.skills)});
         else
         this.setState({ skills:[]});
         if(localStorage.getItem("id"))
         {
         if (response.data.data.jobdata.org_visible_status.org_visible_status !== undefined) {
            if (response.data.data.jobdata.org_visible_status.org_visible_status === 0) {
                this.setState({ visible_check: false, });
            }
            else {
                this.setState({ visible_check: true, });
            }
        }
      }
      var postdate = new Date(response.data.data.jobdata.created_at);
      var strArray=['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var d = postdate.getDate();
      var m = strArray[postdate.getMonth()];
      var y = postdate.getFullYear();
      postdate='' + (d <= 9 ? '0' + d : d) + '-' + m + '-' + y;
      // var post_date=response.data.data.jobdata.created_at.split(" ");
      this.setState({
         jobtitle:response.data.data.jobdata.title,
         companyName:response.data.data.jobdata.company,
         zip:response.data.data.jobdata.zip,
         position_type:response.data.data.jobdata.position_type,
         experience: response.data.data.jobdata.experience,
         jobdescription:response.data.data.jobdata.description,
         country_detail:response.data.data.jobdata.country,
         states:response.data.data.jobdata.state,
         job_city:response.data.data.jobdata.city,
         job_org:response.data.data.jobdata.org,
         job_id:response.data.data.jobdata.id,
         shareUrl:response.data.data.jobdata.share_url,
         countries_reg: response.data.data.country,
         states_reg: response.data.data.state,
         interview_start_date:response.data.data.jobdata.start_date,
         interview_end_date:response.data.data.jobdata.end_date,
         contact_name:response.data.data.jobdata.contact_name,
         nof_openings:response.data.data.jobdata.openings,
         postedDate:postdate,
         distance:response.data.data.jobdata.distance
         // skills:JSON.parse(response.data.data.jobdata.skills)
      });

     if(localStorage.getItem("id")!==null){
        this.getjobseekerDetail();
     }
      }
      else{
         this.props.history.push('/joblisting');
      }
      // window.scrollBy(0, 530);
   })
   .catch(function (error) {
      console.log(error);
   })
}
componentWillMount(){
   // window.scrollTo(0, 0);
  this.getMyLocation();
}
signUp(){
   document.getElementById('modal-dismiss-login').click();
   document.getElementById('basicActive').click();
 }
 loginPopup(){
   document.getElementById('basicDisable').click();
   document.getElementById('loginActive').click();
 }
getjobseekerDetail(){
   const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
   }
   axios.post(DOMAIN + JOB_APPLY_FAVOURITE,{
      job_id:this.state.job_id,
      job_org:this.state.job_org,
      jobseeker_id:localStorage.getItem("id")
   },
      {headers: headers}
   )
      .then(response => {
         if(response.data.status===200)
         {
            if(response.data.job_status===1)
            {
               this.setState({
               btn_apply:"Applied",
               btn_enable:true});
            }
            if(response.data.job_favourite===1)
            {
               this.setState({
                 favourite:0,
                  btn_favourite:"Marked as favourite"
                });
            }
            else
            {
               this.setState({
                 favourite:1,
                  btn_favourite:"Favourite"
                });
            }

         }
      })
      .catch(function (error) {
         console.log(error);
      })
}
linkedingetUser(){
   const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json"
   }
   if(this.state.code!==""){
      document.getElementById("fade").style.opacity = "0.5";
      document.getElementById("fade").style['pointer-events'] = 'none';
      document.getElementById("fade1").style.opacity = "0.5";
      document.getElementById("fade1").style['pointer-events'] = 'none';
      this.setState({isLoad:false});
   axios.post(DOMAIN + LINKED_IN,{
      code:this.state.code
   },
      {
         headers: headers
      }
   )
   .then(response => {
      document.getElementById("fade").style.opacity = "unset";
      document.getElementById("fade").style['pointer-events'] = '';
      document.getElementById("fade1").style.opacity = "unset";
      document.getElementById("fade1").style['pointer-events'] = '';
      // console.log(response.data);
      var fname=response.data.user_data[0].firstname;
      var lname=response.data.user_data[0].lastname;
      var email=response.data.user_data[0].email["handle~"].emailAddress;
     if(this.state.linkedin_signin_signup===1)
     {
     this.setState({
      code:"",
   },this.login_social(fname,lname,email));
   }
   else{
       this.setState({firstname:response.data.user_data[0].firstname,
           lastname:response.data.user_data[0].lastname,
           email_reg:response.data.user_data[0].email["handle~"].emailAddress,
           code:"",
           passwordError: "",
           error_signup:"",
           isLoad:true
        });
   }

   })
   .catch(function (error) {
      console.log(error);
   })
}
 }
login_social(fname,lname,email){
   var org_visible_status =null;
   if (this.state.visibleStatus)
    org_visible_status = 1;
else
    org_visible_status =null;
   const headers = {
       "Content-Type": "application/json",
       "Accept": "application/json"
    }
    var RegisterData={
                lastname:lname,
                email:email,
                firstname:fname,
                org_visible_status:org_visible_status,
                flag:1
    }
    document.getElementById("fade").style.opacity = "0.5";
    document.getElementById("fade").style['pointer-events'] = 'none';
    this.setState({isLoad:false});
    axios.post(DOMAIN + REGISTER_JOBSEEKERS,{
       data:RegisterData
    },
       {
          headers: headers
       }
    )
    .then(response => {
      document.getElementById("fade").style.opacity = "unset";
      document.getElementById("fade").style['pointer-events'] = '';
      this.setState({isLoad:true,error_signup:""});
      document.getElementById('modal-dismiss-login').click();
       if(response.data.status === 200) {
          localStorage.setItem("token",response.data.api_token);
         //  localStorage.setItem("firstname",fname);
         //  localStorage.setItem("lastname",lname);
         localStorage.setItem("firstname", response.data.first_name);
         localStorage.setItem("lastname", response.data.last_name);
          localStorage.setItem('email',email);
          localStorage.setItem('id',response.data.user_id);
          this.props.history.push('/job/'+this.props.match.params.id);
          this.getjobseekerDetail();
          if(this.state.Check_fav_apply===1)
          {
             if(response.data.check_resume!==null){
            // if (this.state.apply_id != "" && this.state.apply_org != "")
            this.applyJob();
            }else{
               //  toast.info("Please upload resume to apply job", {
               //      position: toast.POSITION.TOP_CENTER
               //  });
               if(response.data.org_visible_status!==undefined)
               this.setState({org_status:1});
                this.setState({check_resume_login:1,upload_heading:"Please Upload Resume to Apply Job."})
                document.getElementById('resumeModal1').click();
            }

          }
          else
          {
             var favStatus = 0;
            if(this.state.favourite===1){
               this.setState({
                  btn_favourite:"Marked as favourite",
                  favourite:0,
               });
               favStatus=1;
            }
            else{
               this.setState({
                  btn_favourite:"Favourite",
                  favourite:1,
               });
               favStatus=0;
            }
          this.favouriteJob();
         }
         //  this.handlePageChange(this.state.activePage);

         //  document.getElementById('basicDisable').click();
         //  this.props.history.push('/joblisting');
       }else if(response.data.status===201){
        document.getElementById('modal-dismiss-login').click();
        document.getElementById('deactivate').click();
    }else{
          if(response.data.error_code===1){
             this.setState({
                emailValidMsg:response.data.msg,
                error_signup:"Please check your form carefully"
             })
          }
       }
    })
    .catch(function (error) {
       console.log(error);
    })

 }
 changeterms() {
   if (this.state.terms) {
     this.setState({
       terms: false,
     })
   } else {
     this.setState({
       terms: true
     })
   }
 }
 registerJobseekers() {
   var org_visible_status = 0;
   if (this.state.visible_reg)
    org_visible_status = 0;
else
    org_visible_status = 1;
   var personaldata = this.state;
   if (personaldata.password_reg !== personaldata.confirmpassword) {
     this.setState({
       passwordStatus: true,
       error_signup:"Please check your form carefully"
     })
   } else {
     var RegisterData = {
       country: personaldata.country_reg,
       state: personaldata.state_reg,
       firstname: personaldata.firstname,
       lastname: personaldata.lastname,
       email: personaldata.email_reg,
       phone_number: personaldata.phone_number,
       address: personaldata.address,
       city: personaldata.city,
       zipcode: personaldata.zipcode,
       password: personaldata.password_reg,
       org_visible_status: org_visible_status,
       flag:0
     }

     const headers = {
       "Content-Type": "application/json",
       "Accept": "application/json"
     }
     document.getElementById("fade").style.opacity = "0.5";
     document.getElementById("fade").style['pointer-events'] = 'none';
     document.getElementById("fade1").style.opacity = "0.5";
     document.getElementById("fade1").style['pointer-events'] = 'none';
     this.setState({isLoad:false,error_signup:""});
     axios.post(DOMAIN + REGISTER_JOBSEEKERS, {
       data: RegisterData
     },
       {
         headers: headers
       }
     )
       .then(response => {
         this.setState({isLoad:true});
         document.getElementById("fade").style.opacity = "unset";
         document.getElementById("fade").style['pointer-events'] = '';
         document.getElementById("fade1").style.opacity = "unset";
         document.getElementById("fade1").style['pointer-events'] = '';

         if (response.data.status === 200) {
            this.setState({user_Id:response.data.user_id})
            this.myResumeUpload(1);
         //   localStorage.setItem("token", response.data.api_token);
         //   localStorage.setItem("firstname", this.state.firstname);
         //   localStorage.setItem("lastname", this.state.lastname);
         //   localStorage.setItem('email', this.state.email_reg);
         //   localStorage.setItem('id', response.data.user_id);

         //   if(this.state.Check_fav_apply===1)
         //   this.applyJob();
         //   else
         //   { var favStatus = 0;
         //    if(this.state.favourite===1){
         //       this.setState({
         //          btn_favourite:"Marked as favourite",
         //          favourite:0,
         //       });
         //       favStatus=1;
         //    }
         //    else{
         //       this.setState({
         //          btn_favourite:"Favourite",
         //          favourite:1,
         //       });
         //       favStatus=0;
         //    }
         //   this.favouriteJob();
         // }
         //   this.props.history.push('/job/'+this.props.match.params.id);
         } else if(response.data.status===201){
           document.getElementById('modal-dismiss-login').click();
           document.getElementById('deactivate').click();
       }else {
           if (response.data.error_code === 1) {
             this.setState({
               emailValidMsg: response.data.msg,
               error_signup:"Please check your form carefully"
             })
           }
         }
       })
       .catch(function (error) {
         console.log(error);
       })
   }
 }
 isDisabled() {
   // if (this.state.firstname === ""||this.state.city==="" || this.state.lastname === "" || this.state.state_reg === "" || this.state.zipcode === "" || this.state.terms === false || this.state.password_reg === "" || this.state.confirmpassword === "" || this.state.address === "" || this.state.phone_number === "" || this.state.email_reg === "" || this.state.emailValidMsg !== "" || this.state.passwordError !== "" || this.state.invalid_file!=="" || this.state.uploadActive!==true) {
   if (this.state.firstname === "" || this.state.lastname === "" || this.state.terms === false || this.state.password_reg === "" || this.state.confirmpassword === "" || this.state.email_reg === "" || this.state.emailValidMsg !== "" || this.state.passwordError !== "" || this.state.invalid_file!=="" || this.state.uploadActive!==true || this.state.file_label===false) {
     return true;
   }
   return false;
 }
 responseGoogleSign = (response) => {
   if(response.error!=="popup_closed_by_user")
   {
   var name=response.profileObj.name.split(" ");
   this.setState({email_reg:response.profileObj.email,firstname:name[0],lastname:name[1], passwordError: "",
   error_signup:""});
   }
 }
 responseFacebookSign = (response) => {
   if(response.status!=="unknown")
   {
   var name=response.name.split(" ");
   this.setState({email_reg:response.email,firstname:name[0],lastname:name[1], passwordError: "",
   error_signup:""});
   }
 }
 responseFacebook = (response) => {
   if(response.status!=="unknown")
   {
   var name=response.name.split(" ");
   this.setState({email:response.email,firstname:name[0],lastname:name[1], passwordError: "",
   error_signup:""});
   this.login_social(name[0],name[1],response.email);
   }else{
      toast.error("Do you have faced any issue please contact admin to access social login module",{
         position: toast.POSITION.TOP_CENTER
     });
   }

 }
 responseGoogle = (response) => {
   if(response.error!=="popup_closed_by_user")
   {
   var name=response.profileObj.name.split(" ");
   this.setState({email:response.profileObj.email,firstname:name[0],lastname:name[1], passwordError: "",
   error_signup:""});
   this.login_social(name[0],name[1],response.profileObj.email);
   }else{
      toast.error("Do you have faced any issue please contact admin to access social login module",{
         position: toast.POSITION.TOP_CENTER
     });
   }

 }
  render() {
  function activefile() {
      document.getElementById('resumeUpload').click();
    }
    var basicCloseStyle = {
      position: "relative",
      left: "41rem",
      top: "14px",
      zIndex: "10"
    }
   // const code = this.state.code;
   // if(code!="")
   // {
   //   this.linkedingetUser();
   // }
   // const errorMessage=this.state.errorMessage;
   // const responseFacebookSign = (response) => {
   //    if(response.status!=="unknown")
   //    {
   //    var name=response.name.split(" ");
   //    this.setState({email_reg:response.email,firstname:name[0],lastname:name[1]});
   //    }
   //  }
   //  const responseFacebook = (response) => {
   //    if(response.status!=="unknown")
   //    {
   //    var name=response.name.split(" ");
   //    this.setState({email:response.email,firstname:name[0],lastname:name[1]});
   //    this.login_social(name[0],name[1],response.email);
   //    }

   //  }
   //  const responseGoogleSign = (response) => {
   //    if(response.error!=="popup_closed_by_user")
   //    {
   //    var name=response.profileObj.name.split(" ");
   //    this.setState({email_reg:response.profileObj.email,firstname:name[0],lastname:name[1]});
   //    }
   //  }
   //  const responseGoogle = (response) => {
   //    if(response.error!=="popup_closed_by_user")
   //    {
   //    var name=response.profileObj.name.split(" ");
   //    this.setState({email:response.profileObj.email,firstname:name[0],lastname:name[1]});
   //    this.login_social(name[0],name[1],response.profileObj.email);
   //    }

   //  }
    return (



        <>
            <section className="bg-half page-next-level job-details">
      <div className="bg-overlay"></div>
      <div className="container">
         <div className="row justify-content-center">
            <div className="col-md-6">
               <div className="text-center text-white">
                  <div className="section-title text-center mb-4 pb-2">
                     <h4 className="text-uppercase title mb-4">Job Detail</h4>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
{/* Job Detail Section  Start */}
 <section  className="section">
 <Loader loaded={this.state.isLoad}/>
         <div  className="container">
            <div  className="row">
               <div  className="col-lg-12">
                  <h4  className="text-dark mb-3 title-heading-sub">{this.state.jobtitle}</h4>
               </div>
            </div>
            <div  className="row">
               <div  className="col-lg-9 col-md-8">
                  <div  className="row">
                     <div  className="col-lg-12 col-md-12">
                        <div  className="job-list-box border rounded">
                           <div  className="p-3">
                              <div  className="row align-items-center">
                                 <div  className="col-lg-3 col-md-3">
                                    <Link to="/">
                                    <div  className="company-logo-img text-center">
                                       <img src="/assets/images/featured-job/img-4.png" alt=""  className="img-fluid mx-auto d-block"/>
                                       <h6  className="mb-2"><div className="text-dark text-center">UP4HIRE</div></h6>
                                    </div>
                                    </Link>
                                 </div>
                                 <div  className="col-lg-9 col-md-9">
                                    <div  className="job-list-desc">
                  <h4  className="text-dark mb-3 title-heading-sub">{this.state.jobtitle}</h4>
                                       <h6  className="mb-2"><a href="#"  className="text-dark job-heading"> {this.state.title}</a></h6>
                                       <p  className="text-muted mb-0 jobdetails-font mt-3"><i  className="mr-1 fas fa-building jobdetails-icon"></i>  {this.state.companyName}</p>
                                       <ul  className="list-inline mob-align mb-0 mt-0">
                                          <li  className="list-inline-item mr-3">
                                             <p  className="text-muted mb-0 jobdetails-font"><i  className="mdi mdi-map-marker mr-1 jobdetails-icon"></i>{this.state.job_city}</p>
                                          </li>
                                          <li  className="list-inline-item mr-3">
                                             <p  className="text-muted mb-0 jobdetails-font"><i  className="fas fa-suitcase mr-1 jobdetails-icon"></i> {this.state.position_type}</p>
                                          </li>

                                          <li  className="list-inline-item  mr-3">
                                             <p  className="text-muted mb-0 jobdetails-font"><i  className="fas fa-calendar-week mr-1 jobdetails-icon"></i> {this.state.postedDate}</p>
                                          </li>
                                          <li  className="list-inline-item">
                                          {this.state.distance !== 0 ?
                                             <p  className="text-muted mb-0 jobdetails-font">
                                                <i  className="fas fa-road mr-1 jobdetails-icon"></i> {Number(this.state.distance).toFixed(2) + "KM"}</p>: ''}
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div  className="col-lg-3 col-md-4 mt-4 mt-sm-0">
                  <div  className="job-detail border rounded  pt-0 pb-0 pr-4 pl-4 mb-4">
                     <div  className="job-detail-location pt-0 text-center">
                        <div  className="job-list-button-sm text-center">
                           <div  className="mt-1">
                              <button className="btn btn-sm btn-favourite" onClick={this.favouriteJob}>{this.state.btn_favourite}</button>
                           </div>
                           <div  className="mt-1">
                              <button className={'btn btn-sm btn-apply-details'+ (this.state.btn_apply=='Applied'? " greenJob":" ")} onClick={this.confirm}  disabled={this.state.btn_enable}>{this.state.btn_apply}</button>
                           </div>
                        </div>
                        <h6  className="text-dark f-17 mt-1 mb-0">Share Job :</h6>
                        <ul  className="social-icon list-inline mt-1 mb-0">
                           <li  className="list-inline-item">
                              <FacebookShareButton  className="social-button" url={this.state.shareUrl}   quote={this.state.jobtitle} >
                              <a href="#"  className="rounded">
                                  <i  className="mdi mdi-facebook"></i>
                              </a>
                             </FacebookShareButton>
                              </li>
                           <li  className="list-inline-item">
                              <EmailShareButton className="social-button"  url={this.state.shareUrl} subject={this.state.jobtitle}  title="Please use default mail reading app to share url.To change settings.(Go to Settings > Apps. Click Default apps in the pane on the left.  You'll see your default apps for common uses here, like Mail,Outlook,Thunderbird and more. Click an app to see others installed on your system that can take its place, then select the one you'd like to set as default).">
                              <a href="#"  className="rounded">
                              <i  className="mdi mdi-gmail">  </i>
                              </a>
                           </EmailShareButton>
                           </li>
                           <li  className="list-inline-item">
                              <LinkedinShareButton className="social-button" url={this.state.shareUrl} summary={this.state.jobtitle}>
                               <a href="#"  className="rounded">
                               <i  className="mdi mdi-linkedin"></i>
                               </a>
                                 </LinkedinShareButton>
                          </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
            <div  className="row">
               <div  className="col-lg-9 col-md-8">
               {this.state.skills.length!==0?
               <>
                  <div  className="row">
                     <div  className="col-lg-12">
                        <h4  className="text-dark mb-3 mt-3 title-heading-sub">Key Skills</h4>
                     </div>
                  </div>
                  <div  className="row">
                     <div  className="col-lg-12 col-md-12">
                        <div  className="job-detail border rounded mt-2 p-4">
                           <div  className="job-detail-desc">
                              <p  className="text-muted mb-3">
                              {this.state.skills.map((value, key) => (
                               <span key={key}>{value.skill} |</span>
                              ))}
                              </p></div>
                        </div>
                     </div>
                  </div>
                  </>:null}
                  {this.state.jobdescription!==""?
                  <>
                  <div  className="row">
                     <div  className="col-lg-12">
                        <h5  className="text-dark mt-4 title-heading-sub">Job Summary</h5>
                     </div>
                  </div>
                  <div  className="row">
                     <div  className="col-lg-12">
                        <div  className="job-detail border rounded mt-2 p-4">
                           <div  className="job-detail-desc">
                              <p  className="text-muted mb-3"
                              style={{wordBreak: 'break-all' }}  dangerouslySetInnerHTML={{ __html: this.state.jobdescription }}></p>
                                </div>
                        </div>
                     </div>
                  </div>
                  </>:''}
               </div>
               <div  className="col-lg-3 col-md-4 mt-4 mt-sm-0">
                  <div  className="job-detail border rounded p-4">
                     <div  className="job-detail-location pt-4">
                        <div  className="job-details-desc-item">
                           <div  className="row">
                              <div  className="col-lg-2 col-md-2 col-sm-2 col-2">
                                 <div  className="float-left mr-2">
                                    <i  className="fa fa-suitcase text-muted jobdetails-icon"></i>
                                 </div>
                              </div>
                              <div  className="col-lg-10 col-md-10 col-sm-5 col-10">
                                 <h5>Job Type:</h5>
                              </div>
                              <div  className="col-lg-12 col-md-12 col-sm-5 col-12 mr-l-sidewiget">
                                 <p  className="text-muted mb-2">{this.state.position_type}</p>
                              </div>
                           </div>
                        </div>
                        <div  className="job-details-desc-item">
                           <div  className="row">
                              <div  className="col-lg-2 col-md-2 col-sm-2 col-2">
                                 <div  className="float-left mr-2">
                                    <i  className="fa fa-certificate text-muted jobdetails-icon"></i>
                                 </div>
                              </div>
                              <div  className="col-lg-10 col-md-10 col-sm-5 col-10">
                                 <h5>Experience:</h5>
                              </div>
                              <div  className="col-lg-12 col-md-12 col-sm-5 col-12 mr-l-sidewiget">
                                 <p  className="text-muted mb-2">{this.state.experience} Yrs</p>
                              </div>
                           </div>
                        </div>
                        <div  className="job-details-desc-item">
                           <div  className="row">
                              <div  className="col-lg-2 col-md-2 col-sm-2 col-2">
                                 <div  className="float-left mr-2">
                                    <i  className="fa fa-calendar-week text-muted jobdetails-icon"></i>
                                 </div>
                              </div>
                              <div  className="col-lg-10 col-md-10 col-sm-5 col-10">
                                 <h5>Date Posted:</h5>
                              </div>
                              <div  className="col-lg-12 col-md-12 col-sm-5 col-12 mr-l-sidewiget">
                                 <p  className="text-muted mb-2">{this.state.postedDate}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

{/* Job Detail Section end */}

            <ToastContainer />
        {/* <div  className="container mt-5">
           <div  className="col-md-12 col-sm-12 col-xs-12">
              <div  className="job-description">

                 <ul  className="section-3 mb35 ml-20-mob mr40-tab">
                    <li  className="job-describ-section job-describ-section1 mt20">
                       <div  className="row">
                          <div  className="col-md-12 col-sm-12 col-xs-12">

                          <div  className="col-md-12 col-sm-12 col-xs-12" >
                                 <div  className="btn-grp tab-job-button" style={{float:"right"}}>
                                    <button  className="btn btn-block sign-in-button" style={{width:"unset"}} onClick={this.favouriteJob}>{this.state.btn_favourite}</button> &nbsp;&nbsp;<button  className="btn btn-block sign-in-button" onClick={this.confirm}  disabled={this.state.btn_enable}>{this.state.btn_apply}</button> &nbsp;&nbsp;<button  className="btn btn-block sign-in-button" data-toggle="modal" data-target="#myModal">Share</button>
                                 </div>
                              </div>


                              <h4 className="col-md-12 p0">{this.state.jobtitle}</h4>

                              <div  className="row">

                          <div  className="col-md-4 col-sm-6 col-xs-12 ml5-mob job_detail" >
                          <p> <span id="emp-type">Company Name: </span>  {this.state.companyName}</p>
                          </div>
                          <div  className="col-md-4 col-sm-6 col-xs-12 ml5-mob job_detail" >
                          <p> <span id="emp-type">Location: </span>{this.state.job_city},{this.state.zip}.</p>
                          </div>
                          <div  className="col-md-4 col-sm-6 col-xs-12 ml5-mob job_detail" >
                          <p><span id="emp-type">Employment Type: </span> {this.state.position_type}</p>
                          </div>
                          <div  className="col-md-4 col-sm-6 col-xs-12 ml5-mob job_detail" >
                          <p><span id="emp-type">Posted Date: </span> {this.state.postedDate}</p>
                          </div>
                          <div  className="col-md-4 col-sm-6 col-xs-12 ml5-mob job_detail" >
                          {this.state.distance !== 0 ?
                            <p><span id="emp-type">Distance: </span>{Number(this.state.distance).toFixed(2) + "KM"}</p> : null}
                          </div>

                       </div>
                       {this.state.skills.length!==0?
                             <div className="job_detail">
                                <h4>Keyskills:</h4>
                                <p className="skill-jobDetails"> {this.state.skills.map((value, key) => (
                               <span key={key}>{value.skill}</span>
                              ))}</p>
                             </div>:null}
                     {this.state.jobdescription!==""?  <div>
                             <h4>Job Summary:</h4>
                              <p className="job-desc-line" style={{wordBreak: 'break-all' }}  dangerouslySetInnerHTML={{ __html: this.state.jobdescription }}></p>
                             <br/>
                             </div>:null}
                          </div>
                       </div>
                    </li>
                 </ul>
              </div>
           </div>

        </div> */}
        <div  className="modal fade" id="myModal" role="dialog" style={{display:"none"}}>
         <div  className="modal-dialog">
            <div  className="container-fluid">
               <div  className="col-md-8 col-md-offset-2">
                  <div  className="modal-content">
                  <a href="#/" data-dismiss="modal" id="modal-dismiss-share" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a>
                     <div  className="form form-mob newModal-pad">
                        <div  className="container-fluid">
                           <div  className="col-md-12">
                              <div  className="head">
                                 <h4>Share with your friends</h4>
                              </div>
                                 <div className="form-group mob-job-doApply">
                                 <FacebookShareButton  className="social-button"  url={this.state.shareUrl}   quote={this.state.jobtitle} >
                                    <SocialIcon url="http://facebook.com/in/jaketrent" />
                                 </FacebookShareButton>
                                 <EmailShareButton className="social-button"  url={this.state.shareUrl} subject={this.state.jobtitle}  title="Please use default mail reading app to share url.To change settings.(Go to Settings > Apps. Click Default apps in the pane on the left.  You'll see your default apps for common uses here, like Mail,Outlook,Thunderbird and more. Click an app to see others installed on your system that can take its place, then select the one you'd like to set as default).">
                                       <SocialIcon url="http://email.com/in/jaketrent" />
                                 </EmailShareButton>
                                 <LinkedinShareButton className="social-button" url={this.state.shareUrl} summary={this.state.jobtitle}>
                                    <SocialIcon url="http://linkedin.com/in/jaketrent" />
                                 </LinkedinShareButton>

                                  </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <a href="#/" data-toggle="modal" data-target="#loginModel" id="loginActive" hidden> </a>
                    <div  className="modal fade" id="loginModel" role="dialog" style={{display:"none"}}>
                        <div  className="modal-dialog">
                            <div  >
                            <Loader loaded={this.state.isLoad}/>
                            <div  className="col-md-10 col-md-offset-1" id="fade">
                                <div  className="modal-content jb-signup-pop">
                                {/* <a href="#/" data-dismiss="modal" id="modal-dismiss-login" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                                    <div  className="form form-mob">
                                        <div  >
                                        <div  className="col-md-12 card">
                                            {/* <div  className="head">
                                                <h4>LOGIN</h4>
                                            </div> */}
                                            <div className="modal-header">
                                                   <h5 className="modal-title" id="exampleModalLabel">Login</h5>
                                                   <button type="button" id="modal-dismiss-login" className="close" data-dismiss="modal" aria-label="Close">
                                                      <span aria-hidden="true">&times;</span>
                                                   </button>
                                             </div>
                                             <div className="modal-body">
                                                   {/* <div className="form-group text-center">
                                                   <h6>LogIn with Social Media or Manually</h6>
                                                <ul className="  plr0 justify col-md-12" style={{display: "flex",paddingLeft: "unset"}}>
                                                   <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                                      <FacebookLogin
                                                            appId={FB_APP_ID}
                                                            fields="name,email,picture"
                                                            callback={this.responseFacebook}
                                                            render={renderProps => (
                                                               <a href="#/" onClick={renderProps.onClick}><i className="fa fa-facebook-f" style={{fontSize: "19px"}}></i> </a>
                                                            )}
                                                      />
                                                   </li>
                                                   <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                                      <GoogleLogin
                                                         clientId={GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                                         render={renderProps => (
                                                            <a href="#/" onClick={renderProps.onClick} disabled={renderProps.disabled}><i className="fab fa-google-plus-g" style={{fontSize: "19px"}}></i> </a>
                                                         )}
                                                         buttonText="Login"
                                                         onSuccess={this.responseGoogle}
                                                         onFailure={this.responseGoogle}
                                                         cookiePolicy={'single_host_origin'}
                                                      />
                                                   </li>
                                                   <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                                         <LinkedIn
                                                            clientId={LINKEDIN_CLIENT_ID}
                                                            redirectUri={`${window.location.origin}/linkedin`}
                                                            scope = {["r_liteprofile","r_emailaddress","w_member_social"]}
                                                            state="ba015d"
                                                            onFailure={this.handleFailure}
                                                            onSuccess={this.handleSuccess}
                                                            supportIE
                                                            redirectPath='/linkedin'
                                                            renderElement={({ onClick, disabled }) => (
                                                                  <a href="#/" onClick={onClick} disabled={disabled}><i className="fa fa-linkedin " style={{fontSize: "19px"}}></i> </a>
                                                            )}
                                                      >
                                                      </LinkedIn>
                                                      </li>
                                                </ul>

                                                </div>
                                                <hr style={{borderTop: "1px solid #cecece",marginBottom: "1rem"}}/> */}
                                            <div className="form-group">
                                                <label>Email  <span  className="text-danger">*</span></label>
                                                <input type="email" name="email" maxLength={50} onChange={this.onChange.bind(this)} value={this.state.email} className="form-control fs10-mob" placeholder="Enter your email address" />
                                                {this.state.EmailError!==""? <span style={{color:"red"}}>{this.state.EmailError}</span>:""}
                                            </div>
                                            <div className="form-group">
                                                <label>Password  <span  className="text-danger">*</span></label>
                                                <input type="password" name="password" maxLength={15} autoComplete="nope" onChange={this.onChange.bind(this)} value={this.state.password} className="form-control fs10-mob" placeholder="Enter your password" />
                                                {this.state.PasswordError!==""? <span style={{color:"red"}}>{this.state.PasswordError}</span>:""}
                                            </div>
                                            <div className="md-checkbox m0" style={{display:"flex"}}>
                                                <input id="i2" type="checkbox" style={{marginRight: "8px"}} onChange={this.visibleOrgLogin.bind(this)} defaultChecked={this.visible} />
                                                <label htmlFor="i2" className="fs12-mob job-apply-checkbox" style={{position: "relative",top: "-4px"}}>Make my profile visible to a particular employers.</label>
                                            </div><br/>
                                            <p  className="float-right forgot-pass"><a href="#"  onClick={this.forgetTab.bind(this)}>Forgot password ?</a></p>
                                            <button className="btn btn-block sign-in-button" style={{backgroundColor: "#182b6a",color: "white"}} onClick={this.login.bind(this)} disabled={this.state.email==="" || this.state.password===""}>SIGN IN</button>
                                            <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                                            <br />


                                         <div className="modelAcc-mob" style={{textAlign: "-webkit-center"}}> Don't you have account?<a href="#/" onClick={this.signUp}> SignUp</a></div>
                                        </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <a href="#/" data-toggle="modal" data-target="#loginModel1" id="applyActive" hidden> </a>
                    {/* <div  className="modal fade" id="loginModel1" data-backdrop="static" role="dialog" style={{display:"none"}}>
                        <div  className="modal-dialog">
                            <div  className="container-fluid">
                            <div  className="col-md-8 col-md-offset-2">
                                <div  className="modal-content">
                                <a href="#/" data-dismiss="modal" id="modal-dismiss-apply" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a>
                                    <div  className="form form-mob newModal-pad">
                                        <div  className="container-fluid">
                                        <div  className="col-md-12">
                                            <div  className="head">
                                                <h4>Do you want to Apply?</h4>
                                            </div>
                                            {this.state.visible_check === true ?

                                             <div className="form-group">
                                                <div className="md-checkbox m0">
                                                   <input id="i3" type="checkbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                                   <label htmlFor="i3" className="fs12-mob job-apply-checkbox"> Do you want to visible your profile for all organization</label>
                                                </div>
                                             </div> : null}
                                                <div className="form-group mob-job-doApply">

                                                <button  className="btn btn-block sign-in-button" onClick={() => this.confirmation_yes(1)}>Yes</button>
                                                <button style={{marginLeft: '30px'}}  className="btn btn-block sign-in-button"onClick={() => this.confirmation_yes(2)} >No</button>
                                                </div>

                                            </div>

                                            <br />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div> */}

                        <div  className="modal fade" id="loginModel1" data-backdrop="static" role="dialog" style={{display:"none"}}>
                        <div  className="modal-dialog">
                            <div  className="container-fluid">
                            <div  className="col-md-10 col-md-offset-1">
                                <div  className="modal-content model-border-st">
                                {/* <a href="#/" data-dismiss="modal" id="modal-dismiss-apply" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                                    <div  className="form form-mob">
                                        <div  className="container-fluid jb-resume-container-fluid">
                                        <div  className="col-md-12 card">
                                            {/* <div  className="head">
                                                <h4>Do you want to Apply?</h4>
                                            </div> */}
                                            <div  className="modal-header">

                                                <h4  className="modal-title">Do you want to Apply?</h4>
                                                <button type="button" id="modal-dismiss-apply"  className="close" data-dismiss="modal">&times;</button>
                                            </div>
                                            <div  className="modal-body">
                                                {this.state.visible_check === true ?
                                                    <div className="form-group">
                                                    <div className="md-checkbox m0 d-flex">
                                                        <input id="i3" type="checkbox" className ="alignCheckbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                                        <label htmlFor="i3" className="fs12-mob job-apply-checkbox"> Make my profile visible to all employers.</label>
                                                    </div>
                                                    </div> : null}
                                                <div className="form-group d-flex">
                                                    <button  className="btn btn-block sign-in-button mr-1" onClick={() => this.confirmation_yes(1)}>Yes</button>
                                                    <button  className="btn btn-block sign-in-button mt-0" onClick={() => this.confirmation_yes(2)} >No</button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>

                     <a href="#/" data-toggle="modal" data-target="#deactivate1" id="deactivate" hidden> </a>
                    <div  className="modal fade" id="deactivate1" role="dialog" style={{display:"none"}}>
                        <div  className="modal-dialog">
                            <div  className="container-fluid">
                            <div  className="col-md-8 col-md-offset-2">
                                <div  className="modal-content model-border-st">
                                {/* <a href="#/" data-dismiss="modal" id="modal-dismiss-deactivate" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                                    <div  className="form form-mob newModal-pad">
                                        <div  className="container-fluid jb-resume-container-fluid">
                                        <div  className="col-md-12 card">
                                          <div className="modal-header">
                                                <h5 className="modal-title" id="exampleModalLabel">Your Account is deactivated</h5>
                                                <button type="button" id="modal-dismiss-deactivate" className="close" data-dismiss="modal" aria-label="Close">
                                                   <span aria-hidden="true">&times;</span>
                                                </button>
                                          </div>
                                          <div className="modal-body">
                                             <div className="form-group col-md-12 d-flex">
                                                <div className="mr-1">
                                                   <button className="btn btn-block sign-in-button" disabled={this.state.activate_btn} onClick={() => this.activateAccount(1)} >Activate</button><span>&nbsp;&nbsp;</span>&nbsp;&nbsp;
                                                </div>
                                                <div className="ml-1">
                                                   <button className="btn btn-block sign-in-button" onClick={() => this.activateAccount(2)} >Cancel</button>
                                                </div>
                                             </div>
                                          </div>


                                            </div>

                                            <br />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>



                        <a href="#/" data-toggle="modal" data-target="#basicModal" id="basicActive" hidden> </a>
                <div className="modal fade" id="basicModal" role="dialog">
          <div className="modal-dialog ">
            <div className="container-fluid">
            <Loader loaded={this.state.isLoad}/>
              <div className="col-md-10 col-md-offset-1" id="fade1">

                <div className="modal-content jb-signup-pop">
                  {/* <a href="#/" data-dismiss="modal" id="basicDisable" style={{ position: "relative", left: "42rem", top: "1rem", zIndex: "3" }} ><i className="fa fa-times" aria-hidden="true"></i> </a> */}
                  <div className="form form-mob card">
                  {/* <div className="head">
                        <h4>Sign Up</h4>
                      </div> */}
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Sign Up</h5>
                        <button type="button" id="basicDisable" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                      </div>
                    <div className="modal-body" style={{ overflowY: "scroll" ,height:"500px",paddingTop: "10px"}}>
                        <div className="container-fluid" >

                            {/* <form  > */}
                            {/* <div className="form-group text-center">
                                <h6>Sign Up with Social Media or Manually</h6>
                                <ul className="  plr0 justify col-md-12" style={{display: "flex",paddingLeft: "unset"}}>
                                    <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                        <FacebookLogin
                                            appId={FB_APP_ID}
                                            fields="name,email,picture"
                                            callback={this.responseFacebookSign}
                                            render={renderProps => (
                                                <a href="#/" onClick={renderProps.onClick}><i className="fa fa-facebook-f" style={{fontSize: "19px"}}></i> </a>
                                            )}
                                        />
                                    </li>
                                    <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                        <GoogleLogin
                                            clientId={GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                            render={renderProps => (
                                                <a href="#/" onClick={renderProps.onClick} disabled={renderProps.disabled}><i className="fab fa-google-plus-g" style={{fontSize: "19px"}}></i> </a>
                                            )}
                                            buttonText="Login"
                                            onSuccess={this.responseGoogleSign}
                                            onFailure={this.responseGoogleSign}
                                            cookiePolicy={'single_host_origin'}
                                        />
                                    </li>
                                    <li className="col-md-4" style={{paddingLeft: "unset"}}>
                                    <LinkedIn
                                            clientId={LINKEDIN_CLIENT_ID}
                                            redirectUri={`${window.location.origin}/linkedin`}
                                            scope = {["r_liteprofile","r_emailaddress","w_member_social"]}
                                            state="ba015d"
                                            onFailure={this.handleFailureSignup}
                                            onSuccess={this.handleSuccessSignup}
                                            supportIE
                                            redirectPath='/linkedin'
                                            renderElement={({ onClick, disabled }) => (
                                                <a href="#/" onClick={onClick} disabled={disabled}><i className="fa fa-linkedin " style={{fontSize: "19px"}}></i> </a>
                                            )}
                                            >
                                            </LinkedIn>
                                        </li>
                                </ul>

                            </div>
<hr style={{borderTop: "1px solid #cecece",marginBottom: "1rem"}}/> */}
                            <div className="form-group">
                                <label>First Name<span style={{ color: "red" }}>*</span>:</label>
                                <input type="text" className="form-control fs10-mob" maxLength={20} name="firstname" onChange={this.onChange.bind(this)} value={this.state.firstname} title="Enter Your First Name" placeholder="Enter Your First Name" required />
                            </div>
                            <div className="form-group">
                                <label>Last Name<span style={{ color: "red" }}>*</span>:</label>
                                <input type="text" className="form-control fs10-mob" maxLength={20} name="lastname" onChange={this.onChange.bind(this)} value={this.state.lastname} title="Enter Your Last Name" placeholder="Enter Your Last Name" required />
                            </div>

                            <div className="form-group">
                                <label>Email<span style={{ color: "red" }}>*</span>:</label>
                                <input type="email" className="form-control fs10-mob" name="email_reg" maxLength={50} onChange={this.onChange.bind(this)} value={this.state.email_reg} title="Enter your email address" placeholder="Enter your email address" required />
                                {this.state.emailValidMsg !== "" ? <span style={{ color: "red" }}>{this.state.emailValidMsg}</span> : ""}
                            </div>

                            {/* <div className="form-group">
                                <label>Mobile Number<span style={{ color: "red" }}>*</span>:</label>
                                <input type="text" className="form-control fs10-mob" name="phone_number" minLength={9}  maxLength={15} onChange={this.onChange.bind(this)} value={this.state.phone_number} title="Enter your mobile number" placeholder="Enter your mobile number"  />
                                {this.state.phone_number_issue!=""?<p style={{color:"red"}}>{this.state.phone_number_issue}</p>:""}
                            </div>

                            <div className="form-group">
                                <label>Address<span style={{ color: "red" }}>*</span>:</label>
                                <textarea className="form-control fs10-mob" maxLength={255} name="address" onChange={this.onChange.bind(this)} value={this.state.address} title="Enter your Address" placeholder="Enter your Address" ></textarea>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Country<span style={{ color: "red" }}>*</span>:</label>
                                        <select className="form-control fs10-mob" name="country_reg" value={this.state.countryId_reg} onChange={this.onChange.bind(this)} title="Choose your country" placeholder="Choose your country">
                                            <option value="" disabled>Choose your country</option>
                                            {this.state.countries_reg.map((value, key) => {
                                                return <option key={key} value={key}>{value.country}</option>;
                                            })}
                                        </select>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>State<span style={{ color: "red" }}>*</span>:</label>
                                        <select className="form-control fs10-mob" name="state_reg" id="state_reg" value={this.state.state_reg} onChange={this.onChange.bind(this)} title="Choose your State" placeholder="Choose your State" disabled={this.state.country_reg === ""} >
                                            <option value="" disabled>Choose your state</option>
                                            {this.state.states_reg.map((value, key) => {
                                                if (value.country === this.state.selectedCountry)
                                                    return <option key={key} value={value.id}>{value.state}</option>;
                                            })}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div className="form-group">
                                <div className="row">
                                <div className="col-sm-6">
                                        <label>City<span style={{ color: "red" }}>*</span>:</label>
                                        <input type="text" className="form-control fs10-mob" onChange={this.onChange.bind(this)} value={this.state.city} maxLength={25} name="city" title="Enter your City" placeholder="Enter your City"  />
                                        {this.state.city_error !== "" ? <div className="col-md-12 text-right"><span style={{ color: "red" }}>{this.state.city_error}</span></div> : ""}
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Zipcode:</label>
                                        <input type="text" className="form-control fs10-mob" name="zipcode" maxLength={7} onChange={this.onChange.bind(this)} value={this.state.zipcode} title="Enter your Zipcode" placeholder="Enter your Zipcode" required />
                                    </div>
                                    {this.state.zipcode_error !== "" ? <div className="col-md-12 text-right"><span style={{ color: "red" }}>{this.state.zipcode_error}</span></div> : ""}
                                </div>

                            </div> */}
                            {/* <div className="form-group"> */}

                          {/* </div> */}
                            <div className="form-group">

                                <label>Password<span style={{ color: "red" }}>*</span>:</label>
                                <input type="password" maxLength={15} autoComplete="nope" className="form-control fs10-mob" name="password_reg" onChange={this.onChange.bind(this)} value={this.state.password_reg} title="Enter your Password" placeholder="Enter your Password" required />
                                {this.state.passwordError !== "" ? <span style={{ color: "red" }}>{this.state.passwordError}</span> : ""}
                            </div>
                            <div className="form-group">
                                <label>Confirm Password<span style={{ color: "red" }}>*</span>:</label>

                                <input type="password" maxLength={15} autoComplete="nope" className="form-control fs10-mob" name="confirmpassword" onChange={this.onChange.bind(this)} value={this.state.confirmpassword} title="Enter your Confirm Password" placeholder="Enter your Confirm Password" disabled={this.state.password_reg.length < 5} />
                                {this.state.passwordStatus === true ? <span style={{ color: "red" }}>confirm Invalid Password. Please tray with valid password !</span> : ""}
                            </div>
                            <label>Upload Resume<span style={{ color: "red" }}>*</span>:</label>
                            <div className="form-group resume-align">
                            {/* <input id="resumeUpload" placeholder="Upload Resume" disabled="disabled" /> */}
                            <label htmlFor="resumeUpload" className="custom-file-upload btn btn-block">
                             <i className="fa fa-cloud-upload"></i> Upload Resume
                          </label>
                          <input name="resume"   id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUpload.bind(this)}  type="file" style={{display:"none"}}/>
                 <label id="file-name"></label>
                            {this.state.invalid_file!==""?<div><br/><span style={{color:"red"}}>{this.state.invalid_file}</span></div>:null}
              {/* <button data-toggle="modal" title="upload you resume here" data-target="#resumeModal" onClick={this.uploadResume} style={uploadBtnStyle}>upload</button> */}
              </div>
                            <div className="md-checkbox m0" style={{display:"flex"}}>
                                <input id="i2" type="checkbox" style={{marginRight: "8px"}} onChange={this.visibleOrg.bind(this)} defaultChecked={this.visible_reg} />
                                <label htmlFor="i2" className="fs12-mob job-apply-checkbox" style={{position: "relative",top: "-4px"}}> Make my profile visible to all employers.</label>
                            </div><br/>
                            <div className="md-checkbox m0" style={{display:"flex"}}>
                                <input id="i1" type="checkbox" style={{marginRight: "8px"}} onChange={this.changeterms.bind(this)} defaultChecked={this.terms} />
                                <label htmlFor="i1" className="fs12-mob" style={{position: "relative",top: "-4px"}}>I Agree to the <a href="/terms" target="_blank" className="font-bold">Terms of Use</a><span style={{ color: "red" }}>*</span></label>
                            </div><br/>
        {this.state.error_signup!==""?
                        <div>    <span style={{color:"red"}}>{this.state.error_signup}</span><br></br></div>:null}
                            <button type="submit" onClick={this.registerJobseekers} className="btn btn-block sign-in-button" style={{backgroundColor: "#182b6a",color: "white"}} disabled={this.isDisabled()} title="All fields are mandatory">SIGN UP</button>
                            <small>All <span  className="text-danger">*</span> fields are mandatory</small>
                            <br/><br/> {/* <Link to="/login" style={{marginLeft:"10px"}}>Do you want to Login?</Link> */}
                            <div className="modelAcc-mob">Do you have an account? <a href="#/" onClick={this.loginPopup}>SignIn</a></div>
                            <br />
                            {/* </form> */}
                        </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a href="#/" data-toggle="modal" data-target="#resumeModal" id="resumeModal1" hidden> </a>
        {/* <div className="modal fade" id="resumeModal" role="dialog" data-backdrop="static" style={{ display: "none" }}>
                                    <div className="modal-dialog">
                                        <div className="container-fluid">
                                        <div className="col-md-10 col-md-offset-2">
                                            <div className="modal-content">

                                            <a href="#/" data-dismiss="modal" id="closeModel" style={basicCloseStyle} ><i className="fa fa-times" aria-hidden="true"></i> </a>
                                            <input type="file" name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUpload.bind(this)} placeholder="Enter your email address" style={{ visibility: "hidden" }} />
                                            <div className="form form-mob newModal-pad">
                                                <div className="container-fluid">
                                                <div className="col-md-12">
                                                    <div className="head">
                                                    <div style={{color:"red"}}>{this.state.upload_heading}</div><br/>
                                                    <h4>Upload Resume</h4>
                                                    </div>
                                                    <div className="form-group">
                                                    <button className="btn btn-block sign-in-button fs12-mob mob-resume-upload" style={{ width: "31rem" }} onClick={activefile} >{this.state.uploadActive===true?this.state.resumeName: "RESUME"}</button>

                                                    <br /><br /><span className="mob-mb15"> Max 2 MB, doc, docx, pdf<br />
                                                        We will tried to fill your details</span><br /> <br />
                                                        {this.state.org_status===1 ?
                                                        <div className="form-group">
                                                        <div className="md-checkbox m0">
                                                            <input id="i8" type="checkbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                                            <label htmlFor="i8" className="fs12-mob job-apply-checkbox"> Do you want to visible your profile for all organization</label>
                                                        </div>
                                                    </div>:null}
                                                        <div className="col-md-8"></div>
                                                        <button className="btn btn-primary"  onClick={() => this.myResumeUpload(2)}  disabled={this.state.uploadActive===false}>Upload</button>
                                                        <br/> <span style={{color:"red"}}>{this.state.invalid_file}</span>


                                                    </div>
                                                </div>

                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div> */}

                                    <div className="modal fade" id="resumeModal" role="dialog" data-backdrop="static" style={{ display: "none" }}>
                                    <div className="modal-dialog">
                                        <div className="container-fluid">
                                        <div className=" col-md-offset-1">
                                            <div className="modal-content model-border-st">

                                            {/* <a href="#/" data-dismiss="modal" id="closeModel" style={basicCloseStyle} ><i className="fa fa-times" aria-hidden="true"></i> </a> */}

                                            <div className="form form-mob">
                                                <div className="container-fluid jb-resume-container-fluid">
                                                <div className="col-md-12 card">

                                                    {/* <div className="head">
                                                          <div style={{color:"red"}}>{this.state.upload_heading}</div><br/>
                                                    <h4>Upload Resume</h4>
                                                    </div> */}
                                                    <div  className="modal-header">
                                                        <h4  className="modal-title text-nowrap">Upload Resume</h4>

                                                        <input type="file" name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUpload.bind(this)} placeholder="Enter your email address" style={{ visibility: "hidden" }} />
                                                        <button type="button" id="closeModel"  className="close" data-dismiss="modal">&times;</button>
                                                    </div>
                                                    <div  className="modal-body">
                                                    <div className="form-group">
                                                    <h6>{this.state.upload_heading}</h6>
                                                    <button className="btn btn-block sign-in-button fs12-mob mob-resume-upload w-100"  onClick={activefile} >{this.state.uploadActive===true?this.state.resumeName: "RESUME"}</button>

                                                    <br /><span className="mob-mb15"> Max 2 MB, doc, docx, pdf<br />
                                                        We will tried to fill your details</span><br /><br/>
                                                        {this.state.org_status===1 ?
                                                        <div className="form-group">
                                                        <div className="md-checkbox m0 d-flex">
                                                            <input id="i8" type="checkbox" className ="alignCheckbox" onChange={this.ChangeVisbleStatus.bind(this)} defaultChecked={this.visible} />
                                                            <label htmlFor="i8" className="fs12-mob job-apply-checkbox"> Make my profile visible to all employers.</label>
                                                        </div>
                                                    </div>:null}
                                                        <button className="btn btn-primary float-right"  onClick={() => this.myResumeUpload(2)}  disabled={this.state.uploadActive===false}>Upload</button>
                                                        <br/> <span style={{color:"red"}}>{this.state.invalid_file}</span>
                                                    {/* <span style={{color:this.state.color}}>{this.state.EmailError}</span> */}

                                                    </div>
                                                    </div>
                                                </div>

                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>


     </>

    );
  }
 }
 export default Jobdetails;

