import React, { Component } from "react";
import axios from 'axios';
import {DOMAIN,GET_SEEKERS_DATA,OLD_PASS_CHECK,CHANGE_PASSWORD,CHANGE_VISIBLE_STATUS,NOTIFICATION_STATUS_CHANGE,DEACTIVATE_ACCOUNT} from '../../AppConfig';
import { ToastContainer, toast } from 'react-toastify';
import {Link,withRouter} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Loader from 'react-loader'

class Settings extends Component {

    constructor(props) {
        super(props);
        this.state={
            oldPassword:"",
            newPassword:"",
            confirmPassword:"",
            oldpassErrMsg:"",
            passwordError:"",
            oldpassSucess:"",
            confirmPassError:"",
            // resetSucess:"",
            general:true,
            alert:false,
            EmailNotify:false,
            WebNotify:false,
            updateNotify:false,
            Alerts:[],
            deactivate:false,
            label:"",
            popup_label:"",
            visible_status:false,
            visible_status_value:"",
            deleteAlertId:"",
            isLoad:true

        }
        this.inputData = this.inputData.bind(this);
        this.ResetPassword = this.ResetPassword.bind(this);
        this.deactivateAccount=this.deactivateAccount.bind(this);
        this.confirmation_yes=this.confirmation_yes.bind(this);
    }
    confirmation_yes(flag){
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization":localStorage.getItem('token')
         }
        if(flag===1 && this.state.deactivate===true){
            this.setState({isLoad:false})
         axios.post(DOMAIN + DEACTIVATE_ACCOUNT,
            {
                deactivate:this.state.deactivate,
                userId:localStorage.getItem('id'),
                name:localStorage.getItem('firstname'),
            }, {
                headers: headers
             }
         )
            .then(response => {
                this.setState({isLoad:true})
                if(response.status===200)
                {
                    document.getElementById('modal-dismiss-login').click();
                    localStorage.removeItem("id");
                    localStorage.removeItem("firstname");
                    localStorage.removeItem("email");
                    localStorage.removeItem("lastname");
                    localStorage.removeItem("token");
                    this.props.history.push('/');
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                  }

            })
            .catch(function (error) {
               console.log(error);
            })
        }
        else if(flag===1 && this.state.deactivate===false)
        {
                this.setState({isLoad:false})
             axios.post(DOMAIN + CHANGE_VISIBLE_STATUS,
                {
                    org_visible_status:this.state.visible_status_value,
                    userId:localStorage.getItem('id'),
                }, {
                    headers: headers
                 }
             )
                .then(response => {
                    this.setState({isLoad:true})
                    if(response.status===200)
                    {
                        if(this.state.visible_status)
                        {
                            this.setState({
                                        // label:"Do you want set visible your profile to all organization",
                                        visible_status:true,
                                        // visible_status_value:0
                        })
                        }
                        else{
                            this.setState({
                                //  label:"Do you want set visible your profile to particular organization",
                            visible_status:false,
                            // visible_status_value:1
                        })
                        }
                        document.getElementById('modal-dismiss-login').click();
                        this.props.history.push('/settings');
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                    else{
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }

                })
                .catch(function (error) {
                   console.log(error);
                })
        }
        else{
            this.setState({
                deactivate:false,
            });
            if(this.state.visible_status && this.state.deactivate===false){
                this.setState({
                    visible_status:false,
                });
            }else if(this.state.visible_status===false && this.state.deactivate===false){
                this.setState({
                    visible_status:true,
                });
            }
            document.getElementById('modal-dismiss-login').click();
        }
    }
    componentWillMount(){
        this.setState({isLoad:false})
        if(!localStorage.getItem('id')){
            this.props.history.push('/login');
        }
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization":localStorage.getItem('token')
         }
         axios.post(DOMAIN + GET_SEEKERS_DATA,
            {
                userId:localStorage.getItem('id')
            }, {
                headers: headers
             }
         )
        .then(response => {
            this.setState({isLoad:true})
            if (response.data.status === 200) {
                if(response.data.data.org_visible_status===1){
                    this.setState({
                        visible_status:false,
                        // label:"Do you want set visible your profile to all organization",
                        // visible_status_value:0
                    });
                }
                else if(response.data.data.org_visible_status===null){
                    this.setState({
                        visible_status:false,
                        // label:"Do you want set visible your profile to all organization",
                        // visible_status_value:0
                    });
                }else{
                    this.setState({
                        visible_status:true,
                        // label:"Do you want set visible your profile to particular organization",
                        // visible_status_value:1
                    });
                }
                this.setState({
                    EmailNotify:response.data.data.email_notify,
                    WebNotify:response.data.data.web_notify
                })
            }else if(response.data.status === 401){
                localStorage.clear();
                this.props.history.push('/login');
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        axios.post(DOMAIN + '/getAlert',
            {
                userId:localStorage.getItem('id')
            }, {
                headers: headers
             }
         )
        .then(response => {
            if(response.data.status === 200) {
                this.setState({
                    Alerts:response.data.data
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    deactivateAccount(){
        document.getElementById('loginActive').click();
    }
    inputData(e){
        e.preventDefault();
        if(e.target.name==="oldPassword" && e.target.value.length>4){
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization":localStorage.getItem('token')
             }
             this.setState({isLoad:false})
             axios.post(DOMAIN + OLD_PASS_CHECK,
                {
                    oldPassword:e.target.value,
                    userId:localStorage.getItem('id')
                }, {
                    headers: headers
                 }
             )
                .then(response => {
                    this.setState({isLoad:true})
                   if (response.data.status === 200) {
                    this.setState({
                        oldpassErrMsg:"",
                        oldpassSucess:"Success"
                    })
                   }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                  }else{
                    this.setState({
                        oldpassErrMsg:"Old Invalid Password. Please tray with valid password !",
                        oldpassSucess:""
                    })

                   }
                })
                .catch(function (error) {
                   console.log(error);
                })
        }
        if(e.target.name==='newPassword'){
            if(e.target.value.length<5){
               this.setState({
                  passwordError:"Invalid Password . Password length must be minimum 5 letters !"
               })
            }else{
               this.setState({
                  passwordError:""
               })
            }
        }
        this.setState({
            [e.target.name]:e.target.value
        })
    }
    ResetPassword(){
        if(this.state.newPassword!==this.state.confirmPassword){
            this.setState({
              confirmPassError:"Confirmation password is not matched",
            })
        }else{
        this.setState({
            confirmPassError:"",
            })
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization":localStorage.getItem('token')
             }
             this.setState({isLoad:false})
            axios.post(DOMAIN + CHANGE_PASSWORD,
                {
                    newPassword:this.state.newPassword,
                    userId:localStorage.getItem('id')
                }, {
                    headers: headers
                 }
             )
                .then(response => {
                    this.setState({isLoad:true})
                   if (response.data.status === 200) {
                        this.setState({
                            newPassword:"",
                            confirmPassword:"",
                            oldPassword:"",
                            oldpassSucess:"",
                            // resetSucess:response.data.message
                        })
                        toast.success(response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                        // setTimeout(function(){
                        //     this.setState({
                        //         resetSucess:""
                        //     })
                        // }.bind(this), 3000);
                   }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                  }
                })
                .catch(function (error) {
                   console.log(error);
                })
            }

    }
    notificationModify(e){
        if(e.target.name==='deactivate'){
            if(this.state.deactivate){
                this.setState({
                    deactivate:false,
                    popup_label:"Do you want to deactivate?"
                })
            }else{
                this.setState({
                    deactivate:true,
                    popup_label:"Do you want to deactivate?"
                })
            }
            this.deactivateAccount();
        }
        if(e.target.name==='visible_status')
        {
            if(this.state.visible_status){
                this.setState({
                    visible_status:false,
                    visible_status_value:1,
                    popup_label:"Do you want to set visible your profile for particular organization?"
                })
            }else{
                this.setState({
                    visible_status_value:0,
                    visible_status:true,
                    popup_label:"Do you want to set visible your profile for all organization?"
                    // popup_label:this.state.label+"?"
                })
            }
            this.deactivateAccount();
        }
        if(e.target.name==='EmailNotify'){
            this.setState({
                updateNotify:true
            })
            if(this.state.EmailNotify){
                this.setState({
                    EmailNotify:false
                })
            }else{
                this.setState({
                    EmailNotify:true
                })
            }
        }
        if(e.target.name==='WebNotify'){
            this.setState({
                updateNotify:true
            })
            if(this.state.WebNotify){
                this.setState({
                    WebNotify:false
                })
            }else{
                this.setState({
                    WebNotify:true
                })
            }
        }

    }
    notificationStatusChange(){
        this.setState({
            updateNotify:false
        })
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization":localStorage.getItem('token')
         }
         this.setState({isLoad:false})
        axios.post(DOMAIN + NOTIFICATION_STATUS_CHANGE,
            {
                user_id:localStorage.getItem('id'),
                email_notify:this.state.EmailNotify,
                web_notify:this.state.WebNotify
            }, {
                headers: headers
            }
        )
        .then(response => {
            this.setState({isLoad:true})
            if(response.data.status === 200) {
                toast.success(response.data.msg, {
                    position: toast.POSITION.TOP_CENTER
                });
                // this.setState({
                //     resetSucess:response.data.msg
                // })
                // setTimeout(function(){
                //     this.setState({
                //         resetSucess:""
                //     })
                // }.bind(this), 3000);
            }else if(response.data.status === 401){
                localStorage.clear();
                this.props.history.push('/login');
              }
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    tabChange(e){
        if(e.target.name==="general"){
            this.setState({
                general:true,
                alert:false
            })
        }else{
            this.setState({
                general:false,
                alert:true
            })
        }
    }
    deleteAlert(id){
        this.setState({
            deleteAlertId:id
        })
        document.getElementById('confirmActive').click();
    }
    statusChange(id,status){
        if(status===1)
            status = 0;
        else
            status = 1;

        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization":localStorage.getItem('token')
        }
        this.setState({isLoad:false})
        axios.post(DOMAIN + '/alertStatusChange',
            {
                id:id,
                user_id:localStorage.getItem('id'),
                status:status
            }, {
                headers: headers
            }
            )
        .then(response => {
            this.setState({isLoad:true})
            if(response.data.status === 200) {
                this.setState({
                    Alerts:response.data.data
                })
            }else if(response.data.status === 401){
                localStorage.clear();
                this.props.history.push('/login');
              }
        })
        .catch(function (error) {
            console.log(error);
        })

    }
    confirmation_alert_yes(status){
        if(status==1){
            const headers = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization":localStorage.getItem('token')
            }
            this.setState({isLoad:false})
            axios.post(DOMAIN + '/deleteAlert',
                {
                    id:this.state.deleteAlertId,
                    user_id:localStorage.getItem('id'),
                }, {
                    headers: headers
                }
                )
            .then(response => {
                this.setState({isLoad:true})
                document.getElementById('modal-dismiss-apply').click();
                if(response.data.status === 200) {
                    this.setState({
                        Alerts:response.data.data
                    })
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                  }
            })
            .catch(function (error) {
                console.log(error);
            })
        }else{
            document.getElementById('modal-dismiss-apply').click();
        }
    }
    render() {
        // var col =this.state.error.oldpassbordercolor;
        return (
            <div >
                <ToastContainer />
                <>
    <section className="bg-half page-next-level my-profile">
         <div className="bg-overlay"></div>
         <div className="container">
            <div className="row justify-content-center">
               <div className="col-md-6">
                  <div className="text-center text-white">
                     <div className="section-title text-center mb-4 pb-2">
                        <h4 className="text-uppercase title mb-4">Settings</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section  className="section">

    <Loader loaded={this.state.isLoad}/>
         <div  className="container">
            <div  className="row align-items-center">
               <div  className="col-lg-12">
                  <div  className="show-results">
                     <div  className="float-left mob-center pb-2">
                        <a  name="general"  className ={'btn mr-2 btn-sm btn-my-profile mt-1' + (this.state.general?" active":" ")} onClick={this.tabChange.bind(this)}  >General</a>
                        <a name="alert"  className={'btn btn-sm btn-my-profile mt-1' + (this.state.alert?" active":" ")} onClick={this.tabChange.bind(this)} >Alerts</a>
                     </div>
                     <div className="sort-button float-right">
                     {this.state.alert===true?
                     <>
                        <Link to="/joblisting"  className="btn btn-sm btn-alert mob-hide"><i className="fas fa-plus"></i>Create New Alert</Link>
                        <Link to="/joblisting"  className="mob-view"><img className="alert-ico-al" title="Create Alert" src="https://cdn3.iconfinder.com/data/icons/time-and-date-5/48/add_create_new_alarm_alert-512.png"  width="40" /></Link>
</>
                    :null}

                        </div>
                  </div>
               </div>
            </div>
            {this.state.general===true?
            <div  className="row">
               <div  className="col-lg-12  col-md-12">
                  <div  className="job-detail border rounded mt-2 p-4">
                     <form  className="login-form">
                        <div  className="row">
                           <div  className="col-lg-3 col-md-3">
                              <div  className="col-md-12">
                                 <h5  className="text-dark mb-0 pt-2 f-18 title-heading-sub">Job Alert</h5>
                              </div>
                           </div>
                           <div  className="col-lg-3  col-md-3">
                              <div  className="col-md-12 mt-2">
                                 <div  className="form-group position-relative">
                                    <input type="checkbox" id="i12" className="mr-1" name="EmailNotify" checked={this.state.EmailNotify} onChange={this.notificationModify.bind(this)}/>
                                    <label htmlFor="Email"  className="lable-font text-muted"> By Email</label>
                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-3  col-md-3">
                              <div  className="col-md-12 mt-2">
                                 <div  className="form-group position-relative">
                                    <input type="checkbox" id="i21" className="mr-1" name="WebNotify" checked={this.state.WebNotify} onChange={this.notificationModify.bind(this)}/>
                                    <label htmlFor="Email"  className="lable-font text-muted"> Web Notification</label>
                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-3  col-md-3">
                              <div  className="col-md-12">
                                 <button  className="btn btn-primary w-100 btn-update" onClick={this.notificationStatusChange.bind(this)} disabled={this.state.updateNotify===false}>Update</button>
                              </div>
                           </div>
                        </div>
                     </form>
                        <div  className="row">
                           <div  className="col-lg-12  col-md-12">
                           <h4  className="mb-2 title-heading-sub pl-3 blue mt-1">Change Password</h4>
                           </div>
                           <div  className="col-lg-4  col-md-4">
                              <div  className="col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Old Password</label>
                                       <input type="password" autoComplete="nope" className="form-control" onChange={this.inputData} value={this.state.oldPassword} placeholder="Enter your password" name="oldPassword"/>
                                       {this.state.oldpassErrMsg!==""? <span style={{color:"red"}}>{this.state.oldpassErrMsg}</span>:""}
                                            {this.state.oldpassSucess!==""? <span style={{color:"green"}}>{this.state.oldpassSucess}</span>:""}

                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-4 col-md-4">
                              <div  className="col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>New Password </label>
                                       <input type="password"  autoComplete="nope" maxLength={15} className="form-control" name="newPassword" onChange={this.inputData} value={this.state.newPassword} placeholder="Enter your password" disabled={this.state.oldPassword==="" || this.state.oldpassErrMsg!==""}/>
                                       {this.state.passwordError!==""? <span style={{color:"red"}}>{this.state.passwordError}</span>:""}

                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-4 col-md-4">
                              <div  className="col-md-12">
                                 <div  className="form-group position-relative">
                                    <label>Confirm New Password </label>
                                       <input type="password" autoComplete="nope" maxLength={15} className="form-control" name="confirmPassword" onChange={this.inputData} value={this.state.confirmPassword} placeholder="Enter your password" disabled={this.state.oldPassword==="" || this.state.oldpassErrMsg!==""}/>
                                       {this.state.confirmPassError!==""? <span style={{color:"red"}}>{this.state.confirmPassError}</span>:""}

                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-4">
                              <div  className="col-md-12 mt-3">
                                 <div  className="form-group position-relative">
                                    <h5  className="text-dark">De-Activate Your Account</h5>
                                    <input type="checkbox" id="1234"className="mr-1"  name="deactivate" checked={this.state.deactivate} onChange={this.notificationModify.bind(this)} />
                                    <label htmlFor="de-active"  className="lable-font text-muted"> De-Activate Your Account</label>
                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-6">
                              <div  className="col-md-12 mt-3">
                                 <div  className="form-group position-relative">
                                    <h5  className="text-dark">Profile visible status</h5>
                                    <input type="checkbox" className="mr-1" id="visible-status" name="visible_status" checked={this.state.visible_status} onChange={this.notificationModify.bind(this)}/>
                                    <label htmlFor="Email"  className="lable-font text-muted">Make my profile visible to all employers.</label>
                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-2 col-md-4">
                              <div  className="col-md-12 mt-4">
                                 <button  className="btn btn-primary w-100" onClick={this.ResetPassword} disabled={this.state.oldpassSucess==="" || this.state.newPassword==="" || this.state.confirmPassword==="" || this.state.passwordError!==""}>Save</button>
                              </div>
                           </div>
                        </div>
                  </div>
               </div>
            </div>
            :
            <div  className="row">
                  {this.state.Alerts.length!=0?
                  <div className="col-lg-12">
                    <div   className="col-lg-12">
                    {this.state.Alerts.map((value, key) => (
                  <div key={key} className="job-detail border rounded mt-2 p-2 bg-light">
                     <form  className="login-form">
                        <div  className="row">
                        <div className="col-md-3">
                              <div className="col-md-12 mt-2 text-center">
                                 <h5 className="text-dark mb-0">{value.alert_name}</h5>
                              </div>
                           </div>
                           <div className="col-md-6">
                              <div className="col-md-12 mt-2">
                                 <div className="form-group position-relative float-left all-center">
                                    <h6 dangerouslySetInnerHTML={{ __html: value.title }}  className="text-dark mb-0  f-18"></h6>
                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-3 col-md-3 col-12">
                              <div  className="col-md-12 mt-3">
                                 <div  className=" mob-left float-right">
                                    <label  className="switch mr-2 al-switch">
                                       <input type="checkbox" checked={value.status===1} onChange={()=>this.statusChange(value.id,value.status)}/>
                                       <span  className="slider-toggle round"></span>
                                     </label>
                                 <a onClick={() => this.deleteAlert(value.id)} className="btn btn-sm btn-trash"><i  className="fas fa-trash-alt"></i></a>
                              </div>
                              </div>
                           </div>

                        </div>
                     </form>

                  </div>
               ))}
                                </div></div>:
               <div className="text-center col-md-12 footer-bottom-set"><p className="jobs myjobs">No Records Found</p></div>}
            </div>}
         </div>
      </section>
      </>

                        <a data-toggle="modal" data-target="#loginModel1" id="loginActive" hidden></a>
                    <div  className="modal fade" id="loginModel1" data-backdrop="static" role="dialog" style={{display:"none"}}>
                        <div  className="modal-dialog">
                            <div  className="container-fluid">
                            <div  className=" col-md-offset-1">
                                <div  className="modal-content model-border-st" id="settingPopup">
                                {/* <a data-dismiss="modal" id="modal-dismiss-login" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true" onClick={() => this.confirmation_yes(2)}></i></a> */}
                                    <div  className="form form-mob">
                                        <div  className="container-fluid jb-resume-container-fluid">
                                        <div  className="col-md-12 card innerPopup">
                                            {/* <div  className="head">
                                                <h4>{this.state.popup_label}</h4>
                                            </div> */}
                                            <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">{this.state.popup_label}</h5>
                                <a data-dismiss="modal" id="modal-dismiss-login" style={{ visibility: "hidden" }}><i className="fa fa-times" aria-hidden="true"></i></a>

                                                        <button type="button"  onClick={() => this.confirmation_yes(2)} className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                    <div className="form-group mob-job-doApply d-flex">

                                                    <button  className="btn btn-block sign-in-button mr-1" onClick={() => this.confirmation_yes(1)}>Yes</button>
                                                    <button  className="btn btn-block sign-in-button mt-0" onClick={() => this.confirmation_yes(2)} >No</button>
                                                    </div>
                                                </div>

                                            </div>

                                            <br />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <a data-toggle="modal" data-target="#confirmModel" id="confirmActive" hidden></a>
                    <div className="modal fade" id="confirmModel" data-backdrop="static" role="dialog" style={{ display: "none" }}>
                    <div className="modal-dialog">
                        <div className="container-fluid">
                            <div className="col-md-offset-1">
                                <div className="modal-content model-border-st" id="alertDelete">
                                    {/* <a data-dismiss="modal" id="modal-dismiss-apply" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i></a> */}
                                    <div className="form form-mob">
                                        <div className="container-fluid jb-resume-container-fluid">
                                            <div className="col-md-12 card innerPopup">
                                                {/* <div className="head">
                                                    <h4>Are you sure! you want to delete?</h4>
                                                </div> */}
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Are you sure! you want to delete?</h5>
                                                        <button type="button" id="modal-dismiss-apply" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body ">
                                                    <div className="form-group mob-job-doApply d-flex">

                                                        <button className="btn btn-block sign-in-button mr-1" onClick={() => this.confirmation_alert_yes(1)}>Yes</button>
                                                        <button className="btn btn-block sign-in-button mt-0" onClick={() => this.confirmation_alert_yes(2)} >No</button>
                                                    </div>
                                                </div>

                                            </div>

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
export default Settings;