import React, { Component } from "react";
import axios from 'axios';
import { DOMAIN, GOOGLE_MAP_API_KEY, REGISTER_JOBSEEKERS, GET_BASIC_DATA, FB_APP_ID, LINKED_IN, GOOGLE_CLIENT_ID, LINKEDIN_CLIENT_ID, UPLOAD_MY_RESUME } from '../../AppConfig';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
// import LinkedIn from "linkedin-login-for-react";
import { LinkedIn } from '../../Linkedin';
import Loader from 'react-loader';
// import styles from './styles.css'
class SignUp extends Component {

   constructor(props) {
      super(props);
      this.state = {
         code: '',
         errorMessage: '',
         countries: [],
         states: [],
         country: "",
         selectedCountry: "",
         countryId: "",
         state: "",
         firstname: "",
         lastname: "",
         email: "",
         phone_number: "",
         address: "",
         city: "",
         zipcode: "",
         password: "",
         confirmpassword: "",
         terms: false,
         passwordStatus: false,
         emailValidMsg: "",
         isLoad: true,
         uploadActive: false,
         resume_upload: true,
         activefile: true,
         invalid_file: "",
         file_label: false,
         phone_number_issue: "",
         zipcode_error: "",
         city_error: '',
         Selectedstate: ''
      }
      this.registerJobseekers = this.registerJobseekers.bind(this);
      // this.linkedIn=this.linkedIn.bind(this);
      // this.check_URL=this.check_URL.bind(this);
      this.linkedingetUser = this.linkedingetUser.bind(this);
      this.zipcode_validation = this.zipcode_validation.bind(this);
      this.cityValidation = this.cityValidation.bind(this);
   }
   resumeUpload(e) {
      document.querySelector("#file-name").textContent = "";
      this.setState({ file_label: false, uploadActive: false });
      if (e.target.files[0] !== undefined) {
         var ext = e.target.files[0].name.split('.').pop();
         if (ext == "pdf" || ext == "docx" || ext == "doc") {
            document.querySelector("#file-name").textContent = e.target.files[0].name;
            this.setState({
               uploadActive: true,
               resumeName: e.target.files[0].name,
               invalid_file: "",
               file_label: true
            });
         } else {
            document.querySelector("#file-name").textContent = "";
            this.setState({
               invalid_file: "File Format should be .pdf or .docx or .doc",
               uploadActive: false,
            });
         }

      }
   }
   myResumeUpload() {
      this.setState({ resume_upload: false });
      document.getElementById('closeModel').click();
   }

   handleSuccess = (data) => {
      this.setState({
         code: data.code,
         errorMessage: '',
      });
      if (this.state.code != "") {
         this.linkedingetUser();
      }
   }

   handleFailure = (error) => {
      this.setState({
         code: '',
         errorMessage: error.errorMessage,
      });
      if (this.state.code != "") {
         this.linkedingetUser();
      }
   }

   componentWillMount() {
      if (localStorage.getItem('id')) {
         this.props.history.push('/joblisting');
      }
      const headers = {
         "Content-Type": "application/json",
         "Accept": "application/json"
      }
      axios.get(DOMAIN + GET_BASIC_DATA,
         {
            headers: headers
         }
      )
         .then(response => {
            if (response.data.status === 200) {
               this.setState({
                  countries: response.data.data.country,
                  states: response.data.data.state
               })

            }
         })
         .catch(function (error) {
            console.log(error);
         })
   }
   cityValidation() {
      this.zipcode_validation(this.state.zipcode, this.state.selectedCountry, this.state.city, 'city');
   }
   zipcode_validation(zipcode, input_country, input_city, type = '') {
      let selectedState = this.state.selectedState;
      const header = {
         "Content-Type": "application/x-www-form-urlencoded",
         "Accept": "application/json",
      }
      let url = '';
      if (type === 'city') {
          url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + input_city + '&key=' + GOOGLE_MAP_API_KEY;
       }  else {
         url = 'https://maps.googleapis.com/maps/api/geocode/json?key=' + GOOGLE_MAP_API_KEY + '&components=postal_code:' + zipcode;
      }
      axios.get(url, {
         headers: header
      })
         .then(response => {
            if (type == 'city') {
               if (response?.data?.status == 'ZERO_RESULTS') {
                  this.setState({
                     zipcode: '',
                     zipcode_error: '',
                     city_error: 'Please enter valid city.'
                  });
               }
            }
            if (Object.keys(response.data.results).length > 0) {
               var address = response.data.results[0].address_components;
               var zipcode_error = "";

               //break up the components
               var country = "", city = "", zip = "", state = '';
               address.forEach(function (item, index) {
                  var type = item.types[0];

                  if (city == "" && (type == "sublocality_level_1" || type == "locality")) {
                     city = (item.long_name).trim();
                  }
                  // if ($state == "" && type=="administrative_area_level_1") {
                  //     $state = trim(item.short_name);
                  // }
                  if (country == "" && type == "country") {
                     country = (item.long_name).trim();

                  }
                  if (type === 'postal_code') {
                     zip = (item.long_name).trim();
                  }
                  if (type === 'administrative_area_level_1') {
                     state = (item.long_name).trim();
                  }

               })
               if (type === 'city') {
                  this.setState({
                     zipcode: zip
                  });
                  if (zip === '') {
                     zipcode_error = '';
                  }
               }
               let city_error = '';

               if (country.toLowerCase().toString() != 'canada' && country.trim().toLowerCase().toString() != 'united states') {
                  zipcode_error = "This Zipcode is not available in this Country and City";
                  this.setState({
                     zipcode_error: zipcode_error
                  });
               }
               if (((country.toLowerCase().toString()) !==  input_country.toLowerCase())) {
                  zipcode_error = "Country";
               }
               if (type === 'city' && selectedState && state && selectedState.toLowerCase() !== String(state).toLowerCase()) {
                     city_error = 'Please enter a valid city based on selected state.'
               }
               if ((country.toLowerCase().toString()) != 'canada' && (country.toLowerCase().toString()) != 'united states') {
                  zipcode_error = "Country";
               }
               if ((input_city).toLowerCase() != (city.toLowerCase())) {
                  if((type === 'zipcode' && zip == '' && city == '') || type === 'city') {
                     if (zipcode_error != "") {
                        zipcode_error = zipcode_error + " and City"
                     } else {
                        zipcode_error = "City";
                     }
                  }
               }
               if (zipcode === '') {
                  zipcode_error = '';
               }
               // if (input_country === '' || this.state.state === '') {
               //    if (type == 'city' && input_city !== '') {
               //       city_error = 'Please select the country and state first.'
               //    }
               // }
                  this.setState({
                     city_error: city_error
                  })
               if (zipcode_error != "") {
                  zipcode_error = "This Zipcode is not available in this " + zipcode_error + ". This Zipcode is available from (" + country + " and " + city + ")";

                  this.setState({
                     zipcode_error: zipcode_error
                  });
               } else {
                  this.setState({
                     zipcode_error: ''
                  });
               }
            } else if (zipcode != "") {
               this.setState({
                  zipcode_error: "zipcode is not valid"
               });
            }
         })
      setTimeout(() => {
         if (this.state.zipcode === '') {
            this.setState({
               zipcode_error: ""
            });
         }
      }, 800)
   }
   onChange(e) {
      if (e.target.name === 'country') {
         this.setState({
            countryId: e.target.value,
            selectedCountry: this.state.countries[e.target.value].country,
            country: this.state.countries[e.target.value].id,
            state:'',
            city: '',
            zipcode: '',
            city_error: '',
            zipcode_error: ''
         })
         // this.state.country=this.state.countries[e.target.value].id;
         // this.state.selectedCountry=this.state.countries[e.target.value].country;

      } else if (e.target.name === 'state') {
         console.log(this.state.states[(e.target.value - 1)].state);
         this.setState({
            state: e.target.value,
            selectedState: this.state.states[(e.target.value - 1)].state,
            city: '',
            zipcode: '',
            city_error: '',
            zipcode_error: ''
         })
      } else if (e.target.name === 'city') {
         this.setState({
            city: e.target.value,
            zipcode: '',
            zipcode_error: ''

         })
         // this.state.country=this.state.countries[e.target.value].id;
         // this.state.selectedCountry=this.state.countries[e.target.value].country;

      } else if (e.target.name === 'phone_number') {
         const numregx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
         if (numregx.test(e.target.value) || e.target.value === "") {
            if (e.target.value.length < 9) {
               this.setState({
                  phone_number_issue: "Please enter valid phone number.Phone number must be a minimum Country Code + 10 digits !"
               })
            } else {
               this.setState({
                  phone_number_issue: ""
               })
            }
            this.setState({
               [e.target.name]: e.target.value
            })
         }
      }
      else if (e.target.name === 'zipcode') {
         const zipregx = /^[^/+<>%#@&"*?=^!~`.,'_:;\$/|\/\\{}()\[/\]/-]*$/;
         if (zipregx.test(e.target.value) || e.target.value === "") {
            this.setState({
               [e.target.name]: e.target.value
            })
            // this.zipcode_validation(e.target.value, this.state.selectedCountry, this.state.city, 'zipcode');
         }
      } else if (e.target.name === 'firstname' || e.target.name === 'lastname' || e.target.name === 'city') {
         const strregx = /^[A-Za-z]+$/;
         if (strregx.test(e.target.value) || e.target.value === "") {
            this.setState({
               [e.target.name]: e.target.value
            })

         }
      } else if (e.target.name === 'address') {
         const addressregx = /^[^<>%*?=^!~`]*$/;
         if (addressregx.test(e.target.value) || e.target.value === "") {
            this.setState({
               [e.target.name]: e.target.value
            })
         }
      } else if (e.target.name === 'email') {
         const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
         if (emailregex.test(e.target.value)) {
            this.setState({
               emailValidMsg: ""
            })
         } else {
            this.setState({
               emailValidMsg: "Please enter a valid  email id !"
            })
         }
         this.setState({
            [e.target.name]: e.target.value
         })
      } else {
         if (e.target.name === 'password') {
            if (e.target.value.length < 5) {
               this.setState({
                  passwordError: "Invalid Password . Password length must be minimum 5 letters !"
               })
            } else {
               this.setState({
                  passwordError: ""
               })
            }
         }
         if (e.target.name === 'confirmpassword') {
            this.setState({
               passwordStatus: false
            })
         }
         this.setState({
            [e.target.name]: e.target.value
         })
      }
   }
   changeterms() {
      if (this.state.terms) {
         this.setState({
            terms: false,
         })
      } else {
         this.setState({
            terms: true
         })
      }
   }
   registerJobseekers() {
      var personaldata = this.state;
      if (personaldata.password !== personaldata.confirmpassword) {
         this.setState({
            passwordStatus: true,
         })
      } else {
         var RegisterData = {
            country: personaldata.country,
            state: personaldata.state,
            firstname: personaldata.firstname,
            lastname: personaldata.lastname,
            email: personaldata.email,
            phone_number: personaldata.phone_number,
            address: personaldata.address,
            city: personaldata.city,
            zipcode: personaldata.zipcode,
            password: personaldata.password,
            org_visible_status: 1,
            flag: 0
         }

         const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
         }
         this.setState({ isLoad: false });
         axios.post(DOMAIN + REGISTER_JOBSEEKERS, {
            data: RegisterData
         },
            {
               headers: headers
            }
         )
            .then(response => {

               if (response.data.status === 200) {
                  this.setState({ user_Id: response.data.user_id })
                  localStorage.setItem('signup_war', 1);
                  var formData = new FormData();
                  formData.append('file', document.getElementById('resumeUpload').files[0]);
                  formData.append('userId', this.state.user_Id);
                  axios.post(DOMAIN + UPLOAD_MY_RESUME, formData, {
                     headers: headers
                  })
                     .then(response => {
                        this.setState({ isLoad: true });
                        if (response.data.status === 200) {
                           this.props.history.push('/login');
                        } else if (response.data.status === 500) {
                           localStorage.clear();

                        }
                     });
                  //    toast.error("Email verification sended to your email. Please verify your email!!", {
                  //       position: toast.POSITION.TOP_CENTER
                  //   });
                  //    toast.error("Please verify your email confirmation", {
                  //       position: toast.POSITION.TOP_CENTER
                  //   });
                  // localStorage.setItem("token",response.data.api_token);
                  // localStorage.setItem("firstname",this.state.firstname);
                  // localStorage.setItem("lastname",this.state.lastname);
                  // localStorage.setItem('email',this.state.email);
                  // localStorage.setItem('id',response.data.user_id);
                  // localStorage.setItem('is_confirm',0);
                  // this.props.history.push('/joblisting');
                  // this.props.history.push('/login');

               } else {
                  this.setState({ isLoad: true });
                  if (response.data.error_code === 1) {
                     this.setState({
                        emailValidMsg: response.data.msg
                     });
                     window.scrollTo(0, 0);
                  }
               }
            })
            .catch(function (error) {
               console.log(error);
            })
      }
   }
   isDisabled() {
      // if (this.state.firstname === "" || this.state.city_error !== '' || this.state.zipcode_error != "" || this.state.phone_number_issue != "" || this.state.lastname === "" || this.state.terms === false || this.state.password === "" || this.state.confirmpassword === "" || this.state.email === "" || this.state.emailValidMsg !== "" || this.state.passwordError !== "" || this.state.invalid_file !== "" || this.state.uploadActive !== true) {
         if (this.state.firstname === ""  || this.state.lastname === "" || this.state.terms === false || this.state.password === "" || this.state.confirmpassword === "" || this.state.email === "" || this.state.emailValidMsg !== "" || this.state.passwordError !== "" || this.state.invalid_file !== "" || this.state.uploadActive !== true) {
         return true;
      }
      return false;
   }
   linkedingetUser() {

      const headers = {
         "Content-Type": "application/json",
         "Accept": "application/json"
      }
      if (this.state.code != "") {
         if (document.getElementById("fade")) {
            document.getElementById("fade").style.opacity = "0.5";
            document.getElementById("fade").style['pointer-events'] = 'none';
         }
         this.setState({ isLoad: false });
         axios.post(DOMAIN + LINKED_IN, {
            code: this.state.code
         },
            {
               headers: headers
            }
         )
            .then(response => {
               if (document.getElementById("fade")) {
                  document.getElementById("fade").style.opacity = "unset";
                  document.getElementById("fade").style['pointer-events'] = '';
               }
               //  this.setState({});
               this.setState({
                  firstname: response.data.user_data[0].firstname,
                  lastname: response.data.user_data[0].lastname,
                  email: response.data.user_data[0].email["handle~"].emailAddress,
                  code: "",
                  emailValidMsg: "",
                  isLoad: true
               });
            })
            .catch(function (error) {
               console.log(error);
            })
      }
   }
   responseGoogleSign = (response) => {
      if (response.error != "popup_closed_by_user") {
         var name = response.profileObj.name.split(" ");
         this.setState({ email: response.profileObj.email, firstname: name[0], lastname: name[1], emailValidMsg: "" });
      }

   }
   responseFacebookSign = (response) => {
      if (response.status != "unknown") {
         // this.setState({styles:{ pointerEvents: "NamedNodeMap",
         //    opacity: "0.4"}})
         var name = response.name.split(" ");
         this.setState({ email: response.email, firstname: name[0], lastname: name[1], emailValidMsg: "" });
      }
   }
   render() {
      if (this.props.location.state !== undefined) {
         if (this.props.location.state.Footer !== undefined) {
            window.scrollTo(0, 0);
            this.props.history.push('/signup');
         }
      }
      var uploadBtnStyle = {
         color: "white",
         border: "none",
         background: "#6868fb",
         width: "142px",
         height: "36px",
         // marginTop: "-39px",
         // marginLeft: "456px",
         // position: "absolute",


      };
      function activefile() {
         document.getElementById('resumeUpload').click();
      }
      var basicCloseStyle = {
         position: "relative",
         left: "41rem",
         top: "14px",
         zIndex: "10"
      }

      return (
         <React.Fragment>
            <div >
               <ToastContainer />
               <section className="vh-100-desk signup">

                  <div className="home-center">
                     <div className="home-desc-center">
                        <div className="container">
                           <div className="row justify-content-center  mr-top">
                              <div className="col-md-6 col-md-6 mb-4">
                                 <div className="login_page bg-white shadow rounded p-4">
                                    <div className="text-center">
                                       <h4 className="mb-4">Sign Up</h4>
                                    </div>
                                    <div className="row">
                                       <div className="col-md-12">
                                          <div className="form-group position-relative">
                                             <label>First name <span className="text-danger">*</span></label>
                                             <input type="text" className="form-control" placeholder="Enter Your First Name" name="firstname" maxLength={20} onChange={this.onChange.bind(this)} value={this.state.firstname} />
                                          </div>
                                       </div>
                                       <div className="col-md-12">
                                          <div className="form-group position-relative">
                                             <label>Last name <span className="text-danger">*</span></label>
                                             <input type="text" className="form-control" placeholder="Enter Your Last Name" name="lastname" maxLength={20} onChange={this.onChange.bind(this)} value={this.state.lastname} />
                                          </div>
                                       </div>
                                       <div className="col-md-12">
                                          <div className="form-group position-relative">
                                             <label>Your Email <span className="text-danger">*</span></label>
                                             <input type="email" className="form-control" placeholder="Enter Your Email Address" name="email" maxLength={50} onChange={this.onChange.bind(this)} value={this.state.email} />
                                             {this.state.emailValidMsg != "" ? <span style={{ color: "red" }}>{this.state.emailValidMsg}</span> : ""}
                                          </div>
                                       </div>
                                       {/* <div className="col-md-6">
                                          <div className="form-group position-relative">
                                             <label>Mobile <span className="text-danger">*</span></label>
                                             <input type="text" className="form-control" placeholder="Enter Your Mobile Number" minLength={9} maxLength={15} name="phone_number" onChange={this.onChange.bind(this)} value={this.state.phone_number} />
                                             {this.state.phone_number_issue != "" ? <p style={{ color: "red" }}>{this.state.phone_number_issue}</p> : ""}
                                          </div>
                                       </div> */}
                                       {/* <div className="col-md-12">
                                          <div className="form-group position-relative">
                                             <label>Address</label>
                                             <textarea className="form-control" maxLength={255} name="address" onChange={this.onChange.bind(this)} value={this.state.address} title="Enter your Address" placeholder="Enter your Address" required></textarea>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group position-relative">
                                             <label>Country <span className="text-danger">*</span></label>
                                             <select className="demo-default selectize-input form-control" name="country" value={this.state.countryId} onChange={this.onChange.bind(this)}>
                                                <option value="" disabled>Choose your country</option>
                                                {this.state.countries.map((value, key) => {
                                                   return <option key={key} value={key}>{value.country}</option>;
                                                })}
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group position-relative">
                                             <label>State <span className="text-danger">*</span></label>
                                             <select className="demo-default selectize-input form-control" name="state" id="state" value={this.state.state} onChange={this.onChange.bind(this)}>
                                                <option value="" disabled>Choose your state</option>
                                                {this.state.states.map((value, key) => {
                                                   if (value.country == this.state.selectedCountry)
                                                      return <option key={key} value={value.id}>{value.state}</option>;
                                                })}
                                             </select>
                                          </div>
                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group position-relative">
                                             <label>City <span className="text-danger">*</span></label>
                                             <input type="text" className="form-control" placeholder="Enter Your City" onChange={this.onChange.bind(this)} onBlur={this.cityValidation.bind(this)} value={this.state.city} maxLength={25} name="city" disabled={this.state.state == ''}/>
                                             {this.state.city_error !== "" ? <div className="col-md-12 text-right"><span style={{ color: "red" }}>{this.state.city_error}</span></div> : ""}
                                          </div>

                                       </div>
                                       <div className="col-md-6">
                                          <div className="form-group position-relative">
                                             <label>Zip Code</label>
                                             <input type="text" className="form-control" placeholder="Enter Your Zip Code" name="zipcode" maxLength={7} onChange={this.onChange.bind(this)} value={this.state.zipcode} disabled={this.state.city === '' || this.state.city_error !== ''}/>
                                          </div>
                                          {this.state.zipcode_error !== "" ? <div className="col-md-12 text-right"><span style={{ color: "red" }}>{this.state.zipcode_error}</span></div> : ""}
                                       </div> */}
                                       <div className="col-md-12">
                                          <div className="form-group position-relative">
                                             <label>Password <span className="text-danger">*</span></label>
                                             <input type="password" maxLength={15} autoComplete="nope" className="form-control" placeholder="Enter Your Password" name="password" onChange={this.onChange.bind(this)} value={this.state.password} />
                                             {this.state.passwordError != "" ? <span style={{ color: "red" }}>{this.state.passwordError}</span> : ""}
                                          </div>
                                       </div>
                                       <div className="col-md-12">
                                          <div className="form-group position-relative">
                                             <label>Confirm Password <span className="text-danger">*</span></label>
                                             <input type="password" maxLength={15} autoComplete="nope" className="form-control" name="confirmpassword" onChange={this.onChange.bind(this)} value={this.state.confirmpassword} placeholder="Confirm Password" />
                                             {this.state.passwordStatus == true ? <span style={{ color: "red" }}>confirm Invalid Password. Please tray with valid password !</span> : ""}

                                          </div>
                                       </div>

                                       <div className="col-md-12 text-center form-group">
                                          <label htmlFor="resumeUpload" className="custom-file-upload btn btn-block">
                                             <i className="fa fa-cloud-upload"></i> Upload Resume <span className="text-danger">*</span>
                                          </label>
                                          <input name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control" onChange={this.resumeUpload.bind(this)} type="file" style={{ display: "none" }} />
                                          <label id="file-name"></label>
                                          {this.state.invalid_file !== "" ? <div><br /><span style={{ color: "red" }}>{this.state.invalid_file}</span></div> : null}

                                       </div>

                                       <div className="col-md-12">
                                          <div className="form-group">
                                             <div className="custom-control m-0 custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="i1" onChange={this.changeterms.bind(this)} defaultChecked={this.terms} />
                                                <label className="custom-control-label" htmlFor="i1">I Accept to the<a href="/terms" target="_blank" className="text-primary">Terms of use</a></label>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="col-md-12">
                                          <button type="submit" onClick={this.registerJobseekers} className="btn btn-primary w-100" disabled={this.isDisabled()}>Sign Up</button>
                                          <small>All <span className="text-danger">*</span> fields are mandatory</small>
                                       </div>
                                       {/* <div  className="col-lg-12 mt-4 text-center">
                                    <h6>Or Login With</h6>
                                    <ul  className="list-unstyled social-icon mb-0 mt-3">
                                        <li  className="list-inline-item">
                                        <FacebookLogin
                                          appId={FB_APP_ID}
                                          fields="name,email,picture"
                                          callback={this.responseFacebookSign}
                                          render={renderProps => (
                                             <a  className="rounded" onClick={renderProps.onClick}>
                                                <i className="mdi mdi-facebook" title="Facebook"></i></a>
                                          )}
                                          />
                                         </li>
                                        <li  className="list-inline-item">
                                        <GoogleLogin
                                             clientId={GOOGLE_CLIENT_ID} //CLIENTID NOT CREATED YET
                                             render={renderProps => (
                                                <a className="rounded" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                                   <i className="mdi mdi-gmail" title="Gmail"></i></a>
                                             )}
                                             buttonText="Login"
                                             onSuccess={this.responseGoogleSign}
                                             onFailure={this.responseGoogleSign}
                                             cookiePolicy={'single_host_origin'}
                                          />
                                          </li>
                                        <li  className="list-inline-item">
                                             <LinkedIn
                                                   clientId={LINKEDIN_CLIENT_ID}
                                                   redirectUri={`${window.location.origin}/linkedin`}
                                                   scope = {["r_liteprofile","r_emailaddress","w_member_social"]}
                                                   state="ba015d"
                                                   onFailure={this.handleFailure}
                                                   onSuccess={this.handleSuccess}
                                                   supportIE
                                                   redirectPath='/linkedin'
                                                   renderElement={({ onClick, disabled }) => (
                                                      <a className="rounded" onClick={onClick} disabled={disabled}>
                                                         <i className="mdi mdi-linkedin" title="LinkedIn"></i></a>
                                                   )}/>
                                             </li>
                                    </ul>
                                </div> */}
                                       <div className="mx-auto">
                                          <p className="mb-0 mt-3"><small className="text-dark mr-2">Already have an account ?</small> <Link to="/login" className="font-weight-bold">SigIn</Link></p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>

            </div>

         </React.Fragment>
      );
   }

}

export default SignUp;