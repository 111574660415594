import React, { Component } from 'react';
import { GOOGLE_MAP_API_KEY } from '../AppConfig';
import axios from 'axios';

export default class ZipcodeValidate extends Component{

async zipcode_validation(zipcode, input_country, input_city) {
        let zip_error = false;
        const header = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
        }
        let result = await axios.get('https://maps.googleapis.com/maps/api/geocode/json?key='+GOOGLE_MAP_API_KEY+'&components=postal_code:'+zipcode, {
            headers: header
        })
        .then(response => {

                if (Object.keys(response.data.results).length > 0) {
                    var address = response.data.results[0].address_components;
                    var zipcode_error = "";

                    //break up the components
                    var country = "", city = "";
                    address.forEach(function (item, index) {
                        var type = item.types[0];

                        if (city == "" && (type == "sublocality_level_1" || type == "locality")) {
                            city = (item.long_name).trim();
                        }
                        // if ($state == "" && type=="administrative_area_level_1") {
                        //     $state = trim(item.short_name);
                        // }
                        if (country == "" && type == "country") {
                            country = (item.long_name).trim();

                        }

                    })
                    if (country.toLowerCase().toString() != 'canada' && country.trim().toLowerCase().toString() != 'united states') {
                        zipcode_error = "This Zipcode is not available in this Contry and City";
                        return zipcode_error;
                    }
                    if (((country.toLowerCase().toString()) == 'canada' && input_country != 'Canada') || ((country.toLowerCase().toString()) == 'united states' && input_country != 'United States')) {

                        zipcode_error = "Country";
                    }
                    if ((country.toLowerCase().toString()) != 'canada' && (country.toLowerCase().toString()) != 'united states') {
                        zipcode_error = "Country";
                    }
                    if ((input_city).toLowerCase() != (city.toLowerCase())) {
                        if (zipcode_error != "") {
                            zipcode_error = zipcode_error + " and City"
                        } else {
                            zipcode_error = "City";
                        }
                    }
                    if (zipcode_error != "") {
                        zipcode_error = "This Zipcode is not available in this " + zipcode_error + ". This Zipcode is available from (" + country + " and " + city + ")";

                        return zipcode_error;
                    } else {
                        return zipcode_error;
                    }
                }else{
                    return "zipcode is not valid"
                }
            })
}
render(){
return(
<>
</>
)
}
}