import React, { Component } from "react";
import axios from 'axios';
import ReactTags from 'react-tag-autocomplete';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DOMAIN,GET_BASIC_DATA,GET_SKILL_CERT,UPDATE_SKILL_CERT } from '../../AppConfig';
import Loader from 'react-loader'

class SkillCert extends Component {

    constructor(props) {
        super(props);
        this.state={
            skills:[],
            certifications:[],
            Certsuggestions:[],
            suggestions:[],
            is_disable:true,
            isLoad:true
        }
    }
    componentWillMount(){
        this.setState({isLoad:false})
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
         }
         axios.get(DOMAIN + GET_BASIC_DATA,
            {
               headers: headers
            }
         )
        .then(response => {
            this.setState({isLoad:true})
            if (response.data.status === 200) {
                this.setState({
                    suggestions: response.data.data.skills,
                    Certsuggestions: response.data.data.certifications
                });
                const header = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": localStorage.getItem('token')
                 }
                 axios.post(DOMAIN + GET_SKILL_CERT,{
                     userId:localStorage.getItem('id')
                 },
                    {
                       headers: header
                    }
                 )
                .then(response => {
                    if (response.data.status === 200) {
                        this.setState({
                            skills: response.data.data.skill,
                            certifications: response.data.data.certification
                        })
                    }else if(response.data.status === 401){
                        localStorage.clear();
                        this.props.history.push('/login');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                })
            }
        })
        .catch(function (error) {
            console.log(error);
        })

    }
    certAddition(certification) {
        this.setState({
            is_disable:false
        })
        const certifications = [].concat(this.state.certifications, certification);
        const Certsuggestions = this.state.Certsuggestions.filter(x => x.id !== certification.id);
        this.setState({ certifications, Certsuggestions });
    }
    certDelete(i) {
        this.setState({
            is_disable:false
        })
        if (i > -1) {
            const certifications = this.state.certifications.slice(0)
            certifications.splice(i, 1)
            const removedTag = this.state.certifications[i];
            const Certsuggestions = [].concat(this.state.Certsuggestions, removedTag);
            this.setState({ certifications, Certsuggestions })
        }
    }
    handleAddition(skill) {
        this.setState({
            is_disable:false
        })
        const skills = [].concat(this.state.skills, skill);
        const suggestions = this.state.suggestions.filter(x => x.id !== skill.id);

        this.setState({ skills, suggestions });
    }
    handleDelete(i) {
        this.setState({
            is_disable:false
        })
        if (i > -1) {
            const skills = this.state.skills.slice(0)
            skills.splice(i, 1)
            const removedTag = this.state.skills[i];
            const suggestions = [].concat(this.state.suggestions, removedTag);
            this.setState({ skills, suggestions })
        }
    }
    updateSkillCert(){
        this.setState({isLoad:false})
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        axios.post(DOMAIN + UPDATE_SKILL_CERT, {
            userId:localStorage.getItem('id'),
            skill:this.state.skills,
            certification:this.state.certifications
        },
        {
            headers: headers
        })
        .then(response => {
            this.setState({isLoad:true})
            if (response.data.status === 200) {
                this.setState({
                    is_disable:true
                })
                toast.success("Profile Updated successfully", {
                    position: toast.POSITION.TOP_CENTER
                });
            }else if(response.data.status === 401){
                localStorage.clear();
                this.props.history.push('/login');
            }
        })
        .catch(function (error) {
            console.log(error);
        })
    }
    render() {       
        return (  
               <div  className="col-lg-12">
                <Loader loaded={this.state.isLoad}/>
                  <div  className="job-detail border rounded mt-2 p-4"> 
                        <div  className="row">
                           <div  className="col-lg-6">
                              <div  className="col-md-12">
                                 <div  className="form-group position-relative">                                                
                                    <label  className="title-heading-sub">Skills</label>
                                    <ReactTags
                             maxSuggestionsLength={500}
                            tags={this.state.skills}
                            suggestions={this.state.suggestions}
                            noSuggestionsText={'No suggestions found'}
                            placeholder ={'Add skills'}
                            handleDelete={this.handleDelete.bind(this)}
                            handleAddition={this.handleAddition.bind(this)} />
                                 </div>
                              </div>
                           </div>
                           <div  className="col-lg-6">
                              <div  className="col-md-12">
                                 <div  className="form-group position-relative">                                                
                                    <label  className="title-heading-sub">Certifications</label>
                                    <ReactTags
                             maxSuggestionsLength={500}
                            tags={this.state.certifications}
                            suggestions={this.state.Certsuggestions}
                            noSuggestionsText={'No suggestions found'}
                            placeholder ={'Add Certification'}
                            handleDelete={this.certDelete.bind(this)}
                            handleAddition={this.certAddition.bind(this)} />
                                  </div>
                              </div>
                              <div  className="col-md-12">
                                 <button  className="btn btn-primary w-100 btn-save" onClick={this.updateSkillCert.bind(this)} disabled={this.state.is_disable===true}>Save</button>
                              </div>
                           </div>
                        </div> 
                  </div>
                  <style>{"\
                    .react-tags {\
                        height: 125px;\
                    }\
                "}</style>
               </div> 
        );
    }
}

export default SkillCert;