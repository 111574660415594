import React, { Component } from "react";
import axios from 'axios';
import {DOMAIN,LOGOUT,ATS_URL} from '../../AppConfig'
import {Link,withRouter} from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
import '../common/notification-style.css'
class Header extends Component {
  constructor(props){
    super(props);

    this.state={
      notifications:[],
      count:0,
      alertNotification:[],
    Footer:"footer",
    mobileToggleStatus:0,
    userToggleStatus:0,
    notiToggleStatus:0,
    userId:null

    }
    this.logout = this.logout.bind(this);
    this.getMyLocation=this.getMyLocation.bind(this);
    this.postJob=this.postJob.bind(this);
  }
  mobileToggle (){
    this.setState({mobileToggleStatus:this.state.mobileToggleStatus?0:1})
  }
  userToggle (){
    this.setState({userToggleStatus:this.state.userToggleStatus?0:1})
  }
  notificationToggle (){
    this.setState({notiToggleStatus:this.state.notiToggleStatus?0:1})
  }

  postJob(){
    document.getElementById('postjob').click();
    this.mobileToggle();
  }
  Signin_signup(flag){
    document.getElementById('modal-dismiss-postjob').click();
    if(flag===1)
    {
    this.props.history.push('/organization',this.state);
    }
    else
    {
      var win = window.open(ATS_URL, '_blank');
      win.focus();
    }

  }
  getMyLocation() {
    const location = window.navigator && window.navigator.geolocation
    if (location) {
        location.getCurrentPosition((position, error) => {
          var latlong={
            lat:position.coords.latitude,
            long:position.coords.longitude
          };
          return latlong;
            // this.setState({ latitude: position.coords.latitude, longitude: position.coords.longitude }, () =>
            // console.log("search"));

        }, (error) => {
          var latlong={
            lat:"",
            long:""
          };
            // this.setState({date:true,dates:1,distance:false,distances:0,distances_status:false});
            // this.setState({ latitude: '', longitude: ''});
            return latlong;
        })
    }

}

  componentDidMount(){
  // var latlong=this.getMyLocation();
  this.setState({userId:localStorage.getItem('id')})
  var lat="",long="";
  const location = window.navigator && window.navigator.geolocation
    if(location) {
        location.getCurrentPosition((position, error) => {
            lat=position.coords.latitude;
            long=position.coords.longitude;

   if(localStorage.getItem('id')){
            this.getNotification(lat,long);
            setInterval(function(){
              this.getNotification(lat,long)
            }.bind(this), 10000);
          }
        });
    }
  // alert(latlong);


  }

getNotification=(lat,long)=>{
  if(localStorage.getItem('token')){
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization":localStorage.getItem('token')
   }
    axios.post(DOMAIN + '/getNotification',
      {
        id:localStorage.getItem('id'),
        latitude:lat,
        longitude:long
      },
      {
         headers: headers
      }
   )
   .then(response => {
      if(response.data.status === 200) {
        this.setState({
          count:response.data.count,
          notifications:response.data.notifications,
          alertNotification:response.data.alertNotification
        })
      }else if(response.data.status === 401){
        localStorage.clear();
        this.props.history.push('/');
      }
    })
    .catch(function (error) {
       console.log(error);
    })
  }
}

  SeendNotifications(){
    document.getElementById("notify-show").classList.toggle("show");
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization":localStorage.getItem('token')
   }
    axios.post(DOMAIN + '/seendNotifications',
      {
        id:localStorage.getItem('id'),
      },
      {
         headers: headers
      }
   )
   .then(response => {
      if(response.data.status === 200) {

      }else if(response.data.status === 401){
        localStorage.clear();
        this.props.history.push('/login');
      }
    })
    .catch(function (error) {
       console.log(error);
    })
  }
  logout(){
    this.mobileToggle();
    const headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "Authorization":localStorage.getItem('token')
   }
    axios.post(DOMAIN + LOGOUT,{id:localStorage.getItem('id')},
      {
         headers: headers
      }
   )
   .then(response => {

      this.setState({userId:null})

      if(response.data.status === 200) {
        localStorage.clear();
        // this.props.history.push('/');
        window.location.href = "/";
      }else if(response.data.status === 401){
        localStorage.clear();
        window.location.href = "/";
        // this.props.history.push('/');
        // this.props.history.push('/login');
      }
    })
    .catch(function (error) {
       console.log(error);
    })

  }

 render() {
  const card = document.querySelector('body1');

  // card.addEventListener('dblclick', function (e) {
  //   if(document.getElementById("notify-show"))
  //   document.getElementById("notify-show").classList.remove("show");
  // });
  // card.addEventListener('click', function (e) {

  //   if(document.getElementById("navbar7").classList.contains("in")){
  //     if(!e.target.classList.contains("icon-bar") && !e.target.classList.contains("mobile-view") && !e.target.classList.contains("notification-identity"))
  //       document.getElementById("navbar7").classList.remove("in");
  //   }

  //   if(!e.target.classList.contains("profile-username")){
  //     if(document.getElementById("profile-dropdown"))
  //       document.getElementById("profile-dropdown").classList.remove("show");
  //   }

  //   // if(document.getElementById("profile-dropdown").style.display=="block"){
  //   //   document.getElementById("profile-dropdown").style.display="none";
  //   // }
  // });
  function closenotificationPopup(){
    // console.log(document.getElementById("profile-dropdown").style.display);
    // var profileClass = document.getElementById("profile-dropdown").classList;
    // if(document.getElementById("profile-dropdown").style.display=="none"){
    //   document.getElementById("profile-dropdown").style.display="block";
      // document.getElementById("profile-dropdown").classList.add("show");
    // }
    // else{
    //   // document.getElementById("profile-dropdown").classList.remove("show");
    //   document.getElementById("profile-dropdown").style.display="none";
    // }



    document.getElementById("profile-dropdown").classList.toggle("show");
    if(document.getElementById("notify-show"))
      document.getElementById("notify-show").classList.remove("show");
  }
  // function mobileDropdownClick(){
  //   document.getElementById("navbar7").classList.toggle("show");
  // }
   let headComp;
   let username = localStorage.getItem('firstname')+" "+localStorage.getItem('lastname')
  if (localStorage.getItem('id')){
    headComp=( <React.Fragment>
      <div className="float-right">
              <ul className="navigation-menu">
                <li className="has-submenu">
                  <a hre="#"  onMouseOver={this.SeendNotifications.bind(this)}  className="notification">
                    <i className="fa fa-bell" /><span className="badge">{this.state.count}</span></a>
                  <ul className="submenu notify cont model-border-st" id="notify-show">
                    <li className = "notificaitonTitle"> Notification({this.state.count})</li>
                    {this.state.notifications.map((value, key) => (
                    <li key={key}>
                      <a href="">
                      <strong className="text-info">{value.notification_type}</strong><small className="float-right">{value.created_at}</small>
                      <div className="notificationLineHeight text-center" dangerouslySetInnerHTML={{ __html: value.notification_content }}>
                      </div>

                      </a></li>
                    ))}


                  {this.state.alertNotification.length>0?
                  <li className = "notificaitonTitle" >RECOMMENDED JOBS</li>:""}
                    {this.state.alertNotification.map((value, key) => (
                    <li key={key}>
                      <a href="">
                      <strong className="text-info">{value.notification_type}</strong><small className="float-right">{value.created_at}</small>
                      <div className="notificationLineHeight text-center" dangerouslySetInnerHTML={{ __html: value.notification_content }}>
                      </div>

                      </a></li>
                    ))}
                    <Link to="/notification" className="notificationViewAll">View All</Link>
                    </ul>
                    </li>
                <li className="has-submenu userName">
                    <a href="#">{username}</a><span className="menu-arrow" />
                  <ul className="submenu">
                    <li><Link to="/profile"  className="edit-border ml0">Profile</Link></li>
                    <li><Link to="/myjobs"  className="edit-border ml0">My Jobs</Link></li>
                    <li><Link to ="/settings"  className="edit-border ml0">Settings</Link></li>
                    <li><a href="#/" onClick={this.logout}>Log Out</a></li>
                  </ul>
                </li>
              </ul>
            </div>
      {/* <li>

      <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{display: 'unset !important'}}>
        <ul className="nav nav-pills mr-auto justify-content-end" style={{marginTop: '18px !important'}}>
          <li className="nav-item dropdown">
            <Link className="nav-link text-light notification-identity" href="#/" id="navbarDropdown" onClick={this.SeendNotifications.bind(this)} style={{padding:"0px 0px"}} role="button" aria-haspopup="true" aria-expanded="false">
              <i className="fa fa-bell notification-identity" style={{fontSize: "large",backgroundColor: "#1b2034"}}/>
              <span className="badge  badge-pill badge-primary notification-identity" style={{top: "-10px",position: "relative",left: "-17px"}}>{this.state.count}</span>
            </Link>
            <ul className="dropdown-menu" id="notify-show" style={{overflowY: "scroll",maxHeight: "40rem",    overflowX: "hidden"}}>
              <li className="head-notify text-light bg-dark" >
                <div className="row">
                  <div className="col-lg-12 col-sm-12 col-12 ">
                    <span>Notifications ({this.state.count})</span>
                  </div>
                </div>
              </li>
              {this.state.notifications.map((value, key) => (
                 <li key={key} className="notification-box">
                  <div className="row">
                    <div className="col-lg-12 col-sm-8 col-12 row-padding">
                      <strong className="text-info">{value.notification_type}</strong>
                      <div dangerouslySetInnerHTML={{ __html: value.notification_content }}>
                      </div>
                      <small className="text-warning">{value.created_at}</small>
                    </div>
                  </div>
                </li>
              ))}
              {this.state.alertNotification.length>0?<li style={{paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#bbbbbb', fontWeight: 'bold', fontSize: '14px', fontFamily: 'Times New Roman, Times, serif'}}>RECOMMENDED JOBS</li>:""}
              {this.state.alertNotification.map((value, key) => (
                 <li key={key} className="notification-box">
                  <div className="row">
                    <div className="col-lg-12 col-sm-8 col-12">
                      <strong className="text-info">{value.notification_type}</strong>
                      <div dangerouslySetInnerHTML={{ __html: value.notification_content }}>
                      </div>
                      <small className="text-warning">{value.created_at}</small>
                    </div>
                  </div>
                </li>
              ))}
              <li className="footer bg-dark text-center">
                <Link href="./notification" className="text-light">View All</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      </li>
      <li  className="dropdown" id="profile-menu">
                <Link href="#/" className="sign-in flex" id="profile-username" onClick={closenotificationPopup}> <span  className="tab-view profile-username"> {username}</span><br/><span  className="mobile-view">My Account</span><i className="fa fa-caret-down my-acc-usr-acc profile-username notification-identity"></i> </Link>
            <div  id="profile-dropdown" className="dropdown-content mt0 ml25 p5 dropdown-mob profile-dropdown" style={{display:"none"}}>
                  <Link to="/profile"  className="edit-border ml0">Profile</Link>
                  <Link to="/myjobs"  className="edit-border ml0">My Jobs</Link>
                  <Link to ="/settings"  className="edit-border ml0">Settings</Link>
                  <Link href="#/" onClick={this.logout}>Log Out</Link>
                </div>
      </li> */}
    </React.Fragment>
    )
  }else{
    headComp= ( <React.Fragment>
        {/* <li>
        <Link className="sign-in mt0" to={{pathname: `/login`,state: {Footer:"footer"}}}>SIGN IN</Link> */}
          {/* <Link to="/login"  className="sign-in mt0">SIGN IN</Link> */}
          {/* </li>
        <li>
        <Link to={{pathname: `/signup`,state: {Footer:"footer"}}}><button  className="btn sign-up sign-up-mob"> SIGN UP</button></Link> */}
          {/* <Link to="/signup"><button  className="btn sign-up sign-up-mob"> SIGN UP</button></Link> */}
          {/* </li> */}
          <div className="float-right">
                  <div className="buy-button">
                     <Link to={{pathname: `/signup`,state: {Footer:"footer"}}} className="btn btn-signout"> SIGN UP</Link>
                  </div>
                  <div className="buy-button">
                     <Link to={{pathname: `/login`,state: {Footer:"footer"}}} className="btn btn-signin"> SIGN IN</Link>
                  </div>
               </div>
      </React.Fragment>
    )
  }
  return (

      <React.Fragment>

{/* <ToastContainer /> */}

{/* <nav  className="navbar navbar  navbar-fixed-top bg">

    <div  className="container-fluid">

      <div  className="navbar-header">

        <button type="button"    className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar7">

          <span  className="sr-only">Toggle navigation</span>

          <span  className="icon-bar"></span>

          <span  className="icon-bar"></span>

          <span  className="icon-bar"></span>

        </button>
              <Link  className="navbar-brand" to={{pathname: `/`,state: {Footer:"footer"}}}>UP4HIRE </Link>

      </div>







      <div id="navbar7"  className="navbar-collapse collapse">

        <ul  className="nav navbar-nav navbar-left mobile-menu-hide">

           <li  className="head-country"><Link href="#/" className="menu-country" onClick={this.postJob} >POST JOB</Link></li>

			 <li  className="head-country">
       <Link className="menu-country" to={{pathname: `/joblisting`,state: {Footer:"footer"}}}>SEARCH JOB</Link>
         </li>

			 <li  className="head-country">
          <Link href="#/" onClick={this.postJob} className="menu-country">APPLICANT TRACKING SYSTEM <span className="trackFree-head">Free</span> </Link>
          </li>
			   <li  className="head-country">
         <Link className="menu-country" to={{pathname: `/aboutus`,state: {Footer:"footer"}}}>ABOUT US</Link>
           </li>{}

        </ul>

        <ul  className="nav navbar-nav navbar-right">

        {headComp}

        </ul>

      </div>

    </div>

  </nav> */}


  <header id="topnav" className="defaultscroll scroll-active">
         <div className="tagline">
            <div className="container">
               {/* <div className="float-right">
                  <div className="buy-button">
                     <Link href="#" className="btn btn-signout"> SIGN UP</Link>
                  </div>
                  <div className="buy-button">
                     <Link href="#" className="btn btn-signin"> SIGN IN</Link>
                  </div>
               </div> */}
               {headComp}
               <div className="clearfix"></div>
            </div>
         </div>
         <div className="container">
            <div>
               <Link to="/" className="logo">
               <img src="assets/images/featured-job/img-4.png" style={{width: "41px"}} alt="" className="img-fluid mx-auto" />
               <img src="/assets/images/logo-light.png" alt="" className="logo-light"/>
               <img src="/assets/images/logo-dark.png" alt="" className="logo-dark"/>
               </Link>
            </div>
            <div className="buy-button">
               <div className="hoverCursor" onClick={this.postJob} className="btn btn-primary">
                 <i className="mdi mdi-cloud-upload"></i> Post a Job</div>
            </div>
            <div onClick ={()=>this.mobileToggle()} className="menu-extras">
               <div className="menu-item">
                  <div className={'navbar-toggle'+(this.state.mobileToggleStatus? " open":"")}>
                     <div className="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                     </div>
                  </div>
               </div>
            </div>
            <div id="navigation" className={(this.state.mobileToggleStatus ?"showMenu":" ")}>
               <ul className="navigation-menu">
                  <li><Link to="/" onClick ={()=>this.mobileToggle()}>Home</Link></li>
                  <li>
                     <Link to="/joblisting" onClick ={()=>this.mobileToggle()}>Search Job</Link>
                  </li>
                  <li>
                     <a  href="#" onClick={this.postJob}>Application tracking system
                     <span className="freenav">FREE</span>
                     </a>
                  </li>
                  <li>
                     <Link to="/aboutus"  onClick ={()=>this.mobileToggle()} >about us</Link>
                  </li>

{(this.state.userId === null || this.state.userId === undefined) ?
        <>
              {/* <li className="menu-extras">
            <Link to={{pathname: `/signup`,state: {Footer:"footer"}}}  onClick ={()=>this.mobileToggle()} className="btn btn-signout"> SIGN UP</Link>
            </li>
            <li>
            <Link to={{pathname: `/login`,state: {Footer:"footer"}}} onClick ={()=>this.mobileToggle()}  className="btn btn-signin"> SIGN IN</Link>
            </li> */}
          <li  onClick ={()=>this.mobileToggle()} className="btn-signout-mobile">
                <Link to={{pathname: `/signup`,state: {Footer:"footer"}}} > SIGN UP</Link>
            </li>
            <li  onClick ={()=>this.mobileToggle()} className="btn-signin-mobile">
                <Link to={{pathname: `/login`,state: {Footer:"footer"}}} > SIGN IN</Link>
          </li>
        </>
        :
    <>
                  <li  onClick={()=>this.notificationToggle()} className={'has-submenu btn-signout-mobile notification-alert'+(this.state.notiToggleStatus?" open":"")}>

                     <a href="#" onMouseOver={this.SeendNotifications.bind(this)}>Notifications({this.state.count})</a><span  className="menu-arrow"></span>
                     <ul  id="notify-show" className={'notify cont submenu'+(this.state.notiToggleStatus?" open":"")}>
                     <li><a href="" className="notificationHeading" >Notification({this.state.count})</a></li>
                    {this.state.notifications.map((value, key) => (
                    <li key={key}>
                      <a href="" >
                      <strong className="text-info">{value.notification_type}</strong>
                      <div dangerouslySetInnerHTML={{ __html: value.notification_content }}>
                      </div>
                      <small className="text-warning">{value.created_at}</small>
                      </a></li>
                    ))}


                  {this.state.alertNotification.length>0?
                  <li ><a href="" className="notificationHeading">RECOMMENDED JOBS</a></li>:""}
                    {this.state.alertNotification.map((value, key) => (
                    <li key={key}>
                      <a href="">
                      <strong className="text-info">{value.notification_type}</strong>
                      <div dangerouslySetInnerHTML={{ __html: value.notification_content }}>
                      </div>
                      <small className="text-warning">{value.created_at}</small>
                      </a></li>
                    ))}
                    <Link to="/notification" onClick ={()=>this.mobileToggle()}  className="border-top notificationViewAll">View All</Link>
                    </ul>
                </li>
                  <li onClick={()=>this.userToggle()} className={'has-submenu btn-signout-mobile'+(this.state.userToggleStatus?" open":"")}>
                     <a href="#" >User</a><span  className="menu-arrow"></span>
                     <ul  className={'submenu'+(this.state.userToggleStatus?" open":"")}>
                        <li><Link to="/profile" onClick ={()=>this.mobileToggle()}>Profile</Link></li>
                        <li><Link to="/myjobs" onClick ={()=>this.mobileToggle()}>My Jobs</Link></li>
                        <li><Link to="/settings" onClick ={()=>this.mobileToggle()}>Settings</Link></li>
                        <li><a href="#" onClick={this.logout}>Log Out</a></li>
                     </ul>
                  </li>
                 </>

}

               </ul>
            </div>
         </div>
      </header>
  <a href="#/" data-toggle="modal" data-target="#postjob1" id="postjob" hidden> </a>
                <div className="modal fade common-modal-popupSite" id="postjob1" role="dialog" style={{ display: "none" }}>
                    <div className="modal-dialog" >
                        {/* <div className="container-fluid"> */}
                            <div className="col-md-12">
                                <div className="modal-content" id="ats-alert-popup">
                                    {/* <Link href="#/" data-dismiss="modal" id="modal-dismiss-postjob" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i> </Link> */}
                                    <div className="form form-mob">
                                        <div className="container-fluid jb-resume-container-fluid">
                                            <div className="col-md-12 card innerPopup">

                                                {/* <div className="head">
                                                    <h4>Do you want to login or register to post a job?</h4>
                                                </div>
                                                <div className="form-group mob-popup-btn"> */}
                                                {/* <Link to="/organization">FREE ATS</Link> */}
                                                    {/* <button className="btn btn-block sign-in-button" onClick={() => this.Signin_signup(2)}  >LOG IN</button>
                                                <button style={{ marginLeft: '30px' }} className="btn btn-block sign-in-button" onClick={() => this.Signin_signup(1)}  >REGISTER</button>
                                                </div> */}
                                                <div className="modal-header">
                                                  <h5 className="modal-title" id="exampleModalLabel">Do you want to login or register to post a job?</h5>
                                                  <button type="button" id="modal-dismiss-postjob" className="close" data-dismiss="modal" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                  </button>
                                                </div>
                                                <div className="modal-body" style={{display:'flex'}}>
                                                  <div className="col-md-8">
                                                    <button className="btn btn-block ats-sign-in-button" onClick={() => this.Signin_signup(2)}  >LOG IN <i className="fas fa-lock"></i></button>
                                                    <button className="btn btn-block ats-sign-in-button" onClick={() => this.Signin_signup(1)}  >REGISTER <i className="fas fa-pencil-alt"></i></button>
                                                  </div>
                                                    <div className="col-md-4">
                                                      <img src="/assets/images/conseltent.png" style={{maxWidth: "132%"}} />
                                                  </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
    </React.Fragment>

  );

}
}

export default withRouter(Header);