import React, { Component } from "react";
import ProfileExperience from "./ProfileExperience";
import SkillCert from "./SkillCert";
import axios from 'axios';
import { DOMAIN,GOOGLE_MAP_API_KEY, GET_BASIC_DATA, GET_SEEKERS_DATA, UPDATE_JOBSEEKERS_PROFILE,UPLOAD_PROFILE,DOWNLOAD_RESUME,UPLOAD_MY_RESUME } from '../../AppConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import ImageUploader from 'react-images-upload';
import Loader from 'react-loader';
class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            generalCheck: true,
            experienceCheck: false,
            skillCertCheck: false,

            countries: [],
            states: [],
            country: "",
            selectedCountry: "",
            countryId: "",
            state: "",
            visaTypes: [],
            visa: "",
            submit: false,

            firstname: "",
            lastname: "",
            email: "",
            phone_number: "",
            address: "",
            city: "",
            zipcode: "",
            anualSalary: "",
            qualify: "",
            payRate: "",
            designation:[],
            design:"",
            uploadActive:false,
            resumeName:"",
            resume_path:"",
            path:"",
            yoe:"",
            emailValidMsg:"",
            zipcodeValidMsg:"",
             pictures_path:"",
             picture:[],
             pictures:[],
             photo_obj:[],
             loaded:true,
             is_loader_disable:false,
             downloadUrl:"",
             invalid_file:"",
             file_label:false,
             phone_number_issue:"",
             zipcode_error:""
        }
         this.onDrop = this.onDrop.bind(this);
         this.addPhoto=this.addPhoto.bind(this);
        this.downloadResume=this.downloadResume.bind(this);
        this.zipcode_validation = this.zipcode_validation.bind(this);
    }
    onDrop(picture) {
        console.log(document.getElementsByClassName(".errorMessage "));
        console.log(picture);
        this.setState({
            pictures: this.state.pictures.concat(picture),
            photo_obj:picture,
        });

    }
    addPhoto(){
        if(this.state.photo_obj.length===0){
            this.setState({
                pictures:this.state.pictures.concat(this.state.photo_obj),
                picture:[]
            });
        }
        var formData = new FormData();
        formData.append('file', this.state.photo_obj[0]);
        formData.append('userId',localStorage.getItem('id'));
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        axios.post(DOMAIN + UPLOAD_PROFILE, formData,{
            headers: headers
        })
        .then(response => {
            document.getElementById("modal-dismiss").click();
            if (response.data.status === 200) {
                if(this.state.photo_obj.length!==0){
                if(response.data.filename.trim()!=""||response.data.filename!=null){
                    this.setState({
                        pictures_path: response.data.filename,
                        picture: [response.data.filename],
                    });
                }
            }
                else{
                    this.setState({
                        pictures_path: "",
                        picture: [],
                    });
                }
            }
            });
    }
    downloadResume(){
        // this.setState({
        //     downloadUrl:DOMAIN+'/downloadfiles'+"/"+this.state.resume_path
        // })
        // const headers = {
        //     "Content-Type": "application/json",
        //     "Accept": "application/json",
        //     "Authorization": localStorage.getItem('token')
        // }
        // axios.get(DOMAIN + DOWNLOAD_RESUME+"/"+this.state.resume_path,
        //     {
        //         headers: headers
        //     }
        // )
        //     .then(response => {

        //        if(response.data.status==200){
        //         const headers = {
        //             // "Content-Type": "application/json",
        //             // "Accept": "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
        //             // "path":this.state.resume_path
        //         }
        //         axios.get(DOMAIN + '/downloadfiles',
        //             {
        //                 headers: headers
        //             }
        //         )
        //             .then(response => {
        //             });
        //        this.setState({path:response.data.path});
        document.getElementById("download-file").href =DOMAIN+'/downloadfiles'+"/"+this.state.resume_path+"/"+localStorage.getItem('firstname')+"_"+localStorage.getItem('lastname');

                document.getElementById('download-file').click();
            //    }else if(response.data.status === 401){
            //     localStorage.clear();
            //     this.props.history.push('/login');
            //   }
            // })
            // .catch(function (error) {
            //     console.log(error);
            // })


    }
    componentWillMount() {
        if(!localStorage.getItem('id')){
            this.props.history.push('/login');
        }
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
        axios.get(DOMAIN + GET_BASIC_DATA,
            {
                headers: headers
            }
        )
            .then(response => {
                if (response.data.status === 200) {
                    this.setState({
                        countries: response.data.data.country,
                        states: response.data.data.state,
                        visaTypes: response.data.data.visaType,
                        designation:response.data.data.designation
                    })
                    const header = {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": localStorage.getItem('token')
                    }
                    axios.post(DOMAIN + GET_SEEKERS_DATA,
                        {
                            userId: localStorage.getItem('id')
                        }, {
                        headers: header
                    }
                    )
                        .then(response => {
                            if (response.data.status === 200) {
                                this.setState({
                                    firstname: response.data.data.firstname,
                                    lastname: response.data.data.lastname,
                                    email: response.data.data.email,
                                    phone_number: response.data.data.phone_number,
                                    address: response.data.data.address,
                                    city: response.data.data.city,
                                    zipcode: response.data.data.zipcode,
                                    anualSalary: response.data.data.current_salary,
                                    qualify: response.data.data.qualification,
                                    payRate: response.data.data.payrate,
                                    country: response.data.data.country,
                                    state: response.data.data.state,
                                    visa: response.data.data.policy,
                                    design:response.data.data.designation,
                                    resume_path:response.data.data.resume_location,
                                    yoe:response.data.data.year_of_experience,

                                });
                                if(response.data.data.profile_path!==null){
                                    this.setState({  picture:[response.data.data.profile_path],
                                        pictures_path:response.data.data.profile_path});
                                }else{
                                    this.setState({  picture:[],
                                        pictures_path:""});
                                }
                                (this.state.countries).forEach((value, key) => {
                                    if (this.state.country === value.id) {
                                        this.setState({
                                            country: value.id,
                                            selectedCountry: value.country,
                                            countryId: key
                                        })
                                    }
                                });

                                // if(response.data.data.designation){
                                //     this.setState({
                                //         design:response.data.data.designation
                                //     })
                                // }
                            }else if(response.data.status === 401){
                                localStorage.clear();
                                this.props.history.push('/login');
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        })
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }
    tabChange(e) {
        if (e.target.name === "general") {
            this.setState({
                generalCheck: true,
                experienceCheck: false,
                skillCertCheck: false
            })
        } else if (e.target.name === "experience") {
            this.setState({
                generalCheck: false,
                experienceCheck: true,
                skillCertCheck: false
            })
        } else if (e.target.name === "skillCert") {
            this.setState({
                generalCheck: false,
                experienceCheck: false,
                skillCertCheck: true
            })
        }
    }
    zipcode_validation(zipcode, input_country, input_city) {
        if(input_city==null){
            input_city = "";
        }
        if(input_country==null){
            input_country = "";
        }
        const header = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
        }
        axios.get('https://maps.googleapis.com/maps/api/geocode/json?key='+GOOGLE_MAP_API_KEY+'&components=postal_code:'+zipcode, {
            headers: header
        })
        .then(response => {

                if (Object.keys(response.data.results).length > 0) {
                    var address = response.data.results[0].address_components;
                    var zipcode_error = "";

                    //break up the components
                    var country = "", city = "";
                    address.forEach(function (item, index) {
                        var type = item.types[0];

                        if (city == "" && (type == "sublocality_level_1" || type == "locality")) {
                            city = (item.long_name).trim();
                        }
                        // if ($state == "" && type=="administrative_area_level_1") {
                        //     $state = trim(item.short_name);
                        // }
                        if (country == "" && type == "country") {
                            country = (item.long_name).trim();

                        }

                    })
                    if (country.toLowerCase().toString() != 'canada' && country.trim().toLowerCase().toString() != 'united states') {
                        zipcode_error = "This Zipcode is not available in this Contry and City";
                        this.setState({
                            zipcode_error : zipcode_error
                            });
                    }
                    if (((country.toLowerCase().toString()) == 'canada' && input_country != 'Canada') || ((country.toLowerCase().toString()) == 'united states' && input_country != 'United States')) {

                        zipcode_error = "Country";
                    }
                    if ((country.toLowerCase().toString()) != 'canada' && (country.toLowerCase().toString()) != 'united states') {
                        zipcode_error = "Country";
                    }
                    if ((input_city).toLowerCase() != (city.toLowerCase())) {
                        if (zipcode_error != "") {
                            zipcode_error = zipcode_error + " and City"
                        } else {
                            zipcode_error = "City";
                        }
                    }
                    if (zipcode_error != "") {
                        zipcode_error = "This Zipcode is not available in this " + zipcode_error + ". This Zipcode is available from (" + country + " and " + city + ")";

                        this.setState({
                            zipcode_error : zipcode_error
                            });
                    } else {
                        this.setState({
                            zipcode_error : zipcode_error
                            });
                    }
                }else{
                    this.setState({
                        zipcode_error : "zipcode is not valid"
                        });
                }
            })
     }
    onChange(e) {
        // if(this.state.emailValidMsg==="" && this.state.zipcodeValidMsg===""){
            this.setState({
                submit: true
            })
        // }
        if (e.target.name === 'country') {
            this.setState({
                countryId: e.target.value,
                selectedCountry: this.state.countries[e.target.value].country,
                country: this.state.countries[e.target.value].id,
            })
            this.zipcode_validation(this.state.zipcode,this.state.countries[e.target.value].country,this.state.city);
            // this.state.country=this.state.countries[e.target.value].id;
            // this.state.selectedCountry=this.state.countries[e.target.value].country;

        } else if (e.target.name === 'city') {
            this.setState({
                city: e.target.value

            })
            this.zipcode_validation(this.state.zipcode,this.state.selectedCountry,e.target.value);
            // this.state.country=this.state.countries[e.target.value].id;
            // this.state.selectedCountry=this.state.countries[e.target.value].country;

        } else if (e.target.name === 'design') {
            this.setState({
                design: e.target.value
            })

            // this.state.country=this.state.countries[e.target.value].id;
            // this.state.selectedCountry=this.state.countries[e.target.value].country;

        }else if(e.target.name==='qualify'){
            const qualifyregx = /^[^/<>%*?=^!~`:;|\/\\{}()]*$/;
            if(qualifyregx.test(e.target.value)){
                this.setState({
                    [e.target.name]: e.target.value
                });
            }else{
                this.setState({
                    [e.target.name]: this.state.qualify
                });
            }

        }
        else if(e.target.name==='zipcode'){
            const zipcoderegx = /^[^:;<>%$#&"\/\\|*()?=^@!~`]*$/;
            if(zipcoderegx.test(e.target.value) || e.target.value===""){
              this.setState({ zipcode: e.target.value,});
            }
            this.zipcode_validation(e.target.value,this.state.selectedCountry,this.state.city);
        }
        else if(e.target.name === 'anualSalary' || e.target.name === 'payRate')
        {
            const numregx = /^\d*\.?\d*$/;
            if(numregx.test(e.target.value)) {
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
            else{
                if(e.target.name === 'anualSalary'){
                    if(this.state.anualSalary==null){
                        this.setState({
                            [e.target.name]: ""
                        });
                    }else{
                        this.setState({
                            [e.target.name]: this.state.anualSalary
                        });
                    }

                }else{
                    if(this.state.payRate==null){
                        this.setState({
                            [e.target.name]: ""
                        });
                    }else{
                        this.setState({
                            [e.target.name]: this.state.payRate
                        });
                    }
                }

                // this.setState({
                //     [e.target.name]: this.state.payRate
                // })
            }
        }
         else if (e.target.name === 'phone_number') {
            const numregx = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
            if(numregx.test(e.target.value)) {
                if(e.target.value.length<9){
                    this.setState({
                        phone_number_issue:"Please enter valid phone number.Phone number must be a minimum Country Code + 10 digits !"
                    })
                }else{
                    this.setState({
                        phone_number_issue:""
                    })
                }
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
            else{
                if(this.state.phone_number==null){
                    this.setState({
                        [e.target.name]: ""
                    });
                }else{
                    this.setState({
                        [e.target.name]: this.state.phone_number
                    })
                }
            }
        } else if (e.target.name === 'firstname' || e.target.name === 'lastname' || e.target.name === 'city') {
            const strregx = /^[A-Za-z]+$/;
            if (strregx.test(e.target.value) || e.target.value === "") {
                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        } else if (e.target.name === 'address') {
            const addressregx =/^[^<>%*?=^!~`]*$/;
            if (addressregx.test(e.target.value) || e.target.value === "") {

                this.setState({
                    [e.target.name]: e.target.value
                })
            }
        } else if(e.target.name === 'email') {
            const emailregex = /^[a-zA-Z0-9.!@#$%^&*()_-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            if(emailregex.test(e.target.value)) {
                this.setState({
                    emailValidMsg: ""
                })
            } else {
                this.setState({
                    emailValidMsg: "Please enter a valid  email id !"
                })
            }
            this.setState({
                [e.target.name]: e.target.value
            })
        }
         else {
            // if (e.target.name === 'password') {
            //     if (e.target.value.length < 5) {
            //         this.setState({
            //             passwordError: "please type minimum length 5"
            //         })
            //     } else {
            //         this.setState({
            //             passwordError: ""
            //         })
            //     }
            // }
            this.setState({
                [e.target.name]: e.target.value
            })
        }
    }
    updateUserPersonal() {
        if(!this.state.zipcode){
            this.setState({
                zipcodeValidMsg:"ZipCode should not empty"
            })
        }else{
        this.setState({
            zipcodeValidMsg:""
        })
        var personaldata = this.state;
        var ProfileData = {
            id: localStorage.getItem('id'),
            country: personaldata.country,
            state: personaldata.state,
            firstname: personaldata.firstname,
            lastname: personaldata.lastname,
            email: personaldata.email,
            phone_number: personaldata.phone_number,
            address: personaldata.address,
            city: personaldata.city,
            zipcode: personaldata.zipcode,
            payrate: personaldata.payRate,
            current_salary: personaldata.anualSalary,
            policy: personaldata.visa,
            qualification: personaldata.qualify,
            designation:personaldata.design,
            year_of_experience:personaldata.yoe,
        }

        this.setState({is_loader_disable:true})
        const headers = {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": localStorage.getItem('token')
        }
        axios.post(DOMAIN + UPDATE_JOBSEEKERS_PROFILE, {
            ProfileData
        },
            {
                headers: headers
            }
        )
            .then(response => {
                this.setState({is_loader_disable:false})
                if (response.data.status === 200) {
                    toast.success("Profile updated successfully", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }else if(response.data.status === 401){
                    localStorage.clear();
                    this.props.history.push('/login');
                  }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
    }
    resumeUpload(e){
        this.setState({file_label:false,resumeName:"",uploadActive:false});
        if(e.target.files[0]!==undefined)
      {
         var ext = e.target.files[0].name.split('.').pop();
         if(ext=="pdf" || ext=="docx" || ext=="doc"){
            this.setState({
               uploadActive:true,
               resumeName:e.target.files[0].name,
               invalid_file:""
           });
        } else{
         this.setState({invalid_file:"Invalid File Format . File Format should be .pdf or .docx or .doc !"});
        }
   }
    }
    myResumeUpload(){
        var formData = new FormData();
    formData.append('file', document.getElementById('resumeUpload').files[0]);
    formData.append('userId',localStorage.getItem('id'));
    this.setState({
        loaded:false,
        is_loader_disable:true
    })
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": localStorage.getItem('token')
    }
    axios.post(DOMAIN + UPLOAD_MY_RESUME, formData,{
        headers: headers
    })
      .then(response => {
        if (response.data.status === 200) {
            this.setState({
                loaded:true,
                is_loader_disable:false,
                resumeName:"Resume"
            })
            this.setState({resume_path:response.data.filename});
            toast.success("Resume Uploaded Successfully !", {
                position: toast.POSITION.TOP_CENTER
            });
          document.getElementById('closeModel').click();
        }else if(response.data.status === 401){
            localStorage.clear();
            this.props.history.push('/login');
          }
        });
    }
    isSubmit() {
        if(this.state.emailValidMsg==="" && this.state.zipcode_error==="" && this.state.zipcodeValidMsg==="" && this.state.phone_number_issue==="" && this.state.submit===true){
            console.log(false);
            return false;
        }else{
            console.log(true);
            return true;
        }
    }
    render() {
        var uploadBtnStyle={
            color: "white",
            border: "none",
            background: "rgb(27 32 52)",
            width: "142px",
            height: "36px",
            // marginTop: "-39px",
            // marginLeft: "456px",
            // position: "absolute",


        };
        function activefile() {
            document.getElementById('resumeUpload').click();
          }
          var basicCloseStyle = {
            position: "relative",
            left: "41rem",
            top: "14px",
            zIndex: "10"
          }
        return (
            <React.Fragment>
                <ToastContainer />
                <section  className="bg-half page-next-level my-profile">
         <div  className="bg-overlay"></div>
         <div  className="container">
            <div  className="row justify-content-center">
               <div  className="col-md-6">
                  <div  className="text-center text-white">
                     <div  className="section-title text-center mb-4 pb-2">
                        <h4  className="text-uppercase title mb-4">My Profile</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section  className="section">
         <div  className="container">
            <div  className="row align-items-center">
               <div  className="col-lg-12">
               <div  className="show-results">
               <div  className="float-left mob-center pb-2">
                  <a name="general" className={'btn mr-2 btn-sm btn-my-profile mt-1' +(this.state.generalCheck?" active":"")} onClick={this.tabChange.bind(this)} checked={this.state.generalCheck}>General</a>
                  <a className={'btn mr-2 btn-sm btn-my-profile mt-1' +(this.state.experienceCheck?" active":"'")} name="experience" onClick={this.tabChange.bind(this)} checked={this.state.experienceCheck}>Experience</a>
                  <a className={'btn btn-sm btn-my-profile mt-1' +(this.state.skillCertCheck?" active":"")}  name="skillCert" onClick={this.tabChange.bind(this)} checked={this.state.skillCertCheck} >Skill and Certifications</a>
               </div>
               <div  className="sort-button float-right">

               <a  className="btn btn-sm btn-alert mr-1" data-toggle="modal" title="upload you resume here" data-target="#resumeModal" onClick={this.uploadResume} ><i  className="fa fa-upload"></i> Upload</a>
               {this.state.resume_path!=null ?
                <a  href=""  id="download-file" title="Download your resume here" className="btn btn-sm btn-location" >
                      <i onClick={this.downloadResume} className=" btn btn-sm btn-location fa fa-download">
                       </i>
                </a>
               :null}
               </div>
               </div>
               </div>
               </div>

               <div  className="row">
               {this.state.generalCheck == true ?
                  <div  className="col-lg-12">
                     <div  className="job-detail border rounded mt-2 p-4">
                     <Loader loaded={!this.state.is_loader_disable}/>
                           <div  className="row">
                              <div  className="col-lg-4">
                                 <div  className="col-md-12">
                                    <div  className="form-group position-relative">
                                       <label>Add Photo </label>
                                       <div  className="image-upload border rounded p-3 photo-height">
                                          <label htmlFor="file-input" style={{height:"100%",width:"100%"}}>
                                          {this.state.pictures_path!=""?
                                              <img src={this.state.pictures_path} id="blah" height="100%;" width="100%;"/>:<i className="fa fa-user  w-100 h-100" style={{fontSize: '109px'}} aria-hidden="true"></i>}
                                              <a data-toggle="modal" data-target="#photoModel" style={{position: "relative",
                                                right: "-64px",
                                                top: "-111px"}}> <i className="fas fa-pencil-alt"></i></a>
                                          </label>

                                      </div>
                                    </div>
                                </div>
                               <div  className="col-md-12">
                                   <div  className="form-group position-relative">
                                       <label>First name</label>
                                       <input type="text"  className="form-control" placeholder="First your first name" maxLength={20} name="firstname" onChange={this.onChange.bind(this)} value={this.state.firstname} required/>
                                   </div>
                               </div>
                               <div  className="col-md-12">
                                   <div  className="form-group position-relative">
                                       <label>Last name </label>
                                       <input type="text"  className="form-control" placeholder="Last your last name" maxLength={20} name="lastname" onChange={this.onChange.bind(this)} value={this.state.lastname} required/>
                                   </div>
                               </div>
                               <div  className="col-md-12">
                                   <div  className="form-group position-relative">
                                       <label>Email</label>
                                       <input type="email"  maxLength={50} className="form-control" placeholder="Enter your Email address" name="email" onChange={this.onChange.bind(this)} value={this.state.email} disabled={true} required/>
                                       {this.state.emailValidMsg != "" ? <span style={{ color: "red" }}>{this.state.emailValidMsg}</span> : ""}
                                       </div>
                               </div>
                               <div  className="col-md-12">
                                   <div  className="form-group position-relative">
                                       <label>Designation</label>
                                       <select  className="demo-default selectize-input form-control" name="design"  value={this.state.design} onChange={this.onChange.bind(this)}>
                                       <option value="" disabled>Choose your Designation</option>
                                        {this.state.designation.map((value, key) => {
                                            return <option key={key} value={value.value}>{value.value}</option>;
                                        })}
                                      </select>
                                   </div>
                               </div>


                              </div>
                              <div  className="col-lg-4">
                                 <div  className="col-md-12">
                                     <div  className="form-group position-relative">
                                         <label>Mobile Number</label>
                                         <input type="text"  className="form-control" placeholder="Enter your phone number" id="phone_number" minLength={9} maxLength={15} name="phone_number" onChange={this.onChange.bind(this)} value={this.state.phone_number || ''} />
                                         {this.state.phone_number_issue!=""?<p style={{color:"red"}}>{this.state.phone_number_issue}</p>:""}
                                    </div>
                                 </div>
                                 <div  className="col-md-12">
                                     <div  className="form-group position-relative">
                                         <label>Address</label>
                                         <textarea  className="form-control address-textarea" name="address" maxLength={255} onChange={this.onChange.bind(this)} value={this.state.address}>Enter your address</textarea>
                                     </div>
                                 </div>
                                 <div  className="col-md-12">
                                     <div  className="form-group position-relative">
                                         <label>City </label>
                                         <input type="text"  className="form-control" placeholder="Enter your city" onChange={this.onChange.bind(this)} value={this.state.city} maxLength={25} name="city"  required/>
                                     </div>
                                 </div>
                                 <div  className="col-md-12">
                                    <div  className="form-group position-relative">
                                        <label>Country </label>
                                        <select id=""  className="demo-default selectize-input form-control" name="country" value={this.state.countryId} onChange={this.onChange.bind(this)} >
                                        <option value="" disabled>Choose your country</option>
                                            {this.state.countries.map((value, key) => {
                                                return <option key={key} value={key}>{value.country}</option>;
                                            })}
                                       </select>
                                    </div>
                                </div>
                                <div  className="col-md-12">
                                 <div  className="form-group position-relative">
                                     <label>State </label>
                                     <select  className="demo-default selectize-input form-control" name="state" id="state" value={this.state.state} onChange={this.onChange.bind(this)}>
                                     <option value="" disabled>Choose your state</option>
                                        {this.state.states.map((value, key) => {
                                            if (value.country == this.state.selectedCountry)
                                                return <option key={key} value={value.id}>{value.state}</option>;
                                        })}
                                    </select>
                                 </div>
                             </div>

                                </div>
                                <div  className="col-lg-4">
                                 <div  className="col-md-12">
                                     <div  className="form-group position-relative">
                                         <label>Zipcode <span  className="text-danger">*</span></label>
                                         <input type="text"  className="form-control" placeholder="Enter your Zipcode"  name="zipcode" maxLength={7} onChange={this.onChange.bind(this)} value={this.state.zipcode} required/>
                                         {this.state.zipcodeValidMsg!=""?<p style={{color: "red",marginLeft: "6px"}}>{this.state.zipcodeValidMsg}</p>:""}
                                         {this.state.zipcode_error !== "" ? <div className="col-md-12 text-right"><span style={{ color: "red" }}>{this.state.zipcode_error}</span></div> : ""}
                                    </div>
                                 </div>
                                 <div  className="col-md-12">
                                     <div  className="form-group position-relative">
                                         <label>Pay Rate</label>
                                         <input type="text"  className="form-control" placeholder="Enter your pay rate" id="payRate" name="payRate"  value={this.state.payRate || ''} onChange={this.onChange.bind(this)} maxLength={10} required/>
                                     </div>
                                 </div>
                                 <div  className="col-md-12">
                                    <div  className="form-group position-relative">
                                        <label>Visa Type</label>
                                        <select  className="demo-default selectize-input form-control" name="visa" id="visa" value={this.state.visa} onChange={this.onChange.bind(this)}>
                                        <option value="" disabled>Choose your Visa-Type</option>
                                            {this.state.visaTypes.map((value, key) => {
                                                return <option key={key} value={value.id}>{value.value}</option>;
                                            })}
                                       </select>
                                    </div>
                                </div>
                                <div  className="col-md-12">
                                 <div  className="form-group position-relative">
                                     <label>Qualification</label>
                                     <input type="text"  className="form-control" maxLength={25} placeholder="Enter your qualification" name="qualify" onChange={this.onChange.bind(this)} value={this.state.qualify || ''} required/>
                                 </div>
                             </div>
                             <div  className="col-md-12">
                              <div  className="form-group position-relative">
                                  <label>Annual Salary </label>
                                  <input type="text"  className="form-control" placeholder="Enter your annual salary" name="anualSalary" maxLength={10} onChange={this.onChange.bind(this)} value={this.state.anualSalary || ''}  required/>
                              </div>
                          </div>
                          <div  className="col-md-12">
                           <div  className="form-group position-relative">
                               <label>Year of Experience </label>
                               <select className="demo-default selectize-input form-control" name="yoe" id="yoe" value={this.state.yoe} onChange={this.onChange.bind(this)}>
                               <option value="">Choose your Experience</option>
                                    {Array.from(new Array(50), (v, i) =>
                                        <option key={i} value={i}>{i}</option>
                                    )}
                              </select>
                           </div>
                       </div>
                                 <div  className="col-md-12">
                                    <button  className="btn btn-primary w-100" onClick={this.updateUserPersonal.bind(this)} disabled={this.isSubmit()}>Submit</button>
                                </div>
                                </div>
                           </div>
                     </div>
                  </div>
            :null }
             {this.state.experienceCheck == true ?
                                    <ProfileExperience /> :
                                    ""}
                                {this.state.skillCertCheck == true ?
                                    <SkillCert /> : ""}
               </div>
         </div>
      </section>

                <div className="modal fade" id="resumeModal" role="dialog" data-backdrop="static" style={{ display: "none" }}>
                                    <div className="modal-dialog">
                                        <div className="">
                                        <div className="col-md-10 col-md-offset-1">
                                            <div className="modal-content model-border-st" id="profileResumeUpload">
                                            <Loader loaded={this.state.loaded} zIndex={2e9}/>
                                            {/* <a data-dismiss="modal" id="closeModel" style={basicCloseStyle} disabled={this.state.is_loader_disable==true}><i className="fa fa-times" aria-hidden="true"></i></a> */}

                                            <div className="form form-mob">
                                                <div className="container-fluid jb-resume-container-fluid">
                                                <div className="col-md-12 card innerPopup">
                                                    <div className="modal-header">
                                                    <input type="file" name="resume" id="resumeUpload" accept=".doc, .docx, .pdf" className="form-control fs10-mob" onChange={this.resumeUpload.bind(this)} placeholder="Enter your email address" style={{ visibility: "hidden",position:"absolute" }} />
                                                        <h5 className="modal-title" id="exampleModalLabel">Upload Resume</h5>
                                                        <button type="button" id="closeModel" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                    <div className="form-group">
                                                    <button className="btn btn-block upload-button fs12-mob" style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }}  onClick={activefile} disabled={this.state.is_loader_disable==true}>{this.state.uploadActive==true?this.state.resumeName: "UPLOAD RESUME"}</button>
                                                    {/* <button className="btn btn-block sign-in-button fs12-mob mob-resume-upload" style={{ width: '100%', whiteSpace: 'normal', wordWrap: 'break-word' }} onClick={activefile} disabled={this.state.is_loader_disable==true}>{this.state.uploadActive==true?this.state.resumeName: "RESUME"}</button> */}

                                                    <span className="mob-mb15"> Max 2 MB, doc, docx, pdf<br />
                                                        We will try to fill your details</span><br />
                                                        <div className="col-md-8"></div>
                                                        <button className="btn btn-primary float-right" onClick={this.myResumeUpload.bind(this)} disabled={this.state.uploadActive==false || this.state.is_loader_disable==true}>Upload</button>
                                                        <br/> <span style={{color:"red"}}>{this.state.invalid_file}</span>
                                                    {/* <span style={{color:this.state.color}}>{this.state.EmailError}</span> */}
                                                    </div>
                                                    </div>
                                                </div>

                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>

                                    <a data-toggle="modal" data-target="#photoModel" id="alertActive" hidden></a>
                    <div className="modal fade" id="photoModel" role="dialog" style={{ display: "none" }}>
                        <div className="modal-dialog">
                            <div className="container-fluid">
                                <div className="col-md-10 col-md-offset-1">
                                    <div className="modal-content model-border-st" id="profileImageUpload">
                                        {/* <a data-dismiss="modal" id="modal-dismiss" style={{ position: "relative", left: "41rem", top: "1rem", zIndex: "3" }}><i className="fa fa-times" aria-hidden="true"></i></a> */}
                                        <div className="form form-mob">
                                            <div className="container-fluid jb-resume-container-fluid">
                                                <div className="col-md-12 card innerPopup">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Profile Upload</h5>
                                                    <button type="button" id="modal-dismiss" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="form-group">
                                                    <ImageUploader
                                                        withIcon={true}
                                                        buttonText='Choose image'
                                                        imgExtension={['.jpg', '.png']}
                                                        withPreview={true}
                                                        defaultImages={this.state.picture}
                                                        maxFileSize={5242880}
                                                        label='Max file size: 5mb, accepted: jpg|png'
                                                        singleImage={true}
                                                        fileTypeError=" is not supported file extension"
                                                        onChange={this.onDrop}
                                                        errorClass="errorMessage"
                                                        // accept="accept=image/*"
                                                    />
                                                    <center>
                                                    <button onClick={this.addPhoto} className="btn btn-block sign-in-button fs12-mob" disabled={document.getElementsByClassName('errorMessage').length>0}>Apply</button>
                                                    {/* <button style={{marginLeft: "92px"}}onClick={this.addPhoto} className="btn btn-block sign-in-button fs12-mob" >Remove</button> */}
                                                     {/* <img src={this.state.pictures_path} height="100px;" width="100px;"/> */}
                                                     </center>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                {/* <style dangerouslySetInnerHTML={{__html: `
                label{
                    font-weight: bold !important;
                }
                `}}></style> */}
            </React.Fragment>
        );
    }
}
export default Profile;