import React, { Component } from 'react';
import { Route,Switch  } from 'react-router-dom';
import Home from './components/Home/Home';
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import Joblisting from "./components/Jobs/Joblisting";
import Jobdetail from "./components/Jobs/Jobdetail";
import Myjobs from "./components/MyJobs/Myjobs";
import Settings from "./components/Settings/Settings";
import resetPassword from "./components/Login/resetPassword";
import Profile from "./components/Profile/Profile";
import AboutUs from "./components/AboutUs/AboutUs";
import ContactUs from "./components/ContactUs/ContactUs";
import Terms from "./components/PrivacyPolicy/Terms";
import Organization from "./components/Home/Organization";
import Notification from "./components/notification/notification";
import { LinkedInPopUp } from './Linkedin';


class App extends Component {

  render() {
    return (
      <React.Fragment>
        <Header/>
          <Switch>
                    <Route exact path='/' component={Home} />
                    <Route path="/linkedin" component={LinkedInPopUp} />
                    <Route path='/login' component={Login} />
                    <Route path='/signup' component={SignUp} />
                    <Route path='/joblisting' component={Joblisting} />
                    <Route path='/job/:id' component={Jobdetail} />
                    <Route path='/myjobs' component={Myjobs} />
                    <Route path='/settings' component={Settings} />
                    <Route path='/resetPassword' component={resetPassword} />
                    <Route path='/profile' component={Profile} />
                    <Route path='/aboutus' component={AboutUs} />
                    <Route path='/contactus' component={ContactUs} />
                    <Route path='/terms' component={Terms} />
                    <Route path='/organization' component={Organization} />
                    <Route path='/notification' component={Notification} />
                    </Switch>

        <Footer/>
      </React.Fragment>
    );
  }
}
export default App;
